import React, { FC } from 'react';

export type ToggleDescriptionProps = {
	title?: string;
	description?: string;
};

export const ToggleDescription: FC<ToggleDescriptionProps> = ({ title, description }) => {
	return (
		<div className={'flex flex-col gap-y-[8px]'}>
			<p className={'font-title-medium text-on-primary-container'}>{title}</p>
			{description && <p className={'font-body-medium text-neutral-80'}>{description}</p>}
		</div>
	);
};
