import React, { FC } from 'react';
import classes from './Dropdown.module.css';

import useOutsideClick from '@hooks/useOutsideClick';

export type DropdownProps = {
	header?: React.ReactNode;
	body?: React.ReactNode;
};

export const Dropdown: FC<DropdownProps> = ({ header, body }) => {
	const { isOpen, setIsOpen, ref } = useOutsideClick();

	return (
		<div className={'relative'} ref={ref}>
			<div
				className={classes['dropdown__header']}
				onClick={() => {
					setIsOpen(true);
				}}
			>
				{header}
			</div>
			{isOpen && (
				<div className={`${classes['dropdown__body']} absolute bg-primary-100 mt-[12px] w-full rounded-[8px]`}>
					{body}
				</div>
			)}
		</div>
	);
};
