import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { StoreRootState } from '../store';
import { Camera, CreateLineParams, Line } from './apiPeopleCounterTypes';

const BASE_URL = 'https://backend.peoplecounter.tevian.ru';

export const apiPeopleCounter = createApi({
	reducerPath: 'apiPeopleCounter',
	baseQuery: fetchBaseQuery({
		prepareHeaders(headers, { getState }) {
			const store = getState() as StoreRootState;
			const basicAuth = store.auth.user?.authString;
			headers.set('Authorization', `Basic ${basicAuth}`);
			return headers;
		},
		baseUrl: BASE_URL,
	}),
	tagTypes: ['Cameras', 'Camera', 'Line', 'Lines'],
	endpoints(build) {
		return {
			getConfig: build.query<unknown, void>({
				query: () => {
					return '/v1/config';
				},
			}),

			// CAMERAS
			getCamera: build.query<Camera, string>({
				query: (id) => {
					return `/v1/cameras/${id}`;
				},
				providesTags: ['Camera'],
			}),
			getCameraSnapshot: build.query<string | undefined, string>({
				queryFn: async (id, api, extraOptions, baseQuery) => {
					try {
						if (id === undefined) {
							return { data: undefined };
						}
						const response = await baseQuery({
							url: `/v1/cameras/${id}/frame`,
							method: 'GET',
							responseHandler(response) {
								return response.blob();
							},
						});

						const image = URL.createObjectURL(response.data as Blob);
						return { data: image };
					} catch (e: unknown) {
						return {
							error: { error: 'Ошибка загрузки камеры с картинки', status: `FETCH_ERROR` } as FetchBaseQueryError,
						};
					}
				},
			}),
			getCameras: build.query<Camera[], void>({
				query: () => {
					return `/v1/cameras`;
				},
				providesTags: ['Cameras'],
				transformResponse: (response: { cameras: Camera[] }) => {
					return response.cameras;
				},
			}),
			createCamera: build.mutation<Camera, Camera>({
				query: (params) => {
					return {
						url: `/v1/cameras`,
						method: 'POST',
						body: params,
					};
				},
				invalidatesTags: ['Cameras'],
			}),
			updateCamera: build.mutation<Partial<Camera>, Camera>({
				query: (params) => {
					return {
						url: `/v1/cameras/${params.id}`,
						method: 'PATCH',
						body: params,
					};
				},
				invalidatesTags: ['Cameras'],
			}),
			deleteCamera: build.mutation<void, string>({
				query: (id) => {
					return {
						url: `/v1/cameras/${id}`,
						method: 'DELETE',
					};
				},
				invalidatesTags: ['Cameras', 'Lines'],
			}),

			//LINES
			getSignalLine: build.query<Line, string>({
				query: (id) => {
					return `/v1/signal_lines/${id}`;
				},
				providesTags: ['Line'],
			}),
			getSignalLines: build.query<Line[], void>({
				query: () => {
					return `/v1/signal_lines`;
				},
				providesTags: ['Lines'],
			}),
			createSignalLine: build.mutation<Line, CreateLineParams>({
				query: ({ id, line }) => {
					return {
						url: `/v1/cameras/${id}/signal_lines`,
						method: 'POST',
						body: line,
					};
				},
				invalidatesTags: ['Cameras', 'Lines'],
			}),
			updateSignalLine: build.mutation<Partial<Line>, Line>({
				query: (params) => {
					return {
						url: `/v1/signal_lines/${params.id}`,
						method: 'PATCH',
						body: params,
					};
				},
				invalidatesTags: ['Cameras', 'Lines'],
			}),
			deleteSignalLine: build.mutation<void, string>({
				query: (id) => {
					return {
						url: `/v1/signal_lines/${id}`,
						method: 'DELETE',
					};
				},
				invalidatesTags: ['Cameras', 'Lines'],
			}),

			//EVENTS
		};
	},
});

export const {
	useGetConfigQuery,
	useLazyGetConfigQuery,
	useGetCameraQuery,
	useLazyGetCameraQuery,
	useGetCamerasQuery,
	useLazyGetCamerasQuery,
	useGetCameraSnapshotQuery,
	useLazyGetCameraSnapshotQuery,
	useGetSignalLineQuery,
	useLazyGetSignalLineQuery,
	useGetSignalLinesQuery,
	useLazyGetSignalLinesQuery,
	useCreateCameraMutation,
	useDeleteCameraMutation,
	useUpdateCameraMutation,
	useCreateSignalLineMutation,
	useUpdateSignalLineMutation,
	useDeleteSignalLineMutation,
} = apiPeopleCounter;
