import { FC } from 'react';
import classes from './DetectionPreview.module.css';
import { Icon, IconName } from '../Icon/Icon';

type DetectionPreviewProps = JSX.IntrinsicElements['div'] & {
	size?: 's' | 'm';
	icon?: IconName;
	title?: string;
	description?: string;
	isActive?: boolean;
};

export const DetectionPreview: FC<DetectionPreviewProps> = ({
	icon,
	title,
	description,
	size = 'm',
	isActive = false,
	className: propClassName,
	...propsProxy
}) => {
	const classList = [classes['card'], classes[size], classes[isActive ? 'active' : ''], propClassName];
	const className = classList.filter(Boolean).join(' ').trim();

	return (
		<div className={className} {...propsProxy}>
			{icon && <Icon name={icon} />}
			<div className={classes['card-textblock']}>
				{title && <p className={'font-headline-xsmall pb-2'}>{title}</p>}
				{description && <p className={'font-body-large text-neutral-40 break-normal'}>{description}</p>}
			</div>
		</div>
	);
};
