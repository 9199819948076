import { useLocation } from 'react-router-dom';
import {
	useGetBucketConfigQuery,
	useGetBucketFacesQuery,
	useGetCamerasByBucketQuery,
	useLazyFindInBaseQuery,
} from '@store/api';
import { useEffect, useState } from 'react';
import { isBefore } from 'date-fns';
import { CameraZone } from '@components/camera/Monitoring/MonitoringSlot/CameraZoneSelector/CameraZoneSelector.typedef';

type FaceList = {
	face_id: number;
	identity: string;
	tags: string[];
}[];
type Match = {
	faceId: number;
	identity: string;
	tags: string[];
	camera_uuid: string;
	camera_name: string;
	similarity: number;
	timestamp: string;
	quality?: number;
};
type UseResultPageResult = {
	isEmpty: boolean;
	matches: Match[];
	isLoading: boolean;
	bucketConfig?: {
		bucket_name: string;
		caption: string;
		tags: string[];
		timestamp_created?: string;
		timestamp_modified?: string;
	};
	onChangeCameras: (cameras: string) => void;
	chosenCameraURL?: string;
	zones: CameraZone[];
};

const SIMILARITY = 0.9;

export const useResultPage = (): UseResultPageResult => {
	const [isLoading, setIsLoading] = useState(false);
	const [matches, setMatches] = useState<Match[]>([]);
	const [findInBase] = useLazyFindInBaseQuery();
	const { search } = useLocation();
	const bucket_id = new URLSearchParams(search).get('bucket_id') ?? undefined;
	const startDate = new URLSearchParams(search).get('startDate') ?? undefined;
	const endDate = new URLSearchParams(search).get('endDate') ?? undefined;

	const { currentData: camerasData } = useGetCamerasByBucketQuery(bucket_id ?? '', { skip: bucket_id === undefined });
	const cameras = (camerasData?.cameras ?? []).map((camera) => {
		return camera.camera_uuid;
	});

	const [chosenCameraURL, setChosenCameraURL] = useState<string>();
	const onChangeCameras = (cameras: string) => {
		setChosenCameraURL(cameras);
	};

	useEffect(() => {
		if (camerasData?.cameras) {
			setChosenCameraURL(cameras[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [camerasData?.cameras]);

	const { currentData: facesData } = useGetBucketFacesQuery(bucket_id ?? '', { skip: bucket_id === undefined });

	const faces: FaceList = facesData?.faces ?? [];

	const { currentData: bucketConfig } = useGetBucketConfigQuery(bucket_id ?? '', { skip: bucket_id === undefined });

	const confidence = bucketConfig
		? Number(
				(
					bucketConfig.tags.find((item) => {
						return item.includes('confidence');
					}) ?? ''
				).replace('confidence:', ''),
		  ) / 100
		: SIMILARITY;

	useEffect(() => {
		if (camerasData === undefined || facesData === undefined) {
			return;
		}
		setIsLoading(true);

		Promise.all(
			faces.map(async ({ face_id, tags, identity }) => {
				const { data } = await findInBase({
					bucket_name: bucket_id ?? '',
					face_id: face_id,
					threshold: confidence,
					limit: 100,
					sort_key: '-timestamp',
					camera: chosenCameraURL ? chosenCameraURL : cameras[0],
					min_ts: startDate === undefined ? undefined : new Date(startDate).toISOString(),
					max_ts: endDate === undefined ? undefined : new Date(endDate).toISOString(),
				});

				return { ...data, tags, identity };
			}),
		)
			.then((r) => {
				const matches = r.reduce<Match[]>((acc, currentValue) => {
					if (currentValue === undefined) return acc;
					const matches: Match[] = (currentValue.history_matches ?? []).map((match) => {
						return {
							camera_name: match.camera_name,
							camera_uuid: match.camera_uuid,
							faceId: match.face_id,
							similarity: match.similarity,
							identity: currentValue.identity,
							tags: currentValue.tags,
							timestamp: match.timestamp,
						};
					});
					return acc.concat(matches);
				}, []);
				const sortedMatches = matches.sort((a, b) => {
					return isBefore(new Date(a.timestamp), new Date(b.timestamp)) ? 1 : -1;
				});
				setMatches(sortedMatches);
			})
			.finally(() => {
				setIsLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [facesData, chosenCameraURL]);

	const zones = (camerasData?.cameras ?? []).reduce<CameraZone[]>((accumulator, item) => {
		accumulator.push({
			id: item.camera_uuid,
			title: item.camera_name,
			isOff: false,
		});
		return accumulator;
	}, []);

	const isEmpty = bucket_id === undefined || facesData === undefined;

	return { isEmpty, matches, isLoading, bucketConfig, onChangeCameras, chosenCameraURL, zones };
};
