import React, { FC, PropsWithChildren } from 'react';
import classes from './MenuDrawer.module.css';
import { Icon, IconName } from '@components/uikit/Icon/Icon';
import { Link, useLocation } from 'react-router-dom';

export type DrawerLinkProps = PropsWithChildren & {
	icon?: IconName;
	path: string;
};

export const DrawerLink: FC<DrawerLinkProps> = ({ icon, path, children }) => {
	const location = useLocation();
	const matchLevel = 0;

	const currentDirs = location.pathname.split('/').filter(Boolean);
	const pathDirs = path.split('/').filter(Boolean);
	const active = currentDirs[matchLevel] === pathDirs[matchLevel];

	const classList = [classes['link']];
	active && classList.push(classes['link--active']);
	const className = classList.filter(Boolean).join(' ').trim();

	return (
		// eslint-disable-next-line jsx-a11y/anchor-is-valid
		<Link to={path} className={className}>
			{icon ? (
				<Icon className={'flex-shrink-0'} name={icon} width={20} height={20} />
			) : (
				<div className={'w-[20px] h-[20px] flex-shrink-0'} />
			)}
			<span className={classes['link__label']}>{children}</span>
		</Link>
	);
};
