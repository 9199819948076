import React, { PropsWithChildren, useState } from 'react';
import { Popover } from '@components/uikit/Popover/Popover';
import { Button } from '@components/uikit/Button/Button';
import { Icon } from '@components/uikit/Icon/Icon';
import classes from './CameraZoneSelector.module.css';
import { CameraZoneSelectorProps } from '@components/camera/Monitoring/MonitoringSlot/CameraZoneSelector/CameraZoneSelector.typedef';
import CameraZone from '@components/camera/Monitoring/MonitoringSlot/CameraZoneSelector/CameraZone/CameraZone';

const CameraZoneSelector = ({
	zones,
	onZoneClick,
	children = <>Выбрать камеру</>,
}: PropsWithChildren<CameraZoneSelectorProps>) => {
	const renderZones = (
		<div className="overflow-auto max-h-[300px] ">
			{zones.map((zone) => {
				return (
					<CameraZone
						key={zone.id}
						zone={zone}
						onZoneClick={() => {
							onZoneClick(zone.id);
							toggleIsOpen(false);
						}}
					/>
				);
			})}
		</div>
	);

	const [isOpen, toggleIsOpen] = useState(false);
	return (
		<>
			{zones.length > 0 && (
				<Popover positions={['bottom']} content={renderZones} isOpen={isOpen}>
					{() => {
						return (
							<Button
								disabled={zones.length === 0}
								size={'xs'}
								variant={'dark'}
								className={classes['button']}
								style={{ paddingBottom: '4px', paddingTop: '4px' }}
								onClick={() => {
									toggleIsOpen((prev) => {
										return !prev;
									});
								}}
							>
								{children}
								<Icon className={isOpen ? 'rotate-180' : ''} name={'chevronCompactDown'} />
							</Button>
						);
					}}
				</Popover>
			)}
		</>
	);
};

export default CameraZoneSelector;
