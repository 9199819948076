import { RefObject, useEffect, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { useUserActivation } from '@hooks/useUserActivation';
import { usePlayState } from '@hooks/react-player/usePlayState';

/**
 * Хук для автоматического проигрывания видео.
 * @param ref - ref на ReactPlayer.
 * @returns
 * Возвращает состояние хука usePlayState и props'ы для ReactPlayer.
 */
export function useAutoplay(ref: RefObject<ReactPlayer>) {
	const { playing, error, isFatalError, props } = usePlayState();

	const [isReady, setIsReady] = useState(false);

	// Автоматическое проигрывание запускаем только один раз (если пользователь хочет остановить видео, не будем запрещать ему этого делать).
	// В противном случае автоматическое проигрывание будет срабатывать когда пользователь захочет поставить видео на паузу.
	const [autoplayDone, setAutoplayDone] = useState(false);

	const onReady: ReactPlayerProps['onReady'] = () => {
		setIsReady(true);
	};

	const activation = useUserActivation();

	useEffect(() => {
		if (!activation || !isReady || playing || autoplayDone) {
			return;
		}

		const reactPlayer = ref.current;
		if (!reactPlayer) {
			console.warn('[useAutoplay] Не удалось запустить авто проигрывание, ref.current не определен!');
			return;
		}

		const internalPlayer = reactPlayer.getInternalPlayer() as HTMLVideoElement;
		'play' in internalPlayer && internalPlayer.play();
		setAutoplayDone(true);
	}, [isReady, activation, playing, ref, autoplayDone]);

	return {
		playing,
		error,
		isFatalError,
		props: {
			...props,
			onReady,
			autoPlay: true,
		},
	};
}
