import React, { ReactNode, useEffect, useRef, useState } from 'react';
import classes from './EventPreviewImage.module.css';
import classNames from 'classnames';
import { useLazyGetEventImageOverviewQuery } from '@store/api';
import { Border } from '@components/uikit/Border/Border';
import { useStoreSelector } from '@store/store';

export const EventPreviewImage = () => {
	const eventPrevieData = useStoreSelector((store) => {
		return store.events.eventPreviewData;
	});

	const [getImage, { data: imageUrl }] = useLazyGetEventImageOverviewQuery();

	const [box, setBox] = useState<ReactNode>();

	const imgRef = useRef<HTMLImageElement>(null);

	useEffect(() => {
		if (eventPrevieData) {
			getImage({
				type: eventPrevieData.type,
				id: eventPrevieData.eventId,
			});
		}
	}, [eventPrevieData, getImage]);

	if (imageUrl === undefined) return <></>;

	return (
		<div className={classNames(classes['container'], eventPrevieData?.eventId ? classes['container-active'] : '')}>
			<img
				className={classes['image']}
				alt={'preview event'}
				src={imageUrl}
				ref={imgRef}
				onLoad={() => {
					setBox(<Border box={eventPrevieData?.box} ref={imgRef} />);
				}}
			/>
			{box}
		</div>
	);
};
