import { RuntimeEnv } from '@modules/runtime-env/RuntimeEnv';

/**
 * Добавляет API URL к пути (относительный путь берем из переменной окружения REACT_APP_API_URL)
 * @param {string} path - Путь, добавляемый в начало
 * @example prependApiUrl('auth/login') // http://localhost:5000/auth/login
 * @returns {string} Полный путь запроса
 * @description В случае когда передаваемый параметр пустая строка - возвращает пустую строку
 */
export function prependApiUrl(path: string) {
	let url = RuntimeEnv.getEnv('REACT_APP_API_URL');
	if (!path) {
		return '';
	}

	if (path.includes(url)) {
		return path;
	}
	if (path.startsWith('/') && url.endsWith('/')) {
		url = url.slice(0, -1);
		return url + path;
	}
	if (!path.startsWith('/') && !url.endsWith('/')) {
		return url + '/' + path;
	}
	return url + path;
}
