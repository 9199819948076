import { EventDataFace } from '@hooks/useEventsPage';
import { genderData, genderName, glassesData, hairData, maskData, raceData } from '@modules/events/mappedValues';
import { StoreEvent } from '@store/slices/eventsSlice';
import { zonedTimeToUtc } from 'date-fns-tz';

export const personMapper = (eventData: EventDataFace, face_id: number, bucket_id?: string): StoreEvent => {
	const personMatchBucket = eventData.bucket_matches.find((element) => {
		return element.bucket_name === bucket_id;
	});
	const [personMatchAll] = eventData.bucket_matches;
	const utcDate = zonedTimeToUtc(new Date(eventData.timestamp_rfc ?? eventData.timestamp), 'Europe/Moscow');

	return {
		camera_name: eventData.camera_name,
		camera_uuid: eventData.camera_uuid ?? '',
		id: face_id,
		identity: personMatchBucket?.identity ?? personMatchAll?.identity ?? genderName[eventData.gender ?? 'male'],
		similarity: personMatchBucket?.similarity ?? personMatchAll?.similarity ?? NaN,
		tags: personMatchBucket?.tags ?? personMatchAll?.tags ?? [],
		timestamp: utcDate.toISOString(),
		timestamp_local: eventData.timestamp_local,
		quality: eventData.quality ?? NaN,
		age: eventData.age ? eventData.age : undefined,
		gender: eventData.gender ? genderData[eventData.gender] : undefined,
		glasses: eventData.glasses ? glassesData[eventData.glasses] : undefined,
		facial_hair: eventData.facial_hair ? hairData[eventData.facial_hair] : undefined,
		mask: eventData.mask ? maskData[eventData.mask] : undefined,
		race: eventData.race ? raceData[eventData.race] : undefined,
		box: eventData.overview_bbox,
		type: 'face' as const,
	};
};
