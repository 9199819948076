import { SelectOption, SelectProps } from '@components/uikit/Select/Select.typedef';
import { FC } from 'react';
import { Select } from '../Select';
import { timezones } from '@constants/utcZones';

const options: NonNullable<SelectOption>[] = timezones;

type CameraModalSelectProps = Pick<
	SelectProps,
	'size' | 'label' | 'className' | 'isClearable' | 'placeholder' | 'isDisabled' | 'error'
> & {
	zone?: number;
	containerClassName?: string;
	handleChange: (e: number) => void;
	disabled: boolean;
};

export const CameraModalSelect: FC<CameraModalSelectProps> = ({
	containerClassName,
	zone,
	handleChange,
	disabled,
	...selectProps
}) => {
	const selectedConfig = options?.find(({ value }) => {
		return +value === zone;
	});

	const onChange = (e: SelectOption) => {
		if (!e) {
			return;
		}

		handleChange(+e?.value);
	};

	return (
		<Select<SelectOption>
			isClearable={true}
			isSearchable={true}
			placeholder={'Временная зона'}
			options={options}
			menuPlacement="auto"
			onChange={onChange}
			className={disabled ? 'opacity-50 pointer-events-none' : ''}
			value={selectedConfig ?? null}
			{...selectProps}
		/>
	);
};
