import React, { FC, PropsWithChildren, ReactElement, ReactNode } from 'react';
import { Button } from '@components/uikit/Button/Button';
import { Icon } from '@components/uikit/Icon/Icon';
import { ControlSidebarProps } from '@components/uikit/ControlSidebar/ControlSidebar';
import { useNavigate } from 'react-router-dom';

export type DashboardContentLayoutProps = PropsWithChildren & {
	headerContent?: ReactNode;
	headerContentClassName?: HTMLDivElement['className'];
	showBackButton?: boolean;
	title?: string;
	sidebar?: ReactElement<ControlSidebarProps>;
	drawDivider?: boolean;
	overrideHeaderRenderer?: (() => ReactNode) | JSX.Element;
};

export const DashboardContentLayout: FC<DashboardContentLayoutProps> = ({
	headerContent,
	headerContentClassName = 'overflow-hidden',
	children,
	sidebar,
	showBackButton = true,
	title,
	drawDivider = true,
	overrideHeaderRenderer,
}) => {
	const headerClassList = ['flex justify-between items-center gap-x-[16px]'];
	drawDivider && headerClassList.push('pb-[23px] border-b border-b-neutral-90');
	const headerClassName = headerClassList.join(' ');

	const navigate = useNavigate();

	return (
		<main className={'flex flex-col h-full'}>
			<header className={headerClassName}>
				{!overrideHeaderRenderer && (
					<>
						<div className={'flex gap-x-[16px] items-center'}>
							{showBackButton && (
								<Button variant={'grey'} size={'l'} onClick={navigate.bind(null, -1)}>
									<Icon name={'arrowLeftShort'} width={24} height={24} />
									Назад
								</Button>
							)}
							{title && <h1 className={'font-display-small text-primary-0 whitespace-nowrap'}>{title}</h1>}
						</div>
						{headerContent && <div className={headerContentClassName}>{headerContent}</div>}
					</>
				)}
				{overrideHeaderRenderer &&
					(typeof overrideHeaderRenderer === 'function' ? overrideHeaderRenderer() : overrideHeaderRenderer)}
			</header>
			<div className={'flex flex-grow gap-x-[56px] pt-[32px]'}>
				<section className={'flex-grow break-all'}>{children}</section>
				{sidebar}
			</div>
		</main>
	);
};
