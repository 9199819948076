import React from 'react';
import { Icon } from '../Icon/Icon';

export const NotFound = () => {
	return (
		<div
			className={
				'flex justify-center items-center h-[200px] gap-4 p-4 border border-primary-95 rounded-2 text-secondary'
			}
		>
			<Icon name="userSearch"></Icon>
			<div>
				<h3 className={'font-headline-xsmall'}>Ничего не найдено</h3>
				<p className={'opacity-70'}>Попробуйте загрузить другой файл</p>
			</div>
		</div>
	);
};
