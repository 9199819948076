import { EventDataPlate } from '@hooks/useEventsPage';
import { purposeData } from '@modules/events/mappedValues';
import { StoreEvent } from '@store/slices/eventsSlice';
import { getCountryNameByAlphe2Code } from '@utils/getCountryNameByAlpha2Code';
import { zonedTimeToUtc } from 'date-fns-tz';

export const plateMapper = (eventData: EventDataPlate, plate_id: number, bucket_id?: string): StoreEvent => {
	const plateMatchBucket = eventData.bucket_matches.find((element) => {
		return element.bucket_name === bucket_id;
	});
	const [plateMatchAll] = eventData.bucket_matches;
	const utcDate = zonedTimeToUtc(new Date(eventData.timestamp_rfc ?? eventData.timestamp), 'Europe/Moscow');
	return {
		camera_name: eventData.camera_name,
		camera_uuid: eventData.camera_uuid ?? '',
		type: 'plate' as const,
		id: plate_id,
		identity: plateMatchBucket?.identity ?? plateMatchAll?.identity ?? 'Незнакомый номер',
		similarity: plateMatchBucket?.similarity ?? plateMatchAll?.similarity ?? NaN,
		tags: plateMatchBucket?.tags ?? plateMatchAll?.tags ?? [],
		timestamp: utcDate.toISOString(),
		timestamp_local: eventData.timestamp_local,
		quality: eventData.plate?.quality,
		occlusion: eventData.plate?.occlusion,
		valid: eventData.plate?.valid,
		box: eventData.overview_bbox,
		plate: eventData.plate?.text,
		country: getCountryNameByAlphe2Code(eventData.plate?.country_iso_alpha2),
		countryCode: eventData.plate?.country_iso_alpha2,
		category: eventData.plate?.category,
		vehicle: eventData.vehicle
			? {
					category: eventData.vehicle.category ?? '',
					purpose: purposeData[eventData.vehicle.purpose ?? 'other'],
					color: eventData.vehicle.color ?? '',
					visibility: eventData.vehicle.visibility ?? undefined,
			  }
			: undefined,
	};
};
