import React, { FC, PropsWithChildren } from 'react';
import { Icon, IconName } from '@components/uikit/Icon/Icon';

export type ArchiveLabelProps = PropsWithChildren & {
	icon: IconName;
	opacity?: number;
};

/**
 * Компонент для отображения метки архива
 * @param icon - Иконка
 * @param opacity - Прозрачность
 * @param children - Описание (строка)
 */
export const ArchiveLabel: FC<ArchiveLabelProps> = ({ icon, opacity = 0.5, children }) => {
	return (
		<p
			className={'flex flex-shrink-0 gap-x-[4px] items-center'}
			style={{
				opacity: opacity.toString(),
			}}
		>
			<Icon className={'text-on-secondary-container flex-shrink-0'} name={icon} width={16} height={16} />
			<span className={'font-body-medium text-on-secondary-container'}>{children}</span>
		</p>
	);
};
