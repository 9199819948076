import React, { FC } from 'react';
import classes from './Button.module.css';
import { Button, ButtonProps } from '@components/uikit/Button/Button';
import { Icon, IconName } from '@components/uikit/Icon/Icon';

export type IconButtonProps = Omit<ButtonProps, 'children'> & {
	icon: IconName;
};

export const IconButton: FC<IconButtonProps> = ({ className: propClassName, icon, ...propsProxy }) => {
	const classList = [classes['only-icon'], propClassName];
	const className = classList.filter(Boolean).join(' ').trim();
	return (
		<Button {...propsProxy} className={className}>
			<Icon name={icon} />
		</Button>
	);
};
