import React, { FC } from 'react';
import { Select } from '../Select';
import { SelectProps } from '@components/uikit/Select/Select.typedef';
import { useGetCamerasQuery } from '@store/api';
import { Spinner } from '@components/uikit/Spinner/Spinner';

export type CameraSelectOption = {
	value: string;
	label: string;
};

type CameraSelectProps = Pick<
	SelectProps,
	'size' | 'label' | 'className' | 'isClearable' | 'placeholder' | 'isSearchable' | 'error'
> & {
	selectedCameraId?: string;
	selectedCameraIds?: string[];
	onChange?: (selectedValue: CameraSelectOption) => void;
	containerClassName?: string;
	filterCameras?: (cameras: unknown) => boolean;
};

export const CameraSelect: FC<CameraSelectProps> = ({
	selectedCameraId,
	selectedCameraIds,
	containerClassName,
	filterCameras,
	...selectProps
}) => {
	const { currentData, isLoading } = useGetCamerasQuery({
		offset: 0,
		limit: 100,
		with_ro_cameras: false,
	});

	const cameras: Array<CameraSelectOption> =
		currentData === undefined
			? []
			: currentData.data.reduce<Array<CameraSelectOption>>((acc, cam) => {
					if (cam.camera_name === undefined) {
						return acc;
					}

					return [
						...acc,
						{
							value: cam.camera_uuid,
							label: cam.camera_name,
						},
					];
			  }, []);

	const cameraOptionsFiltered = cameras.filter((camera) => {
		return !(selectedCameraIds ?? []).includes(camera.value);
	});

	const selectedCamera = cameras?.find(({ value }) => {
		return value === selectedCameraId;
	});

	return (
		<div className={['relative', containerClassName].filter(Boolean).join(' ')}>
			{isLoading && <Spinner />}
			<Select<CameraSelectOption>
				isClearable
				valueContainerIcon={'cameraVideo'}
				placeholder={cameraOptionsFiltered.length === 0 ? 'Нет доступных камер' : 'Камера'}
				/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
				// @ts-ignore
				onChange={selectProps.onChange}
				{...selectProps}
				options={cameraOptionsFiltered}
				value={selectedCamera ?? null}
				isDisabled={cameraOptionsFiltered.length === 0}
			/>
		</div>
	);
};
