import React, { FC } from 'react';
import { Select } from '../Select';
import { SelectProps } from '@components/uikit/Select/Select.typedef';
import { useEventsParams } from '@hooks/useEventsParams';

export type EventTypeSelectType = {
	value: string;
	label: string;
};

const values: EventTypeSelectType[] = [
	{
		value: 'new',
		label: 'Новые события',
	},
	{
		value: 'old',
		label: 'Архированные события',
	},
];

export type EventFilterTypeValues = {
	type: string;
};

type EventTypeSelectProps = Pick<
	SelectProps,
	'size' | 'label' | 'className' | 'isClearable' | 'placeholder' | 'isSearchable' | 'error'
> & {
	selectedDuration?: string | null;
	containerClassName?: string;
};

export const EventTypeSelect: FC<EventTypeSelectProps> = ({ selectedDuration, containerClassName, ...selectProps }) => {
	const { filterParams, onEventTypeChange } = useEventsParams();

	const selectedItem = values?.find(({ value }) => {
		return filterParams.type === value;
	});

	return (
		<div className={['relative', containerClassName].filter(Boolean).join(' ')}>
			<Select<EventTypeSelectType>
				valueContainerIcon={'bell'}
				placeholder={'Тип событий'}
				/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
				// @ts-ignore
				onChange={(e) => {
					e && onEventTypeChange({ type: e?.value });
				}}
				{...selectProps}
				options={values}
				value={selectedItem ?? null}
			/>
		</div>
	);
};
