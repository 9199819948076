import React, { useState } from 'react';
import { ArchiveLabel } from '@components/uikit/ArchiveLabel/ArchiveLabel';
import { Badge } from '@components/uikit/Badge/Badge';
import { Icon } from '@components/uikit/Icon/Icon';
import { OverviewImageModal } from '@components/uikit/OverviewImageModal/OverviewImageModal';

type RecognizedCardProps = {
	imageUrl: string | undefined;
	subTitle: string;
	title: string;
	description: string;
	tags: string[];
	city?: string;
	location?: string;
	camera?: string;
	similarity: string;
	isLowQuality: boolean;
};
const RecognizedCard = ({
	subTitle,
	title,
	description,
	tags,
	imageUrl,
	camera,
	location,
	city,
	similarity,
	isLowQuality,
}: RecognizedCardProps) => {
	const [isOpenOverviewImageModal, setIsOpenOverviewImageModal] = useState<boolean>(false);

	const id = +subTitle.slice(3);

	const handleButtonClick = () => {
		setIsOpenOverviewImageModal(true);
	};

	const handleCloseModal = () => {
		setIsOpenOverviewImageModal(false);
	};

	return (
		<>
			<div
				className={
					'flex flex-col p-4 gap-4 border-[1px] rounded-[12px] border-solid border-outline-variant bg-surface-bright w-max'
				}
			>
				<div className={'w-[456px] h-[259px] relative'}>
					{imageUrl === undefined ? (
						<Icon name="spinner" />
					) : (
						<div className={'relative'}>
							<img className={'rounded-[2px] object-cover w-[456px] h-[259px]'} src={imageUrl} alt="изображение" />
							<button
								onClick={handleButtonClick}
								className={'absolute bottom-4 right-4 rounded-2 border border-on-primary p-1'}
							>
								<Icon className={'text-on-primary'} name={'fullscreen'} width={24} height={24} />
							</button>
						</div>
					)}
					{isLowQuality && (
						<Badge
							className={'!rounded absolute top-[12px] right-[12px]'}
							color={'error'}
							variant={'contained'}
							size={'sm'}
						>
							{'Качество фотографии ниже 50%'}
						</Badge>
					)}
				</div>

				<div className={'flex flex-col gap-1'}>
					<div className={'flex justify-between items-center self-stretch'}>
						<span className={'text-on-background font-title-large'}>{title}</span>
						<span className={'font-body-medium text-secondary-50'}>{subTitle}</span>
					</div>
					<div className={'flex gap-2 self-stretch p-[6px_0px_2px_0px]'}>
						{tags.length ? (
							<div className={'flex gap-2 items-center self-stretch'}>
								<span className={'font-label-medium text-on-primary-container'}>Теги: </span>
								{tags.map((tag) => {
									return (
										<Badge key={tag} className={'!rounded'} color={'primary'} size={'sm'} variant={'contained'}>
											{tag}
										</Badge>
									);
								})}
							</div>
						) : undefined}
						<div className={'flex gap-2 items-center'}>
							<span className={'font-label-medium text-on-primary-container'}>{'Сходство:'}</span>
							<Badge className={'!rounded'} color={'secondary'} size={'sm'} variant={'contained'}>
								{similarity}
							</Badge>
						</div>
					</div>
					<div className={'flex gap-3 items-center p-[4px_0px_8px_0px]'}>
						{/* <ArchiveLabel icon={'geoAlt'}>{city}</ArchiveLabel> */}
						{/* <ArchiveLabel icon={'building'}>{location}</ArchiveLabel> */}
						<ArchiveLabel icon={'cameraVideo'}>{camera}</ArchiveLabel>
					</div>
					<p className={'text-secondary-40 font-body-small'}>{description}</p>
				</div>
				{isOpenOverviewImageModal && (
					<OverviewImageModal faceId={id} isOpen={isOpenOverviewImageModal} onClose={handleCloseModal} />
				)}
			</div>
		</>
	);
};

export default RecognizedCard;
