import React, { FC, useEffect } from 'react';
import { Spinner } from '@components/uikit/Spinner/Spinner';
import { useFindInArchivePlateParamsQuery } from '@store/api';
import { SearchCarCard } from './SearchCarCard';

type SearchCarResultProps = {
	plate: string;
	camera: string[] | string;
	startDate?: string;
	endDate?: string;
	setResults: React.Dispatch<React.SetStateAction<number[]>>;
};
export const SearchCarResult: FC<SearchCarResultProps> = ({ plate, camera, startDate, endDate, setResults }) => {
	const { currentData, isLoading } = useFindInArchivePlateParamsQuery(
		{
			plate_text: plate,
			camera: camera,
			min_ts: startDate ? new Date(startDate).toISOString() : undefined,
			max_ts: endDate ? new Date(endDate).toISOString() : undefined,
		},
		{ skip: !camera || !camera.length },
	);

	useEffect(() => {
		if (currentData)
			setResults((prev) => {
				return [...prev, currentData.data.length];
			});
	}, [currentData, setResults, camera]);

	return (
		<>
			{isLoading && <Spinner />}
			{!isLoading &&
				currentData &&
				currentData.data.map((item) => {
					return (
						<SearchCarCard
							key={item.plate_id}
							eventId={item.plate_id}
							title={item.plate.text}
							description={item.timestamp}
							occlusion={item.plate.occlusion}
							box={item.overview_bbox}
							country={item.plate.country_iso_alpha2}
							vehicle_box={item.vehicle_bbox}
						/>
					);
				})}
		</>
	);
};
