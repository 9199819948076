import React from 'react';
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import { TextInput } from '@components/uikit/TextInput/TextInput';

import styles from './RangeInput.module.css';
import './RangeInputNoUiSlider.css';

export type RangeInputProps = {
	error?: string;
	min: number;
	max: number;
	step?: number;
	value?: {
		start: number;
		end: number;
	};
	onChange: (start: number, end: number) => void;
};
type OnUpdate = [string, string];

export const RangeInput = ({
	error,
	min,
	max,
	step = 1,
	value = { start: 20, end: 80 },
	onChange,
}: RangeInputProps) => {
	const onStartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(+e.target.value, value.end);
	};

	const onEndChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(value.start, +e.target.value);
	};

	const onChangeHandlerNoUiSlider = (e: unknown) => {
		const value = e as OnUpdate;
		onChange(+value[0], +value[1]);
	};

	return (
		<div className={'flex flex-col gap-3'}>
			<div className={styles.range}>
				<span>{min}</span>
				<Nouislider
					range={{ min: min, max: max }}
					start={[value.start, value.end]}
					connect
					step={step}
					onChange={onChangeHandlerNoUiSlider}
				/>
				<span className="ml-3">{max}</span>
			</div>

			<div className={styles.inputs}>
				<TextInput
					variant={'bordered'}
					size={'s'}
					type={'number'}
					value={value.start}
					name="start"
					step={step}
					min={min}
					max={max}
					onChange={onStartChange}
					error={error}
				/>
				<TextInput
					variant={'bordered'}
					size={'s'}
					type={'number'}
					value={value.end}
					name="end"
					min={min}
					max={max}
					step={step}
					onChange={onEndChange}
					error={error}
				/>
			</div>
			{error && <p className="text-error font-label-medium">{error}</p>}
		</div>
	);
};
