import React, { useRef } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import html2canvas from 'html2canvas';
import { VideoPlayer } from './VideoPlayer';

interface VideoScreenshotProps extends ReactPlayerProps {
	url?: string;
	onScreenshot?: (base64: string) => void;
}

export const VideoScreenshot: React.FC<VideoScreenshotProps & { tryToReload?: boolean }> = ({
	url,
	tryToReload,
	onScreenshot,
	...rest
}) => {
	const playerRef = useRef<ReactPlayer>(null);

	const captureScreenshot = () => {
		if (onScreenshot) {
			if (playerRef.current) {
				const playerElement = playerRef.current?.getInternalPlayer() as HTMLElement;
				if (playerElement) {
					html2canvas(playerElement)
						.then((canvas) => {
							onScreenshot?.(canvas.toDataURL('image/png'));
						})
						.catch(console.error);
				}
			}
		}
	};

	const Player = VideoPlayer;

	return <Player url={url} ref={playerRef} {...rest} onStart={captureScreenshot} />;
};
