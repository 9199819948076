import React, { FC, useState } from 'react';
import { FacesStage } from '@pages/search-database/config/FacesStage';
import FaceStageSearchConfig from '@pages/search-database/config/FaceStageSearchConfig';
import { useBucketName } from '@hooks/useBucketName';

export const SearchDatabaseCreateFacesConfigPage: FC = () => {
	const [stage, setStage] = useState<number>(() => {
		return 0;
	});

	const onStepClickHandler = (id: number) => {
		[0, 1].includes(id) && setStage(id);
	};

	const { bucketName } = useBucketName();

	return (
		<>
			{bucketName === undefined && <>Создание картотек недоступно</>}
			{bucketName !== undefined && (
				<>
					<div className={stage === 0 ? '' : `hidden`}>
						<FacesStage bucketName={bucketName} onStepClick={onStepClickHandler} />
					</div>
					<div className={stage === 1 ? '' : `hidden`}>
						<FaceStageSearchConfig bucketName={bucketName} onStepClick={onStepClickHandler} />
					</div>
				</>
			)}
		</>
	);
};
