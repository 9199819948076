export const typeData = ['RTSP', 'Youtube', 'HTTP/HTTPS'] as const;

export const urlToType = (url: string | undefined) => {
	if (!url) {
		return undefined;
	}

	if (url.includes('rtsp')) {
		return typeData[0];
	} else if (url.includes('youtube')) {
		return typeData[1];
	} else {
		return typeData[2];
	}
};
