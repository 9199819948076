import { Spinner } from '@components/uikit/Spinner/Spinner';
import React, { ReactNode } from 'react';

type ListProps = {
	children: ReactNode[];
	isLoading?: boolean;
	emptyState?: ReactNode;
};

export const List = ({ children, isLoading, emptyState }: ListProps) => {
	if (isLoading) {
		return <Spinner />;
	}
	return children.length !== 0 ? <>{children}</> : <>{emptyState}</>;
};
