import React, { FC } from 'react';
import classes from './LicensePlateView.module.css';
import Flag from 'react-world-flags';

export type LicensePlateProps = {
	value?: string;
	country: string;
};

export const LicensePlateView: FC<LicensePlateProps> = ({ value, country }) => {
	return (
		<div className={'flex flex-col gap-y-[16px]'}>
			<div className={'flex gap-x-1'}>
				<div className={classes['number']}>{value?.slice(0, 6)}</div>
				<div className={`${classes['region']} ${classes['region--sm']}`}>
					<span>{value?.slice(6)}</span>
					<p className={classes['region__flag']}>
						<Flag code={country} height={7} width={10} />
						<span className={'text-primary-0 uppercase font-body-small text-[8px] '}>{country}</span>
					</p>
				</div>
			</div>
		</div>
	);
};
