import React, { FC } from 'react';
import { Popover } from '@components/uikit/Popover/Popover';
import { PopoverContentLayout } from '@components/layouts/PopoverContentLayout/PopoverContentLayout';
import { Link } from 'react-router-dom';
import { ROUTER } from '@constants/router';
import { Button } from '@components/uikit/Button/Button';
import { Icon } from '@components/uikit/Icon/Icon';

export const SearchCreateButton: FC = () => {
	return (
		<Popover
			positions={['bottom', 'left']}
			align={'end'}
			content={
				<PopoverContentLayout className={'!p-[12px]'}>
					<div className={'flex flex-col gap-y-[12px]'}>
						<Link
							to={ROUTER.SEARCH_DATABASE_CREATE_FACES.PATH}
							className={'font-title-medium text-on-secondary-container'}
						>
							Создать поиск лиц
						</Link>
						<Link
							to={ROUTER.SEARCH_DATABASE_CREATE_LICENSE_PLATES.PATH}
							className={'font-title-medium text-on-secondary-container'}
						>
							Создать поиск номеров
						</Link>
					</div>
				</PopoverContentLayout>
			}
		>
			{({ toggleIsOpen }) => {
				return (
					<Button variant={'primary'} size={'xl'} onClick={toggleIsOpen}>
						Создать <Icon name={'chevronCompactDown'} width={24} height={24} />
					</Button>
				);
			}}
		</Popover>
	);
};
