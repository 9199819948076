import React, { forwardRef, MouseEvent } from 'react';
import { TextInputBase, TextInputBaseProps } from '@components/uikit/TextInput/TextInputBase/TextInputBase';

export type TextInputProps = Omit<TextInputBaseProps, 'type' | 'ref'> & {
	// Добавляем по мере тестирования
	type?: 'text' | 'number' | 'password' | 'email' | 'time' | 'datetime-local' | 'date';
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({ type = 'text', ...propsProxy }, ref) => {
	// Делаем фокус на инпуте по клику на контейнер
	const onClick = (e: MouseEvent<HTMLDivElement>) => {
		const input = e.currentTarget.querySelector('input');
		if (!input) {
			return;
		}

		input.focus();
	};

	// Активируем всякие пикеры по клику на нашу иконку (системная скрыта стилями)
	const onIconClick = (e: MouseEvent<SVGSVGElement>) => {
		const input = e.currentTarget.nextSibling;
		if (!input) {
			return;
		}

		try {
			(input as HTMLInputElement | null)?.showPicker();
		} catch (err) {
			console.info('[TextInputBase] Не удалось выполнить метод showPicker');
		}
	};

	return (
		<TextInputBase
			ref={ref}
			type={type}
			variant="uneven"
			containerProps={{
				onClick,
			}}
			{...propsProxy}
			iconProps={{
				onClick: onIconClick,
				...propsProxy.iconProps,
			}}
		/>
	);
});
