import React, { FC } from 'react';
import { PersonEntry } from '@components/event/EventSettings/PersonExtra/PersonEntry';

type EventExtraData = {
	event_extra_type: string;
	conf: number;
	person_id: string | number;
}[];

export type PersonExtraProps = {
	extra?: Nullable<EventExtraData>;
};

export const PersonExtra: FC<PersonExtraProps> = ({ extra }) => {
	const persons = extra?.filter((entry) => {
		return entry.event_extra_type === 'person';
	});

	if (!persons?.length) {
		return null;
	}

	return (
		<div className={'flex flex-col gap-y-[16px]'}>
			{persons.map((person) => {
				return <PersonEntry confidence={person.conf} entry={person} key={person.person_id} />;
			})}
		</div>
	);
};
