import React, { FC, useEffect, useState } from 'react';
import RecognitionItemSelect from '@components/recognition/RecognitionItemSelect/RecognitionItemSelect';

import { Modal } from '@components/uikit/Modal/Modal';
import { RecognitionItemSelectWrapperProps } from '@components/recognition/RecognitionItemSelect/wrapper/RecognitionItemSelectWrapper.typedef';
import SearchFaceWrapper from '@components/recognition/SearchFace/wrapper/SearchFaceWrapper';
import { useCopyToAnotherBucketMutation } from '@store/api';
import { notifyError } from '@modules/toast/notifyError';

const RecognitionItemSelectWrapper: FC<RecognitionItemSelectWrapperProps> = ({
	matches,
	imageBlob,
	bucketName,
	prefixBucket,
	id,
	selectedFace,
	type = 'search',
	setSelectedFace,
	recognizedFacesFilter,
}) => {
	const [isMatchModalOpen, setIsMatchModalOpen] = useState(false);
	const [listFaces, setListFaces] = useState(matches);
	const [copyToAnotherBucket] = useCopyToAnotherBucketMutation();
	const [removedFace, setRemovedFace] = useState<string>();
	const [isShowBadge, setIsShowBadge] = useState<boolean>(true);

	const removeFromList = (id: string) => {
		setRemovedFace(id);
		setTimeout(() => {
			setListFaces((prev) => {
				if (prev) {
					return prev.filter((item) => {
						return item.id !== +id;
					});
				}
			});
			setRemovedFace(undefined);
		}, 300);
	};

	const addToBase = async (id: string) => {
		const item = listFaces?.find((item) => {
			return item.id === +id;
		});
		if (!item) {
			return;
		}
		const res = await copyToAnotherBucket({
			b_name_dst: bucketName,
			b_name_src: item.bucketName,
			f_id_src: item.id,
			f_id_dst: item.id,
		});

		if ('error' in res) {
			notifyError('Ошибка добавления лица в картотеку');
			return;
		} else {
			setIsMatchModalOpen(false);
			removeFromList(id);
			setSelectedFace && setSelectedFace(item.title);
		}
	};

	useEffect(() => {
		if (!listFaces?.length) {
			setIsMatchModalOpen(false);
			setIsShowBadge(false);
		}
	}, [listFaces]);

	return (
		<>
			<Modal
				title={'Совпадение лиц'}
				isOpen={isMatchModalOpen}
				onClose={() => {
					setIsMatchModalOpen(false);
				}}
			>
				<div className={'flex flex-col gap-[20px] w-[1000px] max-h-[600px]'}>
					<div>У данной маски есть совпадение с базой лиц.</div>
					<div className={'flex flex-col gap-[20px] overflow-auto'}>
						{listFaces?.map(({ title, similarity, id, tags, bucketName }, i) => {
							return (
								<SearchFaceWrapper
									key={i}
									faceId={id}
									bucketName={bucketName}
									searchFaceProps={{
										tags: tags,
										identifier: id.toString(),
										title: title,
										similarity: similarity,
										removeFromList: removeFromList,
										addToBase: addToBase,
										className: id.toString() === removedFace ? 'opacity-0' : '',
									}}
								/>
							);
						})}
					</div>
				</div>
			</Modal>
			<RecognitionItemSelect
				prefixBucket={prefixBucket}
				bucket_name={bucketName}
				matches={isShowBadge}
				picture={imageBlob}
				onMatchClick={() => {
					setIsMatchModalOpen(true);
				}}
				selectedFace={Boolean(selectedFace)}
				name={selectedFace}
				type={type}
				id={id}
				recognizedFacesFilter={recognizedFacesFilter}
			/>
		</>
	);
};

export default RecognitionItemSelectWrapper;
