import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreRootState } from '@store/store';

type FaceSearchState = {
	sidebarCollapse: boolean;
};

const initialState: FaceSearchState = {
	sidebarCollapse: false,
};

const faceSearch = createSlice({
	name: 'faceSearch',
	initialState,
	reducers: {
		setSidebarCollapse(state, action: PayloadAction<boolean>) {
			state.sidebarCollapse = action.payload;
		},
	},
});

export const { setSidebarCollapse } = faceSearch.actions;
export const faceSearchSelector = (state: StoreRootState) => {
	return state.faceSearch;
};
export default faceSearch;
