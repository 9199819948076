// Уровни прогресса создания / редактирования "Настройка детекции лиц"
import { StepRowProps } from '@components/uikit/StepRow/StepRow';

export const RECOGNITION_FACES_STEP_ROW_PRESET: StepRowProps['steps'] = [
	{
		title: 'Определите лица',
		description: 'Определение лиц из медиа-материалов, которые представляются',
		type: 'default',
	},
	{
		title: 'Настройка поиска',
		description: 'Глобальная настройка поиска по фильтрам',
		type: 'default',
	},
	{
		title: 'Результаты поиска',
		description: 'В данном блоке отражены результаты вашего поиска',
		type: 'default',
	},
];

export const RECOGNITION_LICENSE_PLATES_STEP_ROW_PRESET: StepRowProps['steps'] = [
	{
		title: 'Настройка поиска',
		description: 'Глобальная настройка поиска по фильтрам',
		type: 'default',
	},
	{
		title: 'Результаты поиска',
		description: 'В данном блоке отражены результаты вашего поиска',
		type: 'default',
	},
];
