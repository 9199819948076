import React, { FC, useEffect, useState } from 'react';
import { Button } from '@components/uikit/Button/Button';
import { Icon } from '@components/uikit/Icon/Icon';
import { TextInput } from '@components/uikit/TextInput/TextInput';
import { SearchCameraModal } from '@components/recognition/SearchCameraModal/SearchCameraModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTER } from '@constants/router';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useGetBucketConfigQuery, useGetCamerasByBucketQuery } from '@store/api';
import { useFaceStageSearchConfig } from '@hooks/useFaceStageSearchConfig';

const DEFAULT_CONFIDENCE = 90;

type Camera = {
	camera_id: string;
	title: string;
	confidence?: number;
};

type PnChangeProps = {
	source_type: string;
	title: string;
	confidence: number;
};
export type SearchFormArchiveProps = {
	onChange?: (value: PnChangeProps) => void;
	bucketId: string;
	amount?: number;
	type?: 'face' | 'plate';
	title?: string;
};

type Inputs = {
	title: string;
	cameras: Camera[];
	startDate?: string;
	endDate: string;
	confidence: number;
};

export const SearchFormArchive: FC<SearchFormArchiveProps> = ({ onChange, bucketId, amount, title, type = 'face' }) => {
	const [searchModalOpen, setSearchModalOpen] = useState(false);
	const { currentData: bucketConfig } = useGetBucketConfigQuery(bucketId);
	const { currentData: cameras } = useGetCamerasByBucketQuery(bucketId);
	const { pushToActiveMonitoring, excludeFromActiveMonitoring, updateActiveMonitoring } = useFaceStageSearchConfig();

	const {
		handleSubmit,
		formState: { errors },
		control,
		setValue,
		clearErrors,
		register,
	} = useForm<Inputs>({
		defaultValues: {
			title: '',
			cameras: [],
			confidence: DEFAULT_CONFIDENCE,
		},
	});

	useEffect(() => {
		setValue('title', bucketConfig?.caption ?? '');

		const date = bucketConfig?.tags?.find((item) => {
			return item.includes('archive');
		});
		if (date) {
			const [startDate, endDate] = date.replace('archive:', '').split(',');
			setValue('startDate', startDate);
			setValue('endDate', endDate);
		}

		const confidence = (
			bucketConfig?.tags?.find((item) => {
				return item.includes('confidence');
			}) ?? ''
		).replace('confidence:', '');
		confidence && setValue('confidence', +confidence);
		confidence && setBucketConfidence(+confidence);
	}, [bucketConfig, setValue]);
	const [bucketConfidence, setBucketConfidence] = useState(DEFAULT_CONFIDENCE);

	useEffect(() => {
		return setValue(
			'cameras',
			(cameras?.cameras ?? []).map((camera) => {
				return {
					camera_id: camera.camera_uuid,
					title: camera.camera_name,
					confidence: bucketConfidence / 100,
				};
			}),
		);
	}, [cameras, setValue, bucketConfidence]);

	const onCameraModalAdd = async (config: Camera) => {
		await pushToActiveMonitoring({
			active_monitoring: [
				{
					bucket_name: bucketId,
					limit: 1,
					threshold: bucketConfidence / 100,
				},
			],
			camera_uuid: config.camera_id,
		});
	};

	const onCameraModalSubmit = async (config: Camera) => {
		await pushToActiveMonitoring({
			active_monitoring: [
				{
					bucket_name: bucketId,
					limit: 1,
					threshold: bucketConfidence / 100,
				},
			],
			camera_uuid: config.camera_id,
		});
		setSearchModalOpen(false);
		clearErrors('cameras');
	};

	const onCameraRemove = async (cameraId: string) => {
		await excludeFromActiveMonitoring({
			buckets: [bucketId],
			camera_uuid: cameraId,
		});
	};

	const navigate = useNavigate();
	const location = useLocation();
	const path = location.pathname;

	const onSubmit: SubmitHandler<Inputs> = (values) => {
		onChange?.({
			source_type: `archive:${values.startDate ?? ''},${values.endDate}`,
			title: values.title,
			confidence: values.confidence,
		});

		cameras &&
			cameras.cameras.forEach(async ({ camera_uuid }) => {
				await updateActiveMonitoring({
					active_monitoring: [
						{
							bucket_name: bucketId,
							limit: 1,
							threshold: values.confidence / 100,
						},
					],
					camera_uuid: camera_uuid,
					bucket_id: bucketId,
				});
			});

		const urlSearchParams = new URLSearchParams();
		urlSearchParams.append('bucket_id', bucketId);
		urlSearchParams.append('endDate', values.endDate);

		values.startDate && urlSearchParams.append('startDate', values.startDate);

		if (path === ROUTER.SEARCH_DATABASE_CREATE_FACES.PATH) {
			navigate(`${ROUTER.SEARCH_DATABASE_ARCHIVE_FACE.PATH}?${urlSearchParams.toString()}`);
		} else if (path === ROUTER.SEARCH_DATABASE_CREATE_LICENSE_PLATES.PATH) {
			navigate(`${ROUTER.SEARCH_DATABASE_ARCHIVE_PLATE.PATH}?${urlSearchParams.toString()}`);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col gap-y-[24px]'}>
			{title && <h2 className={'font-headline-xsmall text-on-background mt-[16px]'}>{title}</h2>}
			<div>
				<TextInput
					{...register('title', {
						required: 'Поле обязательно',
					})}
					className={'!w-[348px]'}
					hideNotice={false}
					variant={'filled'}
					placeholder={'Введите название поиска'}
					error={errors.title?.message}
				/>
			</div>

			<h2 className={'font-headline-xsmall text-on-background'}>
				Выберите камеры по которым будет осуществляться поиск
			</h2>
			<Controller
				control={control}
				rules={{
					required: 'Необходимо выбрать камеру(ы)',
				}}
				render={({ field: { ref, value } }) => {
					return (
						<div className={'flex flex-col relative mt-[-8px]'}>
							<div className={'flex flex-wrap max-w-[1563px] gap-[16px]'}>
								<Button
									type={'button'}
									className={'w-fit'}
									variant={errors.cameras ? 'error' : 'grey'}
									size={'xl'}
									onClick={setSearchModalOpen.bind(null, true)}
								>
									<Icon name={'cameraVideo'} width={24} height={24} />
									Добавить камеры
								</Button>
								{searchModalOpen && (
									<SearchCameraModal
										isOpen={searchModalOpen}
										onClose={setSearchModalOpen.bind(null, false)}
										onSubmit={onCameraModalSubmit}
										onSubmitAndAdd={onCameraModalAdd}
										type={type}
									/>
								)}
								{value.map((camera, index) => {
									if (!camera.camera_id) {
										return null;
									}

									const camTitle = camera.title ?? `Камера ${index}`;

									return (
										<Button
											variant={'dark'}
											size={'xl'}
											type="button"
											onClick={onCameraRemove.bind(null, camera.camera_id)}
											key={camera.camera_id}
										>
											<Icon name={'cameraVideo'} width={24} height={24} />
											<span className="max-w-[150px] truncate">{camTitle}</span>
											<Icon name={'x'} width={24} height={24} />
										</Button>
									);
								})}
							</div>
							{errors.cameras && <p className={'font-body-medium text-error mt-3'}>{errors.cameras.message}</p>}
						</div>
					);
				}}
				name={'cameras'}
			/>

			{type === 'face' && (
				<>
					<h2 className={'font-headline-xsmall text-on-background'}>Процент вероятности совпадения</h2>
					<TextInput
						{...register('confidence', {
							required: 'Необходимо заполнить процент совпадения',
							valueAsNumber: true,
							min: { value: 1, message: 'Не может быть меньше чем 1' },
							max: { value: 100, message: 'Не может быть больше чем 100' },
							onChange: (e) => {
								setBucketConfidence(e.target.valueAsNumber);
							},
						})}
						className={'!w-[348px]'}
						variant={'filled'}
						type={'number'}
						hideNotice={false}
						placeholder={'Процент вероятности'}
						error={errors.confidence?.message}
						step={0.01}
						postfix={'%'}
					/>
				</>
			)}

			<h2 className={'font-headline-xsmall text-on-background mt-[4px]'}>Срок анализа архива</h2>
			<div className={'flex gap-x-[32px] mt-[-8px]'}>
				<Controller
					control={control}
					render={({ field }) => {
						return (
							<TextInput
								value={field.value}
								onChange={field.onChange}
								className={'!w-[348px] mb-[-16px]'}
								type={'date'}
								variant={'filled'}
								placeholder={'Дата начала'}
								error={errors.startDate?.message}
								hideNotice={false}
							/>
						);
					}}
					name={'startDate'}
				/>

				<Controller
					control={control}
					rules={{
						required: 'Дата окончания обязательна!',
					}}
					render={({ field }) => {
						return (
							<TextInput
								value={field.value}
								onChange={field.onChange}
								className={'!w-[348px] mb-[-16px]'}
								type={'date'}
								variant={'filled'}
								placeholder={'Дата конца'}
								error={errors.endDate?.message}
								hideNotice={false}
							/>
						);
					}}
					name={'endDate'}
				/>
			</div>
			<Button
				className={'w-fit mt-[6px]'}
				variant={'primary'}
				size={'xl'}
				type={'submit'}
				disabled={amount !== undefined ? !amount : false}
			>
				Продолжить
			</Button>
		</form>
	);
};
