import { Zone, useZonePicker } from '@hooks/useZonePick';
import React, { FC, useEffect, useRef } from 'react';
export type ZonePickerProps = {
	image?: string;
	setFaceZone?: (coords: Zone) => void;
	setPlateZone?: (coords: Zone) => void;
	initialFace?: Zone;
	initialPlate?: Zone;
	type?: 'face' | 'plate';
};

export const ZonePicker: FC<ZonePickerProps> = ({
	image,
	initialFace,
	initialPlate,
	setFaceZone,
	setPlateZone,
	type = 'plate',
}) => {
	const ref = useRef<HTMLImageElement>(null);
	const a = useRef<HTMLDivElement>(null),
		b = useRef<HTMLDivElement>(null),
		c = useRef<HTMLDivElement>(null),
		d = useRef<HTMLDivElement>(null);

	const initial = type === 'plate' ? initialPlate : initialFace;

	const setZone = (coords: Zone) => {
		if (setPlateZone && setFaceZone) {
			return type === 'plate' ? setPlateZone(coords) : setFaceZone(coords);
		}
	};

	const {
		onClick,
		onPointClick,
		onMouseDown,
		onMouseUp,
		onMousemove,
		relativeCoords,
		click,
		draggedPoint,
		rectCoords,
		onlineCoords,
	} = useZonePicker({ image, setZone, ref, a, b, c, d, initial });

	useEffect(() => {
		document.addEventListener('mousemove', onMousemove);

		return () => {
			document.removeEventListener('mousemove', onMousemove);
		};
	});

	return (
		<>
			<div
				className={`absolute top-0 left-0 bottom-0 right-0 ${click || Boolean(draggedPoint) ? 'z-30' : 'z-10'}`}
				onClick={onClick}
			>
				{image && (
					<img className={'w-full h-full select-none invisible'} src={image} alt={'Изображение камеры'} ref={ref} />
				)}

				{relativeCoords && (
					<div
						className={'bg-primary-0 opacity-80 absolute w-full h-full top-0'}
						style={{
							clipPath: relativeCoords.reversal
								? `polygon(0 100%, 0 0, 
						    ${relativeCoords.a.x}% 0, 
						    ${relativeCoords.a.x}% ${relativeCoords.a.y}%, 
						    ${relativeCoords.b.x}% ${relativeCoords.b.y}%, 
						    ${relativeCoords.c.x}% ${relativeCoords.c.y}%, 
						    ${relativeCoords.d.x}% ${relativeCoords.d.y}%, 
						    ${relativeCoords.d.x}% 0, 
						    100% 0, 100% 100%)`
								: `polygon(0% 0%, 0% 100%, 
						${relativeCoords.a.x}% 100%, 
						${relativeCoords.a.x}% ${relativeCoords.a.y}%, 
						${relativeCoords.b.x}% ${relativeCoords.b.y}%, 
						${relativeCoords.c.x}% ${relativeCoords.c.y}%, 
						${relativeCoords.d.x}% ${relativeCoords.d.y}%, 
						${relativeCoords.d.x}% 100%, 
						100% 100%, 100% 0%)`,
						}}
					></div>
				)}

				{relativeCoords && (
					//Точки увеличены пока для удобства
					<>
						<div
							ref={a}
							onMouseDown={onMouseDown}
							onClick={onPointClick}
							onMouseUp={onMouseUp}
							className={'z-30 rounded-[50%] bg-primary w-[20px] h-[20px] absolute -translate-x-1/2 -translate-y-1/2'}
							style={{ left: `${relativeCoords.a.x}%`, top: `${relativeCoords.a.y}%` }}
						></div>

						<div
							ref={b}
							onMouseDown={onMouseDown}
							onClick={onPointClick}
							onMouseUp={onMouseUp}
							className={'z-30 rounded-[50%] bg-primary w-[20px] h-[20px] absolute -translate-x-1/2 -translate-y-1/2'}
							style={{ left: `${relativeCoords.b.x}%`, top: `${relativeCoords.b.y}%` }}
						></div>

						<div
							ref={c}
							onMouseDown={onMouseDown}
							onClick={onPointClick}
							onMouseUp={onMouseUp}
							className={'z-30 rounded-[50%] bg-primary w-[20px] h-[20px] absolute -translate-x-1/2 -translate-y-1/2'}
							style={{ left: `${relativeCoords.c.x}%`, top: `${relativeCoords.c.y}%` }}
						></div>

						<div
							ref={d}
							onMouseDown={onMouseDown}
							onClick={onPointClick}
							onMouseUp={onMouseUp}
							className={'z-30 rounded-[50%] bg-primary w-[20px] h-[20px] absolute -translate-x-1/2 -translate-y-1/2'}
							style={{ left: `${relativeCoords.d.x}%`, top: `${relativeCoords.d.y}%` }}
						></div>
					</>
				)}
				{rectCoords && (
					<div
						className="flex justify-between items-end p-2 z-[29] rounded-[1px] border-2 border-[#3EFF1F] absolute text-[#3EFF1F]"
						style={{
							width: rectCoords.w,
							height: rectCoords.h,
							left: `${rectCoords.x}%`,
							top: `${rectCoords.y}%`,
						}}
					>
						{onlineCoords && (
							<>
								<span className={'font-label-large'}>Зона детекции</span>
								<span className="font-label-large">
									[{`${onlineCoords.x}, ${onlineCoords.y}, ${onlineCoords.w}, ${onlineCoords.h}`}]
								</span>
							</>
						)}
					</div>
				)}
			</div>
		</>
	);
};
