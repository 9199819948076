import React, { FC, useId, useState } from 'react';
import { IconTextRow } from '@components/uikit/IconTextRow/IconTextRow';
import { FramePlaceholder } from '@components/uikit/FramePlaceholder/FramePlaceholder';
import { EventIndicator } from '../EventIndicator/EventIndicator';
import { IconButton } from '@components/uikit/Button/IconButton';
import { Icon } from '@components/uikit/Icon/Icon';
import { Tooltip } from 'react-tooltip';
import Flag from 'react-world-flags';
import { categoryData, categoryDataColorsBg, categoryDataColorsText } from '@modules/events/mappedValues';
import { dateParser } from '@utils/dateParser';
import { EventCopyLinkModal } from '../EventCopyLinkModal/EventCopyLinkModal';
import { MatchPlate } from '@hooks/useEventsPage';

type EventPreviewProps = {
	previewVehicleUrl?: Nullable<string>;
	previewPlateUrl?: Nullable<string>;
	event: MatchPlate;
	active?: boolean;
	onClick?: (event: MatchPlate) => void;
	onPreviewMouseEnter?: () => void;
	onPreviewMouseLeave?: () => void;
	onMarkAsWatched?: () => void;
	onMarkAsFalsy?: () => void;
	onEventRedirect?: () => void;
};

export type Color = {
	[key: string]: {
		color: string;
		name: string;
	};
};

export const colorMap: Color = {
	white: {
		color: '#FFFFFF',
		name: 'белый',
	},
	black: {
		color: '#000000',
		name: 'черный',
	},
	green: {
		color: '#006C45',
		name: 'зеленый',
	},
	blue: {
		color: '#015DB5',
		name: 'синий',
	},
	red: {
		color: '#BA1A1A',
		name: 'красный',
	},
	yellow: {
		color: '#EFC048',
		name: 'желтый',
	},
	orange: {
		color: '#f59e42',
		name: 'оранжевый',
	},
	beige: {
		color: '#f0c3ad',
		name: 'бежевый',
	},
	brown: {
		color: '#251A00',
		name: 'коричнывый',
	},
	silver: {
		color: '#E0E2EC',
		name: 'серебристый',
	},
	gray: {
		color: '#555F71',
		name: 'серый',
	},
	violet: {
		color: '#DA70D6',
		name: 'фиолетовый',
	},
	other: {
		color: '#000000',
		name: 'не определен',
	},
};

export const EventPreviewPlate: FC<EventPreviewProps> = ({
	event,
	previewVehicleUrl,
	previewPlateUrl,
	active,
	onClick,
	onPreviewMouseEnter,
	onPreviewMouseLeave,
}) => {
	const className = [
		'bg-secondary-container rounded-[8px] flex gap-x-4',
		active && 'p-[14px] border-2 border-tertiary-80 box-shadow-elevation-7',
		!active && 'p-[16px]',
	]
		.filter(Boolean)
		.join(' ');

	const {
		id: eventId,
		camera_name,
		timestamp,
		timestamp_local,
		identity: title,
		vehicle,
		category,
		countryCode,
		country,
		plate,
		valid,
		quality,
		occlusion,
	} = event;

	const { gmtLocal, dateStr, timeStr, timeStrLocal } = dateParser(timestamp, timestamp_local);

	const onMouseEnterHandler = () => {
		onPreviewMouseEnter && onPreviewMouseEnter();
	};

	const id = useId();
	const colorPlateId = useId();
	const cameraNameId = useId();
	const timeId = useId();
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<div
				className={className}
				role={'button'}
				tabIndex={0}
				onClick={() => {
					onClick && onClick(event);
				}}
				onFocus={onMouseEnterHandler}
				onMouseEnter={onMouseEnterHandler}
				onMouseLeave={onPreviewMouseLeave}
				onBlur={onPreviewMouseLeave}
			>
				<div className={'flex gap-x-3 grow'}>
					<section className={'flex-shrink-0'}>
						{previewVehicleUrl || previewPlateUrl ? (
							<div className={'rounded-[4px] overflow-hidden'}>
								{previewVehicleUrl && (
									<img className={'w-[128px] h-[100px] object-fill'} src={previewVehicleUrl} alt={'Кадр машины'} />
								)}

								{previewPlateUrl && (
									<img className={'w-[128px] object-fill aspect-[3.3/1]'} src={previewPlateUrl} alt={'Кадр номера'} />
								)}
							</div>
						) : (
							<FramePlaceholder roundness={4} />
						)}
						<span className="opacity-50 font-body-small">ID: {eventId}</span>
					</section>

					<section className={'flex flex-col justify-between flex-grow'}>
						<div className={'flex gap-x-[8px] items-center'}>
							{/* <EventBadge type={type} className={'!rounded-2 !py-0'} /> */}
							<IconTextRow className={'text-on-secondary-container opacity-50 !gap-x-[4px]'} icon={'calendarAll'}>
								{dateStr}
							</IconTextRow>
							<IconTextRow className={'text-on-secondary-container opacity-50 !gap-x-[4px]'} icon={'clock'}>
								{timeStrLocal}
							</IconTextRow>
							<div
								id={timeId}
								className="text-on-secondary-container hover:text-primary hover:opacity-100 opacity-50 transition-all"
							>
								<span>(GMT{gmtLocal <= 0 ? `+${Math.abs(gmtLocal)}` : -gmtLocal})</span>

								<Tooltip
									className={'z-50 !rounded-2'}
									anchorSelect={`#${CSS.escape(timeId)}`}
									place={'top'}
									content={`По московскому времени: ${timeStr}`}
								/>
							</div>
							<div id={cameraNameId}>
								<IconTextRow className={'opacity-50 '} classNameText={'w-[120px] truncate'} icon={'cameraVideo'}>
									{camera_name}
								</IconTextRow>
								<Tooltip
									className={'z-50 !rounded-2'}
									anchorSelect={`#${CSS.escape(cameraNameId)}`}
									place={'top'}
									content={camera_name}
								/>
							</div>
						</div>
						<div className={'flex items-center gap-x-2'}>
							<EventIndicator severity={'REGULAR'} />
							<h2 className={'flex items-center font-title-large text-on-background h-[28px] mt-[2px] mb-[2px]'}>
								{title}
							</h2>
						</div>
						<div className={'flex gap-2 my-2'}>
							{vehicle?.color && (
								<div
									className={
										'w-8 h-8 rounded-2 border border-outline-variant flex items-center justify-center relative'
									}
									style={{
										background:
											vehicle.color === 'white' || vehicle.color === 'silver' || vehicle.color === 'beige'
												? '#001B3D'
												: 'white',
									}}
									id={id}
								>
									<Icon name="commonAuto" style={{ color: colorMap[vehicle.color].color }} />

									<Tooltip
										className={'z-50 !rounded-2'}
										anchorSelect={`#${CSS.escape(id)}`}
										place={'top'}
										content={`Цвет: ${colorMap[vehicle.color].name}. ${vehicle.purpose}`}
									/>
								</div>
							)}

							{event.plate && (
								<div
									className={
										'border border-outline-variant bg-primary-100 rounded-2 w-max py-[5px] px-[11px] font-title-small flex gap-1 items-center'
									}
									style={{
										backgroundColor: category ? categoryDataColorsBg[category] : 'white',
										color: category ? categoryDataColorsText[category] : 'black',
									}}
									id={colorPlateId}
								>
									<Flag code={countryCode} height={15} width={21} />
									<span>{plate}</span>
									<Tooltip
										className={'z-50 !rounded-2'}
										anchorSelect={`#${CSS.escape(colorPlateId)}`}
										place={'top'}
										content={`Страна: ${country}`}
									/>
								</div>
							)}
						</div>
						<div className={'flex flex-wrap gap-y-1 font-body-medium max-w-[550px]'}>
							{vehicle?.category && (
								<p className={'w-1/2 min-w-[200px]'}>
									<span className="font-medium">Категория:</span> {vehicle.category}
								</p>
							)}
							{occlusion && (
								<p className={'w-1/2 min-w-[200px]'}>
									<span className="font-medium">Перекрытие:</span> {occlusion.toFixed(2)}
								</p>
							)}
							{quality && (
								<p className={'w-1/2 min-w-[200px]'}>
									<span className="font-medium">Качество номера:</span> {quality.toFixed(2)}
								</p>
							)}
							{vehicle?.visibility && (
								<p className={'w-1/2 min-w-[200px]'}>
									<span className="font-medium">Качество изображения:</span> {vehicle?.visibility.toFixed(2)}
								</p>
							)}
							{valid !== undefined && (
								<p className={'w-1/2 min-w-[200px]'}>
									<span className="font-medium">Соответствует стандарту:</span> {valid ? 'Да' : 'Нет'}
								</p>
							)}
							{category && (
								<p className="break-normal w-1/2 min-w-[200px]">
									<span className="font-medium">Классификация:</span> {categoryData[category] ?? ''}
								</p>
							)}
						</div>
					</section>

					<section className="flex-grow flex items-end justify-end">
						<IconButton
							className={'flex-shrink-0'}
							icon={'share'}
							size={'xs'}
							onClick={(e) => {
								e.stopPropagation();
								setIsOpen(true);
							}}
						/>
					</section>
				</div>
			</div>
			{isOpen && (
				<EventCopyLinkModal
					eventId={eventId}
					title={title}
					isOpen={isOpen}
					onClose={() => {
						setIsOpen(false);
					}}
				/>
			)}
		</>
	);
};
