import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreRootState } from '@store/store';

type MonitoringSliceState = {
	isEventCollapse: boolean;
};

const initialState: MonitoringSliceState = {
	isEventCollapse: false,
};

export const monitoringSlice = createSlice({
	name: 'monitoring',
	initialState,
	reducers: {
		setEventCollapse(state, action: PayloadAction<boolean>) {
			state.isEventCollapse = action.payload;
		},
		toggleCollapse(state) {
			state.isEventCollapse = !state.isEventCollapse;
		},
	},
});

export const monitoringSelector = (state: StoreRootState) => {
	return state.monitoring;
};
