import React, { FC } from 'react';
import { Icon } from '@components/uikit/Icon/Icon';

export type FramePlaceholderProps = {
	className?: string;
	roundness?: number;
};

/**
 * Изображение отсутствия кадра камеры / изображения с камеры.
 * Стили модицифицируются через className, если нужно переопределить какие-то то используйте ! в начале названия класса.
 * @param className - классы для переопределения стилей.
 * @param roundness - радиус закругления (в пикселях).
 */
export const FramePlaceholder: FC<FramePlaceholderProps> = ({ className: propClassName, roundness = 8 }) => {
	const className = ['w-full h-full bg-secondary-container flex items-center justify-center', propClassName]
		.filter(Boolean)
		.join(' ');
	return (
		<div
			className={className}
			style={{
				borderRadius: `${roundness}px`,
			}}
		>
			<Icon className={'text-primary-90'} name={'cameraVideo'} width={120} height={120} />
		</div>
	);
};
