import React from 'react';
import { withAppProviders } from './providers/withAppProviders';
import 'react-tooltip/dist/react-tooltip.css';
import './styles/styles.css';
import { RouterPage } from '@pages/RouterPage';

function App() {
	return (
		<div className="fixed w-full">
			<RouterPage />
		</div>
	);
}

export default withAppProviders(App);
