import React from 'react';
import { components, DropdownIndicatorProps, OptionProps, ValueContainerProps } from 'react-select';
import { Icon, IconName } from '@components/uikit/Icon/Icon';
import classes from './Select.module.css';
import { SelectOption } from '@components/uikit/Select/Select.typedef';

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
	const menuIsOpen = props.selectProps.menuIsOpen;
	const rotateDegree = menuIsOpen ? 180 : 0;

	const iconClassName = [classes['dropdownIcon'], menuIsOpen && '!text-primary'].filter(Boolean).join(' ');

	return (
		<components.DropdownIndicator {...props}>
			<Icon
				className={iconClassName}
				name={'chevronCompactDown'}
				style={{
					transform: `rotate(${rotateDegree}deg)`,
					transition: 'transform 0.12s ease',
				}}
			/>
		</components.DropdownIndicator>
	);
};

export const Option = (props: OptionProps<SelectOption>) => {
	const classList = [classes['option']];
	props.isSelected && classList.push(classes['option--selected']);
	// props.isFocused && classList.push(classes['option--focused']);
	// props.isDisabled && classList.push(classes['option--disabled']);
	const className = classList.join(' ');

	const icon = props.data?.icon && <Icon name={props.data.icon} width={20} height={20} />;

	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		<components.Option {...props}>
			<div className={className}>
				{icon}
				{props.children}
			</div>
		</components.Option>
	);
};

export const ValueContainer = (icon?: IconName, size?: number, showOnlyOnEmpty?: boolean) => {
	return (props: ValueContainerProps) => {
		const className = [props.className, props.selectProps.menuIsOpen && classes['valueContainer--MenuOpen']]
			.filter(Boolean)
			.join(' ');

		const iconClassName = props.selectProps.menuIsOpen ? 'text-primary' : 'text-secondary';

		const isEmpty = !props.selectProps.value;
		const renderIcon = Boolean(showOnlyOnEmpty ? isEmpty && icon : icon);

		return (
			<components.ValueContainer {...props} className={className}>
				{renderIcon && icon && (
					<Icon className={classes['value-icon'] + ' ' + iconClassName} name={icon} width={size} height={size} />
				)}
				{props.children}
			</components.ValueContainer>
		);
	};
};
