import React, { FC } from 'react';

type ExcludedAttributes = 'type' | 'className';

export type RadioInputProps = Omit<JSX.IntrinsicElements['input'], ExcludedAttributes>;

/**
 * @deprecated Use `<Toggle />` instead.
 */
export const RadioInput: FC<RadioInputProps> = ({ children, ...propsProxy }) => {
	return (
		<label className={'flex items-center gap-x-[12px] cursor-pointer'}>
			<input className={'w-[24px] h-[24px] cursor-pointer flex-shrink-0'} type={'radio'} {...propsProxy} />
			<span className={'font-title-medium whitespace-nowrap overflow-hidden overflow-ellipsis'}>{children}</span>
		</label>
	);
};
