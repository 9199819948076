import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { RuntimeEnv } from '@modules/runtime-env/RuntimeEnv';
import { store, StoreRootState } from '@store/store';

type Sort = 'asc' | 'desc';

type BBox = {
	x: number;
	y: number;
	w: number;
	h: number;
};

type Bucket = {
	bucket: string;
};

type PaginationParams = { offset: number; limit: number };
type PaginationResponseParams = {
	offset: number;
	limit: number;
	total: number;
};
type ErrorResponse = {
	status: 'error';
	msg: string;
};
type HistorySearchCameras = {
	camera_uuid: string;
	threshold: number;
	limit: number;
};

export type FaceDetectorConfig = {
	detection_period: number;
	detection_threshold: number;
	quality_threshold: number;
	face_send_delay: number;
	max_detection_period: number;
	max_yaw: number;
	max_pitch: number;
	min_detection_period: number;
	min_face_duration: number;
	min_face_size: number;
	face_expand_width: number;
	face_expand_top: number;
	face_expand_bottom: number;
	face_jpeg_compression: number;
	face_max_crop_size: number;
	roi: [number, number, number, number];
	rtsp_path: string;
	timezone: string;
	qualified_min_aspect_ratio: number;
	qualified_max_aspect_ratio: number;
};
export type LpDetectionParameters = {
	min_height_px: number;
	max_height_px: number;
	confidence_threshold: number;
	min_readability_threshold: number;
	min_occlusion_threshold: number;
	min_illumination_threshold: number;
	roi: {
		x: number;
		y: number;
		width: number;
		height: number;
	};
};

type PlateDetectorConfig = {
	lp_detection_parameters: Partial<LpDetectionParameters>;
	lp_tracking_parameters?: {
		algorithm?: 'content-matching';
		detection_interval_ms?: number;
	};
	vehicle_classification_parameters?: {
		enable_color: boolean;
		enable_category: boolean;
		enable_purpose: boolean;
	};
};
export type DetectorParams = {
	disable: boolean;
	face?: Partial<FaceDetectorConfig>;
	lpr?: Partial<PlateDetectorConfig>;
	face_archive?: Partial<FaceDetectorConfig>;
	lpr_archive?: Partial<PlateDetectorConfig>;
};
export type StreamParams = {
	source: string;
	video_storage_period_in_seconds?: number;
};

export type ActiveMonitoring = {
	bucket_name: string;
	threshold?: number;
	limit: number;
};
export type CameraResponse = {
	camera_uuid: string;
	camera_name?: string;
	history_search_delay: number;
	tags: string[];
	history_search_cameras: HistorySearchCameras[];
	active_monitoring: ActiveMonitoring[];
	hls?: string;
	hls_lq?: string;
	detector_params: DetectorParams;
	stream_params?: StreamParams;
	timestamp_created: string;
	timezone_offset?: number;
	can_modify: boolean;
};
export type CreateCameraParams = {
	camera_name?: string;
	history_search_delay?: number;
	tags?: string[];
	history_search_cameras?: HistorySearchCameras[];
	active_monitoring?: ActiveMonitoring[];
	detector_params?: DetectorParams;
	timezone_offset?: number;
	stream_params?: StreamParams;
};
export type UpdateCameraParams = {
	camera_uuid: string;
	camera_name?: string;
	history_search_delay?: number;
	timezone_offset?: number;
	tags?: string[];
	history_search_cameras?: HistorySearchCameras[];
	active_monitoring?: ActiveMonitoring[];
	detector_params?: DetectorParams;
	stream_params?: StreamParams;
};

type CamerasResponse = PaginationResponseParams & {
	with_empty: boolean;
	data: CameraResponse[];
	sort?: string;
	sort_key?: string;
};

type GetBucketsParams = PaginationParams & {
	with_wildcard?: boolean;
	with_num_records?: boolean;
	with_empty?: boolean;
	bucket_caption?: string;
	type?: string[];
	sort?: string;
	sort_key?: string;
};
type GetBucketsResponseParams = PaginationResponseParams & {
	with_empty: boolean;
	with_wildcard: boolean;
	with_num_records: boolean;
	data: {
		//bucket_name бек использует как ID
		bucket_name: string;
		bucket_caption: string;
		can_modify: boolean;
		is_wildcard: boolean;
		num_records?: number;
		tags?: string[];
		timestamp_created?: string;
		timestamp_modified?: string;
	}[];
};
export type BucketExcludeFaceParams = {
	b_name: string;
	f_id: number;
};
export type BucketExcludePlateParams = {
	b_name: string;
	p_id: number;
};

type DeleteBucketParams = { b_name: string };

type UpdateBucketParams = {
	bucket_name: string;
	caption?: string;
	tags?: string[];
};

export type PushBucketParams = {
	params: {
		bucket_name: string;
		identity: string;
		f_id?: number;
		tags?: string[];
	};
	image: Blob;
};

type PushBucketResponse = {
	face_id: number;
	bucket_name: string;
	identity: string;
	face_bbox: BBox;
	tags: string[];
};

export type PushBucketPlateParams = {
	params: {
		bucket_name: string;
		identity: string;
		plate_text: string;
		plate_id?: number;
		tags?: string[];
	};
};

type PushBucketPlateResponse = {
	plate_id: number;
	bucket_name: string;
	identity: string;
	plate_text: string;
	tags: string[];
};

type GetMatchParams = {
	params: {
		threshold: number;
		limit: number;
		cameras?: string[];
	};
	image: Blob;
};

export type BucketMatch = {
	face_id: number;
	bucket_name: string;
	identity: string;
	similarity: number;
	tags: string[];
};
export type BucketMatchPlate = {
	plate_id: number;
	bucket_name: string;
	identity: string;
	similarity: number;
	tags: string[];
};
export type HistoryMatch = {
	face_id: number;
	camera_uuid: string;
	camera_name: string;
	similarity: number;
	timestamp: string;
};
type GetMatchResponse = {
	num_faces: number;
	faces: {
		features: {
			age: number;
			gender: string;
			glasses: string;
			facial_hair: string;
			mask: string;
			race: string;
		};
		bbox: BBox;
		history_matches: HistoryMatch[];
		bucket_matches: BucketMatch[];
	}[];
};

type GetFacesResponse = {
	num_detections: number;
	input_type: 'photo' | 'video';
	img_b64: 'image in base64';
	detections: [
		{
			type: 'face' | 'upper_body' | 'full_body';
			bbox: {
				x: number;
				y: number;
				w: number;
				h: number;
			};
			score: number;
			img_b64: string;
			overview_b64: string;
		},
	];
};

type GetFacesParams = {
	params: {
		embedded_img: boolean;
		square: boolean;
		sort_key?: 'score' | 'x-coord' | 'y-coord' | 'square';
		pad_w?: number;
		pad_h?: number;
		type?: string;
	};
	file: Blob;
};

const isErrorResponse = (e: unknown): e is ErrorResponse => {
	return (
		typeof e === 'object' &&
		e !== null &&
		'status' in e &&
		typeof e['status'] === 'string' &&
		'msg' in e &&
		typeof e['msg'] === 'string'
	);
};
type GetBucketFaceParams = {
	bucket_name: string;
	f_id: number;
};

export type GetCamerasParams = {
	tags?: string[];
	camera_name?: string;
	sort?: string;
	sort_key?: string;
	with_ro_cameras?: boolean;
} & Partial<PaginationParams>;

export type FindInBaseResponse = {
	num_history_matches: number;
	history_matches: HistoryMatch[];
	num_bucket_matches: number;
	bucket_matches: BucketMatch[];
};

type FindInBaseParams = {
	bucket_name: string;
	face_id: number;
	threshold: number;
	limit: number;
	bucket?: string[];
	camera?: string[] | string;
	sort_key?: 'timestamp' | '+timestamp' | '-timestamp' | 'similarity';
	min_ts?: string;
	max_ts?: string;
};

type FindInArchivePlateResponse = {
	min_ts: string;
	max_ts: string;
	data: [
		{
			plate_id: number;
			track_id: string;
			timestamp: string;
			overview_bbox: {
				x: number;
				y: number;
				w: number;
				h: number;
			};
			plate: {
				text: string;
				quality: number;
				occlusion: number;
				valid: boolean;
				country: string;
				category: string;
				country_iso_alpha2: string;
				country_iso_alpha3: string;
			};
			vehicle?: {
				category: string;
				purpose: string;
				color: string;
			};
			vehicle_bbox?: {
				x: number;
				y: number;
				w: number;
				h: number;
			};
		},
	];
};

type FindInArchivePlateParams = {
	camera: string[] | string;
	plate_text: string;
	min_ts?: string;
	max_ts?: string;
};

export type GetCamerasByBucketResponse = {
	num_cameras: number;
	cameras: {
		camera_name: string;
		camera_uuid: string;
		threshold: number;
		limit: number;
	}[];
};

type GetEventDataFaceResponse = {
	camera_name: string;
	camera_uuid: string;
	overview_link: string;
	face_link: string;
	search_link: string;
	radius_search_link: string;
	overview_bbox: {
		x: number;
		y: number;
		width: number;
		height: number;
	};
	timestamp: string;
	timestamp_rfc: string;
	timestamp_local: string;
	quality: number;
	age: number;
	gender: string;
	glasses: string;
	track_id: string;
	is_friend: boolean;
	processing_delay: string;
	history_matches: HistoryMatch[];
	bucket_matches: BucketMatch[];
};
type GetEventDataPlateResponse = {
	camera_name: string;
	camera_uuid: string;
	overview_link: string;
	plate_link: string;
	vehicle_link?: string;
	track_id: string;
	timestamp: string;
	timestamp_rfc: string;
	timestamp_local: string;
	plate_id: number;
	overview_bbox: {
		x: number;
		y: number;
		width: number;
		height: number;
	};
	plate?: {
		text: string;
		quality: number;
		occlusion: number;
		valid: boolean;
		country: string;
		country_iso_alpha2: string;
		country_iso_alpha3: string;
		category: string;
	};
	vehicle?: {
		category: string;
		purpose: string;
		color: string;
		visibility: number;
	};
	bucket_matches: BucketMatchPlate[];
};
type GetBucketFacesResponse = {
	num_faces: number;
	faces: {
		face_id: number;
		identity: string;
		tags: string[];
	}[];
};

type GetBucketPlatesResponse = {
	num_plates: number;
	plates: {
		plate_id: number;
		plate_text: string;
		identity: string;
		tags: string[];
	}[];
};

type GetBucketConfigResponse = {
	bucket_name: string;
	tags: string[];
	caption: string;
	timestamp_created: string;
};

type GetFacesFromBucketsResponse = {
	num_faces: number;
	faces: {
		bucket_name: string;
		face_id: number;
		identity: string;
		tags: string[];
	}[];
};

type GetFacesFromBucketsV1Response = {
	data: {
		bucket_name: string;
		bucket_caption: string;
		face_id: number;
		identity: string;
		tags: string[];
	}[];
} & PaginationResponseParams;

type GetPlatesFromBucketsV1Response = {
	data: {
		bucket_name: string;
		bucket_caption: string;
		plate_id: number;
		plate_text: string;
		identity: string;
		tags: string[];
	}[];
} & PaginationResponseParams;

type GetFacesFromBucketsParams =
	| {
			identity: string;
	  }
	| {
			identity_text: string;
	  };

type GetFacesFromBucketsV1Params = Partial<GetFacesFromBucketsParams & Bucket & PaginationParams>;

type CopyToAnotherBucketParams = {
	b_name_dst: string;
	b_name_src: string;
	f_id_src: number;
	f_id_dst?: number;
};
type CopyToAnotherBucketResponse = {
	face_id: number;
	bucket_name: string;
};
type UserData = {
	login: string;
	ro_cameras: string[];
	rw_cameras: string[];
	own_cameras: string[];
	ro_buckets: string[];
	rw_buckets: string[];
};

type GetAnalyticsParams = {
	min_ts?: string;
	max_ts?: string;
	min_quality?: number;
	bucket_threshold?: number;
	bucket?: string[];
	camera?: string[];
};

type GetAnalyticsResponse = {
	min_quality: number;
	min_ts: string;
	max_ts: string;
	personnel_buckets: string[];
	personnel_bucket_threshold: number;
	cameras: string[];
	persons: {
		is_personnel: boolean;
		total_hours: number;
		age: number;
		faces: {
			age: number;
			camera_uuid: string;
			face_id: number;
			quality: number;
			timestamp: string;
			unix_timestamp: number;
		}[];
	}[];
};

type GetFaceEventsByCamerasParams = {
	min_ts?: string;
	max_ts?: string;
	sort?: Sort;
	camera?: string;
	include_boundaries?: boolean;
} & PaginationParams;

type GetLprEventsByCamerasParams = GetFaceEventsByCamerasParams;

type GetEventsByCamerasParams = GetLprEventsByCamerasParams & {
	type?: 'plates' | 'faces';
};

type BoundingBox = {
	x: number;
	y: number;
	width: number;
	height: number;
};

export type FaceCameraEvent = {
	camera_uuid: string;
	camera_name: string;
	face_id: number;
	track_id: string;
	age: number | null;
	gender: string | null;
	glasses: string | null;
	facial_hair: string | null;
	mask: string | null;
	race: string | null;
	is_friend: boolean;
	is_fake: boolean;
	timestamp: string; // ISO формат даты
	timestamp_local: string;
	quality?: number;
	overview_bbox: BoundingBox;
	history_matches: HistoryMatch[];
	bucket_matches: BucketMatch[];
};

type GetFaceEventsByCamerasResponse = {
	min_ts: string; // ISO формат даты
	max_ts: string; // ISO формат даты
	sort: Sort;
	offset: number;
	limit: number;
	total: number;
	data: FaceCameraEvent[];
};

type PlateInfo = {
	text: string;
	quality: number;
	occlusion: number;
	valid: boolean;
	country: string;
	country_iso_alpha2: string;
	country_iso_alpha3: string;
	category: string;
};

type VehicleInfo = {
	category: string | null;
	purpose: string | null;
	color: string | null;
	visibility: number | null;
};

export type LprEventData = {
	camera_uuid: string;
	camera_name: string;
	plate_id: number;
	track_id: string;
	timestamp: string;
	timestamp_local: string;
	overview_bbox: BoundingBox;
	plate: PlateInfo;
	vehicle: VehicleInfo;
	bucket_matches: BucketMatchPlate[];
};

type GetLprEventsByCamerasResponse = {
	min_ts: string; // ISO формат даты
	max_ts: string; // ISO формат даты
	sort: Sort;
	offset: number;
	limit: number;
	total: number;
	data: LprEventData[];
};
type GetEventsByCamerasResponse = {
	min_ts: string; // ISO формат даты
	max_ts: string; // ISO формат даты
	sort: Sort;
	offset: number;
	limit: number;
	total: number;
	data: (LprEventData | FaceCameraEvent)[];
};
export const api = createApi({
	reducerPath: 'api',
	baseQuery: fetchBaseQuery({
		prepareHeaders(headers, { getState }) {
			const store = getState() as StoreRootState;
			const basicAuth = store.auth.user?.authString;
			headers.set('Authorization', `Basic ${basicAuth}`);
			return headers;
		},
		baseUrl: RuntimeEnv.getEnv('REACT_APP_API_URL'),
	}),
	tagTypes: ['Cameras', 'Camera', 'BucketFace', 'BucketPlate', 'Buckets', 'BucketsCameras', 'User'],
	endpoints(build) {
		return {
			getConfig: build.query<unknown, void>({
				query: () => {
					return 'api/config';
				},
			}),
			login: build.mutation({
				query: () => {
					return {
						url: 'api/config',
						method: 'GET',
					};
				},
			}),
			getUserData: build.query<UserData, string>({
				query: (username) => {
					return {
						url: `/api/user/${username}`,
						method: 'GET',
					};
				},
				providesTags: ['User'],
			}),
			getLogin: build.query<UserData, { username: string; password: string }>({
				queryFn: async ({ username, password }, api, extraOptions, baseQuery) => {
					try {
						const response = await baseQuery({
							url: `/api/user/${username}`,
							method: 'GET',
							headers: {
								Authorization: btoa(`${username}:${password}`),
							},
						});
						return { data: response.data as UserData };
					} catch (e: unknown) {
						return {
							error: { error: 'Ошибка авторизации', status: `FETCH_ERROR` } as FetchBaseQueryError,
						};
					}
				},
				providesTags: ['User'],
			}),
			deleteCamera: build.mutation<string, string>({
				queryFn: async (id, api, extraOptions, baseQuery) => {
					await baseQuery({
						url: `api/camera/${id}`,
						method: 'DELETE',
					});
					return {
						data: id,
					};
				},
				invalidatesTags: ['Cameras'],
			}),
			updateCamera: build.mutation<CameraResponse, UpdateCameraParams>({
				query: (params) => {
					return {
						url: `api/camera/${params.camera_uuid}`,
						method: 'POST',
						body: params,
					};
				},
				invalidatesTags: ['Camera', 'BucketsCameras'],
				async onQueryStarted(params, { dispatch, queryFulfilled }) {
					const paramsFromStore = store.getState().camerasParams.params;
					const { data: camerasUpdateData } = await queryFulfilled;

					const res = dispatch(
						api.util.updateQueryData('getCameras', paramsFromStore, (draft) => {
							const data = draft.data.map((elem) => {
								if (elem.camera_uuid === params.camera_uuid) {
									return camerasUpdateData;
								}
								return elem;
							});
							return Object.assign(draft, { ...draft, data });
						}),
					);
					queryFulfilled.catch(res.undo);
				},
			}),
			createCamera: build.mutation<CameraResponse, CreateCameraParams>({
				query: (params) => {
					return {
						url: 'api/cameras',
						method: 'POST',
						body: params,
					};
				},
				invalidatesTags: ['Cameras'],
			}),
			getCameras: build.query<CamerasResponse, GetCamerasParams>({
				query: (params) => {
					return {
						url: 'api/v1/cameras',
						params: params,
					};
				},
				providesTags: ['Cameras'],
			}),
			getCamera: build.query<CameraResponse, string>({
				query: (id) => {
					return {
						url: `api/camera/${id}`,
					};
				},
				providesTags: ['Camera'],
			}),
			getFaceImage: build.query<string | undefined, number>({
				queryFn: async (faceId, api, extraOptions, baseQuery) => {
					try {
						if (faceId === undefined) {
							return { data: undefined };
						}
						const response = await baseQuery({
							url: `/history/${faceId}/face`,
							method: 'GET',
							responseHandler(response) {
								return response.blob();
							},
						});

						const image = URL.createObjectURL(response.data as Blob);
						return { data: image };
					} catch (e: unknown) {
						return {
							error: { error: 'Ошибка загрузки картинки', status: `FETCH_ERROR` } as FetchBaseQueryError,
						};
					}
				},
			}),
			getPlateImage: build.query<string | undefined, number>({
				queryFn: async (plateId, api, extraOptions, baseQuery) => {
					try {
						const response = await baseQuery({
							url: `/plate/${plateId}/plate`,
							method: 'GET',
							responseHandler(response) {
								return response.blob();
							},
						});

						const image = URL.createObjectURL(response.data as Blob);
						return { data: image };
					} catch (e: unknown) {
						return {
							error: { error: 'Ошибка загрузки картинки', status: `FETCH_ERROR` } as FetchBaseQueryError,
						};
					}
				},
			}),
			getVehicleImage: build.query<string | undefined, number>({
				queryFn: async (plateId, api, extraOptions, baseQuery) => {
					try {
						const response = await baseQuery({
							url: `/plate/${plateId}/vehicle`,
							method: 'GET',
							responseHandler(response) {
								return response.blob();
							},
						});

						const image = URL.createObjectURL(response.data as Blob);
						return { data: image };
					} catch (e: unknown) {
						return {
							error: { error: 'Ошибка загрузки картинки', status: `FETCH_ERROR` } as FetchBaseQueryError,
						};
					}
				},
			}),
			getEventImageOverview: build.query<string | undefined, { id: string | number; type: 'plate' | 'face' }>({
				queryFn: async (params, api, extraOptions, baseQuery) => {
					try {
						const response = await baseQuery({
							url: `/${params.type === 'face' ? 'history' : 'plate'}/${params.id}/overview`,
							method: 'GET',
							responseHandler(response) {
								return response.blob();
							},
						});

						const image = URL.createObjectURL(response.data as Blob);
						return { data: image };
					} catch (e: unknown) {
						return {
							error: { error: 'Ошибка загрузки картинки', status: `FETCH_ERROR` } as FetchBaseQueryError,
						};
					}
				},
			}),
			getPlateImageOverview: build.query<string | undefined, number>({
				queryFn: async (plateId, api, extraOptions, baseQuery) => {
					try {
						const response = await baseQuery({
							url: `/plate/${plateId}/overview`,
							method: 'GET',
							responseHandler(response) {
								return response.blob();
							},
						});

						const image = URL.createObjectURL(response.data as Blob);
						return { data: image };
					} catch (e: unknown) {
						return {
							error: { error: 'Ошибка загрузки картинки', status: `FETCH_ERROR` } as FetchBaseQueryError,
						};
					}
				},
			}),
			getFaceImageOverview: build.query<string | undefined, number>({
				queryFn: async (faceId, api, extraOptions, baseQuery) => {
					try {
						if (faceId === undefined) {
							return { data: undefined };
						}
						const response = await baseQuery({
							url: `/history/${faceId}/overview`,
							method: 'GET',
							responseHandler(response) {
								return response.blob();
							},
						});

						const image = URL.createObjectURL(response.data as Blob);
						return { data: image };
					} catch (e: unknown) {
						return {
							error: { error: 'Ошибка загрузки картинки', status: `FETCH_ERROR` } as FetchBaseQueryError,
						};
					}
				},
			}),
			getCameraSnapshot: build.query<string | undefined, { id: string | undefined; isInitialization?: boolean }>({
				queryFn: async ({ id, isInitialization }, api, extraOptions, baseQuery) => {
					try {
						if (id === undefined) {
							return { data: undefined };
						}
						const response = await baseQuery({
							url: `camera/${id}/snapshot`,
							method: 'GET',
							responseHandler(response) {
								return response.blob();
							},
						});

						if (response.error && response.error.status === 404 && isInitialization) {
							return { data: 'initializating' };
						}

						const image = URL.createObjectURL(response.data as Blob);
						return { data: image };
					} catch (e: unknown) {
						return {
							error: { error: 'Ошибка загрузки камеры с картинки', status: `FETCH_ERROR` } as FetchBaseQueryError,
						};
					}
				},
			}),
			getBucketFaces: build.query<GetBucketFacesResponse, string>({
				query: (bName) => {
					return {
						url: `/api/bucket/${bName}/face/list`,
						method: 'GET',
					};
				},
				providesTags: ['BucketFace'],
			}),
			getBucketPlates: build.query<GetBucketPlatesResponse, string>({
				query: (bName) => {
					return {
						url: `/api/bucket/${bName}/plate/list`,
						method: 'GET',
					};
				},
				providesTags: ['BucketPlate'],
			}),
			getBucketConfig: build.query<GetBucketConfigResponse, string>({
				query: (bName) => {
					return {
						url: `/api/v1/bucket/${bName}`,
						method: 'GET',
					};
				},
				providesTags: ['BucketFace', 'BucketPlate'],
			}),
			getBuckets: build.query<GetBucketsResponseParams, GetBucketsParams>({
				query: (params) => {
					return {
						url: '/api/v1/buckets',
						params: params,
						method: 'GET',
					};
				},
				providesTags: ['Buckets'],
			}),
			deleteBucket: build.mutation<void, DeleteBucketParams>({
				query: ({ b_name }) => {
					return {
						url: `/api/bucket/${b_name}`,
						method: 'DELETE',
					};
				},
				invalidatesTags: ['Buckets'],
			}),
			updateBucket: build.mutation<GetBucketFacesResponse, UpdateBucketParams>({
				query: ({ bucket_name, ...params }) => {
					return {
						url: `/api/bucket/${bucket_name}`,
						method: 'POST',
						body: params,
					};
				},
				invalidatesTags: ['BucketFace', 'BucketPlate', 'Buckets'],
			}),
			bucketFacePush: build.mutation<PushBucketResponse, PushBucketParams>({
				queryFn: async (params, api, extraOptions, baseQuery) => {
					const response = await baseQuery({
						url: `/api/bucket/face`,
						method: 'POST',
						params: params.params,
						body: params.image,
						responseHandler(response) {
							return response.json();
						},
					});
					const data = response.data;
					if (isErrorResponse(data)) {
						return { error: { error: 'Ошибка загрузки лица' } as FetchBaseQueryError };
					} else {
						return { data: data as PushBucketResponse };
					}
				},
				invalidatesTags: ['BucketFace'],
			}),
			bucketPlatePush: build.mutation<PushBucketPlateResponse, PushBucketPlateParams>({
				queryFn: async (params, api, extraOptions, baseQuery) => {
					const response = await baseQuery({
						url: `/api/bucket/plate`,
						method: 'POST',
						params: params.params,
						responseHandler(response) {
							return response.json();
						},
					});
					const data = response.data;
					if (isErrorResponse(data)) {
						return { error: { error: 'Ошибка загрузки номера' } as FetchBaseQueryError };
					} else {
						return { data: data as PushBucketPlateResponse };
					}
				},
				invalidatesTags: ['BucketPlate', 'Buckets'],
			}),
			getBucketFace: build.query<string, GetBucketFaceParams>({
				queryFn: async ({ f_id, bucket_name }, api, extraOptions, baseQuery) => {
					try {
						const response = await baseQuery({
							url: `bucket/${bucket_name}/${f_id}/face`,
							method: 'GET',
							responseHandler(response) {
								return response.blob();
							},
						});

						const image = URL.createObjectURL(response.data as Blob);
						return { data: image };
					} catch (e: unknown) {
						return {
							error: { error: 'Ошибка загрузки изображения', status: `FETCH_ERROR` } as FetchBaseQueryError,
						};
					}
				},
			}),
			bucketExcludeFace: build.mutation<void, BucketExcludeFaceParams>({
				query: (params) => {
					return {
						url: `/api/bucket/${params.b_name}/face/${params.f_id}`,
						method: 'DELETE',
					};
				},
				invalidatesTags: ['BucketFace', 'Buckets'],
			}),
			bucketExcludePlate: build.mutation<void, BucketExcludePlateParams>({
				query: (params) => {
					return {
						url: `/api/bucket/${params.b_name}/plate/${params.p_id}`,
						method: 'DELETE',
					};
				},
				invalidatesTags: ['BucketPlate', 'Buckets'],
			}),
			getFacesFromBuckets: build.query<GetFacesFromBucketsResponse, GetFacesFromBucketsParams>({
				query: (params) => {
					return {
						url: `/api/buckets/list`,
						method: 'GET',
						params: params,
					};
				},
				providesTags: ['BucketFace'],
			}),
			getFacesFromBucketsV1: build.query<GetFacesFromBucketsV1Response, GetFacesFromBucketsV1Params>({
				query: (params) => {
					return {
						url: `/api/v1/buckets/face/list`,
						method: 'GET',
						params: params,
					};
				},
				providesTags: ['BucketFace'],
			}),
			getPlatesFromBucketsV1: build.query<GetPlatesFromBucketsV1Response, GetFacesFromBucketsV1Params>({
				query: (params) => {
					return {
						url: `/api/v1/buckets/plate/list`,
						method: 'GET',
						params: params,
					};
				},
				providesTags: ['BucketPlate'],
			}),
			getCamerasByBucket: build.query<GetCamerasByBucketResponse, string>({
				query: (bName) => {
					return {
						url: `/api/bucket/${bName}/cameras/list`,
						method: 'GET',
					};
				},
				providesTags: ['BucketsCameras'],
			}),
			findInBase: build.query<FindInBaseResponse, FindInBaseParams>({
				query: ({ bucket_name, face_id, ...params }) => {
					return {
						url: `/api/bucket/${bucket_name}/${face_id}/matches`,
						method: 'GET',
						params: params,
					};
				},
			}),
			findInArchivePlateParams: build.query<FindInArchivePlateResponse, FindInArchivePlateParams>({
				query: ({ ...params }) => {
					return {
						url: `/api/plates`,
						method: 'GET',
						params: params,
					};
				},
			}),
			copyToAnotherBucket: build.mutation<CopyToAnotherBucketResponse, CopyToAnotherBucketParams>({
				query: ({ b_name_dst, b_name_src, f_id_src, f_id_dst }) => {
					return {
						url: `/api/bucket/${b_name_dst}/insert/from-bucket/${b_name_src}/${f_id_src}`,
						method: 'POST',
						params: { face_id: f_id_dst },
					};
				},
				invalidatesTags: ['BucketFace', 'Buckets'],
			}),
			getEventDataFace: build.query<GetEventDataFaceResponse, number>({
				query: (faceId) => {
					return `/api/history/${faceId}/json?bucket_matches=true&history_matches=true&face_link=true`;
				},
			}),
			getEventDataPlate: build.query<GetEventDataPlateResponse, number>({
				query: (plateId) => {
					return `/api/plate/${plateId}/json?bucket_matches=true&face_link=true`;
				},
			}),
			getMatch: build.mutation<GetMatchResponse, GetMatchParams>({
				queryFn: async (params, api, extraOptions, baseQuery) => {
					const response = await baseQuery({
						url: '/api/photo/matches',
						method: 'POST',
						params: params.params,
						body: params.image,
						responseHandler(response) {
							return response.json();
						},
					});
					const data = response.data;
					if (isErrorResponse(data)) {
						return { error: { error: 'Ошибка загрузки лица' } as FetchBaseQueryError };
					} else {
						return { data: data as GetMatchResponse };
					}
				},
			}),
			getFaces: build.mutation<GetFacesResponse, GetFacesParams>({
				queryFn: async (params, api, extraOptions, baseQuery) => {
					const response = await baseQuery({
						url: '/api/v1/detect',
						method: 'POST',
						params: params.params,
						body: params.file,
						responseHandler(response) {
							return response.json();
						},
					});
					const data = response.data;
					if (isErrorResponse(data)) {
						return { error: { error: 'Ошибка загрузки лица' } as FetchBaseQueryError };
					} else {
						return { data: data as GetFacesResponse };
					}
				},
			}),
			getAnalytics: build.query<GetAnalyticsResponse, GetAnalyticsParams>({
				query: (params) => {
					return {
						url: '/api/analytics/visitors',
						params: params,
						method: 'GET',
					};
				},
			}),
			getFaceEventsByCameras: build.query<GetFaceEventsByCamerasResponse, GetFaceEventsByCamerasParams>({
				query: (params) => {
					return {
						url: '/api/v1/cameras/faces',
						params: params,
						method: 'GET',
					};
				},
			}),
			getLprEventsByCameras: build.query<GetLprEventsByCamerasResponse, GetLprEventsByCamerasParams>({
				query: (params) => {
					return {
						url: '/api/v1/cameras/plates',
						params: params,
						method: 'GET',
					};
				},
			}),
			getEventsByCameras: build.query<GetEventsByCamerasResponse, GetEventsByCamerasParams>({
				keepUnusedDataFor: 0,
				queryFn: async (params, api, extraOptions, baseQuery) => {
					if (params.type === 'faces') {
						const res = await baseQuery({
							url: '/api/v1/cameras/faces',
							method: 'GET',
							params: params,
							responseHandler(response) {
								return response.json();
							},
						});
						return { data: res.data as GetFaceEventsByCamerasResponse };
					}
					if (params.type === 'plates') {
						const res = await baseQuery({
							url: '/api/v1/cameras/plates',
							method: 'GET',
							params: params,
							responseHandler(response) {
								return response.json();
							},
						});
						return { data: res.data as GetLprEventsByCamerasResponse };
					}

					const limit = Math.ceil(params.limit / 2);
					const offset = Math.ceil(params.offset / 2);

					const [resFace, resPlate] = await Promise.allSettled([
						await baseQuery({
							url: '/api/v1/cameras/faces',
							method: 'GET',
							params: { ...params, limit, offset },
							responseHandler(response) {
								return response.json();
							},
						}),
						await baseQuery({
							url: '/api/v1/cameras/plates',
							method: 'GET',
							params: { ...params, limit, offset },
							responseHandler(response) {
								return response.json();
							},
						}),
					]);
					if (resFace.status === 'rejected') {
						return { error: { error: 'Ошибка загрузки событий' } as FetchBaseQueryError };
					}
					if (resPlate.status === 'rejected') {
						return { error: { error: 'Ошибка загрузки событий' } as FetchBaseQueryError };
					}

					const facesRsponse = resFace.value;
					const platesResponse = resPlate.value;
					if (isErrorResponse(facesRsponse) || isErrorResponse(platesResponse)) {
						return { error: { error: 'Ошибка загрузки событий' } as FetchBaseQueryError };
					} else {
						const faces = facesRsponse.data as GetFaceEventsByCamerasResponse;
						const plates = platesResponse.data as GetLprEventsByCamerasResponse;
						const events: (FaceCameraEvent | LprEventData)[] = [...faces.data, ...plates.data];
						const data: GetEventsByCamerasResponse = {
							min_ts: faces.min_ts < plates.min_ts ? faces.min_ts : plates.min_ts,
							max_ts: faces.max_ts > plates.max_ts ? faces.max_ts : plates.max_ts,
							sort: faces.sort,
							offset: faces.offset,
							limit: faces.limit + plates.limit,
							total: faces.total + plates.total,
							data: events,
						};
						return { data: data as GetEventsByCamerasResponse };
					}
				},
			}),
		};
	},
});

export const {
	useGetConfigQuery,
	useLoginMutation,
	useGetCameraQuery,
	useLazyGetCameraQuery,
	useGetCamerasQuery,
	useLazyGetCamerasQuery,
	useGetCameraSnapshotQuery,
	useDeleteCameraMutation,
	useUpdateBucketMutation,
	useCreateCameraMutation,
	useUpdateCameraMutation,
	useGetBucketsQuery,
	useGetBucketConfigQuery,
	useDeleteBucketMutation,
	useBucketFacePushMutation,
	useBucketExcludeFaceMutation,
	useBucketExcludePlateMutation,
	useGetMatchMutation,
	useGetBucketFaceQuery,
	useFindInBaseQuery,
	useLazyFindInBaseQuery,
	useGetBucketFacesQuery,
	useLazyGetBucketFacesQuery,
	useGetBucketPlatesQuery,
	useLazyGetBucketPlatesQuery,
	useGetFaceImageQuery,
	useGetFaceImageOverviewQuery,
	useGetCamerasByBucketQuery,
	useLazyGetCamerasByBucketQuery,
	useLazyGetEventDataFaceQuery,
	useGetEventDataFaceQuery,
	useLazyGetEventDataPlateQuery,
	useGetEventDataPlateQuery,
	useGetFacesMutation,
	useGetFacesFromBucketsQuery,
	useCopyToAnotherBucketMutation,
	useGetFacesFromBucketsV1Query,
	useLazyGetUserDataQuery,
	useGetUserDataQuery,
	useLazyGetLoginQuery,
	useGetAnalyticsQuery,
	useGetPlatesFromBucketsV1Query,
	useBucketPlatePushMutation,
	useFindInArchivePlateParamsQuery,
	useLazyFindInArchivePlateParamsQuery,
	useGetPlateImageOverviewQuery,
	useGetPlateImageQuery,
	useGetEventImageOverviewQuery,
	useLazyGetEventImageOverviewQuery,
	useLazyGetPlateImageOverviewQuery,
	useGetVehicleImageQuery,
	useGetEventsByCamerasQuery,
	useLazyGetEventsByCamerasQuery,
} = api;
