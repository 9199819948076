import React, { FC, useState } from 'react';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { RECOGNITION_FACES_STEP_ROW_PRESET } from '@constants/recognition';
import { Button } from '@components/uikit/Button/Button';
import { FileUpload } from '@components/uikit/FileUpload/FileUpload';
import { TextInput } from '@components/uikit/TextInput/TextInput';
import { FileUploadFile } from '@components/uikit/FileUpload/FileUpload.typedef';
import { StepProps } from '@components/uikit/StepRow/Step/Step';
import RecognitionItemSelectWrapper from '@components/recognition/RecognitionItemSelect/wrapper/RecognitionItemSelectWrapper';
import { filterInvalidFiles } from '@utils/fileValidator';
import { useRecognizedFaces } from '@hooks/useRecognizedFaces';
import { useDebounceCallback } from '@hooks/useDebounceCallback';
import {
	useBucketExcludeFaceMutation,
	useCopyToAnotherBucketMutation,
	useGetBucketFacesQuery,
	useGetFacesFromBucketsV1Query,
} from '@store/api';
import SearchFaceWrapper from '@components/recognition/SearchFace/wrapper/SearchFaceWrapper';
import { Dropdown } from '@components/uikit/Dropdown/Dropdown';
import { numWord } from '@utils/numWord';
import SearchFaceSmallWrapper from '@components/recognition/SearchFace/SearchFaceSmall/SearchFaceSmallWrapper';
import { notifyError } from '@modules/toast/notifyError';
import { getTextWithDeclination } from '@utils/getTextWithDeclination';
import { NotFound } from '@components/uikit/NotFound/NotFound';
import { notify } from '@modules/toast/notify';
import { FACES_BUCKET_NAME } from '@constants/facesBucketName';
import { usePrefixBucket } from '@hooks/usePrefixBucket';
import { StepsHeader } from '@components/uikit/StepsHeader/StepsHeader';
import { ROUTER } from '@constants/router';

type FoundFace = {
	bucketName: string;
	faceId: number;
	tags: string[];
	identity: string;
};

type FoundFaces = FoundFace[];

const steps = [
	{
		...RECOGNITION_FACES_STEP_ROW_PRESET[0],
		type: 'active',
	},
	RECOGNITION_FACES_STEP_ROW_PRESET[1],
	RECOGNITION_FACES_STEP_ROW_PRESET[2],
];

type FacesStageProps = {
	onStepClick: (id: number) => void;
	bucketName: string;
};
export const FacesStage: FC<FacesStageProps> = ({ onStepClick, bucketName }) => {
	const [copyToAnotherBucket] = useCopyToAnotherBucketMutation();
	const [deleteFromBucket] = useBucketExcludeFaceMutation();
	const [files, setFiles] = useState<FileUploadFile[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const { recognizedFaces, makeNewRecognizedFaces } = useRecognizedFaces();
	const [search, setSearch] = useState<string>();
	const [selectedFaceFromBucket, setSelectedFaceFromBucket] = useState<string>();

	const { currentData: bucket } = useGetBucketFacesQuery(bucketName);
	const prefixBucket = usePrefixBucket();

	const handleFaceFromBucketAdd = (title: string | undefined) => {
		setSelectedFaceFromBucket(title);
	};

	const faces = (bucket?.faces ?? []).map((face) => {
		return {
			bucketName: bucketName,
			faceId: face.face_id,
			identity: face.identity,
			tags: face.tags,
		};
	});

	const selectedFaceIds = faces.map(({ faceId }) => {
		return faceId;
	});
	const { currentData: facesFromBase } = useGetFacesFromBucketsV1Query(
		{
			identity_text: search ?? '',
			bucket: prefixBucket + FACES_BUCKET_NAME,
		},
		{ skip: search === undefined || prefixBucket === undefined },
	);

	const foundFace = facesFromBase
		? facesFromBase.data.reduce<{ faces: FoundFaces; nums: Set<number> }>(
				(acc, curr) => {
					if (selectedFaceIds.includes(curr.face_id)) {
						return acc;
					}

					if (!acc.nums.has(curr.face_id)) {
						acc.faces = acc.faces.concat({
							bucketName: curr.bucket_name,
							faceId: curr.face_id,
							tags: curr.tags,
							identity: curr.identity,
						});
						acc.nums.add(curr.face_id);
					}
					return acc;
				},
				{ nums: new Set<number>(), faces: [] },
		  ).faces
		: [];

	const updateFiles = async (files: FileUploadFile[]) => {
		setIsLoading(true);
		await makeNewRecognizedFaces(
			files.map(({ file }) => {
				return file;
			}),
		);
		setFiles(files);
		setIsLoading(false);
	};
	const onDrop = async (fileList: FileList) => {
		const validFiles = filterInvalidFiles(fileList);
		if (!validFiles) {
			return;
		}
		const files = validFiles.map((file) => {
			return { file: file, id: 'currentFile', name: file.name, url: URL.createObjectURL(file) };
		});
		await updateFiles(files);
	};

	const changeSearchQuery = useDebounceCallback((value: string) => {
		setSearch(value);
	}, 1000);

	const searchInBase: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		changeSearchQuery(event.currentTarget.value);
	};

	const addToBucket = async (face: FoundFace) => {
		const res = await copyToAnotherBucket({
			b_name_dst: bucketName,
			b_name_src: face.bucketName,
			f_id_src: face.faceId,
			f_id_dst: face.faceId,
		});
		if ('error' in res) {
			notifyError('Ошибка добавления лица в картотеку');
			return;
		}

		notify('Лицо добавлено в картотеку');
	};

	const revomeFromBucket = async (faceId: number) => {
		const res = await deleteFromBucket({
			b_name: bucketName,
			f_id: faceId,
		});

		if ('error' in res) {
			notifyError('Ошибка добавления лица в картотеку');
			return;
		}

		handleFaceFromBucketAdd(undefined);
	};

	const facesAmount = recognizedFaces.reduce((acc, elem) => {
		return acc + elem.faces.length;
	}, 0);

	return (
		<DashboardLayout>
			<DashboardContentLayout
				overrideHeaderRenderer={
					<StepsHeader
						title={ROUTER.SEARCH_DATABASE_CREATE_FACES.TITLE}
						badges={[{ text: 'Создание' }]}
						steps={steps as StepProps[]}
						onStepClick={faces.length ? onStepClick : undefined}
					/>
				}
				showBackButton={false}
				drawDivider={false}
			>
				<div className={'mt-[8px] flex flex-col gap-y-[24px] min-h-full'}>
					<div className={'flex gap-x-[32px] flex-grow h-[calc(100vh-24px*2-261px-32px-24px-52px-8px)] min-h-[420px] '}>
						<section className={'flex flex-col gap-y-[16px] w-[750px] overflow-y-auto'}>
							<h2 className={'font-headline-xsmall text-on-background'}>Поиск лиц из медиа-файлов</h2>
							<FileUpload
								loading={isLoading}
								onRemove={async (file) => {
									await updateFiles(
										files.filter((f) => {
											return f.url !== file.url;
										}),
									);
								}}
								inputProps={{
									multiple: true,
									accept: 'image/jpeg, image/png, image/bmp, video/mp4',
									onChange(e) {
										const files = e.target.files;
										if (files) {
											onDrop(files);
										}
									},
								}}
								description={'JPG, PNG, BMP, MP4'}
								files={files}
								onDrop={onDrop}
								dragClassName={'!h-[200px]'}
							/>
							<h2 className={'font-headline-xsmall text-on-background'}>Поиск лиц из базы</h2>

							<Dropdown
								header={
									<TextInput
										className={'!h-fit'}
										variant={'bordered'}
										icon={'search'}
										placeholder={'Поиск'}
										onChange={searchInBase}
									/>
								}
								body={
									<div className={'flex flex-col w-full gap-y-[12px] items-start'}>
										<div className={'flex flex-col w-full gap-y-[12px]'}>
											{foundFace.length === 0 && <span className={'rounded-[8px] p-[10px_12px]'}>Не найдено</span>}
											{foundFace.length > 0 &&
												foundFace.map((face) => {
													return (
														<SearchFaceSmallWrapper
															key={face.faceId}
															faceId={face.faceId}
															bucketName={face.bucketName}
															searchFaceProps={{
																identifier: face.faceId.toString(),
																onClick: () => {
																	addToBucket(face);
																},
																tags: face.tags,
																title: face.identity,
															}}
														/>
													);
												})}
										</div>
									</div>
								}
							/>
							<div className={'flex flex-col w-full gap-y-[12px] items-start'}>
								{
									<p className={'font-title-medium text-on-background'}>{`Выбрано: ${faces.length} ${numWord(
										faces.length,
										['лицо', 'лица', 'лиц'],
									)}`}</p>
								}
								<div className={'flex flex-col w-full gap-y-[12px]'}>
									{faces.map((face) => {
										return (
											<SearchFaceWrapper
												key={face.faceId}
												faceId={face.faceId}
												bucketName={face.bucketName}
												searchFaceProps={{
													identifier: face.faceId.toString(),
													onCloseClick: () => {
														revomeFromBucket(face.faceId);
													},
													tags: face.tags,
													title: face.identity,
												}}
											/>
										);
									})}
								</div>
							</div>
						</section>

						{Boolean(files.length) && (
							<>
								<div className={'h-[unset] w-[1px] bg-outline-variant'}>&nbsp;</div>
								<div className={'flex flex-col gap-y-4 w-[50%] max-h-full'}>
									<h2 className={'font-headline-xsmall text-on-background'}>Выберите наиболее подходящие кадры</h2>
									<h3>
										Найдено:{' '}
										<span className={'text-primary font-medium'}>
											{getTextWithDeclination(facesAmount, ['лицо', 'лица', 'лиц'])}
										</span>
									</h3>

									{facesAmount ? (
										<section className={'flex flex-col gap-y-4 h-full overflow-y-auto pr-[24px] w-full'}>
											{recognizedFaces.flatMap((item) => {
												return item.faces.map((face) => {
													const { x, y, w, h } = face.bbox;
													const uniqFaceId = `${x}-${y}-${w}-${h}`;
													return (
														<RecognitionItemSelectWrapper
															prefixBucket={prefixBucket}
															key={uniqFaceId}
															bucketName={bucketName}
															imageBlob={face.imageBlob}
															selectedFace={selectedFaceFromBucket}
															setSelectedFace={handleFaceFromBucketAdd}
															matches={face.matches?.filter((match) => {
																return match.bucketName === prefixBucket + FACES_BUCKET_NAME;
															})}
														/>
													);
												});
											})}
										</section>
									) : (
										<NotFound />
									)}
								</div>
							</>
						)}
					</div>
					<div className={'flex gap-x-4 mt-auto'}>
						<Button
							variant={'primary'}
							size={'xl'}
							onClick={() => {
								onStepClick(1);
							}}
							disabled={!faces.length}
						>
							Продолжить поиск
						</Button>
					</div>
				</div>
			</DashboardContentLayout>
		</DashboardLayout>
	);
};
