import React, { FC, PropsWithChildren } from 'react';
import classes from './MenuDrawer.module.css';
import { ReactComponent as Logo } from '@assets/illustrations/logo.svg';
import { Avatar } from '@components/uikit/Avatar/Avatar';
import { Icon } from '@components/uikit/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import { ROUTER } from '@constants/router';
import { notify } from '@modules/toast/notify';
import { useStoreDispatch, useStoreSelector } from '@store/store';
import { appSelector, setSidebarCollapse } from '@store/slices/appSlice';
import { authSlice, authSelector } from '@store/slices/authSlice';

export type MenuDrawerProps = PropsWithChildren & {
	className?: string;
};

export const MenuDrawer: FC<MenuDrawerProps> = ({ className: propClassName, children }) => {
	const { sidebarCollapse } = useStoreSelector(appSelector);
	const { user } = useStoreSelector(authSelector);

	const dispatch = useStoreDispatch();
	const classList = [classes['drawer'], sidebarCollapse && classes['drawer--collapse'], propClassName];
	const className = classList.filter(Boolean).join(' ').trim();

	const navigate = useNavigate();

	const handleLogoutButtonClick = async () => {
		try {
			dispatch(authSlice.actions.clearUser());
			localStorage.removeItem('creds');
			localStorage.removeItem('monitoring');
			navigate(ROUTER.LOGIN.PATH);
		} catch (err) {
			notify('Ошибка сброса авторизационных данных');
		}
	};

	return (
		<aside className={className}>
			<header className={'flex justify-between items-center h-[62px] p-[8px_22px]'}>
				<a href={'/'} className={classes['logo']}>
					<Logo />
				</a>
				<button
					className={classes['collapse']}
					onClick={() => {
						dispatch(setSidebarCollapse(!sidebarCollapse));
					}}
				>
					<Icon name={'chevronCompactDoubleLeft'} width={24} height={24} />
				</button>
			</header>
			<nav className={'flex flex-col gap-y-[6px]'}>{children}</nav>
			<footer className={classes['footer']}>
				<Avatar />
				<div className={classes['footer__credentials']}>
					<p className={'font-label-large text-on-surface overflow-ellipsis overflow-hidden'}>{user?.userName}</p>
					<p className={'font-body-small text-on-surface opacity-80 overflow-ellipsis overflow-hidden'}>
						Администратор
					</p>
				</div>
				<button className={classes['footer__exit']} onClick={handleLogoutButtonClick}>
					<Icon name={'boxArrowRightFill'} width={20} height={20} />
				</button>
			</footer>
		</aside>
	);
};
