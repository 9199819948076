import React, { FC } from 'react';

export type AvatarProps = {
	url?: string;
	sizePx?: number;
};

export const Avatar: FC<AvatarProps> = ({ url = '/assets/avatar_placeholder.svg', sizePx = '48' }) => {
	return (
		<img
			alt={'Аватар'}
			className={'rounded-[100%] object-cover flex-shrink-0'}
			src={url}
			width={sizePx}
			height={sizePx}
		/>
	);
};
