import React, { FC, PropsWithChildren } from 'react';
import { Icon, IconName } from '@components/uikit/Icon/Icon';

export type IconTextRowProps = PropsWithChildren & {
	className?: string;
	classNameText?: string;
	icon: IconName;
	size?: 'l';
};

/**
 * Компонент для отображения информационного поля с иконкой.
 * Прозрачность определяется через className (ex. opacity-50).
 * Расстоянием между иконкой и текстом регулируется через className (ex. !gap-x-[24px]).
 * Цвет определяется классом text-[color] (ex. text-primary).
 * Реализован как замена Label.
 * @param className - дополнительные классы контейнера
 * @param icon - иконка
 * @param children - текст
 */
export const IconTextRow: FC<IconTextRowProps> = ({
	className: propClassName,
	classNameText,
	icon,
	size,
	children,
}) => {
	const className = ['flex items-center gap-x-[8px]', propClassName].filter(Boolean).join(' ');
	const classNameSpan = [size ? '' : 'font-body-medium', classNameText].filter(Boolean).join(' ');
	return (
		<div className={className}>
			<Icon name={icon} width={size ? 20 : 18} height={size ? 20 : 18} className={'flex-shrink-0'} />
			<span className={classNameSpan}>{children}</span>
		</div>
	);
};
