import React, { FC, useState } from 'react';
import { LicensePlateStage } from '@pages/search-database/config/LicensePlateStage';
import { useBucketName } from '@hooks/useBucketName';

export const SearchDatabaseCreateLicensePlatesConfigPage: FC = () => {
	const [stage, setStage] = useState(1);

	const onStepClick = (index: number) => {
		setStage(index + 1);
	};
	const { bucketName } = useBucketName();

	return (
		<>
			{bucketName !== undefined && (
				<>
					<LicensePlateStage bucketName={bucketName} hidden={stage !== 1} onStepClick={onStepClick} />
				</>
			)}
		</>
	);
};
