import React, { FC, useState } from 'react';
import { Modal, ModalProps } from '@components/uikit/Modal/Modal';
import { CameraSelect, CameraSelectOption } from '@components/uikit/Select/CameraSelect/CameraSelect';
import { Button } from '@components/uikit/Button/Button';
import { Icon } from '@components/uikit/Icon/Icon';
import { SearchCameraModalCamera } from '@components/recognition/SearchCameraModal/SearchCameraModal.typedef';
import { Tooltip } from '@components/uikit/Tooltip/Tooltip';

type Camera = {
	camera_id: string;
	confidence?: number;
	title: string;
};

export type SearchCameraModalProps = Omit<ModalProps, 'title' | 'children'> & {
	selectedCamerasIds?: string[];
	onSubmit?: (detectionConfig: Camera) => void;
	onSubmitAndAdd?: (detectionConfig: Camera) => void;
	type: 'face' | 'plate';
};

export const SearchCameraModal: FC<SearchCameraModalProps> = ({
	onSubmit,
	onSubmitAndAdd,
	selectedCamerasIds,
	type,
	...propsProxy
}) => {
	const [failedFields, setFailedFields] = useState<{
		location?: string;
		target?: string;
		area?: string;
		camera?: string;
		config?: string;
		confidence?: string;
		primary?: string;
	}>({});

	// CAMERA SELECTION

	const [selectedCamera, setSelectedCamera] = useState<Nullable<SearchCameraModalCamera>>(null);

	const onChangeCamera = (option: CameraSelectOption) => {
		if (option?.value === selectedCamera?.id) {
			return;
		}

		if (option?.value) {
			setFailedFields((prev) => {
				return {
					...prev,
					camera: undefined,
				};
			});
		}

		setSelectedCamera(option ? { id: option.value, title: option.label } : null);
	};

	// VLIDAION

	const validate = () => {
		const failed: typeof failedFields = {};

		if (!selectedCamera) {
			failed.camera = 'Выберите камеру';
			failed.primary = failed.camera;
		}

		return Object.keys(failed).length === 0 || failed;
	};

	// SUBMIT

	const onSave = (add?: boolean) => {
		const valid = validate();
		if (valid !== true) {
			setFailedFields(valid);
			return;
		}

		const result: Camera = {
			title: selectedCamera?.title ?? '',
			camera_id: selectedCamera?.id ?? '',
		};

		setFailedFields({});

		if (add) {
			onSubmitAndAdd?.(result);
			setSelectedCamera(null);
			return;
		}
		onSubmit?.(result);
	};

	return (
		<Modal
			// Ограничение по высоте было увеличено по требованию, что не совсем соответствует макетам
			className={'w-[950px] min-w-[950px] !overflow-visible'}
			title={'Выбор камер для отслеживания'}
			{...propsProxy}
		>
			<div className={'h-[1px] bg-outline-variant mb-[20px]'} />
			<div className={'flex flex-col gap-y-[16px] relative'}>
				<div className={'flex flex-wrap gap-[20px]'}>
					<CameraSelect
						selectedCameraId={selectedCamera?.id}
						selectedCameraIds={selectedCamerasIds}
						onChange={onChangeCamera}
						isSearchable={true}
						className={'w-[348px]'}
						size={'lg'}
						error={Boolean(failedFields.camera)}
					/>
				</div>
				{failedFields.primary && <Tooltip error={true}>{failedFields.primary}</Tooltip>}

				<div className={'flex gap-x-[16px] mt-[24px]'}>
					<Button variant={'primary'} size={'xl'} onClick={onSave.bind(null, false)}>
						Сохранить
						<Icon name={'check2Circle'} width={24} height={24} />
					</Button>
					<Button variant={'dark'} size={'xl'} onClick={onSave.bind(null, true)}>
						Добавить ещё одну камеру
						<Icon name={'plus'} width={24} height={24} />
					</Button>
				</div>
			</div>
		</Modal>
	);
};
