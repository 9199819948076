import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreRootState } from '@store/store';

type AppSliceState = {
	sidebarCollapse: boolean;
};

const initialState: AppSliceState = {
	sidebarCollapse: false,
};

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setSidebarCollapse(state, action: PayloadAction<boolean>) {
			state.sidebarCollapse = action.payload;
		},
	},
});

export const { setSidebarCollapse } = appSlice.actions;
export const appSelector = (state: StoreRootState) => {
	return state.app;
};
export default appSlice;
