import React, { FC, useEffect, useState } from 'react';
import styles from './CameraCreate.module.css';
import { Icon } from '@components/uikit/Icon/Icon';
import { Button } from '@components/uikit/Button/Button';
import { TextInput } from '@components/uikit/TextInput/TextInput';
import { notify } from '@modules/toast/notify';

import {
	CameraResponse,
	CreateCameraParams,
	DetectorParams,
	UpdateCameraParams,
	useCreateCameraMutation,
	useDeleteCameraMutation,
	useGetCameraQuery,
	useGetCameraSnapshotQuery,
	useUpdateCameraMutation,
} from '@store/api';
import { Spinner } from '@components/uikit/Spinner/Spinner';
import { CameraIndicatorStatus } from '@components/camera/CameraIndicator/CameraIndicator';
import { cameraStatusAdapter } from '@utils/cameraStatusAdapter';
import { VideoScreenshot } from '@components/uikit/VideoPlayer/VideoScreenshot';
import { RuntimeEnv } from '@modules/runtime-env/RuntimeEnv';
import { Tooltip } from '@components/uikit/Tooltip/Tooltip';
import { Controller, useForm } from 'react-hook-form';
import { Accordion } from '@components/uikit/Accordion/Accordion';
import { ZonePicker } from '@components/uikit/ZonePick/ZonePick';
import { Zone } from '@hooks/useZonePick';
import { CameraModalSelect } from '@components/uikit/Select/CameraModalSelect/CameraModalSelect';
import { useCameraInitialization } from '@hooks/useCameraInitalization';
import { Toggle } from '@components/uikit/Toggle/Toggle';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTER } from '@constants/router';

const MAX_SAFE_NUMBER = 9007199254740991;

type Camera = {
	camera_uuid: string;
	camera_name?: string;
	history_search_delay: number;
	timezone_offset?: number;
	tags: string[];
	source: string;
	hlsUrl: string;
	videoStorage: number;
	status: CameraIndicatorStatus;
	detector_params: DetectorParams;
};

type FormValues = Camera & {
	isFace: boolean;
	isPlate: boolean;
	isExtended: boolean;
};

type FaceRoiType = [number, number, number, number];
type PlateRoiType = { x: number; y: number; width: number; height: number };

const cameraResponseToCameraMapper = (cameraResponse: CameraResponse): Camera => {
	const { camera_name, timezone_offset, camera_uuid, history_search_delay, tags, detector_params, hls, stream_params } =
		cameraResponse;

	const camera = {
		camera_name: camera_name,
		camera_uuid: camera_uuid,
		history_search_delay: history_search_delay,
		tags: tags,
		source: stream_params?.source ?? detector_params.face?.rtsp_path ?? '',
		hlsUrl: hls ?? '',
		timezone_offset: timezone_offset ?? 0,
		videoStorage: stream_params?.video_storage_period_in_seconds ?? 3600,
		status: cameraStatusAdapter(cameraResponse.detector_params),
	};

	if (detector_params.face_archive && detector_params.lpr_archive) {
		return {
			...camera,
			detector_params: {
				...cameraResponse.detector_params,
				face: {
					...cameraResponse.detector_params.face_archive,
				},
				lpr: {
					...cameraResponse.detector_params.lpr_archive,
				},
			},
		};
	}

	if (detector_params.face_archive) {
		return {
			...camera,
			detector_params: {
				...cameraResponse.detector_params,
				face: {
					...cameraResponse.detector_params.face_archive,
				},
				lpr: {
					...cameraResponse.detector_params.lpr,
				},
			},
		};
	}

	if (detector_params.lpr_archive) {
		return {
			...camera,
			detector_params: {
				...cameraResponse.detector_params,
				face: {
					...cameraResponse.detector_params.face,
				},
				lpr: {
					...cameraResponse.detector_params.lpr_archive,
				},
			},
		};
	}

	return {
		...camera,
		detector_params: {
			...cameraResponse.detector_params,
			face: {
				...cameraResponse.detector_params.face,
			},
			lpr: {
				...cameraResponse.detector_params.lpr,
			},
		},
	};
};
const cameraToCameraCreateMapper = (camera: Camera): CreateCameraParams => {
	return {
		camera_name: camera.camera_name,
		history_search_delay: camera.history_search_delay,
		tags: camera.tags,
		timezone_offset: camera.timezone_offset,
		stream_params: {
			source: camera.source,
			video_storage_period_in_seconds: camera.videoStorage,
		},
		detector_params: camera.detector_params,
	};
};
const cameraToCameraUpdateMapper = (camera: Camera): UpdateCameraParams => {
	return {
		camera_uuid: camera.camera_uuid,
		camera_name: camera.camera_name,
		history_search_delay: camera.history_search_delay,
		timezone_offset: camera.timezone_offset,
		tags: camera.tags,
		stream_params: {
			source: camera.source,
			video_storage_period_in_seconds: camera.videoStorage,
		},
		detector_params: camera.detector_params,
	};
};

const defaultCamera: Camera = {
	camera_name: '',
	camera_uuid: '',
	history_search_delay: 0,
	tags: [],
	source: '',
	videoStorage: 3600,
	timezone_offset: 0,
	hlsUrl: '',
	status: 'UNKNOWN',
	detector_params: {
		disable: false,
	},
};

export const CameraCreateZone: FC = () => {
	const [camParams] = useSearchParams();
	const objects = (camParams.get('objects') ?? '').split(',');
	const cameraId = camParams.get('id') ?? undefined;
	const isNewCamera = !cameraId;

	const { currentData } = useGetCameraQuery(cameraId ?? '', { skip: cameraId === undefined });

	const [deleteCamera, { isLoading: deleteCameraLoading }] = useDeleteCameraMutation();
	const [createCamera, { isLoading: createCameraLoading }] = useCreateCameraMutation();
	const [updateCamera, { isLoading: updateCameraLoading }] = useUpdateCameraMutation();

	const { isInitialization } = useCameraInitialization(currentData?.timestamp_created);
	const { currentData: videoScreenshotUrl, isLoading: getCameraSnapshotIsLoading } = useGetCameraSnapshotQuery({
		id: cameraId,
		isInitialization,
	});

	const [zoneFaceCoords, setZoneFaceCoords] = useState<FaceRoiType>();
	const [zonePlateCoords, setZonePlateCoords] = useState<PlateRoiType>();
	const [zoneType, setZoneType] = useState<'plate' | 'face'>();

	const {
		register,
		handleSubmit,
		getValues,
		reset,
		watch,
		control,
		formState: { errors, isDirty },
	} = useForm<FormValues>({
		values: currentData
			? {
					...cameraResponseToCameraMapper(currentData),
					isFace: Boolean(currentData.detector_params.face),
					isPlate: Boolean(currentData.detector_params.lpr),
					isExtended: currentData.tags.includes('isExtended'),
			  }
			: {
					...defaultCamera,
					isFace: objects?.includes('face'),
					isPlate: objects?.includes('plate'),
					isExtended: false,
			  },
		mode: 'all',
	});

	const isExtendedState = watch('isExtended');

	const handleSaveButtonClick = async (values: FormValues) => {
		const isZoneFaceChanged = currentData?.detector_params.face?.roi
			? currentData?.detector_params.face?.roi.reduce((acc, curr, i) => {
					if (zoneFaceCoords && curr !== zoneFaceCoords[i]) {
						acc = true;
					}

					return acc;
			  }, false)
			: true;

		const isZonePlateChanged =
			currentData?.detector_params.lpr?.lp_detection_parameters?.roi && zonePlateCoords
				? () => {
						if (
							currentData?.detector_params.lpr?.lp_detection_parameters?.roi?.x !== zonePlateCoords.x ||
							currentData?.detector_params.lpr?.lp_detection_parameters?.roi?.y !== zonePlateCoords.y ||
							currentData?.detector_params.lpr?.lp_detection_parameters?.roi?.width !== zonePlateCoords.width ||
							currentData?.detector_params.lpr?.lp_detection_parameters?.roi?.height !== zonePlateCoords.height
						) {
							return true;
						}

						return false;
				  }
				: true;

		if (isDirty || isZoneFaceChanged || isZonePlateChanged) {
			const face = {
				...values.detector_params.face,
				roi: zoneFaceCoords,
				detection_threshold: values.detector_params.face?.detection_threshold
					? +values.detector_params.face?.detection_threshold
					: undefined,
				face_send_delay: values.detector_params.face?.face_send_delay
					? +values.detector_params.face?.face_send_delay
					: undefined,
				max_pitch: values.detector_params.face?.max_pitch ? +values.detector_params.face?.max_pitch : undefined,
				max_yaw: values.detector_params.face?.max_yaw ? +values.detector_params.face?.max_yaw : undefined,
				min_face_duration: values.detector_params.face?.min_face_duration
					? +values.detector_params.face?.min_face_duration
					: undefined,
				qualified_max_aspect_ratio: values.detector_params.face?.qualified_max_aspect_ratio
					? +values.detector_params.face?.qualified_max_aspect_ratio
					: undefined,
				qualified_min_aspect_ratio: values.detector_params.face?.qualified_min_aspect_ratio
					? +values.detector_params.face?.qualified_min_aspect_ratio
					: undefined,
				quality_threshold: values.detector_params.face?.quality_threshold
					? +values.detector_params.face?.quality_threshold
					: undefined,
			};
			const lpr = {
				...values.detector_params.lpr,
				lp_tracking_parameters: {
					algorithm: 'content-matching' as const,
					detection_interval_ms: 100,
				},
				vehicle_classification_parameters: {
					enable_color: true,
					enable_category: true,
					enable_purpose: true,
				},
				lp_detection_parameters: {
					roi: zonePlateCoords,
					min_height_px: values.detector_params.lpr?.lp_detection_parameters?.min_height_px
						? +values.detector_params.lpr?.lp_detection_parameters?.min_height_px
						: undefined,
					max_height_px: values.detector_params.lpr?.lp_detection_parameters?.max_height_px
						? +values.detector_params.lpr?.lp_detection_parameters?.max_height_px
						: undefined,
					confidence_threshold: values.detector_params.lpr?.lp_detection_parameters?.confidence_threshold
						? +values.detector_params.lpr?.lp_detection_parameters?.confidence_threshold
						: undefined,
					min_readability_threshold: values.detector_params.lpr?.lp_detection_parameters?.min_readability_threshold
						? +values.detector_params.lpr?.lp_detection_parameters?.min_readability_threshold
						: undefined,
					min_occlusion_threshold: values.detector_params.lpr?.lp_detection_parameters?.min_occlusion_threshold
						? +values.detector_params.lpr?.lp_detection_parameters?.min_occlusion_threshold
						: undefined,
					min_illumination_threshold: values.detector_params.lpr?.lp_detection_parameters?.min_illumination_threshold
						? +values.detector_params.lpr?.lp_detection_parameters?.min_illumination_threshold
						: undefined,
				},
			};

			const params = () => {
				if (values.isFace && values.isPlate) {
					return {
						...values,
						tags: values.isExtended ? ['isExtended'] : [],
						detector_params: {
							disable: values.detector_params.disable,
							face: face,
							lpr: lpr,
						},
					};
				} else if (values.isPlate) {
					return {
						...values,
						tags: values.isExtended ? ['isExtended'] : [],
						detector_params: {
							disable: values.detector_params.disable,
							face_archive: face,
							lpr: lpr,
						},
					};
				} else if (values.isFace) {
					return {
						...values,
						tags: values.isExtended ? ['isExtended'] : [],
						detector_params: {
							disable: values.detector_params.disable,
							face: face,
							lpr_archive: lpr,
						},
					};
				} else {
					return {
						...values,
						tags: values.isExtended ? ['isExtended'] : [],
						detector_params: {
							disable: values.detector_params.disable,
							face_archive: face,
							lpr_archive: lpr,
						},
					};
				}
			};

			if (isNewCamera) {
				const res = await createCamera(cameraToCameraCreateMapper(params()));

				if ('data' in res) {
					notify('Камера создана');
				} else {
					notify('Не удалось создать камеру');
				}
			} else {
				const res = await updateCamera(cameraToCameraUpdateMapper(params()));

				if ('data' in res) {
					notify('Камера обновлена');
				} else {
					notify('Не удалось сохранить камеру');
				}
			}

			reset();
			setZoneType(undefined);

			isNewCamera && navigate(ROUTER.CAMERAS.PATH);
		}
	};

	const handleToggleModeButtonClick = async () => {
		currentData &&
			(await updateCamera({
				camera_uuid: getValues('camera_uuid'),
				detector_params: {
					...currentData.detector_params,
					disable: !getValues('detector_params.disable'),
				},
			}));
	};

	const navigate = useNavigate();
	const handleDeleteButtonClick = () => {
		if (cameraId) {
			deleteCamera(cameraId)
				.unwrap()
				.catch(() => {
					notify('Ошибка при удалении камеры');
				});
		}
		navigate(ROUTER.CAMERAS.PATH);
	};

	const isLoading = deleteCameraLoading || createCameraLoading || updateCameraLoading || getCameraSnapshotIsLoading;

	const cameraHlsURl = currentData?.hls
		? `${RuntimeEnv.getEnv('REACT_APP_API_URL')}${currentData.hls.substring(1, currentData.hls.length + 1)}`
		: undefined;
	const cameraDirectURl = currentData?.stream_params?.source.includes('youtube')
		? currentData?.stream_params?.source
		: undefined;

	const onFaceZone = (coords: Zone) => {
		setZoneFaceCoords([coords.x, coords.y, coords.w, coords.h]);
	};
	const onPlateZone = (coords: Zone) => {
		setZonePlateCoords({
			x: coords.x,
			y: coords.y,
			width: coords.w,
			height: coords.h,
		});
	};

	const handleZoneFaceEditButton = () => {
		zoneType === 'face' ? setZoneType(undefined) : setZoneType('face');
	};

	const handleZonePlateEditButton = () => {
		zoneType === 'plate' ? setZoneType(undefined) : setZoneType('plate');
	};

	useEffect(() => {
		currentData &&
			setZoneFaceCoords(currentData.detector_params.face?.roi ?? currentData.detector_params.face_archive?.roi);
		currentData &&
			setZonePlateCoords(
				currentData.detector_params.lpr?.lp_detection_parameters?.roi ??
					currentData.detector_params.lpr_archive?.lp_detection_parameters?.roi,
			);
	}, [currentData]);

	const cameraState = () => {
		/**
		 * Этот код определяет состояние камеры на основе нескольких переменных. Если isNewCamera или cameraDirectURl или cameraHlsURl имеют значение, состояние камеры считается "okay".
		 * Если videoScreenshotUrl равно 'initializating' и cameraDirectURl илиHlsURl` не имеют значения, состояние камеры считается "initializating". В противном случае состояние камеры считается "error".
		 */
		if (isNewCamera || cameraDirectURl || cameraHlsURl) return 'okay';

		if (videoScreenshotUrl === 'initializating' && !(cameraDirectURl || cameraHlsURl)) return 'initializating';

		return 'error';
	};
	return (
		<div className={'mt-6 flex flex-col gap-[56px] h-full relative'}>
			{isLoading && <Spinner noBg={true} />}

			{!isLoading && (
				<>
					<h2 className={'font-headline-large'}>Основные параметры</h2>
					<form onSubmit={handleSubmit(handleSaveButtonClick)} className="pb-[56px] flex flex-col gap-8">
						<div className="flex gap-6 h-[calc(100vh-40px-56px-32px-135px-32px-56px-56px)] overflow-scroll pt-3 pl-3 -mt-3 min-h-[500px]">
							<div className={styles['video-wrapper']}>
								{cameraState() === 'okay' && (
									<>
										<VideoScreenshot
											playing
											tryToReload
											className={'pointer-events-none'}
											width={'100%'}
											height={'100%'}
											url={cameraDirectURl ?? cameraHlsURl}
										/>
										{zoneType && (
											<ZonePicker
												type={zoneType}
												initialPlate={
													zonePlateCoords
														? {
																x: zonePlateCoords.x,
																y: zonePlateCoords.y,
																w: zonePlateCoords.width,
																h: zonePlateCoords.height,
														  }
														: undefined
												}
												initialFace={
													zoneFaceCoords
														? {
																x: zoneFaceCoords[0],
																y: zoneFaceCoords[1],
																w: zoneFaceCoords[2],
																h: zoneFaceCoords[3],
														  }
														: undefined
												}
												image={videoScreenshotUrl}
												setPlateZone={onPlateZone}
												setFaceZone={onFaceZone}
											/>
										)}
									</>
								)}

								{cameraState() === 'initializating' && (
									<div className="relative h-full flex items-center justify-center">
										<Spinner />
										<p className="break-normal mt-[60px]">Камера инициализируется</p>
									</div>
								)}

								{cameraState() === 'error' && (
									<div className={styles['error']}>
										<Icon name="exclamationTriangleFill" />
									</div>
								)}
							</div>
							<div className={styles['content']}>
								<Accordion
									propIsOpen={true}
									header={
										<>
											<div className="flex gap-3 items-center">
												<Icon name="diagram3" />
												<span className="font-headline-xsmall text-on-background">Данные</span>
											</div>
										</>
									}
									body={
										<>
											<div className="flex flex-col gap-3 pt-3">
												<TextInput
													{...register('camera_name', {
														required: 'Обязательно для заполнения',
													})}
													variant="filled"
													placeholder="Наименование"
													hideNotice={false}
													error={errors.camera_name?.message}
													disabled={currentData ? !currentData?.can_modify : false}
												/>
												<TextInput
													{...register('source', {
														required: 'Обязательно для заполнения',
													})}
													variant="filled"
													placeholder="Uri"
													hideNotice={false}
													error={errors.source?.message}
													disabled={currentData ? !currentData?.can_modify : false}
												/>
											</div>
										</>
									}
								/>

								<div className={'flex flex-col gap-6'}>
									<div className={'flex gap-3 flex-nowrap items-center'}>
										<Icon name="face" />
										<span className="font-headline-xsmall text-on-background">Детекция лиц</span>

										<Controller
											control={control}
											render={({ field: { value, name, onChange } }) => {
												return (
													<Toggle
														type={'toggle'}
														value={'isFace'}
														checked={value}
														name={name}
														onClick={(e) => {
															e.stopPropagation();
														}}
														onChange={onChange}
													/>
												);
											}}
											name={'isFace'}
										/>

										<Button
											type={'button'}
											onClick={handleZoneFaceEditButton}
											variant="dark"
											size="xs"
											className="ml-auto"
											disabled={
												(currentData ? !currentData?.can_modify : false) ||
												isNewCamera ||
												zoneType === 'plate' ||
												videoScreenshotUrl === 'initializating' ||
												!videoScreenshotUrl ||
												!watch('isFace')
											}
										>
											<Icon name="pencilSquare" width={16} height={16} />
											{isNewCamera && (
												<span>
													{zoneType === 'face' ? 'Сохранить зону детекции лиц' : 'Добавить зону детекции лиц'}
												</span>
											)}
											{!isNewCamera && (
												<span>
													{zoneType === 'face' ? 'Сохранить зону детекции лиц' : 'Изменить зону детекции лиц'}
												</span>
											)}
										</Button>
									</div>

									<div className={'flex gap-3 flex-nowrap items-center'}>
										<Icon name="car" />
										<span className="font-headline-xsmall text-on-background">Детекция ГРЗ</span>

										<Controller
											control={control}
											render={({ field: { value, name, onChange } }) => {
												return (
													<Toggle
														type={'toggle'}
														value={'isFace'}
														checked={value}
														name={name}
														onClick={(e) => {
															e.stopPropagation();
														}}
														onChange={onChange}
													/>
												);
											}}
											name={'isPlate'}
										/>

										<Button
											type={'button'}
											onClick={handleZonePlateEditButton}
											variant="dark"
											size="xs"
											className="ml-auto"
											disabled={
												(currentData ? !currentData?.can_modify : false) ||
												isNewCamera ||
												zoneType === 'face' ||
												videoScreenshotUrl === 'initializating' ||
												!videoScreenshotUrl ||
												!watch('isPlate')
											}
										>
											<Icon name="pencilSquare" width={16} height={16} />
											{isNewCamera && (
												<span>
													{zoneType === 'plate' ? 'Сохранить зону детекции ГРЗ' : 'Добавить зону детекции ГРЗ'}
												</span>
											)}
											{!isNewCamera && (
												<span>
													{zoneType === 'plate' ? 'Сохранить зону детекции ГРЗ' : 'Изменить зону детекции ГРЗ'}
												</span>
											)}
										</Button>
									</div>
								</div>

								<div className={'flex gap-3  justify-between flex-nowrap items-center'}>
									<span className="font-headline-xsmall text-on-background">Расширенные настройки камеры </span>

									<Controller
										control={control}
										render={({ field: { value, name, onChange } }) => {
											return (
												<Toggle
													type={'toggle'}
													value={'isFace'}
													checked={value}
													className="!gap-x-0"
													name={name}
													onClick={(e) => {
														e.stopPropagation();
													}}
													onChange={onChange}
												/>
											);
										}}
										name={'isExtended'}
									/>
								</div>
								<div className={'border border-tertiary-80 px-4 py-[6px] rounded-2 bg-tertiary-90'}>
									<Tooltip extraDark={true}>
										Расширенные настройки подразумевают настройку инженером или продвинутым пользователем
									</Tooltip>
								</div>

								{isExtendedState && (
									<>
										<Accordion
											header={
												<>
													<div className="flex gap-3 items-center">
														<Icon name="cameraVideo" />
														<span className="font-headline-xsmall text-on-background">Камера</span>
													</div>
												</>
											}
											body={
												<>
													<div className="flex flex-col gap-3 pt-3">
														<TextInput
															{...register('history_search_delay', {
																min: { value: 0, message: 'Не может быть меньше чем 0' },
																max: { value: MAX_SAFE_NUMBER, message: 'Не может быть слишком большим' },
																pattern: {
																	value: /^\d+$/,
																	message: 'Должно быть целым числом',
																},
															})}
															type="number"
															variant="filled"
															postfix="Сек."
															hideNotice={false}
															error={errors.history_search_delay?.message}
															disabled={currentData ? !currentData?.can_modify : false}
														/>
														<Tooltip>Задержка в секундах при автоматизированном поиске по истории камеры.</Tooltip>

														<Controller
															control={control}
															render={({ field: { value, onChange } }) => {
																return (
																	<>
																		<CameraModalSelect
																			zone={value}
																			handleChange={(e) => {
																				onChange(e);
																			}}
																			disabled={currentData ? !currentData?.can_modify : false}
																		/>
																		<Tooltip>Значение по умолчанию: UTC+00</Tooltip>
																	</>
																);
															}}
															name={'timezone_offset'}
														/>
													</div>
												</>
											}
										/>

										<Accordion
											header={
												<>
													<div className="flex gap-3 items-center">
														<Icon name="face" />
														<span className="font-headline-xsmall text-on-background">Детекция лиц</span>
													</div>
												</>
											}
											body={
												<>
													<div className="flex flex-col gap-6 pt-3">
														<TextInput
															{...register('detector_params.face.detection_threshold', {
																valueAsNumber: true,
																max: { value: 1, message: 'Не может быть больше чем 1' },
																min: { value: 0, message: 'Не может быть меньше чем 0' },
															})}
															step={0.01}
															variant="filled"
															placeholder="Порог уверенности детектора"
															hideNotice={false}
															type="number"
															error={errors.detector_params?.face?.detection_threshold?.message}
															disabled={currentData ? !currentData?.can_modify : false}
															className="-mb-2"
														/>
														<Tooltip>Значение от 0 (не лицо) до 1 (лицо). Значение по умолчанию: 0.95</Tooltip>

														<TextInput
															{...register('detector_params.face.quality_threshold', {
																valueAsNumber: true,
																max: { value: 1, message: 'Не может быть больше чем 1' },
																min: { value: 0, message: 'Не может быть меньше чем 0' },
															})}
															step={0.01}
															variant="filled"
															placeholder="Порог на минимальное качество лиц"
															hideNotice={false}
															type="number"
															error={errors.detector_params?.face?.quality_threshold?.message}
															disabled={currentData ? !currentData?.can_modify : false}
															className="-mb-2"
														/>
														<Tooltip>Значение от 0 (не лицо) до 1 (лицо).</Tooltip>

														<TextInput
															{...register('detector_params.face.face_send_delay', {
																min: { value: 0, message: 'Не может быть меньше чем 0' },
																max: { value: MAX_SAFE_NUMBER, message: 'Не может быть слишком большим' },
																pattern: {
																	value: /^\d+$/,
																	message: 'Должно быть целым числом',
																},
															})}
															variant="filled"
															placeholder="Задержка отправки лиц"
															hideNotice={false}
															type="number"
															postfix="Миллисекунды"
															error={errors.detector_params?.face?.face_send_delay?.message}
															disabled={currentData ? !currentData?.can_modify : false}
														/>
														<Tooltip>Значение по умолчанию: 3000</Tooltip>

														<div
															className={`flex items-center rounded-2 bg-primary-95 -mb-2  ${
																currentData && !currentData.can_modify
																	? 'pointer-events-none opacity-50 select-none'
																	: ''
															}
											${
												errors.detector_params?.face?.max_pitch?.message ||
												errors.detector_params?.face?.max_yaw?.message
													? 'bg-error-90 text-error'
													: 'text-secondary'
											} `}
														>
															<TextInput
																{...register('detector_params.face.max_yaw', {
																	min: { value: 0, message: 'Не может быть меньше чем 0' },
																	max: { value: 90, message: 'Не может быть больше чем 90' },
																	pattern: {
																		value: /^\d+$/,
																		message: 'Должно быть целым числом',
																	},
																})}
																variant="filled"
																className="max-w-[50px] !opacity-100 relative"
																hideNotice={true}
																type="number"
																error={
																	errors.detector_params?.face?.max_yaw?.message ||
																	errors.detector_params?.face?.max_pitch?.message
																}
																disabled={currentData ? !currentData?.can_modify : false}
															/>
															<span className={`text-secondary ${!currentData?.can_modify ? 'opacity-50' : ''}`}>
																/
															</span>
															<TextInput
																{...register('detector_params.face.max_pitch', {
																	min: { value: 0, message: 'Не может быть меньше чем 0' },
																	max: { value: 90, message: 'Не может быть больше чем 90' },
																	pattern: {
																		value: /^\d+$/,
																		message: 'Должно быть целым числом',
																	},
																})}
																hideNotice={true}
																className="max-w-[50px] !opacity-100 relative"
																variant="filled"
																type="number"
																error={
																	errors.detector_params?.face?.max_yaw?.message ||
																	errors.detector_params?.face?.max_pitch?.message
																}
																disabled={currentData ? !currentData?.can_modify : false}
															/>
															<span className={`grow text-right pr-3 }`}>Градусы</span>
														</div>
														{(errors.detector_params?.face?.max_pitch?.message ||
															errors.detector_params?.face?.max_yaw?.message) && (
															<div className=" text-error font-label-medium">
																{errors.detector_params?.face?.max_pitch?.message && (
																	<p>{errors.detector_params?.face?.max_pitch?.message}</p>
																)}
																{errors.detector_params?.face?.max_yaw?.message && (
																	<p>{errors.detector_params?.face?.max_yaw?.message}</p>
																)}
															</div>
														)}

														<Tooltip>Максимальный угол поворота лица. Значение по умолчанию: 55/90</Tooltip>

														<TextInput
															{...register('detector_params.face.min_face_duration', {
																min: { value: 0, message: 'Не может быть меньше чем 0' },
																max: { value: MAX_SAFE_NUMBER, message: 'Не может быть слишком большим' },
																pattern: {
																	value: /^\d+$/,
																	message: 'Должно быть целым числом',
																},
															})}
															variant="filled"
															placeholder="Мин. продолжительность нахождения лица в кадре"
															hideNotice={false}
															postfix="Миллисекунды"
															type="number"
															error={errors.detector_params?.face?.min_face_duration?.message}
															disabled={currentData ? !currentData?.can_modify : false}
														/>
														<Tooltip>Значение по умолчанию: 0</Tooltip>

														<TextInput
															{...register('detector_params.face.qualified_min_aspect_ratio', {
																min: { value: 0, message: 'Не может быть меньше чем 0' },
																max: { value: MAX_SAFE_NUMBER, message: 'Не может быть слишком большим' },
																pattern: {
																	value: /^\d+$/,
																	message: 'Должно быть целым числом',
																},
															})}
															variant="filled"
															placeholder="Мин. отношение высоты лица к ширине"
															hideNotice={false}
															type="number"
															disabled={currentData ? !currentData?.can_modify : false}
															className="-mb-2"
															error={errors.detector_params?.face?.qualified_min_aspect_ratio?.message}
														/>
														<Tooltip>
															Используется для фильтрации лиц с сильными геометрическими искажениями. Данный параметр не
															влияет на скорость работы детектора лиц.
														</Tooltip>

														<TextInput
															{...register('detector_params.face.qualified_max_aspect_ratio', {
																min: { value: 0, message: 'Не может быть меньше чем 0' },
																max: { value: MAX_SAFE_NUMBER, message: 'Не может быть слишком большим' },
																pattern: {
																	value: /^\d+$/,
																	message: 'Должно быть целым числом',
																},
															})}
															variant="filled"
															placeholder="Макс. отношение высоты лица к ширине"
															hideNotice={false}
															type="number"
															disabled={currentData ? !currentData?.can_modify : false}
															className="-mb-2"
															error={errors.detector_params?.face?.qualified_max_aspect_ratio?.message}
														/>
														<Tooltip>
															Используется для фильтрации лиц с сильными геометрическими искажениями. Данный параметр не
															влияет на скорость работы детектора лиц.
														</Tooltip>
													</div>
												</>
											}
										/>

										<Accordion
											header={
												<>
													<div className="flex gap-3 items-center">
														<Icon name="car" />
														<span className="font-headline-xsmall text-on-background">Детекция ГРЗ</span>
													</div>
												</>
											}
											body={
												<>
													<div className="flex flex-col gap-6 pt-3">
														<TextInput
															{...register('detector_params.lpr.lp_detection_parameters.min_height_px', {
																min: { value: 0, message: 'Не может быть меньше чем 0' },
																max: { value: MAX_SAFE_NUMBER, message: 'Не может быть слишком большим' },
																pattern: {
																	value: /^\d+$/,
																	message: 'Должно быть целым числом',
																},
															})}
															variant="filled"
															placeholder="Минимальная высота"
															hideNotice={false}
															postfix="Пиксели"
															type="number"
															error={errors.detector_params?.lpr?.lp_detection_parameters?.min_height_px?.message}
															disabled={currentData ? !currentData?.can_modify : false}
															className="-mb-2"
														/>
														<Tooltip>Значение по умолчанию: 50</Tooltip>

														<TextInput
															{...register('detector_params.lpr.lp_detection_parameters.max_height_px', {
																min: { value: 0, message: 'Не может быть меньше чем 0' },
																max: { value: MAX_SAFE_NUMBER, message: 'Не может быть слишком большим' },
																pattern: {
																	value: /^\d+$/,
																	message: 'Должно быть целым числом',
																},
															})}
															variant="filled"
															placeholder="Максимальная высота"
															hideNotice={false}
															postfix="Пиксели"
															type="number"
															error={errors.detector_params?.lpr?.lp_detection_parameters?.max_height_px?.message}
															disabled={currentData ? !currentData?.can_modify : false}
															className="-mb-2"
														/>
														<Tooltip>Значение по умолчанию: 120</Tooltip>

														<TextInput
															{...register('detector_params.lpr.lp_detection_parameters.confidence_threshold', {
																valueAsNumber: true,
																max: { value: 1, message: 'Не может быть больше чем 1' },
																min: { value: 0, message: 'Не может быть меньше чем 0' },
															})}
															variant="filled"
															placeholder="Порог уверенности детектора "
															hideNotice={false}
															type="number"
															step={0.01}
															error={
																errors.detector_params?.lpr?.lp_detection_parameters?.confidence_threshold?.message
															}
															disabled={currentData ? !currentData?.can_modify : false}
														/>
														<Tooltip>Значение по умолчанию: 0.8</Tooltip>

														<TextInput
															{...register('detector_params.lpr.lp_detection_parameters.min_readability_threshold', {
																valueAsNumber: true,
																max: { value: 1, message: 'Не может быть больше чем 1' },
																min: { value: 0, message: 'Не может быть меньше чем 0' },
															})}
															variant="filled"
															placeholder="Порог читаемости ГРЗ"
															hideNotice={false}
															type="number"
															step={0.01}
															error={
																errors.detector_params?.lpr?.lp_detection_parameters?.min_readability_threshold?.message
															}
															disabled={currentData ? !currentData?.can_modify : false}
														/>
														<Tooltip>Значение по умолчанию: 0.7</Tooltip>

														<TextInput
															{...register('detector_params.lpr.lp_detection_parameters.min_occlusion_threshold', {
																valueAsNumber: true,
																max: { value: 1, message: 'Не может быть больше чем 1' },
																min: { value: 0, message: 'Не может быть меньше чем 0' },
															})}
															variant="filled"
															placeholder="Порог определения перекрытия"
															hideNotice={false}
															type="number"
															step={0.01}
															disabled={currentData ? !currentData?.can_modify : false}
															className="-mb-2"
															error={
																errors.detector_params?.lpr?.lp_detection_parameters?.min_occlusion_threshold?.message
															}
														/>
														<Tooltip>Значение по умолчанию: 1</Tooltip>

														<TextInput
															{...register('detector_params.lpr.lp_detection_parameters.min_illumination_threshold', {
																valueAsNumber: true,
																max: { value: 1, message: 'Не может быть больше чем 1' },
																min: { value: 0, message: 'Не может быть меньше чем 0' },
															})}
															variant="filled"
															placeholder="Неравномерность освещения"
															hideNotice={false}
															type="number"
															step={0.01}
															disabled={currentData ? !currentData?.can_modify : false}
															className="-mb-2"
															error={
																errors.detector_params?.lpr?.lp_detection_parameters?.min_illumination_threshold
																	?.message
															}
														/>
														<Tooltip>Значение по умолчанию: 1</Tooltip>
													</div>
												</>
											}
										/>
									</>
								)}
							</div>
						</div>

						<div className="absolute w-full justify-end flex gap-6 bottom-0 pb-5 bg-neutral ">
							{!isNewCamera && (
								<Button
									type={'button'}
									onClick={handleDeleteButtonClick}
									variant="error"
									outline
									className="flex self-start gap-2 item-center"
									disabled={currentData ? !currentData?.can_modify : false}
								>
									Удалить камеру <Icon name="x" />
								</Button>
							)}

							<Button
								type={'button'}
								onClick={handleToggleModeButtonClick}
								variant="grey"
								className="flex self-start gap-2 item-center"
								disabled={currentData ? !currentData?.can_modify : false}
							>
								<span>{getValues('detector_params.disable') ? 'Включить' : 'Выключить'} камеру</span>
								<Icon name="power" />
							</Button>

							<Button
								variant="primary"
								className="flex self-start gap-2 item-center"
								disabled={currentData ? !currentData?.can_modify : false}
							>
								Сохранить <Icon name="check2Circle" />
							</Button>
						</div>
					</form>
				</>
			)}
		</div>
	);
};
