import { CameraZone } from '@components/camera/Monitoring/MonitoringSlot/CameraZoneSelector/CameraZoneSelector.typedef';
import { useGetBucketConfigQuery, useGetBucketPlatesQuery, useGetCamerasByBucketQuery } from '@store/api';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useResultPlatePage = () => {
	const { search } = useLocation();
	const startDate = new URLSearchParams(search).get('startDate') ?? undefined;
	const endDate = new URLSearchParams(search).get('endDate') ?? undefined;
	const bucket_id = new URLSearchParams(search).get('bucket_id') ?? undefined;

	const { currentData: bucketConfig } = useGetBucketConfigQuery(bucket_id ?? '', { skip: bucket_id === undefined });
	const { currentData } = useGetBucketPlatesQuery(bucket_id ?? '', { skip: bucket_id === undefined });

	const plates = (currentData?.plates ?? []).map((plate) => {
		return plate.plate_text;
	});

	const { currentData: camerasData } = useGetCamerasByBucketQuery(bucket_id ?? '', { skip: bucket_id === undefined });
	const cameras: string[] = (camerasData?.cameras ?? []).map((camera) => {
		return camera.camera_uuid;
	});

	const [chosenCameraURL, setChosenCameraURL] = useState<string>();
	const onChangeCameras = (cameras: string) => {
		setChosenCameraURL(cameras);
	};

	useEffect(() => {
		if (camerasData?.cameras) {
			setChosenCameraURL(cameras[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [camerasData?.cameras]);

	const zones = (camerasData?.cameras ?? []).reduce<CameraZone[]>((accumulator, item) => {
		accumulator.push({
			id: item.camera_uuid,
			title: item.camera_name,
			isOff: false,
		});
		return accumulator;
	}, []);

	return { startDate, endDate, bucketConfig, chosenCameraURL, onChangeCameras, plates, zones };
};
