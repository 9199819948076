import React, { FC } from 'react';
import { Step, StepProps } from '@components/uikit/StepRow/Step/Step';

export type StepRowProps = {
	steps: StepProps[];
	onStepClick?: (index: number) => void;
};

export const StepRow: FC<StepRowProps> = ({ steps, onStepClick }) => {
	return (
		<div
			className={'grid'}
			style={{
				gridTemplateColumns: `repeat(${steps.length}, 1fr)`,
				gridTemplateRows: '1fr',
			}}
		>
			{steps.map((step, index) => {
				return <Step {...step} onClick={onStepClick?.bind(null, index)} key={index} />;
			})}
		</div>
	);
};
