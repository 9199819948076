import { EventDateTimeWrapper } from '@components/event/EventDateTime/EventDateTimeWrapper';
import { EventDetatils } from '@components/event/EventDetails/EventDetails';
import { EventEventsList } from '@components/event/EventEventsList/EventEventsList';
import { EventFilterWrapper } from '@components/event/EventFilter/EventFilterWrapper';
import { EventTypeSelect } from '@components/uikit/Select/EventTypeSelect/EventTypeSelect';

export const EventsOverviewZone = () => {
	return (
		<div className={'flex flex-col gap-y-[32px] flex-grow h-full'}>
			<div className={'flex gap-x-[32px] h-full'}>
				<section className={'flex flex-col gap-y-[20px] flex-grow p-4 bg-neutral-98 rounded-2 min-w-[782px]'}>
					<div className="flex gap-2">
						<EventTypeSelect containerClassName="mr-auto min-w-[240px]" />
						<div className="flex gap-2">
							<EventDateTimeWrapper />
							<EventFilterWrapper />
						</div>
					</div>
					<EventEventsList />
				</section>
				<section className={'flex flex-col min-w-fit h-fit sticky top-[24px]'}>
					<EventDetatils />
				</section>
			</div>
		</div>
	);
};
