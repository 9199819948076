import React, { FC } from 'react';
import classes from './ToggleCheckbox.module.css';
import { Icon } from '@components/uikit/Icon/Icon';

export type ToggleCheckboxProps = Omit<JSX.IntrinsicElements['input'], 'type'>;

export const ToggleCheckbox: FC<ToggleCheckboxProps> = (propsProxy) => {
	const className = [classes['input'], propsProxy.checked && classes['input--checked']].filter(Boolean).join(' ');
	return (
		<div className={'flex justify-center items-center'}>
			<div className={'relative h-[24px]'}>
				<input className={className} type={'checkbox'} {...propsProxy} />
				<Icon className={classes['icon']} name={'check2'} width={16} height={16} />
			</div>
		</div>
	);
};
