import React, { FC, useState } from 'react';

import { Button } from '@components/uikit/Button/Button';
import { TextInput } from '@components/uikit/TextInput/TextInput';
import { Select } from '@components/uikit/Select/Select';
import { isUrl } from '@utils/isUrl';

export type SearchFormExternalFileProps = {
	onChange?: (value: unknown) => void;
	onChangeConfidence?: (value: number) => void;
	onValidate?: () => void;
};

type Errors = {
	fileUrl?: string;
	confidence?: string;
};

export const SearchFormExternalFile: FC<SearchFormExternalFileProps> = ({
	onChange,
	onChangeConfidence,
	onValidate,
}) => {
	const [fileUrl, setFileUrl] = useState('');
	const [confidence, setConfidence] = useState<number>(70);
	const [errors, setErrors] = useState<Errors>();

	const validate = () => {
		const errors: Errors = {};

		if (!fileUrl || !isUrl(fileUrl)) {
			errors.fileUrl = 'Укажите корректную ссылку на файл для анализа';
		}

		if (!confidence) {
			errors.confidence = 'Укажите процент вероятности совпадения';
		}

		if (confidence > 100 || confidence < 0) {
			errors.confidence = 'Допустимое значение от 0 до 100';
		}

		if (Object.keys(errors).length > 0) {
			setErrors(errors);
			return false;
		}

		setErrors({});
		return true;
	};

	const onSubmit = () => {
		onValidate?.();

		const isValid = validate();

		if (!isValid) {
			return;
		}

		onChange?.({
			source_type: 'external_file',
			file_path: fileUrl,
		});

		onChangeConfidence?.(confidence / 100);
	};

	return (
		<div className={'flex flex-col gap-y-[24px]'}>
			<div className={'flex gap-x-[32px]'}>
				<div className={'flex flex-col gap-y-[20px]'}>
					<h2 className={'font-headline-xsmall text-on-background'}>Добавьте файл для анализа</h2>
					<TextInput
						value={fileUrl}
						hideNotice={false}
						error={errors?.fileUrl}
						onChange={(e) => {
							setFileUrl(e.target.value);
						}}
						className={'!w-[348px] mb-[-16px]'}
						type={'text'}
						variant={'filled'}
						placeholder={'Ссылка на файл'}
					/>
				</div>
				<div className={'flex flex-col gap-y-[20px]'}>
					<h2 className={'font-headline-xsmall text-on-background'}>Процент вероятности совпадения</h2>
					<TextInput
						className={'!w-[348px] mb-[-16px]'}
						type={'number'}
						min={0}
						hideNotice={false}
						error={errors?.confidence}
						max={100}
						value={confidence.toString()}
						onChange={(e) => {
							setConfidence(parseInt(e.target.value));
						}}
						variant={'filled'}
						placeholder={'Процент вероятности совпадения'}
					/>
				</div>
				<div className={'flex flex-col gap-y-[20px]'}>
					<h2 className={'font-headline-xsmall text-on-background'}>Уведомление при обнаружении</h2>
					<Select
						className={'!w-[348px]'}
						valueContainerIcon={'clock'}
						size={'lg'}
						placeholder={'Уведомление по анализу потока'}
						value={{
							label: 'Мгновенно',
							value: 'instantly',
						}}
						options={[
							{
								label: 'Мгновенно',
								value: 'instantly',
							},
						]}
					/>
				</div>
			</div>
			<Button className={'w-fit mt-[6px]'} variant={'primary'} size={'xl'} onClick={onSubmit}>
				Продолжить
			</Button>
		</div>
	);
};
