export const numberTypeRegexp = {
	rus: {
		car: /^[A-Z*][0-9*]{3}[A-Z*]{2}[0-9*]{2,3}$/i,
		taxi: /^[A-Z*]{2}[0-9*]{3}[0-9*]{2,3}$/i,
		trailer: /^[A-Z*]{2}[0-9*]{4}[0-9*]{2,3}$/i,
		motorcycle: /^[0-9*]{4}[A-Z*]{2}[0-9*]{2,3}$/i,
	},
	by: {
		car: /^[0-9*]{4}[A-Z*]{2}[0-9*]$/i,
		taxi: /^[0-9*]{1}[A-Z*]{3}[0-9*]{4}$/i,
		trailer: /^[0-9*]{4}[A-Z*]{2}[0-9*]$/i,
		motorcycle: /^[0-9*]{4}[A-Z*]{2}[0-9*]$/i,
	},
	ua: {
		car: /^[A-Z*]{2}[0-9*]{4}[A-Z*]{2}$/i,
		taxi: /^[A-Z*]{2}[0-9*]{4}[A-Z*]{2}$/i,
		trailer: /^[A-Z*]{2}[0-9*]{4}[A-Z*]{2}$/i,
		motorcycle: /^[A-Z*]{2}[0-9*]{4}[A-Z*]{2}$/i,
	},
	kz: {
		car: /^[0-9*]{3}[A-Z*]{3}[0-9*]{1,2}$/i,
		taxi: /^[A-Z*][0-9*]{4}[0-9*]{1,2}$/i,
		trailer: /^[A-Z*]{3}[0-9*]{2}[0-9*]{1,2}$/i,
		motorcycle: /^[0-9*]{2}[A-Z*]{2}[0-9*]{1,2}$/i,
	},
	other: {
		car: /^[0-9A-Z*]{3,10}$/i,
		taxi: /^[0-9A-Z*]{3,10}$/i,
		trailer: /^[0-9A-Z*]{3,10}$/i,
		motorcycle: /^[0-9A-Z*]{3,10}$/i,
	},
} as const;
