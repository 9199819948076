import React from 'react';
import styles from './SimplePaginator.module.css';
import { Button } from '@components/uikit/Button/Button';
import { Icon } from '@components/uikit/Icon/Icon';

type SimplePaginatorProps = {
	onRightClick?: () => void;
	onLeftClick?: () => void;
	className?: HTMLDivElement['className'];
	page?: number;
};

const SimplePaginator = ({ onRightClick, page, onLeftClick, className }: SimplePaginatorProps) => {
	return (
		<div className={className}>
			<div className={styles['container']}>
				<Button size={'sm'} onClick={onLeftClick} disabled={onLeftClick === undefined}>
					<Icon width={24} height={24} className={styles['left-icon']} name={'chevronCompactDown'} />
				</Button>
				<span className="font-title-large whitespace-nowrap">{Number(page) + 1}</span>
				<Button size={'sm'} onClick={onRightClick} disabled={onRightClick === undefined}>
					<Icon width={24} height={24} className={styles['right-icon']} name={'chevronCompactDown'} />
				</Button>
			</div>
		</div>
	);
};

export default SimplePaginator;
