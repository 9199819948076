import { Button } from '@components/uikit/Button/Button';
import { IconButton } from '@components/uikit/Button/IconButton';
import { TextInput } from '@components/uikit/TextInput/TextInput';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { EventFilterValues } from '../EventFilter/EventFilter';

export type EventDateTimeProps = {
	setIsOpen: (isOpen: boolean) => void;
	onDateTimeChange: (data: EventDateTimeValues) => void;
	filterParams?: (EventFilterValues & EventDateTimeValues) | undefined;
};

export type EventDateTimeValues = {
	startDate: string | undefined;
	endDate: string | undefined;
};

export const EventDateTime: FC<EventDateTimeProps> = ({ setIsOpen, onDateTimeChange, filterParams }) => {
	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
	} = useForm<EventDateTimeValues>({
		defaultValues: {
			startDate: filterParams?.startDate ?? '',
			endDate: filterParams?.endDate ?? '',
		},
	});

	const handleForm = (e: EventDateTimeValues) => {
		onDateTimeChange(e);
		setIsOpen(false);
	};

	const handleReset = () => {
		reset({ startDate: '', endDate: '' });
	};

	return (
		<div className="w-[500px] rounded-2 border border-outline-variant">
			<div className={'p-4 flex justify-between items-center border-outline-variant border-b'}>
				<p className={'font-title-medium'}>Выберите период дат и времени</p>
				<IconButton
					onClick={() => {
						setIsOpen(false);
					}}
					className={'flex-shrink-0'}
					icon={'x'}
					variant={'transparent'}
					size="xs"
				/>
			</div>
			<form onSubmit={handleSubmit(handleForm)}>
				<div className="p-4 flex gap-4 items-center">
					<Controller
						control={control}
						render={({ field }) => {
							return (
								<TextInput
									value={field.value}
									onChange={field.onChange}
									type={'datetime-local'}
									variant={'bordered'}
									placeholder={'Начало'}
									error={errors.startDate?.message}
									icon={'calendar'}
									hideNotice={false}
								/>
							);
						}}
						name={'startDate'}
					/>

					<Controller
						control={control}
						render={({ field }) => {
							return (
								<TextInput
									value={field.value}
									onChange={field.onChange}
									type={'datetime-local'}
									variant={'bordered'}
									placeholder={'Конец'}
									error={errors.endDate?.message}
									icon={'calendar'}
									hideNotice={false}
								/>
							);
						}}
						name={'endDate'}
					/>
				</div>
				<div className={'p-4 flex gap-4 justify-between items-center border-t border-outline-variant'}>
					<Button variant="grey" outline={true} className="w-full" type="button" onClick={handleReset}>
						Сбросить
					</Button>
					<Button type="submit" className="w-full">
						Сохранить
					</Button>
				</div>
			</form>
		</div>
	);
};
