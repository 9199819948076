import { useState } from 'react';
import { Matches } from '@components/recognition/RecognitionItemSelect/wrapper/RecognitionItemSelectWrapper.typedef';
import { useGetFacesMutation, useGetMatchMutation } from '@store/api';
import { base64ToBlob } from 'image-process';
import { notifyError } from '@modules/toast/notifyError';

type RecognizedFace = {
	type: 'face' | 'upper_body' | 'full_body';
	bbox: {
		x: number;
		y: number;
		w: number;
		h: number;
	};
	score: number;
	imageBlob: { blob: Blob; url: string };
	overviewBase64: string;
	matches?: Matches[];
};

type RecognizedFaces = {
	fileName: string;
	faces: RecognizedFace[];
};

export const useRecognizedFaces = () => {
	const [match] = useGetMatchMutation();
	const [faces] = useGetFacesMutation();
	const [recognizedFaces, setRecognizedFaces] = useState<RecognizedFaces[]>([]);

	// Функция для поиска совпадений в базе
	const getMatches = async (file: Blob) => {
		try {
			const res = await match({
				params: {
					threshold: 0.9,
					limit: 10,
				},
				image: file,
			}).unwrap();

			const matches = res.faces.flatMap(({ bucket_matches }) => {
				return bucket_matches.map((it) => {
					return {
						id: it.face_id,
						title: it.identity,
						tags: it.tags,
						similarity: it.similarity,
						bucketName: it.bucket_name,
					};
				});
			});
			return matches;
		} catch (error) {
			notifyError('Ну удалось найти совпадения в базе');
		}
	};

	// Функция определяет лица в переданных источниках (изображения, видеофрагменты)
	const makeNewRecognizedFaces = async (files: File[]) => {
		try {
			const promises = files.map(async (file) => {
				const response = await faces({
					params: {
						embedded_img: true,
						square: false,
					},
					file: file,
				}).unwrap();

				const arrFaces = await Promise.all(
					response.detections.map(async (face) => {
						const blob = base64ToBlob(`data:image/jpeg;base64,${face.img_b64}`);
						const blobUrl = URL.createObjectURL(blob);
						const matches = await getMatches(blob);
						return {
							...face,
							imageBlob: { blob, url: blobUrl },
							overviewBase64: face.overview_b64,
							matches,
						};
					}),
				);
				return {
					faces: arrFaces,
					fileName: file.name,
				};
			});

			const recognizedFaces = await Promise.all(promises);
			setRecognizedFaces(recognizedFaces);
		} catch (error) {
			notifyError('Ну удалось определить лица в отправленных источниках.');
		}
	};

	// Функция для скрытия добавленых лиц при добавлении лиц в базу лиц и номеров
	const recognizedFacesFilter = (id: string) => {
		setRecognizedFaces((prev) => {
			return prev.map((item) => {
				return {
					...item,
					faces: item.faces.filter((face) => {
						const uniqFaceId = `${face.bbox.x}-${face.bbox.y}-${face.bbox.w}-${face.bbox.h}`;
						return uniqFaceId !== id;
					}),
				};
			});
		});
	};

	return { recognizedFaces, makeNewRecognizedFaces, recognizedFacesFilter };
};
