import { api, FaceCameraEvent, LprEventData, useGetEventsByCamerasQuery } from '@store/api';
import { useEffect } from 'react';
import { useStoreDispatch, useStoreSelector } from '@store/store';
import { StoreEvent, eventsSelector, eventsSlice } from '@store/slices/eventsSlice';
import { personMapper } from '@modules/events/personMapper';
import { plateMapper } from '@modules/events/plateMapper';
import { eventsListSlice } from '@store/slices/eventListSlice';
import { useDateEventsFinder } from './useDateEventsFinder';

import { filterParamsSectionMapper } from '@utils/filterParamsSectionMapper';
import { useEventsFilter } from './useEventsFilter';
import { useWebSocketEvents } from './useWebSocketEvents';

type UseMonitoringEventsPageProps = {
	camerasId?: string[];
};

/**
 * Хук для работы со списком событий на странице "Панель наблюдения"
 * @param camerasId id выбранных камер
 */

export const useMonitoringEventsPage = ({ camerasId = [] }: UseMonitoringEventsPageProps) => {
	const dispatch = useStoreDispatch();
	const { filterParams, filterFacesHandler, filterPlatesHandler } = useEventsFilter();
	const max_ts = useStoreSelector((store) => {
		return store.events.max_ts;
	});

	const { maxTime, minTime, isActive } = useDateEventsFinder({
		type: filterParams.type,
		startDate: filterParams.startDate,
		endDate: filterParams.endDate,
	});

	const offset = useStoreSelector((store) => {
		return store.events.offset;
	});

	useEffect(() => {
		return () => {
			dispatch(eventsListSlice.actions.clearEvents());
			dispatch(eventsSlice.actions.clearEvents());
		};
	}, [dispatch]);

	const { currentData } = useGetEventsByCamerasQuery(
		{
			camera: camerasId.join(','),
			offset: offset,
			limit: 10,
			sort: 'desc',
			min_ts: minTime,
			max_ts: maxTime,
			include_boundaries: false,
			type: filterParamsSectionMapper(filterParams.section),
		},
		{ skip: !camerasId.length },
	);
	const { trackIds } = useStoreSelector(eventsSelector);

	useEffect(() => {
		dispatch(eventsListSlice.actions.clearEvents());
		dispatch(eventsSlice.actions.clearEvents());
		// eslint-disable-next-line
	}, [filterParams, camerasId]);

	useEffect(() => {
		if (currentData === undefined) {
			return;
		}

		dispatch(eventsSlice.actions.setTotal(currentData.total));

		const dataIds: string[] = [...trackIds];
		const filteredData = () => {
			const data = currentData.data.reduce<(FaceCameraEvent | LprEventData)[]>((acc, item, i) => {
				if (i === 0 && !dataIds.includes(item.track_id)) {
					dataIds.push(item.track_id);
					return [...acc, item];
				}

				if (!dataIds.includes(item.track_id)) {
					dataIds.push(item.track_id);
					return [...acc, item];
				}

				return acc;
			}, []);

			return data;
		};

		const events = filteredData();

		if (events.length === 0) {
			dispatch(eventsSlice.actions.incOffset());
			return;
		}

		const eventsQuenue = events.reduce<StoreEvent[]>((queue, event) => {
			if ('plate_id' in event && ['', 'plates'].includes(filterParams.section) && filterPlatesHandler(event)) {
				queue.push(plateMapper(event, event.plate_id));
			}

			if ('face_id' in event && ['', 'faces'].includes(filterParams.section) && filterFacesHandler(event)) {
				queue.push(personMapper(event, event.face_id));
			}

			return queue;
		}, []);

		if (max_ts === undefined && currentData?.max_ts !== undefined) {
			dispatch(eventsSlice.actions.setMaxTs(currentData?.max_ts));
		}

		if (eventsQuenue.length > 0) {
			dispatch(eventsListSlice.actions.addEvents(eventsQuenue));
		} else {
			setTimeout(() => {
				dispatch(eventsSlice.actions.incOffset());
			}, 100);
		}

		dispatch(eventsSlice.actions.addTrackId(dataIds));
		// eslint-disable-next-line
	}, [currentData, dispatch, filterParams]);

	const onFaceHandler = async (face_id: number, isNew = false) => {
		const { data } = await dispatch(api.endpoints.getEventDataFace.initiate(face_id));
		if (data && filterFacesHandler(data)) {
			const event = personMapper(data, face_id);
			dispatch(eventsListSlice.actions.addEvent(event));
			isNew && dispatch(eventsSlice.actions.incNewEvents());
			return event;
		}
	};

	const onPlateHandler = async (plate_id: number, isNew = false) => {
		const { data } = await dispatch(api.endpoints.getEventDataPlate.initiate(plate_id));
		if (data && filterPlatesHandler(data)) {
			const plate = plateMapper(data, plate_id);
			dispatch(eventsListSlice.actions.addEvent(plate));
			isNew && dispatch(eventsSlice.actions.incNewEvents());
			return plate;
		}
	};

	useWebSocketEvents(
		camerasId,
		filterParams.type !== 'old' && isActive,
		filterParams.section,
		onFaceHandler,
		onPlateHandler,
	);
};
