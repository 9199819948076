import React, { FC, useState } from 'react';
import { Icon } from '../Icon/Icon';

export type DropdownProps = JSX.IntrinsicElements['div'] & {
	header?: React.ReactNode;
	body?: React.ReactNode;
	isClickable?: boolean;
	propIsOpen?: boolean;
};

export const Accordion: FC<DropdownProps> = ({
	propIsOpen = false,
	header,
	body,
	isClickable = true,
	className: propClassName,
}) => {
	const [isOpen, setIsOpen] = useState(propIsOpen);

	const handleClick = () => {
		setIsOpen((prev) => {
			return !prev;
		});
	};
	return (
		<div className={propClassName}>
			<div
				className={'flex justify-between select-none cursor-pointer'}
				onClick={isClickable ? handleClick : undefined}
			>
				{header}
				<Icon className={isOpen ? 'rotate-180 ' : ''} name={'chevronCompactDown'} />
			</div>

			{isOpen && <div className={`mt-[12px] w-full rounded-[8px]`}>{body}</div>}
		</div>
	);
};
