import { useMonitoringCache } from '@components/camera/Monitoring/useMonitoringCache';
import { useGetCamerasQuery } from '@store/api';
import { useState } from 'react';
import { MonitoringGrid } from './Monitoring.typedef';
import { prependApiUrl } from '@utils/prependApiUrl';

export type Camera = {
	title: string;
	hls_url: string;
	hls_lq: string;
	source_url?: string;
	id: string;
	isOff: boolean;
};

export type GridList = (Camera | undefined)[];
export type UseMonitoringReturnType = {
	grid: MonitoringGrid;
	gridList: GridList;
	page: number;
	camerasList: Camera[];
	actions: {
		changeCameraAtPosition: (position: number, cameraId: Nullable<string>) => void;
		setCameras: (cameraIds: Array<string>) => void;
		nextPage: () => void;
		prevPage: undefined | (() => void);
	};
};
export function useMonitoring(): UseMonitoringReturnType {
	const { monitoringCache, setCache } = useMonitoringCache({});
	const [page, setPage] = useState<number>(0);

	const nextPage = () => {
		setPage((prevState) => {
			return prevState + 1;
		});
	};
	const prevPage =
		page === 0
			? undefined
			: () => {
					setPage((prevState) => {
						return prevState - 1;
					});
			  };
	const changeCameraAtPosition = (position: number, cameraId: Nullable<string>) => {
		const prevCameras = monitoringCache.cameras;
		const cameras = [...prevCameras];
		// страница это page
		// размер страницы это monitoringCache.grid
		cameras[position + page * monitoringCache.grid] = cameraId;
		setCache({
			...monitoringCache,
			cameras,
		});
	};
	const setCameras = (cameraIds: Array<string>) => {
		setCache({
			...monitoringCache,
			cameras: cameraIds,
		});
	};

	const { currentData } = useGetCamerasQuery({});
	const camerasList = (currentData?.data ?? []).map((camera) => {
		return {
			id: camera.camera_uuid,
			hls_url: prependApiUrl(camera.hls ?? ''),
			hls_lq: camera.hls_lq ? prependApiUrl(camera.hls_lq) : prependApiUrl(camera.hls ?? ''),
			source_url: camera.stream_params?.source ?? '',
			title: camera.camera_name ?? '',
			isOff: camera.detector_params.disable,
		};
	});

	const gridList: GridList = Array.from({ length: monitoringCache.grid }).map((_, idx) => {
		return camerasList.find((camera) => {
			return camera.id === monitoringCache.cameras[idx + page * monitoringCache.grid];
		});
	});

	const cameraListFiltered = camerasList.filter((camera) => {
		const list = gridList.map((camera) => {
			return camera?.id;
		});
		return camera.title !== '' && camera.title !== undefined && !list.includes(camera.id);
	});

	return {
		grid: monitoringCache.grid,
		gridList,
		page,
		camerasList: cameraListFiltered,
		actions: {
			changeCameraAtPosition,
			setCameras,
			nextPage,
			prevPage,
		},
	};
}
