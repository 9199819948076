import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetCamerasParams } from '@store/api';
import { StoreRootState } from '@store/store';

type CamerasParamsSliceState = {
	params: GetCamerasParams;
};

const initialState: CamerasParamsSliceState = {
	params: {},
};

export const camerasParamsSlice = createSlice({
	name: 'camerasParams',
	initialState,
	reducers: {
		setCameraParams: (state, { payload }: PayloadAction<GetCamerasParams>) => {
			state.params = payload;
		},
	},
});

export const camerasParamsSelector = (state: StoreRootState) => {
	return state.camerasParams;
};
