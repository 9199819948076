import React, { FC } from 'react';
import classes from './Step.module.css';
import { Icon } from '@components/uikit/Icon/Icon';

export type StepType = 'default' | 'active' | 'done';

export type StepProps = {
	title?: string;
	description?: string;
	type: StepType;
	onClick?: () => void;
};

export const Step: FC<StepProps> = ({ title, description, type, onClick }) => {
	const className = [classes['step'], classes['step--' + type]].filter(Boolean).join(' ');
	return (
		<div className={className}>
			<div className={'relative flex items-center'}>
				<div className={classes['circle']} role={'button'} onClick={onClick}>
					{type === 'done' && <Icon name={'check'} className={classes['check']} />}
				</div>
				<div className={classes['line']} />
			</div>
			<div className={'flex flex-col'}>
				<h3 className={'font-title-large text-on-background'}>{title}</h3>
				<p className={'font-body-medium text-secondary'}>{description}</p>
			</div>
		</div>
	);
};
