import React from 'react';
import { Popover } from '@components/uikit/Popover/Popover';
import { Button } from '@components/uikit/Button/Button';
import classes from './CameraSettings.module.css';
import { Icon } from '@components/uikit/Icon/Icon';
import { CameraSettingsProps } from '@components/camera/Monitoring/MonitoringSlot/CameraSettings/CameraSettings.typedef';
import CameraSettingsItem from '@components/camera/Monitoring/MonitoringSlot/CameraSettings/CameraSettingsItem/CameraSettingsItem';

const CameraSettings = ({ settings }: CameraSettingsProps) => {
	const renderZones = (
		<div>
			{settings.map((item) => {
				return <CameraSettingsItem key={item.id} item={item} onItemClick={item.action} />;
			})}
		</div>
	);
	return (
		<Popover align={'end'} positions={['bottom']} content={renderZones}>
			{({ toggleIsOpen }) => {
				return (
					<Button
						size={'xs'}
						variant={'transparent'}
						className={classes['button']}
						style={{ padding: '4px' }}
						onClick={toggleIsOpen}
					>
						<Icon name={'threeDotsVertical'} />
					</Button>
				);
			}}
		</Popover>
	);
};

export default CameraSettings;
