import React, { FC, useEffect, useState } from 'react';
import styles from './CameraCreate.module.css';
import { Icon } from '@components/uikit/Icon/Icon';
import { Button } from '@components/uikit/Button/Button';
import { TextInput } from '@components/uikit/TextInput/TextInput';
import { notify } from '@modules/toast/notify';

import { Spinner } from '@components/uikit/Spinner/Spinner';
import { VideoScreenshot } from '@components/uikit/VideoPlayer/VideoScreenshot';
import { useForm } from 'react-hook-form';
import { Accordion } from '@components/uikit/Accordion/Accordion';
import { ZonePicker } from '@components/uikit/ZonePick/ZonePick';
import { Zone } from '@hooks/useZonePick';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTER } from '@constants/router';
import { Camera, Line } from '@store/ApiPeopleCounter/apiPeopleCounterTypes';
import {
	useCreateCameraMutation,
	useDeleteCameraMutation,
	useGetCameraQuery,
	useGetCameraSnapshotQuery,
	useUpdateCameraMutation,
} from '@store/ApiPeopleCounter/apiPeopleCounter';

type FormValues = Camera;

type RoiType = [number, number, number, number];

const cameraResponseToCameraMapper = (cameraResponse: Camera): Camera => {
	const { name, id, video_url } = cameraResponse;

	const camera = {
		name: name,
		id: id,
		video_url: video_url,
	};

	return {
		...camera,
		head_detection_parameters: {
			...cameraResponse.head_detection_parameters,
		},
	};
};

const cameraToCameraCreateMapper = (camera: Omit<Camera, 'id'>): Camera => {
	return {
		name: camera.name,
		id: '',
		video_url: camera.video_url,
		head_detection_parameters: {
			...camera.head_detection_parameters,
		},
	};
};

const cameraToCameraUpdateMapper = (camera: Camera): Camera => {
	return {
		name: camera.name,
		id: camera.id,
		video_url: camera.video_url,
		head_detection_parameters: {
			...camera.head_detection_parameters,
		},
	};
};

const defaultCamera: Camera = {
	id: '',
	name: '',
	video_url: '',
};

export const CameraCreateLine: FC = () => {
	const [camParams] = useSearchParams();
	const cameraId = camParams.get('id') ?? undefined;
	const isNewCamera = !cameraId;

	const { currentData } = useGetCameraQuery(cameraId ?? '', { skip: !cameraId });

	const [deleteCamera, { isLoading: deleteCameraLoading }] = useDeleteCameraMutation();
	const [createCamera, { isLoading: createCameraLoading }] = useCreateCameraMutation();
	const [updateCamera, { isLoading: updateCameraLoading }] = useUpdateCameraMutation();

	const { currentData: videoScreenshotUrl, isLoading: getCameraSnapshotIsLoading } = useGetCameraSnapshotQuery(
		cameraId ?? '',
		{ skip: !cameraId },
	);

	const lines: Line[] = Object.values(currentData?.signal_lines ?? {});

	const [zoneCoords, setZoneCoords] = useState<RoiType>();
	const [zoneType, setZoneType] = useState<'face'>();

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isDirty },
	} = useForm<FormValues>({
		values: currentData ? { ...cameraResponseToCameraMapper(currentData) } : { ...defaultCamera },
		mode: 'all',
	});

	const handleSaveButtonClick = async (values: FormValues) => {
		if (isDirty) {
			const params = {
				...values,
				head_detection_parameters: {
					...values.head_detection_parameters,
					roi: zoneCoords,
				},
			};

			if (isNewCamera) {
				const res = await createCamera(cameraToCameraCreateMapper(params));

				if ('data' in res) {
					notify('Камера создана');
				} else {
					notify('Не удалось создать камеру');
				}
			} else {
				const res = await updateCamera(cameraToCameraUpdateMapper(params));

				if ('data' in res) {
					notify('Камера обновлена');
				} else {
					notify('Не удалось сохранить камеру');
				}
			}

			reset();
			navigate(ROUTER.CAMERAS.PATH + '?detectionType=lines');
		}
	};

	const navigate = useNavigate();
	const handleDeleteButtonClick = () => {
		if (cameraId) {
			deleteCamera(cameraId)
				.unwrap()
				.catch(() => {
					notify('Ошибка при удалении камеры');
				});
		}
		navigate(ROUTER.CAMERAS.PATH + '?detectionType=lines');
	};

	const isLoading = deleteCameraLoading || createCameraLoading || updateCameraLoading || getCameraSnapshotIsLoading;

	const cameraHlsURl = currentData?.video_url ?? undefined;
	const onZone = (coords: Zone) => {
		setZoneCoords([coords.x, coords.y, coords.w, coords.h]);
	};

	const handleZoneEditButton = () => {
		zoneType === 'face' ? setZoneType(undefined) : setZoneType('face');
	};

	useEffect(() => {
		currentData && setZoneCoords(currentData.head_detection_parameters?.roi);
	}, [currentData]);

	const cameraState = () => {
		/**
		 * Этот код определяет состояние камеры на основе нескольких переменных. Если isNewCamera или cameraDirectURl или cameraHlsURl имеют значение, состояние камеры считается "okay".
		 * Если videoScreenshotUrl равно 'initializating' и cameraDirectURl илиHlsURl` не имеют значения, состояние камеры считается "initializating". В противном случае состояние камеры считается "error".
		 */
		if (isNewCamera || cameraHlsURl) return 'okay';

		if (videoScreenshotUrl === 'initializating' && !cameraHlsURl) return 'initializating';

		return 'error';
	};

	return (
		<div className={'mt-6 flex flex-col gap-[56px] h-full relative'}>
			{isLoading && <Spinner noBg={true} />}

			{!isLoading && (
				<>
					<h2 className={'font-headline-large'}>Основные параметры</h2>
					<form onSubmit={handleSubmit(handleSaveButtonClick)} className="pb-[56px] flex flex-col gap-8">
						<div className="flex gap-6 h-[calc(100vh-40px-56px-32px-135px-32px-56px-56px)] overflow-scroll pt-3 pl-3 -mt-3 min-h-[500px]">
							<div className={styles['video-wrapper']}>
								{cameraState() === 'okay' && (
									<>
										<VideoScreenshot
											playing
											tryToReload
											className={'pointer-events-none'}
											width={'100%'}
											height={'100%'}
											url={cameraHlsURl}
										/>
										{zoneType === 'face' && (
											<ZonePicker
												type={'face'}
												initialFace={
													zoneCoords
														? {
																x: zoneCoords[0],
																y: zoneCoords[1],
																w: zoneCoords[2],
																h: zoneCoords[3],
														  }
														: undefined
												}
												image={videoScreenshotUrl}
												setFaceZone={onZone}
											/>
										)}
									</>
								)}

								{cameraState() === 'initializating' && (
									<div className="relative h-full flex items-center justify-center">
										<Spinner />
										<p className="break-normal mt-[60px]">Камера инициализируется</p>
									</div>
								)}

								{cameraState() === 'error' && (
									<div className={styles['error']}>
										<Icon name="exclamationTriangleFill" />
									</div>
								)}
							</div>
							<div className={styles['content']}>
								<Accordion
									propIsOpen={true}
									header={
										<>
											<div className="flex gap-3 items-center">
												<Icon name="diagram3" />
												<span className="font-headline-xsmall text-on-background">Данные</span>
											</div>
										</>
									}
									body={
										<>
											<div className="flex flex-col gap-3 pt-3">
												<TextInput
													{...register('name', {
														required: 'Обязательно для заполнения',
													})}
													variant="filled"
													placeholder="Наименование"
													hideNotice={false}
													error={errors.name?.message}
												/>
												<TextInput
													{...register('video_url', {
														required: 'Обязательно для заполнения',
													})}
													variant="filled"
													placeholder="Uri"
													hideNotice={false}
													error={errors.video_url?.message}
												/>
											</div>
										</>
									}
								/>

								<Button
									type={'button'}
									onClick={handleZoneEditButton}
									variant="dark"
									disabled={isNewCamera || videoScreenshotUrl === 'initializating' || !videoScreenshotUrl}
								>
									<Icon name="pencilSquare" width={16} height={16} />
									{isNewCamera && (
										<span>{zoneType === 'face' ? 'Сохранить зону детекции лиц' : 'Добавить зону детекции лиц'}</span>
									)}
									{!isNewCamera && (
										<span>{zoneType === 'face' ? 'Сохранить зону детекции лиц' : 'Изменить зону детекции лиц'}</span>
									)}
								</Button>

								<div className="p-4 flex flex-col gap-4 bg-secondary-container rounded-3">
									<p className={'font-title-large'}>Линии детекции</p>
									{lines.map((line) => {
										return (
											<div
												key={line.id}
												className={'py-[10px] p-4 bg-primary-100 rounded-2 flex justify-between gap-2'}
											>
												<TextInput
													variant="uneven"
													hideNotice={false}
													error={errors.video_url?.message}
													value={line.name}
													disabled={true}
												/>

												<div className={'flex gap-2'}>
													<Icon className="text-neutral-90" name="pencilSquare" />
													<Icon className="text-neutral-90" name="trashFill" />
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>

						<div className="absolute w-full justify-end flex gap-6 bottom-0 pb-5 bg-neutral ">
							{!isNewCamera && (
								<Button
									type={'button'}
									onClick={handleDeleteButtonClick}
									variant="error"
									outline
									className="flex self-start gap-2 item-center"
								>
									Удалить камеру <Icon name="x" />
								</Button>
							)}

							<Button variant="primary" className="flex self-start gap-2 item-center">
								Сохранить <Icon name="check2Circle" />
							</Button>
						</div>
					</form>
				</>
			)}
		</div>
	);
};
