import { ActiveMonitoring, useLazyGetCameraQuery, useUpdateCameraMutation } from '@store/api';
import { notifyError } from '@modules/toast/notifyError';

type PushToActiveMonitoringParams = {
	camera_uuid: string;
	active_monitoring: ActiveMonitoring[];
};

type UpdateActiveMonitoringParams = {
	camera_uuid: string;
	active_monitoring: ActiveMonitoring[];
	bucket_id?: string;
};

type ExcludeFromActiveMonitoringParams = {
	camera_uuid: string;
	buckets: string[];
};
export const useFaceStageSearchConfig = () => {
	const [getCamera, { isLoading: getCameraIsLoading }] = useLazyGetCameraQuery();
	const [updateCamera, { isLoading: updateCameraIsLoading }] = useUpdateCameraMutation();

	const isLoading = getCameraIsLoading || updateCameraIsLoading;

	const pushToActiveMonitoring = async ({ camera_uuid, active_monitoring }: PushToActiveMonitoringParams) => {
		const { data } = await getCamera(camera_uuid);
		if (data === undefined) {
			notifyError('Камера не найдена');
			return;
		}
		const newActiveMonitoring = (data.active_monitoring ?? []).concat(active_monitoring);
		await updateCamera({
			camera_uuid: camera_uuid,
			active_monitoring: newActiveMonitoring,
		});
	};

	const updateActiveMonitoring = async ({
		camera_uuid,
		bucket_id,
		active_monitoring,
	}: UpdateActiveMonitoringParams) => {
		const { data } = await getCamera(camera_uuid);
		if (data === undefined) {
			notifyError('Камера не найдена');
			return;
		}
		const newActiveMonitoring = (data.active_monitoring ?? [])
			.filter((item) => {
				return item.bucket_name !== bucket_id;
			})
			.concat(active_monitoring);

		await updateCamera({
			camera_uuid: camera_uuid,
			active_monitoring: newActiveMonitoring,
		});
	};

	const excludeFromActiveMonitoring = async ({ camera_uuid, buckets }: ExcludeFromActiveMonitoringParams) => {
		const { data } = await getCamera(camera_uuid);
		if (data === undefined) {
			notifyError('Камера не найдена');
			return;
		}
		const newActiveMonitoring = (data.active_monitoring ?? []).filter((item) => {
			return !buckets.includes(item.bucket_name);
		});
		await updateCamera({
			camera_uuid: camera_uuid,
			active_monitoring: newActiveMonitoring,
		});
	};

	return { isLoading, pushToActiveMonitoring, excludeFromActiveMonitoring, updateActiveMonitoring };
};
