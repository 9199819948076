import { FC } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';

export type AppHistory = {
	navigate: NavigateFunction;
};

export const appHistory: AppHistory = {
	navigate() {
		console.warn('[AppHistory] Attempt to navigate with not initialized method.');
	},
};

export const HistoryProvider: FC = () => {
	appHistory.navigate = useNavigate();
	return null;
};
