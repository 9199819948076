import { Badge } from '@components/uikit/Badge/Badge';
import { Button } from '@components/uikit/Button/Button';
import { Checkbox } from '@components/uikit/Checkbox/Checkbox';
import { Icon } from '@components/uikit/Icon/Icon';
import { Toggle } from '@components/uikit/Toggle/Toggle';
import { typeData } from '@utils/urlToType';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';

export type CameraFilterProps = {
	onFilterChange: (data: CameraFilterValues) => void;
	filterParams?: CameraFilterValues;
	setIsOpen: (isOpen: boolean) => void;
};

export type CameraFilterValues = {
	type: string[];
	favorite: boolean;
};

export const CameraFilter = ({ setIsOpen, filterParams, onFilterChange }: CameraFilterProps) => {
	const { handleSubmit, getValues, control } = useForm<CameraFilterValues>({
		defaultValues: {
			type: filterParams?.type ?? [],
			favorite: filterParams?.favorite ?? false,
		},
	});

	const handleForm = (e: CameraFilterValues) => {
		onFilterChange(e);
		setIsOpen(false);
	};

	return (
		<div className="p-4 pb-5 w-[500px]">
			<form onSubmit={handleSubmit(handleForm)}>
				<p className={'font-headline-xsmall'}>Фильтрация</p>

				<div className="py-4 flex flex-col gap-4">
					<div className={'flex gap-2 gap-y-4  flex-wrap'}>
						<Controller
							control={control}
							render={({ field: { value, name, onChange } }) => {
								return (
									<Toggle
										type={'checkbox'}
										label={'Избранные'}
										value={'plates'}
										checked={value}
										name={name}
										onChange={onChange}
									/>
								);
							}}
							name={'favorite'}
						/>
					</div>
				</div>

				{typeData && (
					<div className="py-4 flex flex-col gap-4">
						<div className="flex gap-2 items-center">
							<Icon name="aspectRatio" />
							<p className={'font-title-medium '}>Тип данных</p>
							{Boolean(filterParams?.type.length) && (
								<Badge color={'primary'} variant={'filled'} size={'xs'}>
									{filterParams?.type.length}
								</Badge>
							)}
						</div>

						<div className={'flex gap-2 gap-y-4  flex-wrap'}>
							{typeData.map((elem: string) => {
								return (
									<Controller
										key={elem}
										control={control}
										render={({ field: { value, name, onChange } }) => {
											return (
												<Checkbox
													label={elem}
													value={elem}
													name={name}
													checked={value.includes(elem)}
													onChange={(e) => {
														const values = getValues('type');
														if (e.target.checked) {
															values.push(e.target.value);
														} else {
															values.splice(values.indexOf(e.target.value), 1);
														}
														onChange(values);
													}}
												/>
											);
										}}
										name={'type'}
									/>
								);
							})}
						</div>
					</div>
				)}

				<Button className={'mt-2'} size="sm">
					Сохранить
				</Button>
			</form>
		</div>
	);
};
