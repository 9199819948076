import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Icon } from '@components/uikit/Icon/Icon';
import { Popover } from '@components/uikit/Popover/Popover';
import { PopoverContentLayout } from '@components/layouts/PopoverContentLayout/PopoverContentLayout';
import { RadioInput } from '@components/uikit/RadioInput/RadioInput';

export type SortSelectValue = 'newest' | 'oldest';

export type SortSelectProps = {
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	value?: SortSelectValue;
	className?: string;
	hideFilters?: SortSelectValue[];
};

const labelMap: Record<SortSelectValue, string> = {
	newest: 'Сначала новые',
	oldest: 'Сначала старые',
};

export const SortSelect: FC<SortSelectProps> = ({ onChange, value, className: propClassName, hideFilters }) => {
	const [internalValue, setInternalValue] = useState<SortSelectValue>(value ?? 'newest');
	const [isOpen, setIsOpen] = useState(false);

	const handleClose = () => {
		setIsOpen(false);
	};

	// Синхронизируем внутреннее состояние со внешним
	useEffect(() => {
		if (!value) {
			return;
		}
		internalValue !== value && setInternalValue(value);
	}, [value, internalValue]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		// Изменяем внутреннее состояние, только когда не передан onChange
		if (onChange) {
			onChange(e);
			return;
		}
		setInternalValue(e.target.value as SortSelectValue);
	};

	const classList = ['flex items-center gap-x-[8px] whitespace-nowrap p-[14px] rounded-[4px]', propClassName].filter(
		Boolean,
	);
	isOpen && classList.push('bg-secondary-container');
	const className = classList.join(' ');

	return (
		<div className={className}>
			<div className={'flex items-center gap-x-[8px]'}>
				<Icon className={'text-secondary'} name={'sortDownFill'} width={20} height={20} />
				<span className={'font-title-medium text-secondary'}>Сортировка:</span>
			</div>
			<Popover
				align={'end'}
				isOpen={isOpen}
				positions={['bottom', 'left', 'right', 'top']}
				onClose={handleClose}
				padding={24}
				content={
					<PopoverContentLayout className={'flex flex-col gap-y-[12px]'}>
						{!hideFilters?.includes('newest') && (
							<RadioInput value={'newest'} checked={internalValue === 'newest'} onChange={handleChange}>
								{labelMap.newest}
							</RadioInput>
						)}
						{!hideFilters?.includes('oldest') && (
							<RadioInput value={'oldest'} checked={internalValue === 'oldest'} onChange={handleChange}>
								{labelMap.oldest}
							</RadioInput>
						)}
					</PopoverContentLayout>
				}
			>
				<button className={'flex items-center gap-x-[4px]'} onClick={setIsOpen.bind(null, !isOpen)}>
					<span className={'font-title-medium text-primary'}>{labelMap[internalValue]}</span>
					<Icon className={'text-primary'} name={'chevronCompactDown'} width={18} height={18} />
				</button>
			</Popover>
		</div>
	);
};
