import React, { forwardRef, useEffect, useState } from 'react';

export type BorderProps = {
	box?: {
		width: number;
		height: number;
		x: number;
		y: number;
	};
};

export const Border = forwardRef<HTMLImageElement, BorderProps>(({ box }, ref) => {
	const [styles, setStyles] = useState<React.CSSProperties>();

	const offset = 2;
	useEffect(() => {
		const calculation = () => {
			if (ref && 'current' in ref && ref.current !== null && box !== undefined) {
				const x = ref.current?.offsetWidth,
					y = ref.current?.offsetHeight;

				const ratioX = ref.current?.naturalWidth,
					ratioY = ref.current?.naturalHeight;

				if (x && y && ratioX && ratioY) {
					const relativeX = +(x / ratioX).toFixed(2);
					const relativeY = +(y / ratioY).toFixed(2);
					setStyles({
						width: `${Math.ceil(relativeX * (box.width ?? 0)) + (2 * offset) / relativeX}px`,
						height: `${Math.ceil(relativeY * (box.height ?? 0)) + (2 * offset) / relativeY}px`,
						left: `${Math.ceil(relativeX * (box.x ?? 0)) - offset / relativeX}px`,
						top: `${Math.ceil(relativeY * (box.y ?? 0)) - offset / relativeY}px`,
					});
				}
			}
		};

		if (ref && 'current' in ref && ref.current !== null) {
			ref.current.onload = () => {
				calculation();
			};
			window.addEventListener('resize', calculation);
		}

		calculation();
		return () => {
			window.removeEventListener('resize', calculation);
			setStyles(undefined);
		};
	}, [ref, box]);

	return (
		<>{styles && <div className={'z-30 rounded-[1px] border-2 border-[limegreen] absolute'} style={styles}></div>}</>
	);
});
