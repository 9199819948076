import React, { FC, PropsWithChildren } from 'react';

export type PopoverContentLayoutProps = PropsWithChildren & {
	className?: string;
};

export const PopoverContentLayout: FC<PopoverContentLayoutProps> = ({ children, className: propClassName }) => {
	const className = ['p-[16px] border border-secondary-90 rounded-[8px]', propClassName]
		.filter(Boolean)
		.join(' ')
		.trim();
	return <section className={className}>{children}</section>;
};
