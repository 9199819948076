import React, { FC, PropsWithChildren, ReactElement, useMemo } from 'react';
import { MenuDrawer } from '@components/uikit/MenuDrawer/MenuDrawer';
import { DrawerLink } from '@components/uikit/MenuDrawer/DrawerLink';
import { ControlSidebarProps } from '@components/uikit/ControlSidebar/ControlSidebar';
import { ROUTER } from '@constants/router';
import { DrawerDivider } from '@components/uikit/MenuDrawer/DrawerDivider';

export type DashboardLayoutProps = PropsWithChildren & {
	className?: string;
	contentClassName?: string;
	// @see @components/uikit/ControlSidebar/ControlSidebar.tsx
	sidebar?: ReactElement<ControlSidebarProps>;
};

export const DashboardLayout: FC<DashboardLayoutProps> = ({ className, sidebar, children, contentClassName }) => {
	const extendClassName = className ? ` ${className}` : '';
	const extendContentClassName = contentClassName ? ` ${contentClassName}` : '';

	const menu = useMemo(() => {
		return (
			<MenuDrawer>
				<DrawerLink icon={'bell'} path={ROUTER.MONITORING.PATH}>
					Панель наблюдения
				</DrawerLink>
				<DrawerLink icon={'bell'} path={ROUTER.EVENTS.PATH}>
					События
				</DrawerLink>
				<DrawerDivider>Администрирование</DrawerDivider>
				<DrawerLink icon={'cameraVideo'} path={ROUTER.CAMERAS.PATH}>
					Камеры
				</DrawerLink>
				<DrawerLink icon={'fullscreen'} path={ROUTER.SEARCH_DATABASE.PATH}>
					Базы поиска
				</DrawerLink>
				<DrawerLink icon={'emojiSmile'} path={ROUTER.RECOGNITION_DATABASE.PATH}>
					База лиц и номеров
				</DrawerLink>
			</MenuDrawer>
		);
	}, []);
	return (
		<div className={'grid grid-cols-[max-content_auto] gap-x-[32px] bg-neutral' + extendClassName}>
			{menu}
			<div
				className={
					'flex flex-grow gap-x-[32px] pr-[32px] py-[24px] w-full overflow-x-scroll  h-screen' + extendContentClassName
				}
			>
				<div className={'flex-grow break-all w-max'}>{children}</div>
				{sidebar}
			</div>
		</div>
	);
};
