import React, { useEffect, useRef, useState } from 'react';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { ROUTER } from '@constants/router';
import { SearchCarResult } from '@components/recognition/SearchCar/SearchCarResult';
import { StepsHeader } from '@components/uikit/StepsHeader/StepsHeader';
import CameraZoneSelector from '@components/camera/Monitoring/MonitoringSlot/CameraZoneSelector/CameraZoneSelector';
import { getTextWithDeclination } from '@utils/getTextWithDeclination';
import ReactPlayer from 'react-player';
import { useAutoplay } from '@hooks/react-player/useAutoplay';
import { RuntimeEnv } from '@modules/runtime-env/RuntimeEnv';
import { Icon } from '@components/uikit/Icon/Icon';
import { useResultPlatePage } from '@hooks/useResultPlatePage';
import { useGetCameraSnapshotQuery, useLazyGetCameraQuery } from '@store/api';
import { Border } from '@components/uikit/Border/Border';

type ChosenCameraType = {
	name?: string;
	url?: string;
	id?: string;
	box?: {
		width: number;
		height: number;
		x: number;
		y: number;
	};
};

const ResultStage = () => {
	const [cam, setCam] = useState<ChosenCameraType | undefined>();
	const [camera] = useLazyGetCameraQuery();
	// eslint-disable-next-line
	const [results, setResults] = useState<number[]>([]);

	const { startDate, endDate, bucketConfig, chosenCameraURL, onChangeCameras, plates, zones } = useResultPlatePage();

	const ref = useRef<ReactPlayer>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const { isFatalError, props } = useAutoplay(ref);
	const imgRef = useRef<HTMLImageElement>(null);

	const { currentData: imageUrl } = useGetCameraSnapshotQuery({
		id: chosenCameraURL,
	});

	useEffect(() => {
		const getCam = async (cameraId: string) => {
			const res = await camera(cameraId)
				.unwrap()
				.then((r) => {
					const url = r.hls
						? `${RuntimeEnv.getEnv('REACT_APP_API_URL')}${r.hls.substring(1, r.hls.length + 1)}`
						: undefined;
					setCam({
						url,
						name: r.camera_name,
						id: r.camera_uuid,
						box: r.detector_params.lpr?.lp_detection_parameters?.roi ?? undefined,
					});
				});

			return res;
		};

		if (!chosenCameraURL) {
			return;
		}
		getCam(chosenCameraURL);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chosenCameraURL]);

	return (
		<DashboardLayout>
			<DashboardContentLayout
				title={ROUTER.SEARCH_DATABASE_ARCHIVE_PLATE.TITLE}
				showBackButton={false}
				drawDivider={false}
				overrideHeaderRenderer={
					<StepsHeader
						title={ROUTER.SEARCH_DATABASE_ARCHIVE_PLATE.TITLE}
						badges={[
							{ text: 'Зона детекции', color: 'tertiary', classes: '!text-primary-0' },
							{ text: 'Завершено', color: 'green' },
						]}
					/>
				}
			>
				<div className={'flex flex-col gap-8 mt-6'}>
					<div className="flex gap-[56px] justify-center">
						<div className="overflow-hidden rounded-2 w-full aspect-video relative">
							<div className={'absolute top-4 left-4 z-10'}>
								<CameraZoneSelector
									zones={zones}
									onZoneClick={(id) => {
										onChangeCameras(id);
										setResults([]);
										return true;
									}}
								>
									<span className=" truncate max-w-[150px]">{cam?.name}</span>
								</CameraZoneSelector>
							</div>
							{cam?.url && !isFatalError && (
								<>
									<div ref={containerRef} className="pointer-events-none">
										<ReactPlayer
											muted={true}
											playing
											ref={ref}
											width={'100%'}
											height={'100%'}
											url={cam.url}
											stopOnUnmount={true}
											{...props}
										/>
									</div>

									<>
										<img
											className={'w-full pointer-events-none rounded-[8px] object-fill z-20 absolute top-0 opacity-0'}
											src={imageUrl}
											ref={imgRef}
											alt="example"
										/>
										{cam?.url && !isFatalError && <Border box={cam.box} ref={imgRef} />}
									</>
								</>
							)}

							{(!cam?.url || isFatalError) && (
								<div className={'flex h-full bg-secondary-container items-center justify-center'}>
									<Icon name="exclamationTriangleFill" className="text-error-80" />
								</div>
							)}
						</div>

						<div className={'rounded-3 bg-secondary-container p-6 w-full max-w-[530px] flex flex-col gap-6 relative'}>
							<p className="font-headline-small">Данные о зоне детекции</p>
							<p className={'font-headline-xsmall'}>
								Название поиска:{' '}
								<span className={'text-primary break-normal'}>{bucketConfig?.caption ?? 'Без названия'}</span>
							</p>

							<div className="flex flex-col gap-3 p-4 rounded-3 bg-secondary-90">
								<p className={'font-headline-xsmall'}>Найдено объектов</p>
								<p className="font-headline-xsmall text-primary">
									{getTextWithDeclination(
										results.reduce((sum, acc) => {
											return acc + sum;
										}, 0),
										['номер', 'номера', 'номеров'],
									)}
								</p>
							</div>
						</div>
					</div>
					<div className={'flex flex-col gap-4'}>
						<div className={'flex flex-col gap-6'}>
							<div className={'grid grid-cols-3 gap-6'}>
								{plates.map((plate) => {
									return (
										<SearchCarResult
											key={plate}
											plate={plate}
											camera={chosenCameraURL ? chosenCameraURL : []}
											startDate={startDate}
											endDate={endDate}
											setResults={setResults}
										/>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</DashboardContentLayout>
		</DashboardLayout>
	);
};
export default ResultStage;
