import { useEffect } from 'react';
import { EventList } from '@components/event/EventList/EventList';
import { EventPreview } from '@components/event/EventPreview/EventPreview';
import { EventPreviewPlate } from '@components/event/EventPreview/EventPreviewPlate';
import EventPreviewPlateWrapper from '@components/event/EventPreview/EventPreviewPlateWrapper';
import EventPreviewWrapper from '@components/event/EventPreview/EventPreviewWrapper';
import { eventsSelector, eventsSlice } from '@store/slices/eventsSlice';
import { useStoreDispatch, useStoreSelector } from '@store/store';
import { StoreEvent, eventsListSelectors } from '@store/slices/eventListSlice';

export const Events = () => {
	const { activeEventId } = useStoreSelector(eventsSelector);
	const events = useStoreSelector(eventsListSelectors.selectAll);

	const dispatch = useStoreDispatch();

	const handleEventClick = (event: StoreEvent) => {
		if (activeEventId === event.id) {
			dispatch(eventsSlice.actions.claearActiveEvent());
			dispatch(eventsSlice.actions.clearEventPreview());
			return;
		}
		const idFound = events.find((ev) => {
			return ev.id === event.id;
		});

		dispatch(eventsSlice.actions.setaActiveEvent(idFound));
		dispatch(eventsSlice.actions.setaActiveEventId(event.id));
		dispatch(
			eventsSlice.actions.setEventPreview({
				eventId: event.id,
				box: event.box,
				type: event.type,
			}),
		);
	};

	useEffect(() => {
		return () => {
			dispatch(eventsSlice.actions.setaActiveEvent(undefined));
			dispatch(eventsSlice.actions.setaActiveEventId(undefined));
			dispatch(eventsSlice.actions.clearEventPreview());
		};
	}, [dispatch]);

	const renderEventPreview = (event: StoreEvent) => {
		return event.type === 'face' ? (
			<EventPreviewWrapper childrenProps={event}>
				{(event, imageUrl) => {
					return (
						<EventPreview
							event={event}
							previewUrl={imageUrl}
							active={activeEventId === event.id}
							onClick={handleEventClick}
						/>
					);
				}}
			</EventPreviewWrapper>
		) : (
			<EventPreviewPlateWrapper childrenProps={event}>
				{(event, imageVehicleUrl, imagePlateUrl) => {
					return (
						<EventPreviewPlate
							event={event}
							previewVehicleUrl={imageVehicleUrl}
							previewPlateUrl={imagePlateUrl}
							active={activeEventId === event.id}
							onClick={handleEventClick}
						/>
					);
				}}
			</EventPreviewPlateWrapper>
		);
	};

	return (
		<div className={'flex flex-col gap-5'}>
			<EventList events={events}>
				{(event) => {
					return renderEventPreview(event);
				}}
			</EventList>
		</div>
	);
};
