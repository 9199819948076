import React, { FC, useMemo } from 'react';
import styles from './EventIndicator.module.css';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

export type EventIndicatorProps = {
	severity: 'CRITICAL' | 'REGULAR' | 'MINOR';
};

export enum Indicator {
	CRITICAL = 'critical',
	REGULAR = 'regular',
	MINOR = 'minor',
}

const TooltipText: Record<Indicator, string> = {
	[Indicator.CRITICAL]: 'Критичное событие',
	[Indicator.REGULAR]: 'Важное событие',
	[Indicator.MINOR]: 'Незначительное событие',
};

export const EventIndicator: FC<EventIndicatorProps> = ({ severity }) => {
	const tooltipId = useMemo(() => {
		return 'type' + uuidv4();
	}, []);

	const containerClasses = [styles['container'], styles[Indicator[severity]]].join(' ');
	const circleClasses = [styles['circle'], styles[Indicator[severity]]].join(' ');

	return (
		<div id={tooltipId} className={containerClasses}>
			<div className={circleClasses} />
			<Tooltip
				className={'z-50 !rounded-2'}
				anchorSelect={`#${tooltipId}`}
				place={'top'}
				content={TooltipText[Indicator[severity]]}
			/>
		</div>
	);
};
