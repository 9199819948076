import React from 'react';
import { Icon } from '@components/uikit/Icon/Icon';

type TagProps = {
	title: string;
	isDisabled?: boolean;
	onClose?: (title: string) => void;
};
const Tag = ({ title, onClose, isDisabled }: TagProps) => {
	const onCloseHandler = () => {
		onClose && onClose(title);
	};
	return (
		<div
			className={`bg-primary-container rounded-[8px] flex justify-between items-center py-[6px] px-4 gap-3 ${
				isDisabled ? 'opacity-50' : ''
			}`}
		>
			<div className={'text-primary font-title-large'}>{title}</div>
			{<Icon name={'x'} className={'text-primary'} onClick={onCloseHandler} />}
		</div>
	);
};

export default Tag;
