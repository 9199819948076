import React, { forwardRef, useMemo } from 'react';
import { Spinner } from '@components/uikit/Spinner/Spinner';

export type InfinityListLoaderProps = {
	className?: string;
	reachedEnd?: boolean;
};

export const InfinityListLoader = forwardRef<HTMLDivElement, InfinityListLoaderProps>(
	({ className, reachedEnd }, ref) => {
		const containerClassName = useMemo(() => {
			const base = 'relative w-full h-[32px] rounded-[8px] overflow-hidden flex justify-center items-center';
			const classList = base.split(' ');

			reachedEnd && classList.push('bg-secondary opacity-50');
			className && classList.push(className);

			return classList.join(' ');
		}, [className, reachedEnd]);

		return (
			<div className={containerClassName} ref={ref}>
				{!reachedEnd && <Spinner />}
				{reachedEnd && <p className={'font-label-medium text-on-secondary'}>Загружены все элементы списка</p>}
			</div>
		);
	},
);
