import React, { FC } from 'react';
import { IconButton, IconButtonProps } from '@components/uikit/Button/IconButton';
import classes from './PowerButton.module.css';

export type PowerButtonProps = Omit<IconButtonProps, 'children' | 'variant' | 'icon'> & {
	on?: boolean;
};

export const PowerButton: FC<PowerButtonProps> = ({ className: propClassName, on, ...propsProxy }) => {
	const classKey = on ? 'on' : 'off';
	const className = [classes[classKey], propClassName].filter(Boolean).join(' ');
	return <IconButton {...propsProxy} icon={'power'} variant={'primary'} className={className} />;
};
