import React, { useEffect, useState } from 'react';
import { Toggle } from '@components/uikit/Toggle/Toggle';
import { SearchFormStream } from '@components/recognition/SearchForm/SearchFormStream/SearchFormStream';
import { SearchFormExternalFile } from '@components/recognition/SearchForm/SearchFormExternalFile/SearchFormExternalFile';
import { StepProps } from '@components/uikit/StepRow/Step/Step';
import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { RECOGNITION_FACES_STEP_ROW_PRESET } from '@constants/recognition';
import { useGetBucketConfigQuery, useUpdateBucketMutation } from '@store/api';
import { SearchFormArchive } from '@components/recognition/SearchForm/SearchFormArchive/SearchFormArchive';
import { StepsHeader } from '@components/uikit/StepsHeader/StepsHeader';
import { ROUTER } from '@constants/router';

type SourceType = 'ARCHIVE' | 'FILE' | 'EXTERNAL_FILE' | 'STREAM';
type Errors = {
	title?: string;
	selectedNumbers?: string;
	sourceType?: string;
};

const steps = [
	RECOGNITION_FACES_STEP_ROW_PRESET[0],
	{
		...RECOGNITION_FACES_STEP_ROW_PRESET[1],
		type: 'active',
	},
	RECOGNITION_FACES_STEP_ROW_PRESET[2],
];

type FaceStageSearchConfigProps = {
	onStepClick: (id: number) => void;
	bucketName: string;
};
const FaceStageSearchConfig = ({ onStepClick, bucketName }: FaceStageSearchConfigProps) => {
	const [updateBucket] = useUpdateBucketMutation();
	const [sourceType, setSourceType] = useState<Nullable<SourceType>>('STREAM');
	const [errors] = useState<Errors>({});
	const { currentData: bucketConfig } = useGetBucketConfigQuery(bucketName);

	useEffect(() => {
		if (bucketConfig?.tags) {
			const type: SourceType = bucketConfig.tags.includes('stream') ? 'STREAM' : 'ARCHIVE';
			setSourceType(type);
		}
	}, [bucketConfig]);

	const validate = () => {
		return;
	};

	const onStreamChange = async ({
		title,
		source_type,
		duration,
		notification,
		archive,
		confidence,
	}: {
		title: string;
		source_type: string;
		duration?: string;
		notification?: string;
		archive?: string;
		confidence: number;
	}) => {
		await updateBucket({
			bucket_name: bucketName,
			caption: title,
			tags: [
				source_type,
				`confidence:${confidence}`,
				'detectionType:face',
				...(duration ? [duration] : []),
				...(notification ? [notification] : []),
				...(archive ? [archive] : []),
			],
		});
	};

	return (
		<DashboardLayout>
			<DashboardContentLayout
				overrideHeaderRenderer={
					<StepsHeader
						title={ROUTER.SEARCH_DATABASE_CREATE_FACES.TITLE}
						badges={[{ text: 'Создание' }]}
						steps={steps as StepProps[]}
						onStepClick={onStepClick}
					/>
				}
				showBackButton={false}
				drawDivider={false}
			>
				<h2 className={'font-headline-xsmall text-on-background mt-[16px]'}>Выберите метод анализа данных</h2>
				<div className={'flex gap-x-[32px] pt-2'}>
					<Toggle
						type={'radio'}
						label={'Поток'}
						checked={sourceType === 'STREAM'}
						value={'STREAM'}
						description={'Данные анализируются с прямой трансляции'}
						onChange={(e) => {
							setSourceType(e.target.value as SourceType);
						}}
						disabled={bucketConfig?.tags && sourceType !== 'STREAM'}
					/>
					<Toggle
						type={'radio'}
						label={'Архив'}
						checked={sourceType === 'ARCHIVE'}
						description={'Данные анализируются из нашего архива'}
						value={'ARCHIVE'}
						onChange={(e) => {
							setSourceType(e.target.value as SourceType);
						}}
						disabled={bucketConfig?.tags && sourceType !== 'ARCHIVE'}
					/>
					<Toggle
						disabled={true}
						type={'radio'}
						label={'Данные из внешнего ресурса'}
						checked={sourceType === 'EXTERNAL_FILE'}
						value={'EXTERNAL_FILE'}
						description={'Можете загрузить свои архивы и система проанализирует их'}
						onChange={(e) => {
							setSourceType(e.target.value as SourceType);
						}}
					/>
				</div>
				{(() => {
					switch (sourceType) {
						case 'ARCHIVE': {
							return <SearchFormArchive bucketId={bucketName} onChange={onStreamChange} title="Название поиска лиц" />;
						}
						case 'STREAM': {
							return <SearchFormStream bucketId={bucketName} onChange={onStreamChange} title="Название поиска лиц" />;
						}
						case 'EXTERNAL_FILE': {
							const onExternalFileChange = (config: unknown) => {
								// onSubmit(config);
							};

							return <SearchFormExternalFile onChange={onExternalFileChange} onValidate={validate} />;
						}
						default:
							return errors.sourceType ? (
								<p className={'font-body-medium text-error'}>Выберите тип данных для обработки.</p>
							) : (
								<p className={'font-body-medium text-secondary'}>Выберите тип данных для обработки.</p>
							);
					}
				})()}
			</DashboardContentLayout>
		</DashboardLayout>
	);
};

export default FaceStageSearchConfig;
