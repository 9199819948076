import React, { ChangeEvent, FC, useState } from 'react';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { ROUTER } from '@constants/router';
import { SearchDatabaseTable } from '@components/recognition/SearchDatabaseTable/SearchDatabaseTable';
import { SearchCreateButton } from '@components/recognition/SearchCreateButton/SearchCreateButton';
import { SearchDatabaseSearch } from '@components/search-database/SearchDatabaseSearch/SearchDatabaseSearch';
import { SortSelect, SortSelectValue } from '@components/uikit/SortSelect/SortSelect';

export const SearchDatabaseOverviewPage: FC = () => {
	const [sortState, setSortState] = useState<SortSelectValue>('newest');
	const handleSort = (e: ChangeEvent<HTMLInputElement>) => {
		setSortState(e.target.value as SortSelectValue);
	};
	const sortMap: { [key: string]: string } = {
		newest: 'desc',
		oldest: 'asc',
	};

	return (
		<DashboardLayout>
			<DashboardContentLayout title={ROUTER.SEARCH_DATABASE.TITLE} showBackButton={false} drawDivider={false}>
				<div className={'flex flex-col gap-y-[24px]'}>
					<section className={'flex justify-between gap-x-[20px]'}>
						<SearchDatabaseSearch />
						<div className={'flex gap-x-[20px]'}>
							<SortSelect value={sortState} onChange={handleSort} />
							{/* временно убран так как бекенд все равно не позволяет добиться фильтрации */}
							{/* <SearchFilterWrapper /> */}
							<SearchCreateButton />
						</div>
					</section>
					<section>
						<SearchDatabaseTable sort={sortMap[sortState]} />
					</section>
				</div>
			</DashboardContentLayout>
		</DashboardLayout>
	);
};
