import React, { FC } from 'react';
import { Icon, IconProps } from '@components/uikit/Icon/Icon';
import classes from './FavoriteIcon.module.css';

export type FavoriteIconProps = Omit<IconProps, 'name' | 'className'> & {
	active?: boolean;
};

export const FavoriteIcon: FC<FavoriteIconProps> = ({ active, ...propsProxy }) => {
	const name = active ? 'starFill' : 'star';
	const className = [classes['favorite'], active && classes['favorite--active']].filter(Boolean).join(' ');
	return <Icon className={className} name={name} {...propsProxy} />;
};
