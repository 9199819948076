import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import classes from './TextInputBase.module.css';
import { Icon, IconName, IconProps } from '@components/uikit/Icon/Icon';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

type ExcludedAttributes = 'size' | 'type' | 'ref';

export type TextInputBaseProps = Omit<JSX.IntrinsicElements['input'], ExcludedAttributes> & {
	size?: 's' | 'm';
	// Добавляет кнопку очистки поля
	onClear?: () => void;
	variant?: 'underlined' | 'bordered' | 'filled' | 'uneven';
	icon?: IconName;
	// Текст ошибки (красный интерфейс)
	error?: string;
	postfix?: string;
	// Текст (зеленый интерфейс)
	success?: string;
	// Не резервировать место для сообщения об ошибке
	hideNotice?: boolean;
	type?: string;
	containerProps?: JSX.IntrinsicElements['div'];
	iconProps?: Partial<IconProps>;
	tooltip?: string;
};

/**
 * Базовый компонент для реализации на его основе текстовых полей (за исключением textarea).
 */
export const TextInputBase = forwardRef<HTMLInputElement, TextInputBaseProps>(
	(
		{
			size = 'm',
			variant = 'underlined',
			onClear,
			icon,
			className: propClassName,
			error,
			placeholder = '',
			postfix,
			success,
			hideNotice = true,
			containerProps,
			iconProps,
			tooltip,
			...propsProxy
		},
		ref,
	) => {
		const classList = [classes['container'], classes[size], classes[variant], propClassName];
		onClear && classList.push(classes['with-clear']);
		icon && classList.push(classes['with-icon']);
		error && classList.push(classes['with-error']);
		success && classList.push(classes['with-success']);
		const className = classList.filter(Boolean).join(' ').trim();

		const internalRef = useRef<HTMLInputElement>(null);
		const [hasFocus, setHasFocus] = useState(false);

		useEffect(() => {
			if (
				hasFocus &&
				(propsProxy.type === 'datetime-local' || propsProxy.type === 'date' || propsProxy.type === 'time')
			) {
				try {
					internalRef.current?.showPicker();
				} catch (err) {
					console.info('[TextInputBase] Не удалось выполнить метод showPicker');
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [hasFocus]);

		const type = useMemo(() => {
			if (
				(propsProxy.type === 'datetime-local' || propsProxy.type === 'date' || propsProxy.type === 'time') &&
				!hasFocus &&
				!propsProxy.value
			) {
				return 'text';
			}
			return propsProxy.type;
		}, [hasFocus, propsProxy.type, propsProxy.value]);

		// Tooltip unique identifier

		const uid = useMemo(() => {
			return 'text-input-base-' + uuidv4();
		}, []);

		// Костылизировали запрос из #361, когда в начале значения остаётся нолик
		const value = (() => {
			const proxyValue = propsProxy.value;

			if (proxyValue === undefined) {
				return undefined;
			}

			if (proxyValue === null || (typeof proxyValue !== 'string' && typeof proxyValue !== 'number')) {
				return '';
			}

			if (propsProxy.type === 'number') {
				return proxyValue.toString();
			}

			return proxyValue;
		})();

		const setRef = (element: HTMLInputElement) => {
			if (typeof ref === 'function') {
				ref(element);
			} else if (ref) {
				ref.current = element;
			}

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			internalRef.current = element;
		};

		return (
			<div className={className} {...containerProps}>
				<div className={classes['input-container']}>
					<Tooltip className={'z-50'} anchorSelect={`#${uid}`} content={tooltip} place={'top'}>
						{tooltip}
					</Tooltip>
					<label>
						<span className={classes['placeholder']}>
							{placeholder} {tooltip && <Icon id={uid} className={'!w-[16px] !h-[16px]'} name={'info2Circle'} />}
						</span>
						{icon && (
							<Icon className={'flex-shrink-0 cursor-pointer'} name={icon} width={18} height={18} {...iconProps} />
						)}
						<input
							{...propsProxy}
							onFocus={setHasFocus.bind(null, true)}
							onBlur={setHasFocus.bind(null, false)}
							placeholder={placeholder}
							ref={setRef}
							type={type}
							value={value}
						/>
						<div className={classes['extra']}>
							{postfix && <p className={classes['postfix']}>{postfix}</p>}
							{onClear && (
								<button type={'button'} className={classes['clear-button']} onClick={onClear}>
									<Icon name={'x'} />
								</button>
							)}
						</div>
					</label>
				</div>
				{!hideNotice && (error || success) && (
					<p className={classes['notice']}>
						{/* "Ошибка" в приоритете */}
						{error || success}
					</p>
				)}
			</div>
		);
	},
);
