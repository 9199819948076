import React from 'react';
import { Badge } from '@components/uikit/Badge/Badge';

export type SearchFacePropsSmall = {
	imageUrl?: string;
	title?: string;
	identifier: string;
	tags?: string[];
	onClick?: (identifier: string) => void;
};

export function SearchFaceSmall({ imageUrl, tags = [], identifier, title, onClick }: SearchFacePropsSmall) {
	return (
		<div
			className={
				'bg-primary-100 transition hover:bg-primary-95 rounded-[8px] p-[10px_12px] flex gap-x-[12px] min-w-max justify-between'
			}
			onClick={() => {
				onClick && onClick(identifier);
			}}
		>
			{/* IMAGE */}
			<div className={'flex gap-3'}>
				{imageUrl && (
					<img
						className={'w-[36px] h-[36px] object-cover rounded-[100px] flex-shrink-0'}
						src={imageUrl}
						alt={'Изображение лица'}
					/>
				)}
				<div className={'flex flex-col gap-y-[1px]'}>
					<h2 className={'font-title-small text-on-background'}>{title}</h2>
					<p className={'font-body-small text-secondary'}>{`id:${identifier}`}</p>
				</div>
			</div>
			{Boolean(tags.length) && (
				<div className={'flex items-center gap-x-[8px]'}>
					<span className={'font-label-medium text-on-primary-container'}>Теги:</span>
					{tags.map((tag, index) => {
						return (
							<Badge color={'primary'} variant={'contained'} key={index}>
								{tag}
							</Badge>
						);
					})}
				</div>
			)}
		</div>
	);
}
