import { ChangeEvent, FC } from 'react';
import { CountryType, NumberType } from '../LicensePlateInput';
import { LicensePlateInputRUS } from './LicensePlateInputRUS';
import { LicensePlateInputBY } from './LicensePlateInputBY';
import { LicensePlateInputKZ } from './LicensePlateInputKZ';
import { LicensePlateInputUA } from './LicensePlateInputUA';
import { LicensePlateInputOTHER } from './LicensePlateInputOTHER';

export type LicensePlateInputProps = {
	number: string;
	changeNumber: (e: ChangeEvent<HTMLInputElement>) => void;
	numberUid: string;
	numberError: string;
	region: string;
	changeRegion: (e: ChangeEvent<HTMLInputElement>) => void;
	regionUid: string;
	regionError: string;
	numberType?: NumberType;
	numberCountry?: CountryType;
};

export const LicensePlateInputWrapper: FC<LicensePlateInputProps> = ({ numberCountry = 'rus', ...props }) => {
	return (
		<>
			{numberCountry === 'rus' && <LicensePlateInputRUS {...props} />}
			{numberCountry === 'by' && <LicensePlateInputBY {...props} />}
			{numberCountry === 'kz' && <LicensePlateInputKZ {...props} />}
			{numberCountry === 'ua' && <LicensePlateInputUA {...props} />}
			{numberCountry === 'other' && <LicensePlateInputOTHER {...props} />}
		</>
	);
};
