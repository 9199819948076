import React, { FC } from 'react';
import { PageButton } from '@components/uikit/TablePagination/PageButton/PageButton';
import { PageSelect } from '@components/uikit/TablePagination/PageSelect/PageSelect';

export type TablePaginationProps = {
	totalItems: number;
	pageSize: number;
	currentPage: number;
	onPageChange?: (page: number) => void;
	onPageSizeChange?: (pageSize: number) => void;
	onSearchParamsChange?: () => void;
};

export const TablePagination: FC<TablePaginationProps> = ({
	totalItems,
	pageSize,
	currentPage,
	onPageChange,
	onPageSizeChange,
	onSearchParamsChange,
}) => {
	const pages = Math.ceil(totalItems / pageSize);
	const pagesArray = Array.from({ length: pages }).map((_, index) => {
		return index + 1;
	});

	const from = (currentPage - 1) * pageSize;
	const to = Math.min(from + pageSize, totalItems);

	const onPageSizeChangeHandler = (value: number) => {
		const page = Math.round(totalItems / value);
		if (page < currentPage) {
			onPageChange && onPageChange(page);
		}

		onPageSizeChange && onPageSizeChange(value);
	};
	return (
		<div
			className={
				'mt-[-1px] p-[16px] rounded-b-[10px] border border-neutral-90 flex justify-between  max-w-[calc(100vw-293px-32px-32px)]'
			}
		>
			<div
				className={'flex items-center pb-[12px] mb-[-12px] w-[calc(100vw-293px-32px-32px-39px-300px-32px)] flex-wrap'}
			>
				{pagesArray.map((page) => {
					return (
						<PageButton active={page === currentPage} key={page} onClick={onPageChange?.bind(null, page)}>
							{page}
						</PageButton>
					);
				})}
			</div>
			<div className={'flex gap-x-[26px] items-center h-[40px]'}>
				<PageSelect value={pageSize} onChange={onPageSizeChangeHandler} />
				<span className={'font-label-large text-secondary'}>
					{from + 1}-{to} из {totalItems}
				</span>
			</div>
		</div>
	);
};
