import React from 'react';
import { useGetBucketFaceQuery } from '@store/api';
import { SearchFaceSmall } from '@components/recognition/SearchFace/SearchFaceSmall/SearchFaceSmall';

type SearchFaceSmallWrapperProps = {
	searchFaceProps: Omit<React.ComponentProps<typeof SearchFaceSmall>, 'imageUrl'>;
	bucketName: string;
	faceId: number;
};
const SearchFaceSmallWrapper = ({ searchFaceProps, bucketName, faceId }: SearchFaceSmallWrapperProps) => {
	const { currentData } = useGetBucketFaceQuery({
		bucket_name: bucketName,
		f_id: faceId,
	});

	return <SearchFaceSmall {...searchFaceProps} imageUrl={currentData} />;
};

export default SearchFaceSmallWrapper;
