import React, { FC } from 'react';
import { Badge } from '@components/uikit/Badge/Badge';
import { Icon } from '@components/uikit/Icon/Icon';

export type SearchCarProps = {
	className?: string;
	licensePlate?: string;
	identifier?: string;
	tags?: string[];
	onCloseClick?: () => void;
};

export const SearchCar: FC<SearchCarProps> = ({
	className: propClassName,
	licensePlate,
	identifier,
	tags,
	onCloseClick,
}) => {
	const className = ['bg-primary-95 rounded-[8px] p-[8px_12px]', propClassName].filter(Boolean).join(' ');
	return (
		<div className={className}>
			<div className={'flex gap-x-[12px] items-center'}>
				<div className={'flex flex-col'}>
					<h2 className={'font-headline-xsmall text-on-background'}>{licensePlate}</h2>
					<div className={'flex gap-x-[12px] items-center'}>
						<p className={'font-body-medium text-secondary'}>{identifier}</p>
						{tags && Boolean(tags.length) && (
							<>
								<p className={'font-label-medium text-on-primary-container'}>Теги:</p>
								{tags?.map((tag) => {
									return (
										<Badge color={'primary'} variant={'contained'} key={tag}>
											{tag}
										</Badge>
									);
								})}
							</>
						)}
					</div>
				</div>
				<button className={'ml-auto'} onClick={onCloseClick}>
					<Icon name={'x'} width={24} height={24} />
				</button>
			</div>
		</div>
	);
};
