import React, { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import ReactPlayer from 'react-player';
import { BaseReactPlayerProps } from 'react-player/base';
import { Icon } from '../Icon/Icon';
import styles from './VideoPlayer.module.css';

export const VideoPlayer: ForwardRefExoticComponent<BaseReactPlayerProps & RefAttributes<ReactPlayer>> = forwardRef(
	(props, ref) => {
		if (!props.url) {
			return (
				<div className={styles['preview']}>
					<Icon name="cameraVideo" />
				</div>
			);
		}
		return <ReactPlayer ref={ref} {...props} />;
	},
);
