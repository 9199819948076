import React, { FC } from 'react';
import classes from './Tab.module.css';
import { Badge } from '@components/uikit/Badge/Badge';

export type TabProps = JSX.IntrinsicElements['button'] & {
	variant?: 'line' | 'pill'; // TODO: Add pill variant styles (see Figma)
	active?: boolean;
	badge?: string;
	contentClassName?: string;
};

export const Tab: FC<TabProps> = ({
	variant = 'pill',
	active,
	children,
	badge,
	className: propClassName,
	contentClassName: propContentClassName,
	...propsProxy
}) => {
	const classList = [classes['tab'], classes[variant], propClassName];
	active && classList.push(classes['active']);
	const className = classList.filter(Boolean).join(' ');

	const contentClassList = ['overflow-ellipsis overflow-hidden flex items-center gap-x-[12px]', propContentClassName];
	const contentClassName = contentClassList.filter(Boolean).join(' ');

	const badgeVariant = propsProxy.disabled ? 'secondary' : 'primary';

	return (
		<button className={className} {...propsProxy}>
			<span className={contentClassName}>
				{children}
				{badge && (
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					<Badge color={badgeVariant} size={'s'} variant={'filled'}>
						{badge}
					</Badge>
				)}
			</span>
		</button>
	);
};
