/**
 * Функция добавляет склоненное окончание к переданному кол-ву
 *
 * @param {number} num кол-во чего либо(например, дней)
 * @param {Array<string>} declVariants варианты склонения
 *
 * @example 5 дней, 22 студента
 */
export const getTextWithDeclination = (num: number, declVariants: Array<string>): string => {
	if (isNaN(num)) {
		return '';
	}

	const dividedBy100 = num % 100;
	const dividedBy10 = num % 10;

	if (dividedBy100 >= 5 && dividedBy100 <= 20) {
		return `${num} ${declVariants[2]}`;
	}

	if (dividedBy10 === 1) {
		return `${num} ${declVariants[0]}`;
	}

	if (dividedBy10 > 1 && dividedBy10 < 5) {
		return `${num} ${declVariants[1]}`;
	}

	return `${num} ${declVariants[2]}`;
};
