import React, { FC, forwardRef, PropsWithChildren } from 'react';
import { Popover as TinyPopover, PopoverProps as TinyPopoverProps } from 'react-tiny-popover';
import { usePopover } from '@components/uikit/Popover/usePopover';

export const DefaultWrapper = forwardRef<HTMLDivElement, PropsWithChildren>(({ children }, ref) => {
	return (
		<div className={'w-full h-max relative'} ref={ref}>
			{children}
		</div>
	);
});

export type PopoverChildrenRendererArgument = {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	toggleIsOpen: () => void;
};

export type PopoverProps = Omit<TinyPopoverProps, 'onClickOutside' | 'isOpen' | 'children'> & {
	isOpen?: boolean;
	children: ((state: PopoverChildrenRendererArgument) => JSX.Element) | JSX.Element;
	onClose?: () => void;
	// Если передана функция, то она должна вернуть true, если нужно закрыть поповер
	shouldCloseOnClickOutside?: (() => boolean) | boolean;
	// Дефолтная обёртка кнопки, которая открывает popover
	ToggleWrapper?: typeof DefaultWrapper;
};

/**
 * Расстояние между поповером и элементом регулируется prop'ом `padding`
 */
export const Popover: FC<PopoverProps> = (props) => {
	const { Wrapper, children, isOpen, className, onClickOutside, propsProxy } = usePopover(props);
	return (
		<TinyPopover isOpen={isOpen} containerClassName={className} onClickOutside={onClickOutside} {...propsProxy}>
			<Wrapper>
				{/* Передавая функцию-рендер в children, мы получаем доступ к внутреннему состоянию поповера */}
				{children}
			</Wrapper>
		</TinyPopover>
	);
};
