import React, { ChangeEvent, FC } from 'react';
import classes from './PageSelect.module.css';

export type PageSelectProps = {
	value: number;
	onChange?: (value: number) => void;
};

export const PageSelect: FC<PageSelectProps> = ({ value, onChange }) => {
	if (value < 1 || value > 10) {
		throw new Error('[PageSelect]: value must be between 1 and 10');
	}

	const onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
		onChange?.(Number(event.target.value));
	};

	return (
		<div className={'flex gap-x-[8px] items-center'}>
			<span className={'font-label-large text-secondary'}>Отражать на странице:</span>
			<div className={'flex gap-x-[4px] items-center'}>
				<select value={value} className={classes['select']} onChange={onSelectChange}>
					{Array.from({ length: 10 }).map((_, index) => {
						const page = index + 1;
						return (
							<option value={page} key={page}>
								{page}
							</option>
						);
					})}
				</select>
			</div>
		</div>
	);
};
