import { useSearchParams } from 'react-router-dom';
import { SearchFilterValues } from '@components/search-database/searchFilter/SearchFilter';

export const useSearchDatabaseParams = () => {
	const [searchFilterParams, setSearchFilterParams] = useSearchParams();

	const type = searchFilterParams.get('type');
	const status = searchFilterParams.get('status');
	const gettingDataType = searchFilterParams.get('gettingDataType');

	const filterParams: SearchFilterValues = {
		type: type?.split(',') ?? [],
		status: status?.split(',') ?? [],
		gettingDataType: gettingDataType?.split(',') ?? [],
	};

	const onFilterChange = (data: SearchFilterValues) => {
		setSearchFilterParams((prevState) => {
			data?.type.length > 0 ? prevState.set('type', data.type.join(',')) : prevState.delete('type');
			data?.status.length > 0 ? prevState.set('status', data.status.join(',')) : prevState.delete('status');
			data?.gettingDataType.length > 0
				? prevState.set('gettingDataType', data.gettingDataType.join(','))
				: prevState.delete('gettingDataType');

			return prevState;
		});
	};

	return { filterParams, setSearchFilterParams, onFilterChange };
};
