import { useGetPlateImageQuery, useGetVehicleImageQuery } from '@store/api';

type EventPreviewWrapperProps<T extends { id: number }> = {
	childrenProps: T;
	children: (value: T, previewVehicleUrl: string, previewPlateUrl: string) => JSX.Element;
};

function EventPreviewPlateWrapper<T extends { id: number }>({ children, childrenProps }: EventPreviewWrapperProps<T>) {
	const { currentData: vehicleData } = useGetVehicleImageQuery(childrenProps.id);
	const { currentData: plateData } = useGetPlateImageQuery(childrenProps.id);

	return children(childrenProps, vehicleData ?? '', plateData ?? '');
}

export default EventPreviewPlateWrapper;
