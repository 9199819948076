import { Badge } from '@components/uikit/Badge/Badge';
import { ROUTER } from '@constants/router';
import { useStoreSelector } from '@store/store';

export const EventOverviewPageHeader = () => {
	const newEventsLength = useStoreSelector((store) => {
		return store.events.newEvents;
	});

	return (
		<div className={'flex gap-x-[10px] items-center'}>
			<h1 className={'font-display-small text-primary-0 whitespace-nowrap'}>{ROUTER.EVENTS.TITLE}</h1>
			{Boolean(newEventsLength) && (
				<Badge color={'error'} variant={'filled'} size={'sm'}>
					{newEventsLength > 99 ? '99+' : newEventsLength}
				</Badge>
			)}
		</div>
	);
};
