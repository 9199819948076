import React, { FC } from 'react';
import { Badge } from '@components/uikit/Badge/Badge';
import { Icon } from '@components/uikit/Icon/Icon';
import { Button } from '@components/uikit/Button/Button';

export type SearchFaceProps = {
	similarity?: number;
	imageUrl?: string;
	title?: string;
	identifier?: string;
	tags?: string[];
	hasMatch?: boolean;
	hasMedia?: boolean;
	onClick?: () => void;
	onCloseClick?: () => void;
	addToBase?: (id: string) => void;
	removeFromList?: (id: string) => void;
	className?: string;
};

const QUALITY = 90;

export const SearchFace: FC<SearchFaceProps> = ({
	similarity,
	imageUrl,
	tags = [],
	identifier,
	title,
	hasMatch,
	hasMedia,
	onClick,
	onCloseClick,
	addToBase,
	className,
	removeFromList,
}) => {
	const similarityPercent = similarity ? Math.round(similarity * 100) : 0;
	return (
		<div
			className={`bg-primary-95 rounded-[8px] p-[10px_12px] min-w-max flex flex-col gap-4 transition-opacity ${className}`}
			onClick={onClick}
		>
			<div className={'flex gap-x-[12px] '}>
				{/* IMAGE */}
				{imageUrl && (
					<img
						className={'w-[72px] h-[72px] object-cover rounded-[100px] flex-shrink-0'}
						src={imageUrl}
						alt={'Изображение лица'}
					/>
				)}
				{/* META */}
				<div className={'flex flex-col gap-y-[1px]'}>
					<div className={'flex gap-2'}>
						<h2 className={'font-headline-xsmall text-on-background'}>{title}</h2>
						{similarity && (
							<div className={'flex gap-2'}>
								<span className={'text-secondary font-title-medium'}>Процент совпадения:</span>
								<Badge color={similarityPercent >= QUALITY ? 'green' : 'primary'} variant={'filled'}>
									{`${similarityPercent}%`}
								</Badge>
							</div>
						)}
					</div>
					<p className={'font-body-medium text-secondary'}>{`id:${identifier}`}</p>
					{Boolean(tags.length) && (
						<div className={'flex items-center gap-x-[8px]'}>
							<span className={'font-label-medium text-on-primary-container'}>Теги:</span>
							{tags.map((tag, index) => {
								return (
									<Badge color={'primary'} variant={'contained'} key={index}>
										{tag}
									</Badge>
								);
							})}
						</div>
					)}
				</div>
				{/* INDICATORS	*/}
				<div className={'flex gap-x-[8px] items-center justify-end ml-auto'}>
					{hasMatch && (
						<Badge color={'green'} variant={'filled'} size={'sm'}>
							<Icon name={'exclamationCircleFill'} className={'text-primary-100'} />
							Есть совпадение
						</Badge>
					)}
					{hasMedia && (
						<Badge color={'primary'} variant={'filled'} size={'sm'}>
							<Icon name={'cameraFill'} className={'text-primary-100'} />
							Медиа-файл
						</Badge>
					)}
				</div>
				{/* CLOSE	*/}
				{onCloseClick && (
					<div className={'flex items-center justify-center'}>
						<button onClick={onCloseClick}>
							<Icon name={'x'} width={32} height={32} className={'text-secondary-10'} />
						</button>
					</div>
				)}
			</div>
			{identifier && removeFromList && addToBase && (
				<div className={'flex gap-3'}>
					<Button
						variant="red"
						size="sm"
						onClick={() => {
							removeFromList(identifier);
						}}
					>
						Это разные люди
					</Button>

					<Button
						variant="primary"
						size="sm"
						onClick={() => {
							addToBase(identifier);
						}}
					>
						Это один человек
					</Button>
				</div>
			)}
		</div>
	);
};
