import React from 'react';
import { SearchFace } from '@components/recognition/SearchFace/SearchFace';
import { useGetBucketFaceQuery } from '@store/api';

type SearchFaceWrapperProps = {
	searchFaceProps: Omit<React.ComponentProps<typeof SearchFace>, 'imageUrl'>;
	bucketName: string;
	faceId: number;
};
const SearchFaceWrapper = ({ searchFaceProps, bucketName, faceId }: SearchFaceWrapperProps) => {
	const { currentData } = useGetBucketFaceQuery({
		bucket_name: bucketName,
		f_id: faceId,
	});

	return <SearchFace {...searchFaceProps} imageUrl={currentData} />;
};

export default SearchFaceWrapper;
