import { useGetUserDataQuery } from '@store/api';
import { authSelector } from '@store/slices/authSlice';
import { useStoreSelector } from '@store/store';

export const usePrefixBucket = () => {
	const { user } = useStoreSelector(authSelector);
	const { currentData } = useGetUserDataQuery(user?.userName ?? '', { skip: typeof user?.userName !== 'string' });
	const [prefixBucket] = currentData?.rw_buckets ?? [];
	return prefixBucket;
};
