export const EnToRuMap = {
	A: 'А',
	B: 'В',
	E: 'Е',
	K: 'К',
	M: 'М',
	H: 'Н',
	O: 'О',
	P: 'Р',
	C: 'С',
	T: 'Т',
	Y: 'У',
	X: 'Х',
};

// prettier-ignore
export const RuToEnMap = {
  'А': 'A',
  'В': 'B',
  'Е': 'E',
  'К': 'K',
  'М': 'M',
  'Н': 'H',
  'О': 'O',
  'Р': 'P',
  'С': 'C',
  'Т': 'T',
  'У': 'Y',
  'Х': 'X',
};
