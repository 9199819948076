import { useEffect, useMemo } from 'react';
import { io } from 'socket.io-client';
import { EventsSection } from './useEventsParams';
import { StoreEvent } from '@store/slices/eventsSlice';

/**
 * Хук содержит логику получения событий с камер через WebSocket
 * @param camerasId id камер
 * @param allowConnect флаг, отвечающий за получение событий, если false, то события приходить не будут
 * @param type тип события (лицо или номер)
 * @param onFaceHandler функция-обработчик для события-лица
 * @param onPlateHandler функция-обработчик для события-номера
 */

export const useWebSocketEvents = (
	camerasId: string[],
	allowConnect: boolean,
	type: EventsSection,
	onFaceHandler: (face_id: number, isNew?: boolean) => Promise<StoreEvent | undefined>,
	onPlateHandler: (plate_id_id: number, isNew?: boolean) => Promise<StoreEvent | undefined>,
) => {
	const socket = useMemo(() => {
		return io('https://duplo.corp.tevian.ru/camera', {
			autoConnect: false,
			transports: ['websocket'],
		});
	}, []);

	useEffect(() => {
		function onConnect() {
			for (const camera of camerasId) {
				socket.emit('join', camera);
			}
		}

		if (!allowConnect) {
			return;
		}

		socket.on('connect', onConnect);

		socket.connect();

		return () => {
			socket.disconnect();
			socket.off('connect', onConnect);
		};
	}, [camerasId, socket, allowConnect]);

	useEffect(() => {
		const handleFace = async ({ face_id }: { face_id: number }) => {
			await onFaceHandler(face_id, true);
		};

		const handlePlate = async ({ plate_id }: { plate_id: number }) => {
			await onPlateHandler(plate_id, true);
		};

		switch (type) {
			case 'faces':
				socket.on('face', handleFace);
				break;
			case 'plates':
				socket.on('plate', handlePlate);
				break;
			default:
				socket.on('face', handleFace);
				socket.on('plate', handlePlate);
		}

		return () => {
			socket.off('face', handleFace);
			socket.off('plate', handlePlate);
		};
		// eslint-disable-next-line
	}, [socket, type]);
};
