import { CameraResponse } from '@store/api';
import { cameraStatusAdapter } from './cameraStatusAdapter';
import { urlToType } from './urlToType';
import { Camera } from '@components/camera/CameraList/CameraZonesList';

export const cameraMapper = (current: CameraResponse): Camera => {
	const camType = urlToType(current.stream_params?.source ?? current.detector_params?.face?.rtsp_path ?? '') ?? '';

	return {
		id: current.camera_uuid,
		isFavorite: current.tags.includes('isFavorite'),
		isEditable: current.can_modify,
		name: current.camera_name ?? 'Без названия',
		status: cameraStatusAdapter({
			source: current.stream_params?.source,
			disable: current.detector_params?.disable,
		}),
		configCount: current.active_monitoring.length,
		detector_params: current.detector_params,
		stream_params: current.stream_params,
		timestamp_created: current.timestamp_created,
		tags: current.tags,
		type: camType,
	};
};
