import React, { FC, useMemo, useState } from 'react';
import { Button } from '@components/uikit/Button/Button';
import { TextInput } from '@components/uikit/TextInput/TextInput';
import { ReactComponent as Logo } from '@assets/illustrations/logo.svg';
import { Toggle } from '@components/uikit/Toggle/Toggle';
import { isCreds } from '../../types/typeguards/isCreds';
import { Creds } from '../../types/creds';
import { useLazyGetUserDataQuery } from '@store/api';
import { Spinner } from '@components/uikit/Spinner/Spinner';
import { useStoreDispatch } from '@store/store';
import { authSlice } from '@store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { ROUTER } from '@constants/router';
import { notifyError } from '@modules/toast/notifyError';

const defaultCreds: Creds = { username: '', password: '' };

export const LoginPage: FC = () => {
	const push = useNavigate();
	const dispatch = useStoreDispatch();
	const [getUserData, { isLoading }] = useLazyGetUserDataQuery();

	const initialCreds: Creds = useMemo(() => {
		try {
			const value = JSON.parse(localStorage.getItem('creds') || '');
			if (isCreds(value)) {
				const creds: Creds = { username: value.username, password: value.password };
				return creds;
			}
		} catch (error) {
			console.error(error);
		}
		return defaultCreds;
	}, []);

	const [username, setUsername] = useState(initialCreds.username);
	const [password, setPassword] = useState(initialCreds.password);

	const [shouldSaveCreds, setShouldSaveCreds] = useState(true);

	const handleSubmitForm = () => {
		dispatch(authSlice.actions.setUser({ password: password, username }));
		getUserData(username)
			.unwrap()
			.then(() => {
				if (shouldSaveCreds) {
					localStorage.setItem('creds', JSON.stringify({ password, username }));
				} else {
					localStorage.removeItem('creds');
				}

				push(ROUTER.SEARCH_DATABASE.PATH);
			})
			.catch((err) => {
				if (err.originalStatus === 401) {
					notifyError('Неверный логин или пароль');
				} else {
					notifyError('Ошибка при выполнении запроса');
				}
				localStorage.removeItem('creds');
				dispatch(authSlice.actions.clearUser());
			});
	};

	return (
		<div className="flex w-full h-screen justify-center items-center bg-neutral">
			<div className="flex flex-col gap-3 w-[430px] p-8 rounded-4 box-shadow-elevation-4 bg-surface-container-lowest">
				{isLoading && <Spinner />}
				<div className="flex justify-center">
					<Logo height={65} width={220} />
				</div>
				<TextInput
					type="text"
					variant="bordered"
					value={username}
					placeholder="Логин"
					onChange={(e) => {
						return setUsername(e.target.value.trim());
					}}
				/>
				<TextInput
					type="password"
					variant="bordered"
					value={password}
					placeholder="Пароль"
					onChange={(e) => {
						return setPassword(e.target.value.trim());
					}}
				/>
				<div className="mt-1">
					<Toggle
						type={'checkbox'}
						label={'Запомнить меня'}
						checked={shouldSaveCreds}
						onChange={() => {
							setShouldSaveCreds((prevState) => {
								return !prevState;
							});
						}}
					/>
				</div>
				<Button className="mt-3" onClick={handleSubmitForm}>
					Вход в систему
				</Button>
			</div>
		</div>
	);
};
