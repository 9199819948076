import { EventDateTimeValues } from '@components/event/EventDateTime/EventDateTime';
import { EventFilterValues } from '@components/event/EventFilter/EventFilter';
import { EventFilterTypeValues } from '@components/uikit/Select/EventTypeSelect/EventTypeSelect';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export type EventsSection = 'faces' | 'plates' | '';

export const useEventsParams = () => {
	const [searchFilterParams, setSearchFilterParams] = useSearchParams();

	// Функции для упрощения получения параметров.
	const getParam = (key: string) => {
		return searchFilterParams.get(key);
	};

	const getNumberParam = (key: string, defaultValue = 0) => {
		return Number(getParam(key) ?? defaultValue);
	};

	const getArrayParam = (key: string) => {
		return getParam(key)?.split(',') ?? [];
	};

	const getOptionalParam = (key: string, fallback?: string) => {
		const value = getParam(key);
		return value === 'all' ? undefined : value ?? fallback;
	};

	const filterParams: EventFilterValues & EventDateTimeValues & EventFilterTypeValues = useMemo(() => {
		return {
			section: (getParam('section') as EventsSection) ?? '',

			// FACE
			ageRange: [getNumberParam('age-start', 0), getNumberParam('age-end', 100)],
			gender: getOptionalParam('gender'),
			glasses: getOptionalParam('glasses'),
			mask: getOptionalParam('mask'),
			race: getArrayParam('race'),

			// PLATE
			overlay: [getNumberParam('overlay-start', 0), getNumberParam('overlay-end', 1)],
			quality: [getNumberParam('quality-start', 0), getNumberParam('quality-end', 1)],
			valid: getOptionalParam('valid', 'yes'),
			plateCategory: getArrayParam('plateCategory'),
			driverLicense: getArrayParam('driverLicense'),
			color: getArrayParam('color'),
			vehicleCategory: getArrayParam('vehicleCategory'),
			startDate: getParam('startDate') ?? undefined,
			endDate: getParam('endDate') ?? undefined,
			type: getParam('type') ?? 'new',
		};
		// eslint-disable-next-line
	}, [searchFilterParams]);

	const setOrDeleteParam = (params: URLSearchParams, key: string, value?: string | string[]) => {
		if (value && (Array.isArray(value) ? value.length > 0 : true)) {
			params.set(key, Array.isArray(value) ? value.join(',') : value);
		} else {
			params.delete(key);
		}
	};

	const onFilterChange = (data: EventFilterValues) => {
		setSearchFilterParams((prevState) => {
			setOrDeleteParam(prevState, 'section', data.section);

			// FACE
			setOrDeleteParam(prevState, 'gender', data.gender);
			setOrDeleteParam(prevState, 'age-start', String(data.ageRange[0]));
			setOrDeleteParam(prevState, 'age-end', String(data.ageRange[1]));
			setOrDeleteParam(prevState, 'glasses', data.glasses);
			setOrDeleteParam(prevState, 'mask', data.mask);
			setOrDeleteParam(prevState, 'race', data.race);

			// PLATE
			setOrDeleteParam(prevState, 'overlay-start', String(data.overlay[0]));
			setOrDeleteParam(prevState, 'overlay-end', String(data.overlay[1]));
			setOrDeleteParam(prevState, 'quality-start', String(data.quality[0]));
			setOrDeleteParam(prevState, 'quality-end', String(data.quality[1]));
			setOrDeleteParam(prevState, 'valid', data.valid);
			setOrDeleteParam(prevState, 'plateCategory', data.plateCategory);
			setOrDeleteParam(prevState, 'driverLicense', data.driverLicense);
			setOrDeleteParam(prevState, 'color', data.color);
			setOrDeleteParam(prevState, 'vehicleCategory', data.vehicleCategory);

			return prevState;
		});
	};
	const onDateTimeChange = (data: EventDateTimeValues) => {
		setSearchFilterParams((prevState) => {
			setOrDeleteParam(prevState, 'startDate', data.startDate);
			setOrDeleteParam(prevState, 'endDate', data.endDate);
			return prevState;
		});
	};

	const onEventTypeChange = (data: EventFilterTypeValues) => {
		setSearchFilterParams((prevState) => {
			setOrDeleteParam(prevState, 'type', data.type);
			return prevState;
		});
	};

	return { filterParams, onFilterChange, onDateTimeChange, onEventTypeChange };
};
