import { useSearchParams } from 'react-router-dom';
import { CameraFilterValues } from '@components/camera/CameraFilter/CameraFilter';

export const useCamerasParams = () => {
	const [searchFilterParams, setSearchFilterParams] = useSearchParams();

	const type = searchFilterParams.get('type');
	const favorite = searchFilterParams.get('favorite');

	const filterParams: CameraFilterValues = {
		type: type?.split(',') ?? [],
		favorite: Boolean(favorite) ?? false,
	};

	const onFilterChange = (data: CameraFilterValues) => {
		setSearchFilterParams((prevState) => {
			data?.type.length > 0 ? prevState.set('type', data.type.join(',')) : prevState.delete('type');
			data?.favorite ? prevState.set('favorite', data.favorite.toString()) : prevState.delete('favorite');

			return prevState;
		});
	};

	return { filterParams, setSearchFilterParams, onFilterChange };
};
