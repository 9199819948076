import React, { FC, PropsWithChildren } from 'react';
import classes from './Badge.module.css';

// Названия вариантов соответствуют названию палитры, а не label'у из макетов
export type BadgeColor = 'primary' | 'tertiary' | 'secondary' | 'green' | 'error';
export type BadgeVariant = 'filled' | 'contained' | 'outlined' | 'squared';
export type BadgeProps = PropsWithChildren<{
	size?: 'xs' | 'sm' | 'lg';
	color: BadgeColor;
	variant: BadgeVariant;
	className?: string;
}>;

export const Badge: FC<BadgeProps> = ({
	size = 'sm',
	color = 'primary',
	variant,
	children,
	className: propClassName,
}) => {
	const containerClassName = [classes['container'], classes[color], classes[variant], classes[size], propClassName]
		.filter(Boolean)
		.join(' ');

	return (
		<div className={containerClassName}>
			<div className={classes['content']}>{children}</div>
		</div>
	);
};
