import { FC } from 'react';
import classes from '../LicensePlateInput.module.css';
import { Tooltip } from 'react-tooltip';
import { replaceLetters } from '@utils/lisensePlateMapper';
import { RuToEnMap } from '@constants/lisenseLetters';
import { LicensePlateInputProps } from './LisensePlateInputWrapper';

const numberTypeValidation = {
	car: {
		maxLength: 6,
		placeholder: '0000АА',
	},
	taxi: {
		maxLength: 7,
		placeholder: 'ААА0000',
	},
	trailer: {
		maxLength: 6,
		placeholder: '0000АА',
	},
	motorcycle: {
		maxLength: 6,
		placeholder: '0000АА',
	},
};

export const LicensePlateInputBY: FC<Omit<LicensePlateInputProps, 'numberCountry'>> = ({
	number,
	changeNumber,
	numberUid,
	numberError,
	region,
	changeRegion,
	regionUid,
	regionError,
	numberType,
}) => {
	return (
		<div className={'flex gap-x-[8px]'}>
			{numberError && (
				<Tooltip
					className={'z-50'}
					anchorSelect={`#${numberUid}`}
					place={'bottom'}
					content={numberError}
					isOpen={true}
				/>
			)}
			{regionError && (
				<Tooltip className={'z-50'} anchorSelect={`#${regionUid}`} place={'top'} content={regionError} isOpen={true} />
			)}
			<div className={classes['number'] + ` ${numberType === 'taxi' ? 'order-1' : ''}`}>
				<input
					id={numberUid}
					value={replaceLetters(number, RuToEnMap)}
					type={'text'}
					className={classes['number__input'] + ' !w-[240px]'}
					maxLength={numberTypeValidation[numberType || 'car'].maxLength}
					placeholder={' '}
					onChange={changeNumber}
				/>
				<p className={classes['number__placeholder']}>{numberTypeValidation[numberType || 'car'].placeholder}</p>
			</div>
			<div id={regionUid} className={classes['region']}>
				<input
					value={region}
					type={'text'}
					className={classes['region__input']}
					maxLength={1}
					placeholder={' '}
					onChange={changeRegion}
				/>
				<p className={classes['region__placeholder']}>1</p>
			</div>
		</div>
	);
};
