import { CameraCreateLine } from '@components/camera/CameraCreate/CameraCreateLine';
import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { StepsHeader } from '@components/uikit/StepsHeader/StepsHeader';
import { ROUTER } from '@constants/router';

export const CameraCreateLinePage = () => {
	return (
		<DashboardLayout>
			<DashboardContentLayout
				showBackButton={false}
				drawDivider={false}
				overrideHeaderRenderer={
					<StepsHeader
						title={ROUTER.CAMERAS_CREATE_ZONE.TITLE}
						badges={[{ text: 'Линии детекции', color: 'tertiary', classes: '!text-primary-0' }, { text: 'Создание' }]}
					/>
				}
			>
				<CameraCreateLine />
			</DashboardContentLayout>
		</DashboardLayout>
	);
};
