import { Navigate, Route, Routes } from 'react-router-dom';
import React, { FC } from 'react';
import { ROUTER } from '@constants/router';
import { LoginPage } from './Login/LoginPage';
import { NotFoundPage } from './NotFound/NotFoundPage';
import { RecognitionDatabaseOverviewPage } from '@pages/recognition-database/RecognitionDatabaseOverviewPage';
import { RecognitionDatabaseConfigPage } from '@pages/search-database/config/RecognitionDatabaseConfigPage';
import { SearchDatabaseOverviewPage } from '@pages/search-database/SearchDatabaseOverviewPage';
import { authSelector } from '@store/slices/authSlice';
import { useStoreSelector } from '@store/store';
import ResultStage from '@pages/recognition-database/result/ResultStage';
import { EventOverviewPage } from './Events/EventOverviewPage';
import { MonitoringPage } from './monitoring/MonitoringPage';
import { SearchDatabaseCreateLicensePlatesConfigPage } from './search-database/create/SearchDatabaseCreateLicensePlatesConfigPage';
import ResultPlateStage from './recognition-database/result/ResultPlateStage';
import { SearchDatabaseCreateFacesConfigPage } from './search-database/create/SearchDatabaseCreateFacesConfigPage';
import { RecognitionDatabaseCreateFacesConfigPage } from './recognition-database/create/RecognitionDatabaseCreateFacesConfigPage';
import { RecognitionDatabaseCreateLicensePlatesConfigPage } from './recognition-database/create/RecognitionDatabaseCreateLicensePlatesConfigPage';
import { CameraCreateTypePage } from './Cameras/create/CameraCreateTypePage';
import { CameraCreateLinePage } from './Cameras/create/CameraCreateLine';
import { CameraCreateZonePage } from './Cameras/create/CameraCreateZonePage';
import { CamerasListPage } from './Cameras/CamerasListPage';

export const RouterPage: FC = () => {
	const { user } = useStoreSelector(authSelector);

	return (
		<Routes>
			<Route path={ROUTER.LOGIN.PATH} element={<LoginPage />} />
			{user && (
				<>
					<Route path={ROUTER.MONITORING.PATH} element={<MonitoringPage />} />
					<Route path={ROUTER.EVENTS.PATH} element={<EventOverviewPage />} />
					{/* CAMERAS */}
					<Route path={ROUTER.CAMERAS.PATH} element={<CamerasListPage />} />
					<Route path={ROUTER.CAMERAS_CREATE.PATH} element={<CameraCreateTypePage />} />
					<Route path={ROUTER.CAMERAS_CREATE_ZONE.PATH} element={<CameraCreateZonePage />} />
					<Route path={ROUTER.CAMERAS_CREATE_LINES.PATH} element={<CameraCreateLinePage />} />
					{/* SEARCH DATABASE */}
					<Route path={ROUTER.SEARCH_DATABASE.PATH} element={<SearchDatabaseOverviewPage />} />
					{/* RECOGNITION DATABASE */}
					<Route path={ROUTER.RECOGNITION_DATABASE.PATH} element={<RecognitionDatabaseOverviewPage />} />
					<Route path={ROUTER.SEARCH_DATABASE_ARCHIVE_FACE.PATH} element={<ResultStage />} />
					<Route path={ROUTER.SEARCH_DATABASE_ARCHIVE_PLATE.PATH} element={<ResultPlateStage />} />
					<Route path={ROUTER.RECOGNITION_DATABASE_CONFIG.PATH} element={<RecognitionDatabaseConfigPage />} />
					<Route path={ROUTER.SEARCH_DATABASE_CREATE_FACES.PATH} element={<SearchDatabaseCreateFacesConfigPage />} />
					<Route
						path={ROUTER.SEARCH_DATABASE_CREATE_LICENSE_PLATES.PATH}
						element={<SearchDatabaseCreateLicensePlatesConfigPage />}
					/>

					<Route
						path={ROUTER.RECOGNITION_DATABASE_CREATE_FACES.PATH}
						element={<RecognitionDatabaseCreateFacesConfigPage />}
					/>
					<Route
						path={ROUTER.RECOGNITION_DATABASE_CREATE_LICENSE_PLATES.PATH}
						element={<RecognitionDatabaseCreateLicensePlatesConfigPage />}
					/>
					{/* DEFAULT PAGE */}
					<Route path="/" element={<Navigate to={ROUTER.SEARCH_DATABASE.PATH} />} />
				</>
			)}
			{/* FALLBACK */}
			<Route path="*" element={user ? <NotFoundPage /> : <Navigate to={ROUTER.LOGIN.PATH} />} />
		</Routes>
	);
};
