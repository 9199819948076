import React, { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { TOAST_PROVIDER_ID } from '@constants/toastProvider';

export const notify = (content: ReactNode) => {
	toast.info(
		<div className="flex items-center">
			<span className="font-title-medium text-on-tertiary-container">{content}</span>
		</div>,
		{ containerId: TOAST_PROVIDER_ID },
	);
};
