import { Creds } from '../creds';

export const isCreds = (value: unknown): value is Creds => {
	return (
		typeof value === 'object' &&
		value !== null &&
		'username' in value &&
		'password' in value &&
		typeof value.username === 'string' &&
		typeof value.password === 'string'
	);
};
