import React, { FC, useId, useState } from 'react';
import { FramePlaceholder } from '@components/uikit/FramePlaceholder/FramePlaceholder';
import { PersonExtra } from '@components/event/EventSettings/PersonExtra/PersonExtra';
import { IconTextRow } from '@components/uikit/IconTextRow/IconTextRow';
import { useGetPlateImageQuery, useGetVehicleImageQuery } from '@store/api';
import { Button } from '@components/uikit/Button/Button';
import { Icon } from '@components/uikit/Icon/Icon';
import { ReactComponent as FlagRu } from '@assets/illustrations/flag-ru.svg';
import { Tooltip } from 'react-tooltip';
import { colorMap } from '../EventPreview/EventPreviewPlate';
import { dateParser } from '@utils/dateParser';
import { EventCopyLinkModal } from '../EventCopyLinkModal/EventCopyLinkModal';

export type EventSettingsType = {
	eventId: number;
	watched?: Nullable<boolean>;
	falsy?: Nullable<boolean>;
	comment?: Nullable<string>;
	title?: string;
	configTitle?: string;
	target?: string;
	targetId?: string;
	location?: string;
	camera?: string;
	cameraId?: string;
};

export type EventSettingsProps = {
	previewVehicleUrl?: Nullable<string>;
	previewPlateUrl?: Nullable<string>;
	eventId: number;
	watched?: Nullable<boolean>;
	falsy?: Nullable<boolean>;
	comment?: Nullable<string>;
	title?: string;
	configTitle?: string;
	target?: string;
	targetId?: string;
	location?: string;
	camera: string;
	color?: string;
	occlusion?: number;
	valid?: boolean;
	quality?: number;
	qualityVehicle?: number;
	purpose?: string;
	categoryVehicle?: string;
	plate?: string;
	country?: string;
	category?: string;
	onCommentChange?: (comment: string) => void;
	extra?: Nullable<React.ComponentProps<typeof PersonExtra>['extra']>;
	date: string;
	dateLocal: string;
	box?: {
		width: number;
		height: number;
		x: number;
		y: number;
	};
};

export const EventPlateSettings: FC<EventSettingsProps> = ({
	eventId,
	previewVehicleUrl,
	previewPlateUrl,
	plate,
	configTitle,
	title,
	onCommentChange,
	extra,
	camera,
	box,
	qualityVehicle,
	date,
	dateLocal,
	country,
	occlusion,
	valid,
	quality,
	category,
	color,
	purpose,
	categoryVehicle,
}) => {
	const { gmtLocal, dateStr, timeStr, timeStrLocal } = dateParser(date, dateLocal);

	const id = useId();
	const cameraNameId = useId();
	const timeId = useId();
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="flex flex-col gap-y-[20px] w-[749px]">
			{previewVehicleUrl || previewPlateUrl ? (
				<div className={'rounded-[4px] overflow-hidden self-center w-1/2 '}>
					{previewVehicleUrl && <img className={'object-fill w-full'} src={previewVehicleUrl} alt={'Кадр машины'} />}

					{previewPlateUrl && (
						<img className={'w-full object-fill aspect-[3.3/1]'} src={previewPlateUrl} alt={'Кадр номера'} />
					)}
				</div>
			) : (
				<FramePlaceholder className={'!w-[749px] !h-[500px]'} />
			)}
			<div className={'flex flex-col gap-y-[16px]'}>
				<div className={'flex items-start justify-between'}>
					<div className={'flex flex-col items-start justify-center gap-y-2'}>
						<div className={'flex items-center gap-x-2'}>
							<h2 className={'text-on-primary-container w-[416px] font-headline-small truncate'}>{title}</h2>
						</div>
						<div className={'flex gap-x-2'}>
							<IconTextRow
								size="l"
								className={'text-on-secondary-container opacity-50 !gap-x-[4px] '}
								icon={'calendarAll'}
							>
								{dateStr}
							</IconTextRow>
							<IconTextRow size="l" className={'text-on-secondary-container opacity-50 !gap-x-[4px]'} icon={'clock'}>
								{timeStrLocal}
							</IconTextRow>
							<div
								id={timeId}
								className="text-on-secondary-container hover:text-primary hover:opacity-100 opacity-50 transition-all"
							>
								<span>(GMT{gmtLocal <= 0 ? `+${Math.abs(gmtLocal)}` : -gmtLocal})</span>

								<Tooltip
									className={'z-50 !rounded-2'}
									anchorSelect={`#${CSS.escape(timeId)}`}
									place={'top'}
									content={`По московскому времени: ${timeStr}`}
								/>
							</div>
							<div id={cameraNameId}>
								<IconTextRow
									size="l"
									className={'opacity-50'}
									classNameText={'w-[300px] truncate'}
									icon={'cameraVideo'}
								>
									{camera}
								</IconTextRow>
								<Tooltip
									className={'z-50 !rounded-2'}
									anchorSelect={`#${CSS.escape(cameraNameId)}`}
									place={'top'}
									content={camera}
								/>
							</div>
						</div>
					</div>

					<Button
						onClick={() => {
							setIsOpen(true);
						}}
					>
						<Icon name="share" />
						Поделиться
					</Button>
				</div>

				{configTitle && (
					<div className={'grid grid-cols-2 gap-2'}>
						<IconTextRow className={'order-2'} icon={'boundingBox'}>
							{configTitle}
						</IconTextRow>
					</div>
				)}
				<div className={'flex gap-2'}>
					{color && (
						<div
							className={'w-10 h-10 rounded-2 border border-outline-variant flex items-center justify-center relative'}
							style={{
								background: color === 'white' || color === 'silver' || color === 'beige' ? '#001B3D' : 'white',
							}}
							id={id}
						>
							<Icon name="commonAuto" style={{ color: colorMap[color].color }} width={24} height={24} />

							<Tooltip
								className={'z-50 !rounded-2'}
								anchorSelect={`#${CSS.escape(id)}`}
								place={'top'}
								content={`Цвет: ${colorMap[color].name}. ${purpose}`}
							/>
						</div>
					)}

					{plate && (
						<div
							className={
								'border border-outline-variant bg-primary-100 rounded-2 w-max py-[5px] px-[11px] font-title-medium flex gap-1 items-center'
							}
						>
							<FlagRu height={15} width={21} />
							<span>{plate}</span>
						</div>
					)}
				</div>
				<div className={'w-[550px] grid grid-cols-2 grid-flow-row gap-y-1 '}>
					{categoryVehicle && (
						<p>
							<span className="font-medium">Категория:</span> {categoryVehicle}
						</p>
					)}
					{occlusion && (
						<p>
							<span className="font-medium">Перекрытие:</span> {occlusion.toFixed(2)}
						</p>
					)}
					{quality && (
						<p>
							<span className="font-medium">Качество номера:</span> {quality.toFixed(2)}
						</p>
					)}
					{qualityVehicle && (
						<p className={'w-1/2 min-w-[200px]'}>
							<span className="font-medium">Качество изображения:</span> {qualityVehicle.toFixed(2)}
						</p>
					)}
					{valid && (
						<p>
							<span className="font-medium">Соответствует стандарту:</span> {valid ? 'Да' : 'Нет'}
						</p>
					)}
					{category && (
						<p className="break-normal]">
							<span className="font-medium">Классификация:</span> {category}
						</p>
					)}
				</div>

				<PersonExtra extra={extra} />

				<span className={'h-[1px] w-full bg-neutralVariant-80'}></span>
			</div>

			<EventCopyLinkModal
				eventId={eventId}
				title={title}
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false);
				}}
			/>
		</div>
	);
};

export const EventSettingsPlateWithImage: FC<Omit<EventSettingsProps, 'vehicleData' | 'plateData'>> = (props) => {
	const { currentData: vehicleData } = useGetVehicleImageQuery(props.eventId);
	const { currentData: plateData } = useGetPlateImageQuery(props.eventId);

	const newProps = { ...props, previewVehicleUrl: vehicleData ?? '', previewPlateUrl: plateData ?? '' };
	return <EventPlateSettings {...newProps} />;
};
