import { useCallback, useEffect, useRef } from 'react';

/**
 * Custom hook for debouncing a callback function.
 * @param fn The callback function to be debounced.
 * @param delay The delay in milliseconds before the callback is invoked after the last call.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebounceCallback<T>(fn: (...args: T[]) => any, delay: number) {
	// A reference to track if the component unmounted
	const unmountFlagRef = useRef(false);
	// A reference to store the timer ID for setTimeout
	const timerRef = useRef<number>(0);

	// Cleanup effect to clear the timer and update unmountFlag when the component unmounts
	useEffect(() => {
		return () => {
			unmountFlagRef.current = true;
			clearTimeout(timerRef.current);
		};
	}, []);

	// The debounced callback function
	const overrideDelayedCallback = useCallback(
		(...args: T[]) => {
			// Clear the existing timer
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}

			// Set a new timer to invoke the callback after the delay
			timerRef.current = +setTimeout(() => {
				// Check if the component is still mounted before invoking the callback
				if (!unmountFlagRef.current) {
					fn(...args);
				}
			}, delay);
		},
		[fn, delay],
	);

	// Return the debounced callback
	return overrideDelayedCallback as (...args: T[]) => void;
}
