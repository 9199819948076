import React, { useEffect, useState } from 'react';
import { useGetCameraSnapshotQuery } from '@store/api';
import { useCameraInitialization } from '@hooks/useCameraInitalization';
import { CameraPreviewProps, CameraPreviewZone } from './CameraPreviewZone';

type CameraPreviewWrapperProps = Omit<CameraPreviewProps, 'imageUrl'>;
const CameraPreviewWrapper = (props: CameraPreviewWrapperProps) => {
	const { id } = props;
	const [snapshot, setSnapshot] = useState<string | null>(null);

	const { isInitialization } = useCameraInitialization(props.timestamp_created);

	const { currentData } = useGetCameraSnapshotQuery({ id, isInitialization });
	useEffect(() => {
		if (currentData !== undefined) {
			setSnapshot(currentData);
		}
	}, [currentData]);

	return <CameraPreviewZone {...props} imageUrl={snapshot ?? ''} />;
};

export default CameraPreviewWrapper;
