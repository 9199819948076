import React from 'react';
import { CameraZoneProps } from '@components/camera/Monitoring/MonitoringSlot/CameraZoneSelector/CameraZoneSelector.typedef';
import classes from './CameraZone.module.css';

const CameraZone = ({ zone: { id, title, isOff }, onZoneClick }: CameraZoneProps) => {
	const onZoneClickHandler = () => {
		onZoneClick(id);
	};
	return (
		<div className={classes['zone']} onClick={onZoneClickHandler}>
			<span>{title}</span>
			{isOff && <span> (Отключена)</span>}
		</div>
	);
};

export default CameraZone;
