import React, { FC } from 'react';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { ROUTER } from '@constants/router';
import { Button } from '@components/uikit/Button/Button';
import { Icon } from '@components/uikit/Icon/Icon';
import { Link, useSearchParams } from 'react-router-dom';
import { QueryTab } from '@components/uikit/Tab/QueryTab';
import { CameraZonesList } from '@components/camera/CameraList/CameraZonesList';
import { useInitialSearchParams } from '@hooks/useInitialSearchParams';
import { CameraLinesList } from '@components/camera/CameraList/CameraLinesList';

export const CamerasListPage: FC = () => {
	useInitialSearchParams({
		detectionType: 'zones',
	});
	const [sectionParams] = useSearchParams();
	const section = sectionParams.get('detectionType') ?? '';

	return (
		<DashboardLayout>
			<DashboardContentLayout
				title={ROUTER.CAMERAS.TITLE}
				showBackButton={false}
				drawDivider={false}
				headerContent={
					<div className="flex items-center gap-4">
						<div className={'flex bg-primary-95 p-2 rounded-3 gap-1'}>
							<QueryTab variant={'pill'} queryName={'detectionType'} matchOn={'zones'}>
								Зоны детекции
							</QueryTab>
							<QueryTab variant={'pill'} queryName={'detectionType'} matchOn={'lines'}>
								Линии детекции
							</QueryTab>
						</div>

						<Link to={ROUTER.CAMERAS_CREATE.PATH}>
							<Button>
								Создать камеру <Icon name="plusCircle" />
							</Button>
						</Link>
					</div>
				}
			>
				{section === 'zones' && <CameraZonesList />}
				{section === 'lines' && <CameraLinesList />}
			</DashboardContentLayout>
		</DashboardLayout>
	);
};
