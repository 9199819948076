import React, { FC, useState } from 'react';

import { EventFilter } from '@components/event/EventFilter/EventFilter';
import { IconButton } from '@components/uikit/Button/IconButton';
import { Badge } from '@components/uikit/Badge/Badge';
import { Popover } from '@components/uikit/Popover/Popover';
import { useEventsParams } from '@hooks/useEventsParams';

export const EventFilterWrapper: FC = () => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const { filterParams, onFilterChange } = useEventsParams();
	const filterCounter = filterParams
		? Object.values(filterParams).reduce((acc, curr) => {
				if (!curr || !curr.length || (curr[0] === 0 && curr[1] === 1) || (curr[0] === 0 && curr[1] === 100)) {
					return acc;
				}
				return ++acc;
		  }, 0)
		: 0;

	return (
		<Popover
			content={<EventFilter filterParams={filterParams} onFilterChange={onFilterChange} setIsOpen={setIsPopoverOpen} />}
			positions={['bottom', 'left', 'right']}
			padding={8}
			align={'end'}
			isOpen={isPopoverOpen}
			onClose={setIsPopoverOpen.bind(null, false)}
			shouldCloseOnClickOutside={false}
		>
			{({ toggleIsOpen }) => {
				return (
					<div className="relative">
						<IconButton
							onClick={setIsPopoverOpen.bind(null, !isPopoverOpen)}
							className={'flex-shrink-0'}
							icon={'filter'}
							variant={'grey'}
							size="l"
						/>
						{Boolean(filterCounter) && (
							<Badge color={'primary'} variant={'filled'} size={'xs'} className={'absolute top-[-4px] right-[-2px]'}>
								{filterCounter}
							</Badge>
						)}
					</div>
				);
			}}
		</Popover>
	);
};
