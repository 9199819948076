import React, { FC, PropsWithChildren } from 'react';
import { Icon } from '@components/uikit/Icon/Icon';

export const ErrorAlert: FC<PropsWithChildren & { closeToast?: () => void }> = ({ children, closeToast }) => {
	return (
		<div
			className={
				'p-[14px_16px] rounded-[8px] border border-error max-w-[647px] flex items-center gap-x-[12px] bg-error-container'
			}
			role={'button'}
			onClick={closeToast}
		>
			<Icon
				className={'text-on-error-container flex-shrink-0'}
				name={'exclamationDiamondFill'}
				width={24}
				height={24}
			/>
			<p className={'font-title-medium text-on-error-container'}>{children}</p>
		</div>
	);
};
