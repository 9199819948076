import React, { ReactNode } from 'react';
import classes from './CameraSettingsItem.module.css';

type CameraSettingsItemProps = {
	item: { id: string; value: ReactNode };
	onItemClick: (id: string) => void;
};
const CameraSettingsItem = ({ item: { id, value }, onItemClick }: CameraSettingsItemProps) => {
	const onSettingsItemHandler = () => {
		onItemClick(id);
	};
	return (
		<div className={classes['item']} onClick={onSettingsItemHandler}>
			{value}
		</div>
	);
};

export default CameraSettingsItem;
