import { Badge } from '@components/uikit/Badge/Badge';
import { Button } from '@components/uikit/Button/Button';
import { Checkbox } from '@components/uikit/Checkbox/Checkbox';
import { RangeInput } from '@components/uikit/RangeInput/RangeInput';
import { Radio } from '@components/uikit/Radio/Radio';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Accordion } from '@components/uikit/Accordion/Accordion';
import styles from './EventFilter.module.css';
import { colorMap } from '../EventPreview/EventPreviewPlate';
import { Toggle } from '@components/uikit/Toggle/Toggle';
import { EventsSection } from '@hooks/useEventsParams';
import { categoryData, genderData, glassesData, maskData, purposeData, raceData } from '@modules/events/mappedValues';
import { EventDateTimeValues } from '../EventDateTime/EventDateTime';

export type EventFilterValues = {
	ageRange: [number, number];
	gender: string | undefined;
	glasses: string | undefined;
	mask: string | undefined;
	race: string[];
	overlay: [number, number];
	quality: [number, number];
	valid: string | undefined;
	plateCategory: string[];
	driverLicense: string[];
	color: string[];
	vehicleCategory: string[];
	section: EventsSection;
};
export type EventFilterProps = {
	onFilterChange: (data: EventFilterValues) => void;
	filterParams?: (EventFilterValues & EventDateTimeValues) | undefined;
	setIsOpen: (isOpen: boolean) => void;
};

const driverLicenseData = ['A', 'B', 'C', 'D', 'M'];

export const EventFilter: FC<EventFilterProps> = ({ setIsOpen, filterParams, onFilterChange }: EventFilterProps) => {
	const {
		handleSubmit,
		getValues,
		setValue,
		control,
		formState: { errors },
	} = useForm<EventFilterValues>({
		defaultValues: {
			ageRange: filterParams?.ageRange ?? [0, 100],
			gender: filterParams?.gender ?? undefined,
			glasses: filterParams?.glasses ?? undefined,
			mask: filterParams?.mask ?? undefined,
			race: filterParams?.race ?? [],
			section: filterParams?.section ?? '',
			overlay: filterParams?.overlay ?? [0, 1],
			quality: filterParams?.quality ?? [0, 1],
			valid: filterParams?.valid ?? undefined,
			plateCategory: filterParams?.plateCategory ?? [],
			driverLicense: filterParams?.driverLicense ?? [],
			color: filterParams?.color ?? [],
			vehicleCategory: filterParams?.vehicleCategory ?? [],
		},
		mode: 'all',
	});

	const handleForm = (e: EventFilterValues) => {
		onFilterChange(e);
		setIsOpen(false);
	};

	return (
		<div className="p-4 pb-5 w-[500px] relative rounded-2 border border-outline-variant">
			<form onSubmit={handleSubmit(handleForm)}>
				<p className={'font-headline-xsmall mb-4 '}>Фильтрация</p>
				<div className={'flex mb-4 gap-4 flex-wrap'}>
					<Controller
						control={control}
						render={({ field: { value, name, onChange } }) => {
							return (
								<Toggle
									type={'toggle'}
									label={'Отражать все'}
									value={''}
									checked={value === ''}
									name={name}
									onChange={(e) => {
										setValue('section', '');
									}}
								/>
							);
						}}
						name={'section'}
					/>

					<Controller
						control={control}
						render={({ field: { value, name, onChange } }) => {
							return (
								<Toggle
									type={'toggle'}
									label={'Отражать лица'}
									value={'faces'}
									checked={value === 'faces'}
									name={name}
									onChange={(e) => {
										setValue('section', 'faces');
									}}
								/>
							);
						}}
						name={'section'}
					/>

					<Controller
						control={control}
						render={({ field: { value, name, onChange } }) => {
							return (
								<Toggle
									type={'toggle'}
									label={'Отражать номера'}
									value={'plates'}
									checked={value === 'plates'}
									name={name}
									onChange={(e) => {
										setValue('section', 'plates');
									}}
								/>
							);
						}}
						name={'section'}
					/>
				</div>

				<div className={styles.container}>
					<div className={'flex flex-col gap-4'}>
						<Accordion
							className={'border border-outline-variant p-3 bg-neutral rounded-2'}
							header={
								<>
									<div className="flex gap-3">
										<span className="font-headline-xsmall text-on-background">Лица</span>
									</div>
								</>
							}
							body={
								<>
									<div className="py-4 pt-1 border-b border-neutralVariant-80 flex flex-col gap-2">
										<div className="flex gap-2 items-center">
											<p className={'font-title-medium '}>Возраст</p>
										</div>
										<Controller
											control={control}
											render={({ field: { value, onChange } }) => {
												return (
													<RangeInput
														min={0}
														max={100}
														value={{
															start: value[0],
															end: value[1],
														}}
														onChange={(start, end) => {
															onChange([start, end]);
														}}
														error={errors.ageRange?.message}
													/>
												);
											}}
											name={'ageRange'}
											rules={{
												validate: (value) => {
													return (value[1] <= 100 && value[0] <= 100) || 'Не может быть больше 100';
												},
											}}
										/>
									</div>

									{genderData && (
										<div className="py-4 border-b border-neutralVariant-80 flex flex-col gap-2">
											<div className="flex gap-2 items-center">
												<p className={'font-title-medium '}>Пол</p>
											</div>

											<div className={'flex gap-2 gap-y-4 flex-wrap'}>
												{Object.keys(genderData).map((elem: string) => {
													return (
														<Controller
															key={elem}
															control={control}
															render={({ field: { value, name, onChange } }) => {
																return (
																	<Radio
																		label={genderData[elem]}
																		value={elem}
																		name={name}
																		checked={value === elem}
																		onChange={(e) => {
																			onChange(e.target.value);
																		}}
																	/>
																);
															}}
															name={'gender'}
														/>
													);
												})}

												<Controller
													control={control}
													render={({ field: { value, name, onChange } }) => {
														return (
															<Radio
																label={'Все'}
																value={'all'}
																name={name}
																checked={value === 'all'}
																onChange={(e) => {
																	onChange(e.target.value);
																}}
															/>
														);
													}}
													name={'gender'}
												/>
											</div>
										</div>
									)}

									{raceData && (
										<div className="py-4 border-b border-neutralVariant-80 flex flex-col gap-2">
											<div className="flex gap-2 items-center">
												<p className={'font-title-medium '}>Раса</p>
												{Boolean(filterParams?.race.length) && (
													<Badge color={'primary'} variant={'filled'} size={'xs'}>
														{filterParams?.race.length}
													</Badge>
												)}
											</div>

											<div className={'flex gap-2 gap-y-4  flex-wrap'}>
												{Object.keys(raceData).map((elem: string) => {
													return (
														<Controller
															key={elem}
															control={control}
															render={({ field: { value, name, onChange } }) => {
																return (
																	<Checkbox
																		label={raceData[elem]}
																		value={elem}
																		name={name}
																		checked={value.includes(elem)}
																		onChange={(e) => {
																			const values = getValues().race;
																			if (e.target.checked) {
																				values.push(e.target.value);
																			} else {
																				values.splice(values.indexOf(e.target.value), 1);
																			}
																			onChange(values);
																		}}
																	/>
																);
															}}
															name={'race'}
														/>
													);
												})}
											</div>
										</div>
									)}

									{(maskData || glassesData) && (
										<div className="py-4 border-b border-neutralVariant-80 flex gap-4">
											{glassesData && (
												<div className="flex flex-col gap-2 flex-grow">
													<div className=" flex gap-2 items-center">
														<p className={'font-title-medium'}>Очки</p>
													</div>

													<div className={'flex gap-2 gap-y-4  flex-wrap'}>
														<Controller
															control={control}
															render={({ field: { value, name, onChange } }) => {
																return (
																	<Radio
																		label={'Да'}
																		value={'yes'}
																		checked={value === 'yes'}
																		name={name}
																		onChange={(e) => {
																			onChange(e.target.value);
																		}}
																	/>
																);
															}}
															name={'glasses'}
														/>
														<Controller
															control={control}
															render={({ field: { value, name, onChange } }) => {
																return (
																	<Radio
																		label={'Нет'}
																		value={'none'}
																		name={name}
																		checked={value === 'none'}
																		onChange={(e) => {
																			onChange(e.target.value);
																		}}
																	/>
																);
															}}
															name={'glasses'}
														/>

														<Controller
															control={control}
															render={({ field: { value, name, onChange } }) => {
																return (
																	<Radio
																		label={'Все'}
																		value={'all'}
																		name={name}
																		checked={value === 'all'}
																		onChange={(e) => {
																			onChange(e.target.value);
																		}}
																	/>
																);
															}}
															name={'glasses'}
														/>
													</div>
												</div>
											)}

											{maskData && (
												<div className="flex flex-col gap-2 flex-grow">
													<div className="flex gap-2 items-center">
														<p className={'font-title-medium'}>Маска</p>
													</div>

													<div className={'flex gap-2  gap-y-4  flex-wrap'}>
														<Controller
															control={control}
															render={({ field: { value, name, onChange } }) => {
																return (
																	<Checkbox
																		label={'Да'}
																		value={'yes'}
																		checked={value === 'yes'}
																		name={name}
																		onChange={(e) => {
																			onChange(e.target.value);
																		}}
																	/>
																);
															}}
															name={'mask'}
														/>
														<Controller
															control={control}
															render={({ field: { value, name, onChange } }) => {
																return (
																	<Checkbox
																		label={'Нет'}
																		value={'none'}
																		name={name}
																		checked={value === 'none'}
																		onChange={(e) => {
																			onChange(e.target.value);
																		}}
																	/>
																);
															}}
															name={'mask'}
														/>

														<Controller
															control={control}
															render={({ field: { value, name, onChange } }) => {
																return (
																	<Radio
																		label={'Все'}
																		value={'all'}
																		name={name}
																		checked={value === 'all'}
																		onChange={(e) => {
																			onChange(e.target.value);
																		}}
																	/>
																);
															}}
															name={'mask'}
														/>
													</div>
												</div>
											)}
										</div>
									)}
								</>
							}
						/>

						<Accordion
							className={'border border-outline-variant p-3 bg-neutral rounded-2'}
							header={
								<>
									<div className="flex gap-3">
										<span className="font-headline-xsmall text-on-background">Номера</span>
									</div>
								</>
							}
							body={
								<>
									<div className="py-4 pt-1 border-b border-neutralVariant-80 flex flex-col gap-2">
										<div className="flex gap-2 items-center">
											<p className={'font-title-medium '}>Перекрытие</p>
										</div>
										<Controller
											control={control}
											render={({ field: { value, onChange } }) => {
												return (
													<RangeInput
														min={0}
														max={1}
														step={0.01}
														value={{
															start: value[0],
															end: value[1],
														}}
														onChange={(start, end) => {
															onChange([start, end]);
														}}
														error={errors.overlay?.message}
													/>
												);
											}}
											name={'overlay'}
											rules={{
												validate: (value) => {
													return (value[1] <= 1 && value[0] <= 1) || 'Не может быть больше 1';
												},
											}}
										/>
									</div>

									<div className="py-4 pt-1 border-b border-neutralVariant-80 flex flex-col gap-2">
										<div className="flex gap-2 items-center">
											<p className={'font-title-medium '}>Качество номера</p>
										</div>
										<Controller
											control={control}
											render={({ field: { value, onChange } }) => {
												return (
													<RangeInput
														min={0}
														max={1}
														step={0.01}
														value={{
															start: value[0],
															end: value[1],
														}}
														onChange={(start, end) => {
															onChange([start, end]);
														}}
														error={errors.quality?.message}
													/>
												);
											}}
											name={'quality'}
											rules={{
												validate: (value) => {
													return (value[1] <= 1 && value[0] <= 1) || 'Не может быть больше 1';
												},
											}}
										/>
									</div>

									<div className="py-4  pt-1  border-b border-neutralVariant-80 flex flex-col gap-2 ">
										<div className=" flex gap-2 items-center">
											<p className={'font-title-medium'}>Соответствует стандарту</p>
										</div>

										<div className={'flex gap-2 gap-y-4  flex-wrap'}>
											<Controller
												control={control}
												render={({ field: { value, name, onChange } }) => {
													return (
														<Radio
															label={'Да'}
															value={'yes'}
															checked={value === 'yes'}
															name={name}
															onChange={(e) => {
																onChange(e.target.value);
															}}
														/>
													);
												}}
												name={'valid'}
											/>
											<Controller
												control={control}
												render={({ field: { value, name, onChange } }) => {
													return (
														<Radio
															label={'Нет'}
															value={'none'}
															name={name}
															checked={value === 'none'}
															onChange={(e) => {
																onChange(e.target.value);
															}}
														/>
													);
												}}
												name={'valid'}
											/>

											<Controller
												control={control}
												render={({ field: { value, name, onChange } }) => {
													return (
														<Radio
															label={'Все'}
															value={'all'}
															name={name}
															checked={value === 'all'}
															onChange={(e) => {
																onChange(e.target.value);
															}}
														/>
													);
												}}
												name={'valid'}
											/>
										</div>
									</div>

									{categoryData && (
										<div className="py-4 border-b border-neutralVariant-80 flex flex-col gap-2">
											<div className="flex gap-2 items-center">
												<p className={'font-title-medium '}>Категория номера</p>
												{Boolean(filterParams?.plateCategory.length) && (
													<Badge color={'primary'} variant={'filled'} size={'xs'}>
														{filterParams?.plateCategory.length}
													</Badge>
												)}
											</div>

											<div className={'flex gap-2 gap-y-4  flex-wrap'}>
												{Object.keys(categoryData).map((elem: string) => {
													return (
														<Controller
															key={elem}
															control={control}
															render={({ field: { value, name, onChange } }) => {
																return (
																	<Checkbox
																		label={categoryData[elem]}
																		value={elem}
																		name={name}
																		checked={value.includes(elem)}
																		onChange={(e) => {
																			const values = getValues().plateCategory;
																			if (e.target.checked) {
																				values.push(e.target.value);
																			} else {
																				values.splice(values.indexOf(e.target.value), 1);
																			}
																			onChange(values);
																		}}
																	/>
																);
															}}
															name={'plateCategory'}
														/>
													);
												})}
											</div>
										</div>
									)}

									{driverLicenseData && (
										<div className="py-4 border-b border-neutralVariant-80 flex flex-col gap-2">
											<div className="flex gap-2 items-center">
												<p className={'font-title-medium '}>Категория транспортного средства</p>
												{Boolean(filterParams?.driverLicense.length) && (
													<Badge color={'primary'} variant={'filled'} size={'xs'}>
														{filterParams?.driverLicense.length}
													</Badge>
												)}
											</div>

											<div className={'flex gap-2 gap-y-4  flex-wrap'}>
												{driverLicenseData.map((elem: string) => {
													return (
														<Controller
															key={elem}
															control={control}
															render={({ field: { value, name, onChange } }) => {
																return (
																	<Checkbox
																		label={elem}
																		value={elem}
																		name={name}
																		checked={value.includes(elem)}
																		onChange={(e) => {
																			const values = getValues().driverLicense;
																			if (e.target.checked) {
																				values.push(e.target.value);
																			} else {
																				values.splice(values.indexOf(e.target.value), 1);
																			}
																			onChange(values);
																		}}
																	/>
																);
															}}
															name={'driverLicense'}
														/>
													);
												})}
											</div>
										</div>
									)}

									{purposeData && (
										<div className="py-4 border-b border-neutralVariant-80 flex flex-col gap-2">
											<div className="flex gap-2 items-center">
												<p className={'font-title-medium '}>Классификация</p>
												{Boolean(filterParams?.vehicleCategory.length) && (
													<Badge color={'primary'} variant={'filled'} size={'xs'}>
														{filterParams?.vehicleCategory.length}
													</Badge>
												)}
											</div>

											<div className={'flex gap-2 gap-y-4  flex-wrap'}>
												{Object.keys(purposeData).map((elem: string) => {
													return (
														<Controller
															key={elem}
															control={control}
															render={({ field: { value, name, onChange } }) => {
																return (
																	<Checkbox
																		label={purposeData[elem]}
																		value={elem}
																		name={name}
																		checked={value.includes(elem)}
																		onChange={(e) => {
																			const values = getValues().vehicleCategory;
																			if (e.target.checked) {
																				values.push(e.target.value);
																			} else {
																				values.splice(values.indexOf(e.target.value), 1);
																			}
																			onChange(values);
																		}}
																	/>
																);
															}}
															name={'vehicleCategory'}
														/>
													);
												})}
											</div>
										</div>
									)}

									{colorMap && (
										<div className="py-4 flex flex-col gap-2">
											<div className="flex gap-2 items-center">
												<p className={'font-title-medium '}>Цвет кузова</p>
												{Boolean(filterParams?.color.length) && (
													<Badge color={'primary'} variant={'filled'} size={'xs'}>
														{filterParams?.color.length}
													</Badge>
												)}
											</div>

											<div className={'flex gap-2 gap-y-4  flex-wrap'}>
												{Object.keys(colorMap).map((elem: string) => {
													return (
														<Controller
															key={elem}
															control={control}
															render={({ field: { value, name, onChange } }) => {
																return (
																	<Checkbox
																		label={colorMap[elem].name}
																		value={elem}
																		name={name}
																		checked={value.includes(elem)}
																		onChange={(e) => {
																			const values = getValues().color;
																			if (e.target.checked) {
																				values.push(e.target.value);
																			} else {
																				values.splice(values.indexOf(e.target.value), 1);
																			}
																			onChange(values);
																		}}
																	/>
																);
															}}
															name={'color'}
														/>
													);
												})}
											</div>
										</div>
									)}
								</>
							}
						/>
					</div>
				</div>

				<Button className={'mt-6'} size="sm">
					Сохранить
				</Button>
			</form>
		</div>
	);
};
