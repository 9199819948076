/**
 * Навигация по приложению.
 * Описывает доступные в приложении пути и заголовки страниц.
 */
export const ROUTER = {
	ROOT: {
		PATH: '/',
		TITLE: 'Главная',
	},
	LOGIN: {
		PATH: '/login',
		TITLE: 'Авторизация',
	},
	CAMERAS: {
		PATH: '/cameras',
		TITLE: 'Камеры',
	},
	CAMERAS_CREATE: {
		PATH: '/cameras/create',
		TITLE: 'Создание камеры',
	},
	CAMERAS_CREATE_LINES: {
		PATH: '/cameras/create/camera-lines',
		TITLE: 'Создание камеры',
	},
	CAMERAS_CREATE_ZONE: {
		PATH: '/cameras/create/camera-zone',
		TITLE: 'Создание камеры',
	},
	SEARCH_DATABASE: {
		PATH: '/search-database',
		TITLE: 'Базы поиска',
	},
	SEARCH_DATABASE_CREATE_FACES: {
		PATH: '/search-database/faces',
		TITLE: 'Настройка детекции лиц',
	},
	SEARCH_DATABASE_CREATE_LICENSE_PLATES: {
		PATH: '/search-database/license-plates',
		TITLE: 'Настройка детекции номеров',
	},
	SEARCH_DATABASE_ARCHIVE_FACE: {
		PATH: '/search-database/archive-face',
		TITLE: 'Результаты поиска лиц',
	},
	SEARCH_DATABASE_ARCHIVE_PLATE: {
		PATH: '/search-database/archive-plate',
		TITLE: 'Результаты поиска номеров',
	},
	RECOGNITION_DATABASE: {
		PATH: '/recognition-database',
		TITLE: 'База лиц и номеров',
	},
	RECOGNITION_DATABASE_CONFIG: {
		PATH: '/recognition-database/:configId',
		TITLE: 'Редактирование конфигурации',
	},
	RECOGNITION_DATABASE_CREATE_FACES: {
		PATH: '/recognition-database/faces',
		TITLE: 'Добавление лиц в базу',
	},
	RECOGNITION_DATABASE_CREATE_LICENSE_PLATES: {
		PATH: '/recognition-database/license-plates',
		TITLE: 'Добавление номеров в базу',
	},
	EVENTS: {
		PATH: '/events',
		TITLE: 'События',
	},
	MONITORING: {
		PATH: '/monitoring',
		TITLE: 'Панель наблюдения',
	},
	VERSION: {
		PATH: '/api-version',
		TITLE: 'Версия API',
	},
} as const;
