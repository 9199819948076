import { useSearchParams } from 'react-router-dom';
import { usePrefixBucket } from './usePrefixBucket';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useBucketName = () => {
	const prefixBucket = usePrefixBucket();

	const [bucketNameQuery, setBucketNameQuery] = useSearchParams();
	const [bucketName, setBucketName] = useState<string | undefined>(bucketNameQuery.get('bucket_id') ?? undefined);

	useEffect(() => {
		if (bucketName) return;
		if (prefixBucket !== undefined) {
			setBucketNameQuery((prev) => {
				prev.set('bucket_id', `${prefixBucket}${uuidv4()}`);
				return prev;
			});
			setBucketName(`${prefixBucket}${uuidv4()}`);
		}
	}, [bucketName, prefixBucket, setBucketNameQuery]);

	return { bucketName, prefixBucket };
};
