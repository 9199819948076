import React, { FC } from 'react';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { ROUTER } from '@constants/router';

export const RecognitionDatabaseConfigPage: FC = () => {
	return (
		<DashboardLayout>
			<DashboardContentLayout
				title={ROUTER.RECOGNITION_DATABASE_CONFIG.TITLE}
				showBackButton={false}
				drawDivider={false}
			>
				%RECOGNITION_DATABASE_CONFIG%
			</DashboardContentLayout>
		</DashboardLayout>
	);
};
