import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreRootState } from '@store/store';

export type Match = {
	type: 'face';
	id: number;
	identity: string;
	tags: string[];
	camera_uuid: string;
	camera_name: string;
	similarity: number;
	timestamp: string;
	timestamp_local: string;
	quality?: number;
	age?: number;
	gender?: string;
	glasses?: string;
	facial_hair?: string;
	mask?: string;
	race?: string;
	box?: {
		width: number;
		height: number;
		x: number;
		y: number;
	};
};

export type MatchPlate = {
	type: 'plate';
	id: number;
	identity: string;
	tags: string[];
	camera_uuid: string;
	camera_name: string;
	similarity: number;
	timestamp: string;
	timestamp_local: string;
	occlusion?: number;
	valid?: boolean;
	quality?: number;
	box?: {
		width: number;
		height: number;
		x: number;
		y: number;
	};
	plate?: string;
	country?: string;
	countryCode?: string;
	category?: string;
	vehicle?: {
		category: string;
		purpose: string;
		color: string;
		visibility?: number;
	};
};
export type StoreEvent = Match | MatchPlate;

type EventPreviewImage = {
	eventId: number;
	type: 'plate' | 'face';
	box?: {
		width: number;
		height: number;
		x: number;
		y: number;
	};
};

type EventsSliceState = {
	activeEvent: StoreEvent | undefined;
	eventPreviewData: EventPreviewImage | undefined;
	activeEventId: number | undefined;
	offset: number;
	total: number;
	max_ts?: string;
	newEvents: number;
	trackIds: string[];
};

const initialState: EventsSliceState = {
	eventPreviewData: undefined,
	activeEventId: undefined,
	activeEvent: undefined,
	offset: 0,
	total: 0,
	newEvents: 0,
	trackIds: [],
};

export const eventsSlice = createSlice({
	name: 'events',
	initialState,
	reducers: {
		clearEvents(state) {
			state.offset = 0;
			state.total = 0;
			state.activeEventId = undefined;
			state.newEvents = 0;
			state.trackIds = [];
		},
		setEventPreview(state, action: PayloadAction<EventPreviewImage>) {
			state.eventPreviewData = action.payload;
		},
		clearEventPreview(state) {
			state.eventPreviewData = undefined;
		},
		setaActiveEventId(state, action: PayloadAction<number | undefined>) {
			state.activeEventId = action.payload;
		},
		setaActiveEvent(state, action: PayloadAction<StoreEvent | undefined>) {
			state.activeEvent = action.payload;
		},
		claearActiveEvent(state) {
			state.activeEvent = undefined;
			state.activeEventId = undefined;
		},
		incOffset(state) {
			if (state.total > state.offset) {
				state.offset += 10;
			}
		},
		incNewEvents(state) {
			state.newEvents++;
		},
		addTrackId(state, action: PayloadAction<string[]>) {
			state.trackIds = [...new Set([...state.trackIds, ...action.payload])];
		},
		setTotal(state, action: PayloadAction<number>) {
			state.total = action.payload;
		},
		setMaxTs(state, action: PayloadAction<string | undefined>) {
			state.max_ts = action.payload;
		},
	},
});

export const eventsSelector = (state: StoreRootState) => {
	return state.events;
};
