import React, { FC } from 'react';
import styles from './Modal.module.css';
import ReactModal, { Props } from 'react-modal';
import { Icon } from '../Icon/Icon';
import { Spinner } from '../Spinner/Spinner';

type ExcludedAttributes = 'sh';

export type ModalProps = Omit<Props, ExcludedAttributes> & {
	title?: string;
	isLoading?: boolean;
	onClose?: () => unknown;
};

export const Modal: FC<ModalProps> = ({ onClose, isLoading, children, title, className: propClassName, ...props }) => {
	const overlayClassName = {
		base: styles['overlay'],
		afterOpen: styles['overlay-after-open'],
		beforeClose: styles['overlay-before-open'],
	};

	const className = [styles['modal'], propClassName].filter(Boolean).join(' ');
	const hasHeader = typeof title === 'string' || onClose;

	return (
		<ReactModal
			closeTimeoutMS={200}
			overlayClassName={overlayClassName}
			className={className}
			style={{
				content: {
					overflow: 'auto',
				},
			}}
			shouldCloseOnEsc={true}
			shouldCloseOnOverlayClick={true}
			onRequestClose={onClose}
			appElement={document.getElementById('root') as HTMLElement}
			bodyOpenClassName={'overflow-hidden'}
			{...props}
		>
			{hasHeader && (
				<div className={styles['header']}>
					{title && <span className="font-headline-small text-on-primary-container">{title}</span>}
					{onClose && (
						<button className={styles['close-button']} onClick={onClose}>
							<Icon name="x" />
						</button>
					)}
				</div>
			)}
			<div className="flex flex-col">
				{isLoading && <Spinner />}
				{children}
			</div>
		</ReactModal>
	);
};
