import { useEffect, useRef, useState } from 'react';

export type UseLocalStorageValueArg = {
	key: string;
};

/**
 * Хук для получения значения из localStorage (при изменении значения в localStorage происходит перерендер);
 * https://stackoverflow.com/questions/35865481/storage-event-not-firing/65348883#65348883
 * @param key - ключ, по которому хранится значение в localStorage
 */
export function useLocalStorageValue({ key }: UseLocalStorageValueArg) {
	const [value, setValue] = useState(() => {
		return localStorage.getItem(key);
	});

	const listener = (event: StorageEvent) => {
		if (event.key !== key) {
			return;
		}
		const nextValue = event.newValue ? event.newValue : null;
		setValue(nextValue);
	};

	const listenerRef = useRef(listener);
	listenerRef.current = listener;

	useEffect(() => {
		const eventListener = (event: StorageEvent) => {
			listenerRef.current(event);
		};
		window.addEventListener('storage', eventListener);
		return () => {
			window.removeEventListener('storage', eventListener);
		};
	}, []);

	return value;
}
