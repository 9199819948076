import { Badge, BadgeColor } from '@components/uikit/Badge/Badge';
import { ROUTER } from '@constants/router';
import { numWord } from '@utils/numWord';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { SearchTaskAction } from '../SearchTaskAction/SearchTaskAction';
import { useGetCamerasByBucketQuery } from '@store/api';
import { SearchTasksDirty } from '../SearchDatabaseTable';
import { Spinner } from '@components/uikit/Spinner/Spinner';
import dayjs from 'dayjs';

export type SearchDatabaseTableItemProps = SearchTasksDirty & {
	onDelete: (id: string) => void;
	onEdit: (id: string, type: string) => void;
	disabled?: boolean;
};

type TStatus = 'ACTIVE' | 'INACTIVE' | 'DONE';

const PLATE_WORDS = ['номер', 'номера', 'номеров'] as const;
const FACE_WORDS = ['лицо', 'лица', 'лиц'] as const;
const UNKNOWN_WORDS = ['Не определено', 'Не определено', 'Не определено'] as const;

const TASK_TYPE_WORDS = {
	FACE: FACE_WORDS,
	PLATE: PLATE_WORDS,
	UNKNOWN: UNKNOWN_WORDS,
};

const TASK_TYPE: Record<
	SearchTasksDirty['task_type'],
	{
		title: string;
		color: BadgeColor;
		link: string;
	}
> = {
	FACE: {
		title: 'Определение лиц',
		color: 'primary',
		link: ROUTER.SEARCH_DATABASE_ARCHIVE_FACE.PATH,
	},
	PLATE: {
		title: 'Определение номеров',
		color: 'tertiary',
		link: ROUTER.SEARCH_DATABASE_ARCHIVE_PLATE.PATH,
	},
	UNKNOWN: {
		title: 'Не определено',
		color: 'secondary',
		link: '',
	},
};
const STATUSES: Record<
	TStatus,
	{
		title: string;
		color: BadgeColor;
	}
> = {
	ACTIVE: {
		title: 'Активен',
		color: 'green',
	},
	INACTIVE: {
		title: 'Не активен',
		color: 'error',
	},
	DONE: {
		title: 'Завершен',
		color: 'secondary',
	},
};

export const SearchDatabaseTableItem: FC<SearchDatabaseTableItemProps> = ({
	id,
	title,
	task_type,
	detection_type,
	duration,
	dt_created,
	objects,
	date,
	onDelete,
	onEdit,
	disabled = false,
}) => {
	const { currentData, isLoading } = useGetCamerasByBucketQuery(id);

	const isActive = () => {
		if (!duration) return !duration;

		const endTime = Date.parse(dayjs(dt_created).add(duration, 'hour').toISOString());
		return endTime > Date.now();
	};

	const status: TStatus = !currentData?.num_cameras
		? 'INACTIVE'
		: isActive() && detection_type !== 'archive'
		? 'ACTIVE'
		: 'DONE';

	return (
		<tr>
			<td>
				{detection_type === 'archive' ? (
					(() => {
						const urlSearchParams = new URLSearchParams();
						urlSearchParams.append('bucket_id', id);
						if (date) {
							const [startDate, endDate] = date.replace('archive:', '').split(',');
							urlSearchParams.append('endDate', endDate);
							startDate && urlSearchParams.append('startDate', startDate);
						}
						return <Link to={`${TASK_TYPE[task_type].link}?${urlSearchParams.toString()}`}>{title}</Link>;
					})()
				) : (
					<Link to={`${ROUTER.EVENTS.PATH}?bucket_id=${id}`}>{title}</Link>
				)}
			</td>
			<td>
				<Badge className={'!rounded-[8px]'} color={TASK_TYPE[task_type].color} variant={'contained'} size={'lg'}>
					{TASK_TYPE[task_type].title}
				</Badge>
			</td>
			<td>
				{isLoading && <Spinner />}
				{!isLoading && (
					<Badge className={'!rounded-[8px]'} color={STATUSES[status].color} variant={'filled'} size={'lg'}>
						{STATUSES[status].title}
					</Badge>
				)}
			</td>
			<td>{`${objects} ${numWord(objects, TASK_TYPE_WORDS[task_type])}`}</td>
			<td>
				{detection_type && (
					<Badge className={'!rounded-[8px]'} color={'primary'} variant={'contained'} size={'lg'}>
						{detection_type === 'archive' ? 'Архив' : 'Внешние данные'}
					</Badge>
				)}
			</td>
			<td>
				<SearchTaskAction
					disabled={disabled}
					onDelete={() => {
						onDelete(id);
					}}
					onEdit={() => {
						onEdit(id, task_type);
					}}
				/>
			</td>
		</tr>
	);
};
