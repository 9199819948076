import { useEffect, useState, RefObject, useCallback } from 'react';

type ImageParams = {
	kX: number;
	kY: number;
	relativeLeft: number;
	relativeTop: number;
	relativeRight: number;
	relativeBottom: number;
	x: number;
	y: number;
};

type CoordsPoints = {
	a: { x: number; y: number };
	b: { x: number; y: number };
	c: { x: number; y: number };
	d: { x: number; y: number };
	reversal: boolean;
};

export type Zone = {
	x: number;
	y: number;
	w: number;
	h: number;
};

type ZonePickerProps = {
	image?: string;
	setZone?: (coords: Zone) => void;
	ref: RefObject<HTMLImageElement>;
	a: RefObject<HTMLDivElement>;
	b: RefObject<HTMLDivElement>;
	c: RefObject<HTMLDivElement>;
	d: RefObject<HTMLDivElement>;
	initial?: Zone;
};

export const useZonePicker = ({ image, setZone, ref, a, b, c, d, initial }: ZonePickerProps) => {
	const [click, setClick] = useState(false);
	const [imageParams, setImageParams] = useState<ImageParams | undefined>();
	const [draggedPoint, setDraggedPoint] = useState<'a' | 'b' | 'c' | 'd' | undefined>();
	const [coords, setCoords] = useState<CoordsPoints | undefined>();
	const [relativeCoords, setRelativeCoords] = useState<CoordsPoints | undefined>();
	const [rectCoords, setRectCoords] = useState<Zone | undefined>();
	const [onlineCoords, setOnlineCoords] = useState<Zone | undefined>();

	const calculation = () => {
		const offset = 2;

		if (ref && 'current' in ref && ref.current !== null) {
			const x = ref.current.offsetWidth,
				y = ref.current.offsetHeight;

			const ratioX = ref.current.naturalWidth,
				ratioY = ref.current.naturalHeight;

			const relativeLeft = ref.current.getBoundingClientRect().x;
			const relativeTop = ref.current.getBoundingClientRect().y;
			const relativeRight = ref.current.getBoundingClientRect().right;
			const relativeBottom = ref.current.getBoundingClientRect().bottom;

			setImageParams({
				kX: +(x / (ratioX - offset)).toFixed(2),
				kY: +(y / (ratioY - offset)).toFixed(2),
				relativeLeft: Math.round(relativeLeft),
				relativeTop: Math.round(relativeTop),
				relativeRight: Math.round(relativeRight),
				relativeBottom: Math.round(relativeBottom),
				x: x,
				y: y,
			});
		}
	};

	const onResize = () => {
		calculation();

		if (ref && 'current' in ref && ref.current !== null && imageParams) {
			const x = ref.current?.offsetWidth,
				y = ref.current?.offsetHeight;

			if (x !== imageParams.x || y !== imageParams.y)
				if (
					a.current &&
					a.current !== null &&
					b.current &&
					b.current !== null &&
					c.current &&
					c.current !== null &&
					d.current &&
					d.current !== null
				) {
					setCoords((prev) => {
						if (prev) {
							return {
								...prev,
								a: {
									x: a.current?.getBoundingClientRect().x ?? prev.a.x,
									y: a.current?.getBoundingClientRect().y ?? prev.a.y,
								},
								b: {
									x: b.current?.getBoundingClientRect().x ?? prev.b.x,
									y: b.current?.getBoundingClientRect().y ?? prev.b.y,
								},
								c: {
									x: c.current?.getBoundingClientRect().x ?? prev.c.x,
									y: c.current?.getBoundingClientRect().y ?? prev.c.y,
								},
								d: {
									x: d.current?.getBoundingClientRect().x ?? prev.d.x,
									y: d.current?.getBoundingClientRect().y ?? prev.d.y,
								},
							};
						}
					});
				}
		}
	};

	const handleZone = useCallback(
		(func: (coords: Zone) => void) => {
			if (imageParams && relativeCoords) {
				const width = relativeCoords.c.x - relativeCoords.a.x,
					height = relativeCoords.c.y - relativeCoords.a.y;

				if (width > 0 && height < 0) {
					func({
						x: Math.round((relativeCoords.a.x * imageParams.x) / 100 / imageParams.kX),
						y: Math.round((relativeCoords.c.y * imageParams.y) / 100 / imageParams.kY),
						w: Math.round((Math.abs(relativeCoords.c.x - relativeCoords.a.x) * imageParams.x) / 100 / imageParams.kX),
						h: Math.round((Math.abs(relativeCoords.c.y - relativeCoords.a.y) * imageParams.y) / 100 / imageParams.kY),
					});
				} else if (width < 0 && height > 0) {
					func({
						x: Math.round((relativeCoords.c.x * imageParams.x) / 100 / imageParams.kX),
						y: Math.round((relativeCoords.a.y * imageParams.y) / 100 / imageParams.kY),
						w: Math.round((Math.abs(relativeCoords.c.x - relativeCoords.a.x) * imageParams.x) / 100 / imageParams.kX),
						h: Math.round((Math.abs(relativeCoords.c.y - relativeCoords.a.y) * imageParams.y) / 100 / imageParams.kY),
					});
				} else if (width < 0 && height < 0) {
					func({
						x: Math.round((relativeCoords.c.x * imageParams.x) / 100 / imageParams.kX),
						y: Math.round((relativeCoords.c.y * imageParams.y) / 100 / imageParams.kY),
						w: Math.round((Math.abs(relativeCoords.c.x - relativeCoords.a.x) * imageParams.x) / 100 / imageParams.kX),
						h: Math.round((Math.abs(relativeCoords.c.y - relativeCoords.a.y) * imageParams.y) / 100 / imageParams.kY),
					});
				} else {
					func({
						x: Math.round((relativeCoords.a.x * imageParams.x) / 100 / imageParams.kX),
						y: Math.round((relativeCoords.a.y * imageParams.y) / 100 / imageParams.kY),
						w: Math.round((Math.abs(relativeCoords.c.x - relativeCoords.a.x) * imageParams.x) / 100 / imageParams.kX),
						h: Math.round((Math.abs(relativeCoords.c.y - relativeCoords.a.y) * imageParams.y) / 100 / imageParams.kY),
					});
				}
			}
		},
		[imageParams, relativeCoords],
	);

	useEffect(() => {
		if (image && ref && 'current' in ref && ref.current !== null) {
			ref.current.onload = () => {
				calculation();
			};
		}
	});

	useEffect(() => {
		if (initial && imageParams && !click) {
			setCoords({
				a: {
					x: Math.round(initial.x * imageParams.kX + imageParams.relativeLeft),
					y: Math.round(initial.y * imageParams.kY + imageParams.relativeTop),
				},
				b: {
					x: Math.round((initial.w + initial.x) * imageParams.kX + imageParams.relativeLeft),
					y: Math.round(initial.y * imageParams.kY + imageParams.relativeTop),
				},
				c: {
					x: Math.round((initial.w + initial.x) * imageParams.kX + imageParams.relativeLeft),
					y: Math.round((initial.h + initial.y) * imageParams.kY + imageParams.relativeTop),
				},
				d: {
					x: Math.round(initial.x * imageParams.kX + imageParams.relativeLeft),
					y: Math.round((initial.h + initial.y) * imageParams.kY + imageParams.relativeTop),
				},
				reversal: false,
			});
		} else if (imageParams && !click) {
			setCoords({
				a: {
					x: Math.round(imageParams.relativeLeft),
					y: Math.round(imageParams.relativeTop),
				},
				b: {
					x: Math.round(imageParams.relativeRight),
					y: Math.round(imageParams.relativeTop),
				},
				c: {
					x: Math.round(imageParams.relativeRight),
					y: Math.round(imageParams.relativeBottom),
				},
				d: {
					x: Math.round(imageParams.relativeLeft),
					y: Math.round(imageParams.relativeBottom),
				},
				reversal: false,
			});
		}
	}, [initial, imageParams, click]);

	const onFirstClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setCoords({
			a: {
				x: e.clientX,
				y: e.clientY,
			},
			b: {
				x: e.clientX,
				y: e.clientY,
			},
			c: {
				x: e.clientX,
				y: e.clientY,
			},
			d: {
				x: e.clientX,
				y: e.clientY,
			},
			reversal: false,
		});

		setClick(true);
	};

	const onSecondClick = useCallback(() => {
		setClick(false);
		setZone && handleZone(setZone);
	}, [setZone, handleZone]);

	const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (draggedPoint) {
			return;
		}

		if (click) {
			onSecondClick();
		} else {
			onResize();
			onFirstClick(e);
		}
	};

	const onPointClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (click) {
			return;
		}
		e.stopPropagation();
	};

	const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (click) {
			return;
		}
		onResize();

		const target = e.target as HTMLDivElement;
		if (a.current && b.current && c.current && d.current) {
			if (a.current.contains(target)) setDraggedPoint('a');
			if (b.current.contains(target)) setDraggedPoint('b');
			if (c.current.contains(target)) setDraggedPoint('c');
			if (d.current.contains(target)) setDraggedPoint('d');
		}
	};

	const onMouseUp = useCallback(() => {
		if (click) {
			return;
		}
		setDraggedPoint(undefined);
		setZone && handleZone(setZone);
	}, [click, setDraggedPoint, setZone, handleZone]);

	const onMousemove = (e: MouseEvent) => {
		if (!click && !draggedPoint) {
			return;
		}

		if (click) {
			return handleClick(e);
		}

		if (draggedPoint) {
			handleDrag(e);
		}
	};

	const handleClick = (e: MouseEvent) => {
		setCoords((prev) => {
			if (prev && imageParams) {
				return {
					...prev,
					b: {
						x:
							e.clientX > imageParams.relativeRight
								? imageParams.relativeRight
								: e.clientX > imageParams.relativeLeft
								? e.clientX
								: imageParams.relativeLeft,
						y: prev.a.y,
					},
					c: {
						x:
							e.clientX > imageParams.relativeRight
								? imageParams.relativeRight
								: e.clientX > imageParams.relativeLeft
								? e.clientX
								: imageParams.relativeLeft,
						y:
							e.clientY > imageParams.relativeBottom
								? imageParams.relativeBottom
								: e.clientY > imageParams.relativeTop
								? e.clientY
								: imageParams.relativeTop,
					},
					d: {
						x: prev.a.x,
						y:
							e.clientY > imageParams.relativeBottom
								? imageParams.relativeBottom
								: e.clientY > imageParams.relativeTop
								? e.clientY
								: imageParams.relativeTop,
					},
					reversal:
						(prev.a.x - e.clientX < 0 && prev.a.y - e.clientY > 0) ||
						(prev.a.x - e.clientX > 0 && prev.a.y - e.clientY < 0),
				};
			}
		});
	};

	const handleDrag = (e: MouseEvent) => {
		if (!draggedPoint) {
			return;
		}

		switch (draggedPoint) {
			case 'a':
				setCoords((prev) => {
					if (prev && imageParams) {
						return {
							...prev,
							a: {
								x:
									e.clientX > imageParams.relativeRight
										? imageParams.relativeRight
										: e.clientX > imageParams.relativeLeft
										? e.clientX
										: imageParams.relativeLeft,
								y:
									e.clientY > imageParams.relativeBottom
										? imageParams.relativeBottom
										: e.clientY > imageParams.relativeTop
										? e.clientY
										: imageParams.relativeTop,
							},
							b: {
								x: prev.b.x,
								y:
									e.clientY > imageParams.relativeBottom
										? imageParams.relativeBottom
										: e.clientY > imageParams.relativeTop
										? e.clientY
										: imageParams.relativeTop,
							},
							d: {
								x:
									e.clientX > imageParams.relativeRight
										? imageParams.relativeRight
										: e.clientX > imageParams.relativeLeft
										? e.clientX
										: imageParams.relativeLeft,
								y: prev.d.y,
							},
							reversal:
								(prev.b.x - e.clientX < 0 && prev.d.y - e.clientY > 0) ||
								(prev.b.x - e.clientX > 0 && prev.d.y - e.clientY < 0),
						};
					}
				});
				break;
			case 'b':
				setCoords((prev) => {
					if (prev && imageParams) {
						return {
							...prev,
							a: {
								x: prev.a.x,
								y:
									e.clientY > imageParams.relativeBottom
										? imageParams.relativeBottom
										: e.clientY > imageParams.relativeTop
										? e.clientY
										: imageParams.relativeTop,
							},
							b: {
								x:
									e.clientX > imageParams.relativeRight
										? imageParams.relativeRight
										: e.clientX > imageParams.relativeLeft
										? e.clientX
										: imageParams.relativeLeft,
								y:
									e.clientY > imageParams.relativeBottom
										? imageParams.relativeBottom
										: e.clientY > imageParams.relativeTop
										? e.clientY
										: imageParams.relativeTop,
							},
							c: {
								x:
									e.clientX > imageParams.relativeRight
										? imageParams.relativeRight
										: e.clientX > imageParams.relativeLeft
										? e.clientX
										: imageParams.relativeLeft,
								y: prev.c.y,
							},
							reversal:
								(prev.a.x - e.clientX < 0 && prev.c.y - e.clientY < 0) ||
								(prev.a.x - e.clientX > 0 && prev.c.y - e.clientY > 0),
						};
					}
				});
				break;
			case 'c':
				setCoords((prev) => {
					if (prev && imageParams) {
						return {
							...prev,
							c: {
								x:
									e.clientX > imageParams.relativeRight
										? imageParams.relativeRight
										: e.clientX > imageParams.relativeLeft
										? e.clientX
										: imageParams.relativeLeft,
								y:
									e.clientY > imageParams.relativeBottom
										? imageParams.relativeBottom
										: e.clientY > imageParams.relativeTop
										? e.clientY
										: imageParams.relativeTop,
							},
							b: {
								x:
									e.clientX > imageParams.relativeRight
										? imageParams.relativeRight
										: e.clientX > imageParams.relativeLeft
										? e.clientX
										: imageParams.relativeLeft,
								y: prev.b.y,
							},
							d: {
								x: prev.d.x,
								y:
									e.clientY > imageParams.relativeBottom
										? imageParams.relativeBottom
										: e.clientY > imageParams.relativeTop
										? e.clientY
										: imageParams.relativeTop,
							},
							reversal:
								(prev.d.x - e.clientX < 0 && prev.b.y - e.clientY > 0) ||
								(prev.d.x - e.clientX > 0 && prev.b.y - e.clientY < 0),
						};
					}
				});
				break;
			case 'd':
				setCoords((prev) => {
					if (prev && imageParams) {
						return {
							...prev,
							d: {
								x:
									e.clientX > imageParams.relativeRight
										? imageParams.relativeRight
										: e.clientX > imageParams.relativeLeft
										? e.clientX
										: imageParams.relativeLeft,
								y:
									e.clientY > imageParams.relativeBottom
										? imageParams.relativeBottom
										: e.clientY > imageParams.relativeTop
										? e.clientY
										: imageParams.relativeTop,
							},
							c: {
								x: prev.c.x,
								y:
									e.clientY > imageParams.relativeBottom
										? imageParams.relativeBottom
										: e.clientY > imageParams.relativeTop
										? e.clientY
										: imageParams.relativeTop,
							},
							a: {
								x:
									e.clientX > imageParams.relativeRight
										? imageParams.relativeRight
										: e.clientX > imageParams.relativeLeft
										? e.clientX
										: imageParams.relativeLeft,
								y: prev.a.y,
							},
							reversal:
								(prev.c.x - e.clientX < 0 && prev.a.y - e.clientY < 0) ||
								(prev.c.x - e.clientX > 0 && prev.a.y - e.clientY > 0),
						};
					}
				});
				break;
		}
	};

	const isCoordsEqual = (a: CoordsPoints, b: CoordsPoints) => {
		return (
			a.a.x === b.a.x &&
			a.a.y === b.a.y &&
			a.b.x === b.b.x &&
			a.b.y === b.b.y &&
			a.c.x === b.c.x &&
			a.c.y === b.c.y &&
			a.d.x === b.d.x &&
			a.d.y === b.d.y &&
			a.reversal === b.reversal
		);
	};

	useEffect(() => {
		if (coords && imageParams) {
			setRelativeCoords((prev) => {
				const result = {
					a: {
						x: Math.round(((coords.a.x - imageParams.relativeLeft) * 100) / imageParams.x),
						y: Math.round(((coords.a.y - imageParams.relativeTop) * 100) / imageParams.y),
					},
					b: {
						x: Math.round(((coords.c.x - imageParams.relativeLeft) * 100) / imageParams.x),
						y: Math.round(((coords.a.y - imageParams.relativeTop) * 100) / imageParams.y),
					},
					c: {
						x: Math.round(((coords.c.x - imageParams.relativeLeft) * 100) / imageParams.x),
						y: Math.round(((coords.c.y - imageParams.relativeTop) * 100) / imageParams.y),
					},
					d: {
						x: Math.round(((coords.a.x - imageParams.relativeLeft) * 100) / imageParams.x),
						y: Math.round(((coords.c.y - imageParams.relativeTop) * 100) / imageParams.y),
					},
					reversal: coords.reversal,
				};
				if (prev) {
					if (isCoordsEqual(prev, result)) {
						return prev;
					}
				}

				return result;
			});

			//Координаты рамки
			setRectCoords(() => {
				const w = coords.b.x - coords.a.x;
				const h = coords.d.y - coords.a.y;

				if (w > 0 && h < 0) {
					return {
						x: Math.round(((coords.d.x - imageParams.relativeLeft) * 100) / imageParams.x),
						y: Math.round(((coords.d.y - imageParams.relativeTop) * 100) / imageParams.y),
						w: Math.abs(w),
						h: Math.abs(h),
					};
				} else if (w < 0 && h > 0) {
					return {
						x: Math.round(((coords.b.x - imageParams.relativeLeft) * 100) / imageParams.x),
						y: Math.round(((coords.b.y - imageParams.relativeTop) * 100) / imageParams.y),
						w: Math.abs(w),
						h: Math.abs(h),
					};
				} else if (w < 0 && h < 0) {
					return {
						x: Math.round(((coords.c.x - imageParams.relativeLeft) * 100) / imageParams.x),
						y: Math.round(((coords.c.y - imageParams.relativeTop) * 100) / imageParams.y),
						w: Math.abs(w),
						h: Math.abs(h),
					};
				} else {
					return {
						x: Math.round(((coords.a.x - imageParams.relativeLeft) * 100) / imageParams.x),
						y: Math.round(((coords.a.y - imageParams.relativeTop) * 100) / imageParams.y),
						w: Math.abs(w),
						h: Math.abs(h),
					};
				}
			});
		}

		handleZone(setOnlineCoords);
	}, [handleZone, coords, imageParams]);

	useEffect(() => {
		const handleMouseUp = () => {
			if (click) {
				onSecondClick();
			}
			if (draggedPoint) {
				onMouseUp();
			}
		};

		if (click || draggedPoint) {
			document.addEventListener('mouseup', handleMouseUp);
		}

		return () => {
			document.removeEventListener('mouseup', handleMouseUp);
		};
	}, [click, draggedPoint, onMouseUp, onSecondClick]);

	return {
		onClick,
		onPointClick,
		onMouseDown,
		onMouseUp,
		onMousemove,
		rectCoords,
		relativeCoords,
		onlineCoords,
		click,
		draggedPoint,
	};
};
