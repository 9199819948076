export const countries = {
	AF: 'Афганистан',
	AX: 'Аландские острова',
	AL: 'Албания',
	DZ: 'Алжир',
	AS: 'Американское Самоа',
	AD: 'Андорра',
	AO: 'Ангола',
	AI: 'Ангилья',
	AQ: 'Антарктида',
	AG: 'Антигуа и Барбуда',
	AR: 'Аргентина',
	AM: 'Армения',
	AW: 'Аруба',
	AU: 'Австралия',
	AT: 'Австрия',
	AZ: 'Азербайджан',
	BS: 'Багамские острова',
	BH: 'Бахрейн',
	BD: 'Бангладеш',
	BB: 'Барбадос',
	BY: 'Беларусь',
	BE: 'Бельгия',
	BZ: 'Белиз',
	BJ: 'Бенин',
	BM: 'Бермуды',
	BT: 'Бутан',
	BO: 'Боливия',
	BQ: 'Бонэйр, Синт-Эстатиус и Саба',
	BA: 'Босния и Герцеговина',
	BW: 'Ботсвана',
	BV: 'Остров Буве',
	BR: 'Бразилия',
	IO: 'Британская территория в Индийском океане',
	BN: 'Бруней-Даруссалам',
	BG: 'Болгария',
	BF: 'Буркина-Фасо',
	BI: 'Бурунди',
	CV: 'Кабо-Верде',
	KH: 'Камбоджа',
	CM: 'Камерун',
	CA: 'Канада',
	KY: 'Каймановы острова',
	CF: 'Центрально-Африканская Республика',
	TD: 'Чад',
	CL: 'Чили',
	CN: 'Китай',
	CX: 'Остров Рождества',
	CC: 'Кокосовые острова',
	CO: 'Колумбия',
	KM: 'Коморы',
	CG: 'Республика Конго',
	CD: 'Демократическая Республика Конго',
	CK: 'Острова Кука',
	CR: 'Коста-Рика',
	CI: 'Кот-д’Ивуар',
	HR: 'Хорватия',
	CU: 'Куба',
	CW: 'Кюрасао',
	CY: 'Кипр',
	CZ: 'Чешская Республика',
	DK: 'Дания',
	DJ: 'Джибути',
	DM: 'Доминика',
	DO: 'Доминиканская Республика',
	EC: 'Эквадор',
	EG: 'Египет',
	SV: 'Сальвадор',
	GQ: 'Экваториальная Гвинея',
	ER: 'Эритрея',
	EE: 'Эстония',
	ET: 'Эфиопия',
	FK: 'Фолклендские острова',
	FO: 'Фарерские острова',
	FJ: 'Фиджи',
	FI: 'Финляндия',
	FR: 'Франция',
	GF: 'Французская Гвиана',
	PF: 'Французская Полинезия',
	TF: 'Французские Южные территории',
	GA: 'Габон',
	GM: 'Гамбия',
	GE: 'Грузия',
	DE: 'Германия',
	GH: 'Гана',
	GI: 'Гибралтар',
	GR: 'Греция',
	GL: 'Гренландия',
	GD: 'Гренада',
	GP: 'Гваделупа',
	GU: 'Гуам',
	GT: 'Гватемала',
	GG: 'Гернси',
	GN: 'Гвинея',
	GW: 'Гвинея-Бисау',
	GY: 'Гайана',
	HT: 'Гаити',
	HM: 'Остров Херд и острова Макдональд',
	VA: 'Государство-город Ватикан',
	HN: 'Гондурас',
	HK: 'Гонконг',
	HU: 'Венгрия',
	IS: 'Исландия',
	IN: 'Индия',
	ID: 'Индонезия',
	IR: 'Иран',
	IQ: 'Ирак',
	IE: 'Ирландия',
	IM: 'Остров Мэн',
	IL: 'Израиль',
	IT: 'Италия',
	JM: 'Ямайка',
	JP: 'Япония',
	JE: 'Джерси',
	JO: 'Иордания',
	KZ: 'Казахстан',
	KE: 'Кения',
	KI: 'Кирибати',
	KP: 'Северная Корея',
	KR: 'Южная Корея',
	KW: 'Кувейт',
	KG: 'Киргизия',
	LA: 'Лаос',
	LV: 'Латвия',
	LB: 'Ливан',
	LS: 'Лесото',
	LR: 'Либерия',
	LY: 'Ливия',
	LI: 'Лихтенштейн',
	LT: 'Литва',
	LU: 'Люксембург',
	MO: 'Макао',
	MK: 'Македония',
	MG: 'Мадагаскар',
	MW: 'Малави',
	MY: 'Малайзия',
	MV: 'Мальдивы',
	ML: 'Мали',
	MT: 'Мальта',
	MH: 'Маршалловы острова',
	MQ: 'Мартиника',
	MR: 'Мавритания',
	MU: 'Маврикий',
	YT: 'Майотта',
	MX: 'Мексика',
	FM: 'Микронезия',
	MD: 'Молдова',
	MC: 'Монако',
	MN: 'Монголия',
	ME: 'Черногория',
	MS: 'Монтсеррат',
	MA: 'Марокко',
	MZ: 'Мозамбик',
	MM: 'Мьянма',
	NA: 'Намибия',
	NR: 'Науру',
	NP: 'Непал',
	NL: 'Нидерланды',
	NC: 'Новая Каледония',
	NZ: 'Новая Зеландия',
	NI: 'Никарагуа',
	NE: 'Нигер',
	NG: 'Нигерия',
	NU: 'Ниуэ',
	NF: 'Остров Норфолк',
	MP: 'Северные Марианские острова',
	NO: 'Норвегия',
	OM: 'Оман',
	PK: 'Пакистан',
	PW: 'Палау',
	PS: 'Государство Палестина',
	PA: 'Панама',
	PG: 'Папуа — Новая Гвинея',
	PY: 'Парагвай',
	PE: 'Перу',
	PH: 'Филиппины',
	PN: 'Питкэрн',
	PL: 'Польша',
	PT: 'Португалия',
	PR: 'Пуэрто-Рико',
	QA: 'Катар',
	RE: 'Реюньон',
	RO: 'Румыния',
	RU: 'Россия',
	RW: 'Руанда',
	BL: 'Сен-Бартелеми',
	SH: 'Остров Святой Елены, Вознесения и Тристан-да-Кунья',
	KN: 'Сент-Китс и Невис',
	LC: 'Сент-Люсия',
	MF: 'Сен-Мартен',
	PM: 'Сен-Пьер и Микелон',
	VC: 'Сент-Винсент и Гренадины',
	WS: 'Самоа',
	SM: 'Сан-Марино',
	ST: 'Сан-Томе и Принсипи',
	SA: 'Саудовская Аравия',
	SN: 'Сенегал',
	RS: 'Сербия',
	SC: 'Сейшельские Острова',
	SL: 'Сьерра-Леоне',
	SG: 'Сингапур',
	SX: 'Синт-Мартен',
	SK: 'Словакия',
	SI: 'Словения',
	SB: 'Соломоновы Острова',
	SO: 'Сомали',
	ZA: 'Южно-Африканская Республика',
	GS: 'Южная Георгия и Южные Сандвичевы острова',
	SS: 'Южный Судан',
	ES: 'Испания',
	LK: 'Шри-Ланка',
	SD: 'Судан',
	SR: 'Суринам',
	SJ: 'Шпицберген и Ян-Майен',
	SZ: 'Свазиленд',
	SE: 'Швеция',
	CH: 'Швейцария',
	SY: 'Сирия',
	TW: 'Тайвань',
	TJ: 'Таджикистан',
	TZ: 'Танзания',
	TH: 'Таиланд',
	TL: 'Восточный Тимор',
	TG: 'Того',
	TK: 'Токелау',
	TO: 'Тонга',
	TT: 'Тринидад и Тобаго',
	TN: 'Тунис',
	TR: 'Турция',
	TM: 'Туркменистан',
	TC: 'Теркс и Кайкос',
	TV: 'Тувалу',
	UG: 'Уганда',
	UA: 'Украина',
	AE: 'Объединенные Арабские Эмираты',
	GB: 'Великобритания',
	US: 'Соединенные Штаты Америки',
	UM: 'Внешние малые острова (США)',
	UY: 'Уругвай',
	UZ: 'Узбекистан',
	VU: 'Вануату',
	VE: 'Венесуэла',
	VN: 'Вьетнам',
	VG: 'Британские Виргинские острова',
	VI: 'Американские Виргинские острова',
	WF: 'Уоллис и Футуна',
	EH: 'Западная Сахара',
	YE: 'Йемен',
	ZM: 'Замбия',
	ZW: 'Зимбабве',
} as const;
