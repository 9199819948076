import React from 'react';
import { MonitoringGridBar } from './MonitoringGridBar';
import { useMonitoringCache } from '../useMonitoringCache';
import { MonitoringGrid } from '../Monitoring.typedef';

export const MonitoringGridBarWrapper = () => {
	const { monitoringCache, setCache } = useMonitoringCache({});

	const changeMonitoringGrid = (grid: MonitoringGrid) => {
		setCache({
			...monitoringCache,
			grid,
		});
	};

	return <MonitoringGridBar grid={monitoringCache.grid} onChange={changeMonitoringGrid} />;
};
