import React, { FC } from 'react';
import { MonitoringGrid } from '@components/camera/Monitoring/Monitoring.typedef';
import { IconName } from '@components/uikit/Icon/Icon';
import { IconButton } from '@components/uikit/Button/IconButton';

export type MonitoringGridBarProps = {
	grid?: MonitoringGrid;
	onChange?: (grid: MonitoringGrid) => void;
	disabled?: boolean;
};

const gridButtons: { grid: MonitoringGrid; icon: IconName }[] = [
	{ grid: 1, icon: 'Matrix1' },
	{ grid: 4, icon: 'Matrix4' },
	{ grid: 8, icon: 'Matrix7' },
	{ grid: 9, icon: 'Matrix9' },
	{ grid: 10, icon: 'Matrix10' },
	{ grid: 12, icon: 'Matrix12' },
];

export const MonitoringGridBar: FC<MonitoringGridBarProps> = ({ grid: activeGrid, onChange, disabled }) => {
	return (
		<div className={'flex gap-x-2'}>
			{gridButtons.map(({ grid, icon }, idx) => {
				const variant = grid === activeGrid ? 'primary' : 'grey';
				return (
					<IconButton
						disabled={disabled && idx > 0}
						variant={variant}
						icon={icon}
						key={grid}
						onClick={onChange?.bind(null, grid)}
					/>
				);
			})}
		</div>
	);
};
