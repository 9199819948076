import { useLocalStorageValue } from '@hooks/useLocalStorageValue';

export type UseLocalStorageStateArg = {
	key: string;
	defaultValue?: Nullable<string>;
};

/**
 * Хук для получения значения из localStorage с методами управления.
 * @param key - ключ, по которому хранится значение в localStorage.
 * @param defaultValue - значение по умолчанию, если в localStorage нет значения по ключу.
 */
export function useLocalStorageState({ key, defaultValue }: UseLocalStorageStateArg) {
	const storageValue = useLocalStorageValue({ key });

	const removeStorageValue = () => {
		localStorage.removeItem(key);
		const event = new StorageEvent('storage', {
			key: key,
			oldValue: storageValue,
			newValue: value,
		});
		window.dispatchEvent(event);
	};

	const changeStorageValue = (value: Nullable<string>) => {
		if (!value) {
			removeStorageValue();
			return;
		}

		localStorage.setItem(key, value);
		const event = new StorageEvent('storage', {
			key: key,
			oldValue: storageValue,
			newValue: value,
		});
		window.dispatchEvent(event);
	};

	const value = storageValue || defaultValue || null;

	return [value, changeStorageValue] as const;
}
