import { CameraIndicatorStatus } from '@components/camera/CameraIndicator/CameraIndicator';

type CameraStatusAdapterProps = {
	source?: string;
	disable?: boolean;
};
export const cameraStatusAdapter = (params?: CameraStatusAdapterProps): CameraIndicatorStatus => {
	if (params === undefined) return 'UNKNOWN';
	if (params.disable) return 'STOPPED';
	if (params.source === undefined) return 'ERROR';
	return 'ACTIVE';
};
