import React, { FC } from 'react';
import { Button } from '@components/uikit/Button/Button';
import { ReactComponent as Logo } from '@assets/illustrations/logo.svg';
import { ReactComponent as NotFound } from '@assets/illustrations/404.svg';
import { useNavigate } from 'react-router-dom';

export const NotFoundPage: FC = () => {
	const navigate = useNavigate();

	const onBackClick = () => {
		navigate(-1);
	};

	return (
		<section className={'h-[100vh] p-10'}>
			<a href={'/'}>
				<Logo width="196" height="64" />
			</a>

			<div className={'h-[calc(100%-64px)] flex flex-col items-center justify-center'}>
				<NotFound className={'w-[65%]'} />
				<h1 className={'mt-[100px] mb-3 font-display-medium'}>Что-то пошло не так....</h1>
				<p className={'mb-8 opacity-70 text-[20px]'}>Попробуйте перезагрузить страницу или вернуться в главную меню</p>
				<Button onClick={onBackClick} size="xl">
					Вернуться назад
				</Button>
			</div>
		</section>
	);
};
