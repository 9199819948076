import React, { FC } from 'react';
import { Popover } from '@components/uikit/Popover/Popover';
import { PopoverContentLayout } from '@components/layouts/PopoverContentLayout/PopoverContentLayout';
import { IconButton } from '@components/uikit/Button/IconButton';

export type SearchTaskActionProps = {
	onDelete?: () => void;
	onArchive?: () => void;
	onEdit?: () => void;
	disabled?: boolean;
};

export const SearchTaskAction: FC<SearchTaskActionProps> = ({ onDelete, onEdit, disabled = false }) => {
	return (
		<Popover
			positions={['bottom', 'left']}
			align={'end'}
			content={
				<PopoverContentLayout className={'!p-[12px]'}>
					<div className={'flex flex-col gap-y-[12px] w-[299px] items-start'}>
						{onDelete && (
							<button className={'font-title-medium text-on-secondary-container w-full text-left'} onClick={onDelete}>
								Удалить
							</button>
						)}
						{onEdit && (
							<button className={'font-title-medium text-on-secondary-container w-full text-left'} onClick={onEdit}>
								Редактировать
							</button>
						)}
					</div>
				</PopoverContentLayout>
			}
		>
			{({ toggleIsOpen }) => {
				return (
					<IconButton
						disabled={disabled}
						icon={'threeDotsVertical'}
						variant={'grey'}
						outline={true}
						size={'base'}
						onClick={toggleIsOpen}
					/>
				);
			}}
		</Popover>
	);
};
