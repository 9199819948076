import { useGetFaceImageQuery } from '@store/api';

type EventPreviewWrapperProps<T extends { id: number }> = {
	childrenProps: T;
	children: (value: T, previewUrl: string) => JSX.Element;
};

function EventPreviewWrapper<T extends { id: number }>({ children, childrenProps }: EventPreviewWrapperProps<T>) {
	const { currentData } = useGetFaceImageQuery(childrenProps.id);
	return children(childrenProps, currentData ?? '');
}

export default EventPreviewWrapper;
