import { FC, useId, useState } from 'react';
import { IconTextRow } from '@components/uikit/IconTextRow/IconTextRow';
import { FramePlaceholder } from '@components/uikit/FramePlaceholder/FramePlaceholder';
import { EventIndicator } from '../EventIndicator/EventIndicator';
import { IconButton } from '@components/uikit/Button/IconButton';
import { numWord } from '@utils/numWord';
import { Tooltip } from 'react-tooltip';
import { dateParser } from '@utils/dateParser';
import { EventCopyLinkModal } from '../EventCopyLinkModal/EventCopyLinkModal';
import { Match } from '@hooks/useEventsPage';

type EventPreviewProps = {
	event: Match;
	previewUrl?: Nullable<string>;
	active?: boolean;
	onClick?: (event: Match) => void;
	onPreviewMouseEnter?: () => void;
	onPreviewMouseLeave?: () => void;
	onMarkAsWatched?: () => void;
	onMarkAsFalsy?: () => void;
	onEventRedirect?: () => void;
};

export const EventPreview: FC<EventPreviewProps> = ({
	previewUrl,
	active,
	event,
	onClick,
	onPreviewMouseEnter,
	onPreviewMouseLeave,
}) => {
	const className = [
		'bg-secondary-container rounded-[8px] flex gap-x-4',
		active && 'p-[14px] border-2 border-tertiary-80 box-shadow-elevation-7',
		!active && 'p-[16px]',
	]
		.filter(Boolean)
		.join(' ');

	const { timestamp, timestamp_local, id, identity, camera_name, age, gender, mask, race, glasses, facial_hair } =
		event;

	const { gmtLocal, dateStr, timeStr, timeStrLocal } = dateParser(timestamp, timestamp_local);

	const onMouseEnterHandler = () => {
		onPreviewMouseEnter && onPreviewMouseEnter();
	};
	const cameraNameId = useId();
	const timeId = useId();
	const [isOpen, setIsOpen] = useState(false);

	return (
		<>
			<div
				className={className}
				role={'button'}
				tabIndex={0}
				onClick={() => {
					onClick && onClick(event);
				}}
				onFocus={onMouseEnterHandler}
				onMouseEnter={onMouseEnterHandler}
				onMouseLeave={onPreviewMouseLeave}
				onBlur={onPreviewMouseLeave}
			>
				<div className={'flex gap-x-3 grow'}>
					<section className={'flex-shrink-0'}>
						{previewUrl ? (
							<img className={'w-[128px] h-[100px] object-fill rounded-[4px]'} src={previewUrl} alt={'Кадр события'} />
						) : (
							<FramePlaceholder roundness={4} className="!w-[128px] !h-[100px]" />
						)}
						<span className="opacity-50 font-body-small">ID: {id}</span>
					</section>

					<section className={'flex flex-col justify-between flex-grow'}>
						<div className={'flex gap-x-[8px] items-center'}>
							{/* <EventBadge type={type} className={'!rounded-2 !py-0'} /> */}
							<IconTextRow className={'text-on-secondary-container opacity-50 !gap-x-[4px]'} icon={'calendarAll'}>
								{dateStr}
							</IconTextRow>
							<IconTextRow className={'text-on-secondary-container opacity-50 !gap-x-[4px]'} icon={'clock'}>
								{timeStrLocal}
							</IconTextRow>
							<div
								id={timeId}
								className="text-on-secondary-container hover:text-primary hover:opacity-100 opacity-50 transition-all"
							>
								<span>(GMT{gmtLocal >= 0 ? `+${gmtLocal}` : gmtLocal})</span>

								<Tooltip
									className={'z-50 !rounded-2'}
									anchorSelect={`#${CSS.escape(timeId)}`}
									place={'top'}
									content={`По московскому времени: ${timeStr}`}
								/>
							</div>
							<div id={cameraNameId}>
								<IconTextRow className={'opacity-50 '} classNameText={'w-[120px] truncate'} icon={'cameraVideo'}>
									{camera_name}
								</IconTextRow>
								<Tooltip
									className={'z-50 !rounded-2'}
									anchorSelect={`#${CSS.escape(cameraNameId)}`}
									place={'top'}
									content={camera_name}
								/>
							</div>
						</div>
						<div className={'flex items-center gap-x-2'}>
							<EventIndicator severity={'REGULAR'} />
							<h2 className={'flex items-center font-title-large text-on-background h-[28px] mt-[2px] mb-[2px]'}>
								{identity}
							</h2>
						</div>
						<div className={'flex flex-wrap gap-y-1 gap-x-2 max-w-[550px] font-body-medium'}>
							<p className={'w-[calc(50%-4px)] min-w-[200px]'}>
								<span className="font-medium">Возраст:</span>{' '}
								{age ? `${age} ${numWord(age, ['год', 'года', 'лет'])}` : 'Не определено'}
							</p>
							<p className={'w-[calc(50%-4px)] min-w-[200px]'}>
								<span className="font-medium">Пол:</span> {gender ?? 'Не определено'}
							</p>

							<p className={'w-[calc(50%-4px)] min-w-[200px]'}>
								<span className="font-medium">Раса:</span> {race ?? 'Не определено'}
							</p>
							<p className={'w-[calc(50%-4px)] min-w-[200px]'}>
								<span className="font-medium">Маска:</span> {mask ?? 'Не определено'}
							</p>

							<p className={'w-[calc(50%-4px)] min-w-[200px]'}>
								<span className="font-medium">Наличие очков:</span> {glasses ?? 'Не определено'}
							</p>
							<p className={'w-[calc(50%-4px)] min-w-[200px]'}>
								<span className="font-medium">Растительность:</span> {facial_hair ?? 'Не определено'}
							</p>
						</div>
					</section>

					<section className="flex-grow flex items-end justify-end">
						<IconButton
							className={'flex-shrink-0'}
							icon={'share'}
							size={'xs'}
							onClick={(e) => {
								e.stopPropagation();
								setIsOpen(true);
							}}
						/>
					</section>
				</div>
			</div>
			<EventCopyLinkModal
				eventId={id}
				title={identity}
				isOpen={isOpen}
				onClose={() => {
					setIsOpen(false);
				}}
			/>
		</>
	);
};
