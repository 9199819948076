import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from '@app/providers/ToastProvider/ToastProvider';
import { UserActivationProvider } from '@app/providers/UserActivationProvider/UserActivationProvider';
import { HistoryProvider } from '@app/providers/HistoryProvider/HistoryProvider';
import { UserAuthenticationProvider } from '@app/providers/UserAuthenticationProvider/UserAuthenticationProvider';
import { Provider } from 'react-redux';
import { store } from '@store/store';

export function withAppProviders<C extends FC, P extends React.ComponentProps<C>>(App: C): FC<P> {
	return (props) => {
		return (
			<Provider store={store}>
				<BrowserRouter>
					<UserActivationProvider>
						<UserAuthenticationProvider />
						<ToastProvider />
						<HistoryProvider />
						{App(props)}
					</UserActivationProvider>
				</BrowserRouter>
			</Provider>
		);
	};
}
