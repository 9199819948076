import React, { FC } from 'react';
import { Toggle } from '@components/uikit/Toggle/Toggle';
import { Icon } from '@components/uikit/Icon/Icon';
import { Badge } from '@components/uikit/Badge/Badge';

export type PhotoProps = {
	imageUrl: string;
	selected?: boolean;
	confidence?: number;
	onSelect?: () => void;
	onCloseClick?: () => void;
	selectable?: boolean;
};

export const Photo: FC<PhotoProps> = ({ imageUrl, selected, confidence, onSelect, onCloseClick, selectable }) => {
	const className = [
		'relative h-[125px] aspect-[1.38/1] rounded-[8px] overflow-hidden',
		!selected && 'border-2 border-outline',
		selected && 'border-2 border-primary',
	]
		.filter(Boolean)
		.join(' ');

	return (
		<div className={className}>
			<img src={imageUrl} className={'w-full h-full object-cover'} alt={'Изображение лица'} />
			<div className={'absolute left-0 top-0 w-full h-full p-[8px] flex justify-between'}>
				<Toggle
					disabled={!selectable}
					checked={selected}
					onChange={onSelect}
					type={'checkbox'}
					onlyControl={true}
					style={{ visibility: selectable ? 'visible' : 'hidden' }}
				/>
				<div className={'flex flex-col justify-between items-end'}>
					{onCloseClick && (
						<button onClick={onCloseClick}>
							<Icon className={'text-secondary'} name={'x'} width={16} height={16} />
						</button>
					)}
					{confidence && (
						<Badge color={'primary'} variant={'filled'} size={'sm'}>
							{confidence}%
						</Badge>
					)}
				</div>
			</div>
		</div>
	);
};
