import React, { FC, PropsWithChildren } from 'react';
import ReactSelect, { GroupBase } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import classes from './Select.module.css';
import { Icon } from '../Icon/Icon';
import { SelectProps } from '@components/uikit/Select/Select.typedef';
import { DropdownIndicator, Option, ValueContainer } from './Select.custom';
import { SelectComponents } from 'react-select/dist/declarations/src/components';

export const Select = <OptionType = unknown, IsMulti extends boolean = false>({
	label,
	size = 'default',
	onCreateOption,
	formatCreateLabel,
	className: propClassName,
	maxMenuHeight,
	valueContainerIcon,
	overrideClassName,
	error,
	valueContainerIconOnlyEmpty,
	...propsProxy
}: SelectProps<OptionType, IsMulti>) => {
	const selectClassList = [classes['select'], classes[size]];
	error && selectClassList.push(classes['select--error']);
	const selectClassName = selectClassList.join(' ');

	let classList = [propClassName, selectClassName].filter(Boolean);
	if (overrideClassName) {
		classList = [overrideClassName];
	}

	const className = classList.join(' ');

	const props: SelectProps<OptionType, IsMulti> = {
		className,
		isSearchable: typeof onCreateOption === 'function',
		unstyled: true,
		noOptionsMessage: () => {
			return <p className={'font-label-large text-on-primary-container text-left pl-[16px]'}>Нет в системе</p>;
		},
		maxMenuHeight: maxMenuHeight || 176,
		...propsProxy,
		components: {
			DropdownIndicator,
			Option,
			ClearIndicator: () => {
				return null;
			},
			ValueContainer: ValueContainer(valueContainerIcon, 16, valueContainerIconOnlyEmpty),
			...propsProxy.components,
		} as unknown as SelectComponents<OptionType, IsMulti, GroupBase<OptionType>>,
	};

	const Wrapper: FC<PropsWithChildren> = ({ children }) => {
		if (label) {
			return <div className="flex flex-col gap-2">{children}</div>;
		}
		return children as JSX.Element;
	};

	return (
		<Wrapper>
			{label && <span className="font-label-large">{label}</span>}
			{onCreateOption && (
				<CreatableSelect
					onCreateOption={onCreateOption}
					formatCreateLabel={(inputValue) => {
						if (typeof formatCreateLabel === 'function') {
							return formatCreateLabel(inputValue);
						}

						return (
							<button className="flex gap-1 items-center">
								<Icon width={12} height={12} name="plus" className={classes['add-icon']} />
								<span className="font-label-large text-primary">Добавить новый</span>
							</button>
						);
					}}
					{...props}
				/>
			)}
			{!onCreateOption && <ReactSelect {...props} />}
		</Wrapper>
	);
};
