import React, { FC, useRef, useState } from 'react';
import { Spinner } from '@components/uikit/Spinner/Spinner';
import { useGetPlateImageOverviewQuery, useGetVehicleImageQuery } from '@store/api';
import { format } from 'date-fns';
import { Icon } from '@components/uikit/Icon/Icon';
import { Modal } from '@components/uikit/Modal/Modal';
import { LicensePlateView } from '@components/uikit/LicensePlateView/LicensePlateView';
import { Border } from '@components/uikit/Border/Border';

type SearchCarCardProp = {
	eventId: number;
	title: string;
	description: string;
	camera?: string;
	occlusion: number;
	country: string;
	box: {
		x: number;
		y: number;
		w: number;
		h: number;
	};
	vehicle_box?: {
		x: number;
		y: number;
		w: number;
		h: number;
	};
};

export const SearchCarCard: FC<SearchCarCardProp> = ({ eventId, box, vehicle_box, title, description, country }) => {
	const { currentData: imageUrl, isLoading } = useGetVehicleImageQuery(eventId);
	const { currentData: imageModal, isLoading: ImageModalLoading } = useGetPlateImageOverviewQuery(eventId);

	const [isOpenOverviewImageModal, setIsOpenOverviewImageModal] = useState<boolean>(false);
	const handleButtonClick = () => {
		setIsOpenOverviewImageModal(true);
	};

	const handleCloseModal = () => {
		setIsOpenOverviewImageModal(false);
	};
	const imgRef = useRef<HTMLImageElement>(null);
	const imgModalRef = useRef<HTMLImageElement>(null);

	return (
		<>
			<div
				className={
					'flex flex-col p-4 gap-4 border-[1px] rounded-[12px] border-solid border-outline-variant bg-surface-bright min-w-[360px]'
				}
			>
				<div className={'w-full  relative '}>
					<div
						className={
							'relative aspect-[1.5/1] max-h-[400px] flex items-center justify-center overflow-hidden w-full h-full bg-primary-0'
						}
					>
						{imageUrl === undefined || isLoading ? (
							<Spinner />
						) : (
							<>
								<img
									className={'rounded-[2px] w-full absolute inset-0'}
									src={imageUrl}
									alt="изображение"
									ref={imgRef}
								/>
								{imgModalRef && (
									<Border
										box={{
											width: box.w,
											height: box.h,
											x: box.x - (vehicle_box?.x ?? 0),
											y: box.y - (vehicle_box?.y ?? 0),
										}}
										ref={imgRef}
									/>
								)}

								<button
									onClick={handleButtonClick}
									className={'absolute bottom-4 right-4 rounded-2 border border-on-primary p-1'}
								>
									<Icon className={'text-on-primary'} name={'fullscreen'} width={24} height={24} />
								</button>
							</>
						)}
					</div>
				</div>

				<div className={'flex flex-col gap-1'}>
					<div className={'flex justify-between items-center self-stretch'}>
						<LicensePlateView value={title} country={country} />
						<div className={'flex flex-col text-right'}>
							<span className={'font-body-medium text-on-background opacity-50'}>{`id:${eventId}`}</span>
							<span className={'text-secondary-40 font-body-small'}>
								{format(new Date(description), 'dd/MM/yyyy HH:mm:ss')}
							</span>
						</div>
					</div>
				</div>

				{isOpenOverviewImageModal && (
					<Modal isLoading={ImageModalLoading} isOpen={isOpenOverviewImageModal} onClose={handleCloseModal}>
						<div className={'relative aspect-[1.78/1] max-h-[50vh]'}>
							<img ref={imgModalRef} src={imageModal} alt="overview" className={'h-full'} />
							{imgModalRef && (
								<Border
									box={{
										width: box.w,
										height: box.h,
										...box,
									}}
									ref={imgModalRef}
								/>
							)}
						</div>
					</Modal>
				)}
			</div>
		</>
	);
};
