import React, { FC, PropsWithChildren } from 'react';

export type PageButtonProps = PropsWithChildren<{
	active?: boolean;
	onClick?: () => void;
}>;

export const PageButton: FC<PageButtonProps> = ({ active, onClick, children }) => {
	const containerClassName = [
		'w-[40px]  h-[40px] flex justify-center items-center rounded-[4px] flex-shrink-0',
		active ? 'bg-primary-container' : 'bg-transparent',
	].join(' ');

	const textClassName = ['font-body-medium', active ? 'text-primary' : 'text-on-primary-container'].join(' ');

	return (
		<button className={containerClassName} onClick={onClick}>
			<span className={textClassName}>{children}</span>
		</button>
	);
};
