import React, { FC, useState } from 'react';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { FileUpload } from '@components/uikit/FileUpload/FileUpload';
import { FileUploadFile } from '@components/uikit/FileUpload/FileUpload.typedef';
import RecognitionItemSelectWrapper from '@components/recognition/RecognitionItemSelect/wrapper/RecognitionItemSelectWrapper';
import { filterInvalidFiles } from '@utils/fileValidator';
import { useRecognizedFaces } from '@hooks/useRecognizedFaces';
import { getTextWithDeclination } from '@utils/getTextWithDeclination';
import { NotFound } from '@components/uikit/NotFound/NotFound';
import { FACES_BUCKET_NAME } from '@constants/facesBucketName';
import { usePrefixBucket } from '@hooks/usePrefixBucket';
import { StepsHeader } from '@components/uikit/StepsHeader/StepsHeader';

type FacesStageProps = {
	bucketName: string;
};
export const FacesStage: FC<FacesStageProps> = ({ bucketName }) => {
	const [files, setFiles] = useState<FileUploadFile[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const { recognizedFaces, makeNewRecognizedFaces, recognizedFacesFilter } = useRecognizedFaces();
	const [selectedFaceFromBucket, setSelectedFaceFromBucket] = useState<string>();

	const prefixBucket = usePrefixBucket();

	const handleFaceFromBucketAdd = (title: string | undefined) => {
		setSelectedFaceFromBucket(title);
	};

	const updateFiles = async (files: FileUploadFile[]) => {
		setIsLoading(true);
		await makeNewRecognizedFaces(
			files.map(({ file }) => {
				return file;
			}),
		);
		setFiles(files);
		setIsLoading(false);
	};
	const onDrop = async (fileList: FileList) => {
		const validFiles = filterInvalidFiles(fileList);
		if (!validFiles) {
			return;
		}
		const files = validFiles.map((file) => {
			return { file: file, id: 'currentFile', name: file.name, url: URL.createObjectURL(file) };
		});
		await updateFiles(files);
	};

	const facesAmount = recognizedFaces.reduce((acc, elem) => {
		return acc + elem.faces.length;
	}, 0);

	return (
		<DashboardLayout>
			<DashboardContentLayout
				overrideHeaderRenderer={<StepsHeader title={'Добавление лица в базу данных'} badges={[{ text: 'Создание' }]} />}
				showBackButton={false}
				drawDivider={false}
			>
				<div className={'mt-[8px] flex flex-col gap-y-[24px] min-h-full'}>
					<div className={'flex gap-x-[32px] flex-grow h-[calc(100vh-24px*2-261px-32px-24px-52px-8px)] min-h-[420px] '}>
						<section className={'flex flex-col gap-y-[16px] w-[750px] overflow-y-auto'}>
							<h2 className={'font-headline-xsmall text-on-background'}>Поиск лиц из медиа-файлов</h2>
							<FileUpload
								loading={isLoading}
								onRemove={async (file) => {
									await updateFiles(
										files.filter((f) => {
											return f.url !== file.url;
										}),
									);
								}}
								inputProps={{
									multiple: true,
									accept: 'image/jpeg, image/png, image/bmp, video/mp4',
									onChange(e) {
										const files = e.target.files;
										if (files) {
											onDrop(files);
										}
									},
								}}
								description={'JPG, PNG, BMP, MP4'}
								files={files}
								onDrop={onDrop}
								dragClassName={'!h-[200px]'}
							/>
						</section>

						{Boolean(files.length) && (
							<>
								<div className={'h-[unset] w-[1px] bg-outline-variant'}>&nbsp;</div>
								<div className={'flex flex-col gap-y-4 w-[50%] max-h-full'}>
									<h2 className={'font-headline-xsmall text-on-background'}>Выберите наиболее подходящие кадры</h2>
									<h3>
										Найдено:{' '}
										<span className={'text-primary font-medium'}>
											{getTextWithDeclination(facesAmount, ['лицо', 'лица', 'лиц'])}
										</span>
									</h3>

									{facesAmount ? (
										<section className={'flex flex-col gap-y-4 h-full overflow-y-auto pr-[24px] w-full'}>
											{recognizedFaces.flatMap((item) => {
												return item.faces.map((face) => {
													const { x, y, w, h } = face.bbox;
													const uniqFaceId = `${x}-${y}-${w}-${h}`;
													return (
														<RecognitionItemSelectWrapper
															prefixBucket={prefixBucket}
															key={uniqFaceId}
															id={uniqFaceId}
															recognizedFacesFilter={recognizedFacesFilter}
															bucketName={bucketName}
															imageBlob={face.imageBlob}
															selectedFace={selectedFaceFromBucket}
															setSelectedFace={handleFaceFromBucketAdd}
															type={'recognition'}
															matches={face.matches?.filter((match) => {
																return match.bucketName === prefixBucket + FACES_BUCKET_NAME;
															})}
														/>
													);
												});
											})}
										</section>
									) : (
										<NotFound />
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</DashboardContentLayout>
		</DashboardLayout>
	);
};
