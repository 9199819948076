import React, { FC } from 'react';
import { TargetSelect } from '@components/uikit/Select/TargetSelect/TargetSelect';
import { Icon } from '@components/uikit/Icon/Icon';
// import { useEventsSearchParams } from '@hooks/event/useEventsSearchParams';
import CameraZoneSelector from '@components/camera/Monitoring/MonitoringSlot/CameraZoneSelector/CameraZoneSelector';
import { CameraZone } from './CameraZoneSelector/CameraZoneSelector.typedef';
import { Camera } from '../useMonitoring';

export type MonitoringCameraPlaceholderProps = {
	onTargetChange: React.ComponentProps<typeof TargetSelect>['onChange'];
	onCameraChange: (id: string) => void;
	camerasList: Array<Camera>;
};

export const MonitoringSlotPlaceholder: FC<MonitoringCameraPlaceholderProps> = ({
	camerasList,
	onTargetChange,
	onCameraChange,
}) => {
	const isTargetSelected = true;
	const zones = (camerasList ?? []).reduce<CameraZone[]>((accumulator, item) => {
		accumulator.push({
			id: item.id,
			title: item.title,
			isOff: item.isOff,
		});
		return accumulator;
	}, []);

	return (
		<div
			className={'flex justify-center items-center px-4 border border-primary rounded-[8px] relative w-[100%] h-[100%]'}
		>
			{isTargetSelected && (
				<div className={'absolute top-4 left-4 z-10'}>
					<CameraZoneSelector
						zones={zones}
						onZoneClick={(id) => {
							onCameraChange && onCameraChange(id);
						}}
					/>
				</div>
			)}
			<div className={'flex flex-col items-center gap-4'}>
				<div className={'bg-primary-container p-3 rounded-[120px]'}>
					<Icon name={'building'} />
				</div>
				{isTargetSelected && (
					<>
						<div className={'text-center'}>Выберите камеру</div>
					</>
				)}
			</div>
		</div>
	);
};
