import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { StoreRootState } from '@store/store';
import { isBefore } from 'date-fns';

export type Match = {
	type: 'face';
	id: number;
	identity: string;
	tags: string[];
	camera_uuid: string;
	camera_name: string;
	similarity: number;
	timestamp: string;
	timestamp_local: string;
	quality?: number;
	age?: number;
	gender?: string;
	glasses?: string;
	facial_hair?: string;
	mask?: string;
	race?: string;
	box?: {
		width: number;
		height: number;
		x: number;
		y: number;
	};
};

export type MatchPlate = {
	type: 'plate';
	id: number;
	identity: string;
	tags: string[];
	camera_uuid: string;
	camera_name: string;
	similarity: number;
	timestamp: string;
	timestamp_local: string;
	occlusion?: number;
	valid?: boolean;
	quality?: number;
	box?: {
		width: number;
		height: number;
		x: number;
		y: number;
	};
	plate?: string;
	country?: string;
	countryCode?: string;
	category?: string;
	vehicle?: {
		category: string;
		purpose: string;
		color: string;
		visibility?: number;
	};
};
export type StoreEvent = Match | MatchPlate;

const eventAdapter = createEntityAdapter<StoreEvent>({
	selectId: (event) => {
		return event.id;
	},
	//чтобы новые события появлялись наверху
	sortComparer: (a, b) => {
		return isBefore(new Date(a.timestamp), new Date(b.timestamp)) ? 1 : -1;
	},
});

export const eventsListSlice = createSlice({
	name: 'eventsList',
	initialState: eventAdapter.getInitialState(),
	reducers: {
		addEvent: eventAdapter.addOne,
		addEvents: eventAdapter.addMany,
		clearEvents: eventAdapter.removeAll,
	},
});

export const eventsListSelectors = eventAdapter.getSelectors<StoreRootState>((state) => {
	return state.eventsList;
});
