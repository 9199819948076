import React, { DragEvent, FC, MouseEvent, useEffect, useRef } from 'react';
import { Icon } from '@components/uikit/Icon/Icon';
import { FileUploadFile } from '@components/uikit/FileUpload/FileUpload.typedef';
import { Badge } from '@components/uikit/Badge/Badge';
import { Spinner } from '@components/uikit/Spinner/Spinner';

export type FileUploadProps = {
	description?: string;
	files?: FileUploadFile[];
	onRemove?: (file: FileUploadFile) => void;
	inputProps?: Omit<JSX.IntrinsicElements['input'], 'type' | 'ref'>;
	onDrop?: (fileList: FileList) => void;
	loading?: boolean;
	dragClassName?: string;
};

export const FileUpload: FC<FileUploadProps> = ({
	description,
	files,
	onDrop,
	inputProps,
	onRemove,
	loading,
	dragClassName,
}) => {
	// UI

	const inputRef = useRef<HTMLInputElement>(null);

	const className = [
		'bg-surface-container-low border-[2px] border-dashed border-outline',
		'flex justify-center items-center rounded-[8px] w-full h-full p-[12px]',
		dragClassName,
	]
		.filter(Boolean)
		.join(' ');

	// LISTENER

	useEffect(() => {
		const preventNewTabOpenOnDropListener = (e: globalThis.DragEvent) => {
			e.preventDefault();
		};

		window.addEventListener('dragover', preventNewTabOpenOnDropListener);
		return () => {
			window.removeEventListener('dragover', preventNewTabOpenOnDropListener);
		};
	}, []);

	// HANDLERS

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		onDrop?.(e.dataTransfer.files);
	};

	const handleClick = (e: MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		inputRef.current?.click();
	};

	return (
		<div className={'flex flex-col gap-y-[16px] relative'}>
			{loading && <Spinner />}
			<div className={className} role={'button'} onDrop={handleDrop} onClick={handleClick}>
				<div className={'flex flex-col items-center gap-y-[8px] pointer-events-none'}>
					<Icon className={'text-primary'} name={'cloudDownload'} width={40} height={40} />
					<h2 className={'font-title-medium text-on-surface'}>Нажмите или перетащите файлы</h2>
					<p className={'font-body-small text-on-surface'}>Минимальная высота изображения 32px.</p>
					{description && <p className={'font-body-small text-on-surface'}>{description}</p>}
				</div>
				<input value="" ref={inputRef} type={'file'} hidden={true} {...inputProps} />
			</div>
			{!!files?.length && (
				<div className={'flex gap-[8px] flex-wrap'}>
					{files?.map((uplFile, index) => {
						return (
							<Badge color={'primary'} variant={'contained'} size={'lg'} key={index}>
								<Icon name={'filePlay'} />
								{uplFile.name}
								<button onClick={onRemove?.bind(null, uplFile)}>
									<Icon name={'x'} width={16} height={16} />
								</button>
							</Badge>
						);
					})}
				</div>
			)}
		</div>
	);
};
