import React, { FC } from 'react';
import { ToggleDescription } from '@components/uikit/Toggle/ToggleDescription/ToggleDescription';
import { ToggleCheckbox } from '@components/uikit/Toggle/ToggleCheckbox/ToggleCheckbox';
import { ToggleToggle } from '@components/uikit/Toggle/ToggleToggle/ToggleToggle';
import classes from './Toggle.module.css';
import { ToggleRadio } from '@components/uikit/Toggle/ToggleRadio/ToggleRadio';

const componentMap: Record<ToggleProps['type'], FC> = {
	toggle: ToggleToggle,
	checkbox: ToggleCheckbox,
	radio: ToggleRadio,
};

export type ToggleProps = Omit<JSX.IntrinsicElements['input'], 'type'> & {
	type: 'toggle' | 'checkbox' | 'radio';
	dark?: boolean;
	label?: string;
	description?: string;
	onlyControl?: boolean;
};

export const Toggle: FC<ToggleProps> = ({
	type: propType,
	label,
	description,
	onlyControl,
	dark,
	className: propClassName,
	...propsProxy
}) => {
	const Component = componentMap[propType];

	const classList = [classes['root'], propClassName].filter(Boolean);
	propsProxy.disabled && classList.push(classes['disabled']);
	dark && classList.push(classes['dark']);
	const className = classList.join(' ');

	const controlContainerClassName = ['flex justify-center', !onlyControl && 'w-min-content'].filter(Boolean).join(' ');

	return (
		// Этот самый control внутри динамического компонента (Component)
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label className={className}>
			<div className={controlContainerClassName}>
				<Component {...propsProxy} />
			</div>
			{!onlyControl && <ToggleDescription title={label} description={description} />}
		</label>
	);
};
