import React, { FC, useEffect } from 'react';
import classes from './SearchDatabaseTable.module.css';
import { TablePagination } from '@components/uikit/TablePagination/TablePagination';
import { useDeleteBucketMutation, useGetBucketsQuery } from '@store/api';

import { SearchDatabaseTableItem } from './SearchDatabaseTableItem/SearchDatabaseTableItem';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTER } from '@constants/router';
import { Spinner } from '@components/uikit/Spinner/Spinner';
import { useSearchParamsPaginator } from '@hooks/useSearchParamsPaginator';
import { useSearchDatabaseParams } from '@hooks/useSearchDatabaseParams';
import { usePrefixBucket } from '@hooks/usePrefixBucket';
import { FACES_BUCKET_NAME } from '@constants/facesBucketName';
import { PLATES_BUCKET_NAME } from '@constants/platesBucketName';

const TASK_TYPE: Record<string, 'UNKNOWN' | 'FACE' | 'PLATE'> = {
	unknown: 'UNKNOWN',
	face: 'FACE',
	plate: 'PLATE',
};

export type SearchTasksDirty = {
	id: string;
	title: string;
	task_type: 'UNKNOWN' | 'FACE' | 'PLATE';
	objects: number;
	detection_type?: string;
	date?: string;
	dt_created?: string;
	duration: number;
	notification?: string;
	archive?: string;
};

type TitleTaskMapping = {
	[key: string]: {
		title: string;
		taskType: 'FACE' | 'PLATE';
	};
};

type SearchDatabaseTableProps = {
	sort?: string;
};

export const SearchDatabaseTable: FC<SearchDatabaseTableProps> = ({ sort, ...propsProxy }) => {
	const navigate = useNavigate();
	const { filterParams } = useSearchDatabaseParams();

	const prefix = usePrefixBucket();

	// QUERY
	const { searchParamsPage, onSearchParamsPageChange, searchParamsPageSize, onSearchParamsPageSizeChange } =
		useSearchParamsPaginator();
	const [searchParams] = useSearchParams();
	const search = searchParams.get('search');
	const { currentData, isLoading } = useGetBucketsQuery({
		limit: searchParamsPageSize,
		offset: (searchParamsPage - 1) * searchParamsPageSize,
		with_num_records: true,
		sort: sort,
		sort_key: 'timestamp_created',
		type: filterParams?.type.length ? filterParams.type : ['face', 'plate'],
		bucket_caption: search ?? undefined,
	});

	const [deleteBucket] = useDeleteBucketMutation();

	const handleDeleteBucket = (id: string) => {
		deleteBucket({ b_name: id });
	};
	const handleEditBucket = (id: string, type: string) => {
		if (type === 'FACE') {
			navigate(`${ROUTER.SEARCH_DATABASE_CREATE_FACES.PATH}?bucket_id=${id}`);
		} else {
			navigate(`${ROUTER.SEARCH_DATABASE_CREATE_LICENSE_PLATES.PATH}?bucket_id=${id}`);
		}
	};

	const taskType = (arr: string[] | undefined) => {
		if (arr && arr.includes('detectionType:plate')) {
			return TASK_TYPE.plate;
		}
		if (arr && arr.includes('detectionType:face')) {
			return TASK_TYPE.face;
		}

		return TASK_TYPE.unknown;
	};

	const titleTaskMapping: TitleTaskMapping = {
		[`${prefix}${FACES_BUCKET_NAME}`]: { title: 'База лиц', taskType: 'FACE' },
		[`${prefix}${PLATES_BUCKET_NAME}`]: { title: 'База номеров', taskType: 'PLATE' },
	};

	const searchTasksDirty: SearchTasksDirty[] = (currentData?.data ?? []).map((bucket) => {
		const { bucket_name, bucket_caption, num_records, tags, timestamp_created } = bucket;
		const title = titleTaskMapping[bucket_caption]?.title || bucket_caption;
		const task = titleTaskMapping[bucket_name]?.taskType || taskType(tags);
		const detectionType = tags?.includes('stream') ? 'stream' : 'archive';

		const duration = Number(
			tags
				?.find((item) => {
					return item.includes('duration');
				})
				?.replace('duration:', '') ?? 0,
		);

		return {
			id: bucket_name,
			title,
			dt_created: timestamp_created,
			task_type: task,
			objects: num_records ?? 0,
			detection_type: !titleTaskMapping[bucket_name] ? detectionType : undefined,
			date: tags?.find((item) => {
				return item.includes('archive');
			}),
			duration: duration,
		};
	});

	useEffect(() => {
		onSearchParamsPageChange(1);
		//eslint-disable-next-line
	}, [search]);

	return (
		<>
			{isLoading && <Spinner />}
			{!isLoading && (
				<div>
					<table className={classes['table']} {...propsProxy}>
						<tbody>
							<tr>
								<th>Название определения</th>
								<th>Тип определения</th>
								<th>Статус определения</th>
								<th>Объектов определяется</th>
								<th>Получение данных</th>
								<th>&nbsp;</th>
							</tr>
							{searchTasksDirty?.map((searchTask) => {
								return (
									<SearchDatabaseTableItem
										disabled={searchTask.id in titleTaskMapping}
										key={searchTask.id}
										id={searchTask.id}
										title={searchTask.title}
										task_type={searchTask.task_type}
										date={searchTask.date}
										dt_created={searchTask.dt_created}
										duration={searchTask.duration}
										detection_type={searchTask.detection_type}
										objects={searchTask.objects}
										onDelete={handleDeleteBucket}
										onEdit={handleEditBucket}
									/>
								);
							})}
						</tbody>
					</table>
					<TablePagination
						totalItems={currentData?.total ?? 0}
						pageSize={searchParamsPageSize}
						currentPage={searchParamsPage}
						onPageChange={onSearchParamsPageChange}
						onPageSizeChange={onSearchParamsPageSizeChange}
					/>
				</div>
			)}
		</>
	);
};
