import { Button } from '@components/uikit/Button/Button';
import { List } from '@components/List/List';
import { Link } from 'react-router-dom';
import { ROUTER } from '@constants/router';
import { Icon } from '@components/uikit/Icon/Icon';
import { ReactComponent as NoCameras } from '@assets/illustrations/no-cameras.svg';
import { useGetCamerasQuery } from '@store/ApiPeopleCounter/apiPeopleCounter';
import { Camera } from '@store/ApiPeopleCounter/apiPeopleCounterTypes';
import CameraPreviewWrapper from '../CameraPreview/CameraPreviewLine/CameraPreviewWrapper';
import { urlToType } from '@utils/urlToType';

export const CameraLinesList = () => {
	const { currentData, isLoading } = useGetCamerasQuery();
	const cameras: Camera[] = currentData ?? [];

	return (
		<>
			<div className="flex flex-col"></div>
			<ul className="flex gap-6 flex-col mt-6 mb-6 relative">
				<List
					isLoading={isLoading}
					emptyState={
						<div className={'w-full h-[calc(100%-60px)] flex gap-4 flex-col items-center justify-center'}>
							<NoCameras className={'w-[65%] mb-4'} />
							<h1 className={'font-display-large'}>Нет доступных камер</h1>
							<p className={'font-body-large opacity-80'}>
								Добавьте новую камеру или обновите список, чтобы увидеть их в списке
							</p>
							<Link to={ROUTER.CAMERAS_CREATE.PATH}>
								<Button>
									Создать камеру <Icon name="plusCircle" />
								</Button>
							</Link>
						</div>
					}
				>
					{cameras.map((camera, i) => {
						const camType = urlToType(camera.video_url ?? '') ?? '';

						return (
							<li key={camera.id}>
								{<CameraPreviewWrapper source={camera.video_url} id={camera.id} name={camera.name} type={camType} />}
							</li>
						);
					})}
				</List>
			</ul>
		</>
	);
};
