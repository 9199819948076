import React, { FC, useEffect, useState } from 'react';
import { Button } from '@components/uikit/Button/Button';
import { Icon } from '@components/uikit/Icon/Icon';
import { TextInput } from '@components/uikit/TextInput/TextInput';
import { SearchCameraModal } from '@components/recognition/SearchCameraModal/SearchCameraModal';
import { useNavigate } from 'react-router-dom';
import { ROUTER } from '@constants/router';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useGetBucketConfigQuery, useGetCamerasByBucketQuery } from '@store/api';
import { useFaceStageSearchConfig } from '@hooks/useFaceStageSearchConfig';
import { SearchDurationSelect } from '@components/uikit/Select/SearchDurationSelect/SearchDurationSelect';
import { MoveToArchiveSelect } from '@components/uikit/Select/MoveToArchiveSelect/MoveToArchiveSelect';
import { NotificationDurationSelect } from '@components/uikit/Select/NotificationDurationSelect/SearchDurationSelect';

const DEFAULT_CONFIDENCE = 90;

type Camera = {
	camera_id: string;
	title: string;
	confidence?: number;
};

type PnChangeProps = {
	source_type: 'stream';
	title: string;
	duration?: string;
	notification?: string;
	archive?: string;
	confidence: number;
};
export type SearchFormStreamProps = {
	onChange?: (value: PnChangeProps) => void;
	bucketId: string;
	amount?: number;
	type?: 'face' | 'plate';
	title?: string;
};

type Inputs = {
	title: string;
	cameras: Camera[];
	duration: number;
	notification: number;
	archive: number;
	confidence: number;
};

export const SearchFormStream: FC<SearchFormStreamProps> = ({ onChange, bucketId, amount, title, type = 'face' }) => {
	const [searchModalOpen, setSearchModalOpen] = useState(false);
	const { currentData: bucketConfig } = useGetBucketConfigQuery(bucketId);
	const { currentData: cameras } = useGetCamerasByBucketQuery(bucketId);
	const { pushToActiveMonitoring, updateActiveMonitoring, excludeFromActiveMonitoring } = useFaceStageSearchConfig();

	const {
		handleSubmit,
		formState: { errors },
		control,
		setValue,
		clearErrors,
		register,
	} = useForm<Inputs>({
		defaultValues: {
			title: '',
			cameras: [],
			notification: 0,
			archive: 24,
			confidence: DEFAULT_CONFIDENCE,
		},
	});

	useEffect(() => {
		setValue('title', bucketConfig?.caption ?? '');

		const duration = (
			(bucketConfig?.tags ?? []).find((item) => {
				return item.includes('duration');
			}) ?? ''
		).replace('duration:', '');
		duration.length && setValue('duration', +duration);

		const notification = (
			(bucketConfig?.tags ?? []).find((item) => {
				return item.includes('notification');
			}) ?? ''
		).replace('notification:', '');
		notification.length && setValue('archive', +notification);

		const archive = (
			(bucketConfig?.tags ?? []).find((item) => {
				return item.includes('archiveMoving');
			}) ?? ''
		).replace('archiveMoving:', '');
		archive.length && setValue('archive', +archive);

		const confidence = (
			bucketConfig?.tags?.find((item) => {
				return item.includes('confidence');
			}) ?? ''
		).replace('confidence:', '');
		confidence && setValue('confidence', +confidence);
		confidence && setBucketConfidence(+confidence);
	}, [bucketConfig, setValue]);

	const [bucketConfidence, setBucketConfidence] = useState(DEFAULT_CONFIDENCE);

	useEffect(() => {
		return setValue(
			'cameras',
			(cameras?.cameras ?? []).map((camera) => {
				return {
					camera_id: camera.camera_uuid,
					title: camera.camera_name,
					confidence: bucketConfidence / 100,
				};
			}),
		);
	}, [cameras, setValue, bucketConfidence]);

	const onCameraModalAdd = async (config: Camera) => {
		await pushToActiveMonitoring({
			active_monitoring: [
				{
					bucket_name: bucketId,
					limit: 1,
					threshold: bucketConfidence / 100,
				},
			],
			camera_uuid: config.camera_id,
		});
	};

	const onCameraModalSubmit = async (config: Camera) => {
		await pushToActiveMonitoring({
			active_monitoring: [
				{
					bucket_name: bucketId,
					limit: 1,
					threshold: bucketConfidence / 100,
				},
			],
			camera_uuid: config.camera_id,
		});
		setSearchModalOpen(false);
		clearErrors('cameras');
	};

	const onCameraRemove = async (cameraId: string) => {
		await excludeFromActiveMonitoring({
			buckets: [bucketId],
			camera_uuid: cameraId,
		});
	};
	const navigate = useNavigate();

	const onSubmit: SubmitHandler<Inputs> = (values) => {
		onChange?.({
			source_type: 'stream',
			title: values.title,
			confidence: values.confidence,
			duration: values.duration || values.duration === 0 ? `duration:${values.duration}` : undefined,
			archive: values.archive || values.archive === 0 ? `archiveMoving:${values.archive}` : undefined,
			notification:
				values.notification || values.notification === 0 ? `notification:${values.notification}` : undefined,
		});

		cameras &&
			cameras.cameras.forEach(async ({ camera_uuid }) => {
				await updateActiveMonitoring({
					active_monitoring: [
						{
							bucket_name: bucketId,
							limit: 1,
							threshold: values.confidence / 100,
						},
					],
					camera_uuid: camera_uuid,
					bucket_id: bucketId,
				});
			});
		navigate(`${ROUTER.EVENTS.PATH}?bucket_id=${bucketId}`);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col gap-y-[24px]'}>
			{title && <h2 className={'font-headline-xsmall text-on-background mt-[16px]'}>{title}</h2>}
			<div>
				<TextInput
					{...register('title', {
						required: 'Поле обязательно',
					})}
					className={'!w-[348px]'}
					hideNotice={false}
					variant={'filled'}
					placeholder={'Введите название поиска'}
					error={errors.title?.message}
				/>
			</div>
			<h2 className={'font-headline-xsmall text-on-background'}>
				Выберите камеры по которым будет осуществляться поиск
			</h2>
			<Controller
				control={control}
				rules={{
					required: 'Необходимо выбрать камеру(ы)',
				}}
				render={({ field: { ref, value } }) => {
					return (
						<div className={'flex flex-col relative mt-[-8px]'}>
							<div className={'flex flex-wrap max-w-[1563px] gap-[16px]'}>
								<Button
									type={'button'}
									className={'w-fit'}
									variant={errors.cameras ? 'error' : 'grey'}
									size={'xl'}
									onClick={setSearchModalOpen.bind(null, true)}
								>
									<Icon name={'cameraVideo'} width={24} height={24} />
									Добавить камеры
								</Button>
								{searchModalOpen && (
									<SearchCameraModal
										selectedCamerasIds={value.map((val) => {
											return val.camera_id;
										})}
										isOpen={searchModalOpen}
										onClose={setSearchModalOpen.bind(null, false)}
										onSubmit={onCameraModalSubmit}
										onSubmitAndAdd={onCameraModalAdd}
										type={type}
									/>
								)}
								{value.map((camera, index) => {
									if (!camera.camera_id) {
										return null;
									}

									const camTitle = camera.title ?? `Камера ${index}`;

									return (
										<Button
											type={'button'}
											variant={'dark'}
											size={'xl'}
											onClick={onCameraRemove.bind(null, camera.camera_id)}
											key={camera.camera_id}
										>
											<Icon name={'cameraVideo'} width={24} height={24} />
											<span className="max-w-[150px] truncate">{camTitle}</span>
											<Icon name={'x'} width={24} height={24} />
										</Button>
									);
								})}
							</div>
							{errors.cameras && <p className={'font-body-medium text-error mt-3'}>{errors.cameras.message}</p>}
						</div>
					);
				}}
				name={'cameras'}
			/>

			{type === 'face' && (
				<>
					{' '}
					<h2 className={'font-headline-xsmall text-on-background'}>Процент вероятности совпадения</h2>
					<TextInput
						{...register('confidence', {
							required: 'Необходимо заполнить процент совпадения',
							valueAsNumber: true,
							min: { value: 1, message: 'Не может быть меньше чем 1' },
							max: { value: 100, message: 'Не может быть больше чем 100' },
							onChange: (e) => {
								setBucketConfidence(e.target.valueAsNumber);
							},
						})}
						className={'!w-[348px]'}
						variant={'filled'}
						type={'number'}
						placeholder={'Процент вероятности'}
						error={errors.confidence?.message}
						step={0.01}
						hideNotice={false}
						postfix={'%'}
					/>
				</>
			)}
			<div className="flex gap-8">
				<div className="max-w-[350px]">
					<h2 className={'font-headline-xsmall text-on-background mb-[20px]'}>Определите длительность поиска</h2>
					<Controller
						control={control}
						rules={{
							required: 'Необходимо выбрать длительность поиска',
						}}
						render={({ field }) => {
							return (
								<SearchDurationSelect
									onChange={field.onChange}
									error={!!errors.duration?.message}
									selectedDuration={field.value}
								/>
							);
						}}
						name={'duration'}
					/>
					{errors.duration && (
						<p className={'font-body-medium text-error mt-3 break-normal'}>{errors.duration.message}</p>
					)}
				</div>
				<div className="max-w-[350px]">
					<h2 className={'font-headline-xsmall text-on-background mb-[20px]'}>Уведомление при анализе потока</h2>
					<Controller
						control={control}
						rules={{
							required: 'Необходимо выбрать частоту уведомлений',
						}}
						render={({ field }) => {
							return (
								<NotificationDurationSelect
									error={!!errors.notification?.message}
									onChange={field.onChange}
									selectedValue={field.value}
								/>
							);
						}}
						name={'notification'}
					/>
					{errors.notification && (
						<p className={'font-body-medium text-error mt-3 break-normal'}>{errors.notification.message}</p>
					)}
				</div>
				<div className="max-w-[350px]">
					<h2 className={'font-headline-xsmall text-on-background mb-[20px]'}>Убирать новые события в архив</h2>
					<Controller
						control={control}
						rules={{
							required: 'Необходимо выбрать время, после которого события переходят в архив',
						}}
						render={({ field }) => {
							return (
								<MoveToArchiveSelect
									error={!!errors.archive?.message}
									onChange={field.onChange}
									selectedValue={field.value}
								/>
							);
						}}
						name={'archive'}
					/>
					{errors.archive && (
						<p className={'font-body-medium text-error mt-3 break-normal'}>{errors.archive.message}</p>
					)}
				</div>
			</div>

			<Button
				className={'w-fit mt-[6px]'}
				variant={'primary'}
				size={'xl'}
				type={'submit'}
				disabled={amount !== undefined ? !amount : false}
			>
				Продолжить
			</Button>
		</form>
	);
};
