import { useEffect, useRef } from 'react';

/**
 * Хук для добавления обработчика события нажатия клавиши
 * @param callback - Обработчик события
 * @param options - Опции добавления обработчика события
 */
export function useKeypressEventListener(
	callback: (event: KeyboardEvent) => void,
	options?: AddEventListenerOptions,
): void {
	const onKeypressRef = useRef(callback);
	onKeypressRef.current = callback;

	useEffect(() => {
		const keypressListener = (event: KeyboardEvent) => {
			onKeypressRef.current?.(event);
		};

		window.addEventListener('keydown', keypressListener, options);

		return () => {
			window.removeEventListener('keydown', keypressListener, options);
		};
	}, [callback, options]);
}
