import React, { FC } from 'react';
import { Select } from '../Select';
import { SelectProps } from '@components/uikit/Select/Select.typedef';

export type MoveToArchiveSelectType = {
	value: number;
	label: string;
};

const values: MoveToArchiveSelectType[] = [
	{
		value: 1,
		label: 'Раз в час',
	},
	{
		value: 2,
		label: 'Раз в 2 часa',
	},
	{
		value: 4,
		label: 'Раз в 4 часa',
	},
	{
		value: 6,
		label: 'Раз в 6 часов',
	},
	{
		value: 12,
		label: 'Раз в 12 часов',
	},
	{
		value: 18,
		label: 'Раз в 18 часов',
	},
	{
		value: 24,
		label: 'Раз в сутки',
	},
];

type MoveToArchiveSelectProps = Pick<
	SelectProps,
	'size' | 'label' | 'className' | 'isClearable' | 'placeholder' | 'isSearchable' | 'error'
> & {
	selectedValue?: number | null;
	onChange: (selectedValue: number) => void;
	containerClassName?: string;
};

export const MoveToArchiveSelect: FC<MoveToArchiveSelectProps> = ({
	selectedValue,
	containerClassName,
	onChange,
	...selectProps
}) => {
	const selectedItem = values?.find(({ value }) => {
		return value === selectedValue;
	});

	return (
		<div className={['relative', containerClassName].filter(Boolean).join(' ')}>
			<Select<MoveToArchiveSelectType>
				valueContainerIcon={'clock'}
				placeholder={'Убирать новые события в архив'}
				/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
				// @ts-ignore
				onChange={(e) => {
					e && onChange(e.value);
				}}
				{...selectProps}
				options={values}
				value={selectedItem ?? null}
			/>
		</div>
	);
};
