import React, { FC } from 'react';
import { RuntimeEnv } from '@modules/runtime-env/RuntimeEnv';
import { Photo } from '@components/recognition/Photo/Photo';
import { Badge } from '@components/uikit/Badge/Badge';

export type PersonEntryProps = {
	entry: unknown;
	confidence: number;
};

type Data = {
	title: string;
	faces: {
		url: string;
		id: string;
	}[];
};
export const PersonEntry: FC<PersonEntryProps> = ({ entry, confidence }) => {
	const isError = false;
	const data: Data = {
		title: 'string',
		faces: [],
	};

	return (
		<>
			<h2 className={'font-headline-xsmall text-on-primary-container'}>
				Совпадение с маской: <span className={'font-title-medium text-on-primary-container'}>{data?.title}</span>{' '}
				<Badge color={'primary'} variant={'filled'} size={'sm'}>
					{confidence * 100}%
				</Badge>
			</h2>
			{isError && <p className={'font-title-small text-error-60'}>Произошла ошибка при получении данных о маске.</p>}
			{data && (
				<>
					<div className={'flex flex-wrap gap-[8px] max-w-[912px]'}>
						{data.faces.map((face) => {
							const url = RuntimeEnv.getEnv('REACT_APP_API_URL') + face.url;
							return <Photo imageUrl={url} key={face.id} />;
						})}
					</div>
				</>
			)}
		</>
	);
};
