import React, { useEffect, useState } from 'react';
import { CameraPreviewLine, CameraPreviewProps } from './CameraPreviewLine';
import { useGetCameraSnapshotQuery } from '@store/ApiPeopleCounter/apiPeopleCounter';

type CameraPreviewWrapperProps = Omit<CameraPreviewProps, 'imageUrl'>;
const CameraPreviewWrapper = (props: CameraPreviewWrapperProps) => {
	const { id } = props;
	const [snapshot, setSnapshot] = useState<string | null>(null);

	const { currentData } = useGetCameraSnapshotQuery(id);
	useEffect(() => {
		if (currentData !== undefined) {
			setSnapshot(currentData);
		}
	}, [currentData]);

	return <CameraPreviewLine {...props} imageUrl={snapshot ?? ''} />;
};

export default CameraPreviewWrapper;
