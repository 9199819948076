import { useEffect, useRef, useState } from 'react';

/**
 * Хук для получения ссылки на состояние
 * https://ryankubik.com/blog/use-ref-state
 * @param initialValue - Начальное значение
 */
export function useStateRef<T>(initialValue: T) {
	const [value, setValue] = useState<T>(initialValue);
	const ref = useRef<T>(value);

	useEffect(() => {
		ref.current = value;
	}, [value]);

	return [value, setValue, ref] as const;
}
