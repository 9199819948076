import React, { createContext, FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useStateRef } from '@hooks/useStateRef';

type ClickEventListener = (this: Window, ev: WindowEventMap['click']) => void;
export const UserActivationContext = createContext<boolean>(false);

const shouldUseLegacyWay = () => {
	return typeof navigator.userActivation === 'undefined';
};

/**
 * TODO: Синхронизировать с Redux'ом (добавить slice)
 * https://developer.mozilla.org/en-US/docs/Web/API/UserActivation/hasBeenActive
 * Не следит за значением isActive, а только за тем, что было активировано
 * Поддерживает, в том числе и Firefox (legacy way)
 */
export const UserActivationProvider: FC<PropsWithChildren> = ({ children }) => {
	const [hasBeenActivated, setHasBeenActivated, hasBeenActivatedRef] = useStateRef(false);
	const prevListener = useRef<ClickEventListener | null>(null);

	/**
	 * Chrome, Safari, Opera
	 */

	useEffect(() => {
		if (shouldUseLegacyWay()) {
			return;
		}

		const timerId = setInterval(() => {
			if (hasBeenActivatedRef.current) {
				return;
			}

			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const userActivation = navigator.userActivation!;
			if (!userActivation.hasBeenActive) {
				return;
			}

			userActivation.hasBeenActive && setHasBeenActivated(true);
			clearInterval(timerId);
		}, 500);

		return () => {
			clearInterval(timerId);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Mozilla Firefox (legacy way)
	 */

	useEffect(() => {
		if (!shouldUseLegacyWay()) {
			return;
		}

		// Remove previous (old) listener
		// Not necessary but if you suddenly change something in future this will keep you safe
		prevListener.current && window.removeEventListener('click', prevListener.current);

		const listener: ClickEventListener = (e) => {
			if (!e.isTrusted || hasBeenActivatedRef.current) {
				return;
			}
			setHasBeenActivated(true);
		};

		window.addEventListener('click', listener);
		prevListener.current = listener;
		return () => {
			window.removeEventListener('click', listener);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <UserActivationContext.Provider value={hasBeenActivated}>{children}</UserActivationContext.Provider>;
};
