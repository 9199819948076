import { notify } from '@modules/toast/notify';

const allowedFileFormats = ['image/jpeg', 'image/png', 'video/mp4', 'image/bmp'] as const;
type AllowedFileFormats = (typeof allowedFileFormats)[number];

function validateFile(file: File): boolean {
	// Check file size (500MB limit)
	const maxSizeBytes = 500 * 1024 * 1024; // 500MB in bytes
	if (file.size > maxSizeBytes) {
		notify(`Размер файла ${file.name} не должен превышать 500 МБ`);
		return false;
	}

	// Check if the file extension is in the allowed formats
	const allowedFormats: AllowedFileFormats[] = ['image/jpeg', 'image/png', 'video/mp4', 'image/bmp'];
	if (!(allowedFormats as string[]).includes(file.type)) {
		notify(`Формат файла ${file.name} не поддерживается`);
		return false;
	}

	// If both size and format checks pass, the file is valid
	return true;
}

export function filterInvalidFiles(fileList: FileList, onInvalid?: (file: File) => void) {
	const validFiles: File[] = [];

	// Iterate through each file in the FileList
	for (let i = 0; i < fileList.length; i++) {
		const file = fileList[i];

		// Check if the file is valid using the validateFile function
		if (validateFile(file)) {
			validFiles.push(file);
		} else {
			onInvalid?.(file);
		}
	}

	return validFiles.length ? validFiles : null;
}
