import React, { FC } from 'react';
import { FacesStage } from '@pages/recognition-database/config/FacesStage';
import { useBucketName } from '@hooks/useBucketName';

export const RecognitionDatabaseCreateFacesConfigPage: FC = () => {
	const { bucketName } = useBucketName();
	return (
		<>
			{bucketName === undefined && <>Создание картотек недоступно</>}
			{bucketName !== undefined && <FacesStage bucketName={bucketName} />}
		</>
	);
};
