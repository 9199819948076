import { useSearchParams } from 'react-router-dom';

const DEFAULT = {
	page: 1,
	pageSize: 8,
};

export const useSearchParamsPaginator = () => {
	const [searchParams, setSearchParams] = useSearchParams({
		page: String(DEFAULT.page),
		'page-size': String(DEFAULT.pageSize),
	});

	const searchParamsPage = searchParams.get('page') ? Number(searchParams.get('page')) : DEFAULT.page,
		searchParamsPageSize = searchParams.get('page-size') ? Number(searchParams.get('page-size')) : DEFAULT.pageSize;

	const onSearchParamsPageChange = (page: number) => {
		setSearchParams((prevState) => {
			prevState.set('page', String(page));
			return prevState;
		});
	};

	const onSearchParamsPageSizeChange = (pageSize: number) => {
		setSearchParams((prevState) => {
			prevState.set('page-size', String(pageSize));
			return prevState;
		});
	};

	return { searchParamsPage, onSearchParamsPageChange, searchParamsPageSize, onSearchParamsPageSizeChange };
};
