import React, { FC } from 'react';
import { MonitoringSlotPlaceholder } from '@components/camera/Monitoring/MonitoringSlot/MonitoringSlotPlaceholder';
import { MonitoringSlotCamera } from '@components/camera/Monitoring/MonitoringSlot/MonitoringSlotCamera';
import classes from './MonitoringSlot.module.css';
import { Camera } from '../../useMonitoring';

export type MonitoringSlotProps = {
	camera?: Nullable<Camera>;
	camerasList: Array<Camera>;
	onChange?: (cameraId: Nullable<string>) => void;
	onTargetChange: React.ComponentProps<typeof MonitoringSlotPlaceholder>['onTargetChange'];
	showHighQuality?: boolean;
};

export const MonitoringSlot: FC<MonitoringSlotProps> = ({
	camera,
	camerasList,
	onChange: propOnChange,
	onTargetChange,
	showHighQuality = false,
}) => {
	const onChange = (cameraId: string) => {
		propOnChange?.(cameraId);
	};

	const onClose = () => {
		propOnChange?.(null);
	};

	const getUrl = () => {
		if (camera?.source_url?.includes('youtube')) {
			return camera.source_url;
		}

		return showHighQuality ? camera?.hls_url : camera?.hls_lq;
	};

	const content = camera ? (
		<MonitoringSlotCamera
			cameraName={camera.title}
			onChangeCamera={onChange}
			cameraId={camera.id}
			url={getUrl()}
			onClose={onClose}
			camerasList={camerasList}
		/>
	) : (
		<MonitoringSlotPlaceholder onTargetChange={onTargetChange} onCameraChange={onChange} camerasList={camerasList} />
	);

	return (
		<div className={classes['box']}>
			<div className={classes['content']}>{content}</div>
		</div>
	);
};
