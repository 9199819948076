import { TextInput } from '@components/uikit/TextInput/TextInput';
import { useDebounceCallback } from '@hooks/useDebounceCallback';
import { useSearchParamsPaginator } from '@hooks/useSearchParamsPaginator';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const SearchDatabaseSearch = () => {
	// eslint-disable-next-line
	const [_, setSearchParams] = useSearchParams();

	const [searchInputValue, setSearchInputValue] = useState('');
	const { onSearchParamsPageChange } = useSearchParamsPaginator();

	const changeSearchQuery = useDebounceCallback((value: string) => {
		onSearchParamsPageChange(1);
		setSearchParams((prevState) => {
			value ? prevState.set('search', value) : prevState.delete('search');
			return prevState;
		});
	}, 1000);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInputValue(e.target.value);
		changeSearchQuery(e.target.value);
	};

	return (
		<TextInput
			value={searchInputValue}
			onChange={handleChange}
			className={'!w-[350px]'}
			icon={'search'}
			placeholder={'Поиск'}
			variant={'bordered'}
		/>
	);
};
