import React from 'react';
import RecognizedCard from '@components/recognition/RecognizedCard/RecognizedCard';
import { useGetFaceImageQuery } from '@store/api';
import { format } from 'date-fns';

type RecognizedCardProps = React.ComponentProps<typeof RecognizedCard>;

type RecognizedCardWrapperProps = {
	faceId: number;
	cameraId: string;
	date: Date;
	identity: RecognizedCardProps['title'];
	tags: RecognizedCardProps['tags'];
	name: RecognizedCardProps['description'];
	cameraName: string;
	similarity: string;
	isLowQuality: boolean;
};
const RecognizedCardWrapper = ({
	date,
	tags,
	name,
	faceId,
	cameraName,
	similarity,
	isLowQuality,
}: RecognizedCardWrapperProps) => {
	const { currentData } = useGetFaceImageQuery(faceId);

	return (
		<RecognizedCard
			similarity={similarity}
			key={faceId}
			imageUrl={currentData}
			subTitle={`id:${faceId}`}
			title={name}
			description={format(date, 'dd/MM/yyyy HH:mm:ss')}
			tags={tags}
			camera={cameraName}
			isLowQuality={isLowQuality}
		/>
	);
};

export default RecognizedCardWrapper;
