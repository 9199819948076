export const glassesData: { [key: string]: string } = {
	none: 'Нет',
	dark: 'Темные',
	usual: 'Обычные',
};

export const genderData: { [key: string]: string } = {
	male: 'Мужской',
	female: 'Женский',
};

export const genderName: { [key: string]: string } = {
	male: 'Незнакомец',
	female: 'Незнакомка',
};

export const maskData: { [key: string]: string } = {
	none: 'Нет',
	medmask_correct: 'Медицинская правильная',
	medmask_incorrect: 'Медицинская неправильная',
	other_mask: 'Другая маска',
};

export const hairData: { [key: string]: string } = {
	none: 'Нет',
	beard: 'Борода',
	bristle: 'Щетина',
	moustache: 'Усы',
};

export const raceData: { [key: string]: string } = {
	europid: 'Европеоидная',
	mongoloid: 'Монголоидная',
	negroid: 'Негроидная',
	indian: 'Индийская',
	pamir: 'Памиро-ферганская',
	caucasian: 'Кавказская',
	other: 'Не определена',
};

export const purposeData: { [key: string]: string } = {
	common: 'Обычный',
	police: 'Полиция',
	ambulance: 'Скорая помощь',
	firetruck: 'Пожарная машина',
	emergency: 'МЧС',
	other: 'Не определена',
};

export const categoryData: { [key: string]: string } = {
	civil: 'Гражданский',
	government: 'Правительственный',
	police: 'Полиция',
	diplomatic: 'Дипломатический',
	military: 'Военный',
	route: 'Общественный транспорт',
	unknown: 'Не определен',
};
export const categoryDataColorsBg: { [key: string]: string } = {
	civil: 'white',
	government: 'white',
	police: 'blue',
	diplomatic: 'red',
	military: 'black',
	route: 'yellow',
	unknown: 'white',
};

export const categoryDataColorsText: { [key: string]: string } = {
	civil: 'black',
	government: 'black',
	police: 'white',
	diplomatic: 'white',
	military: 'white',
	route: 'black',
	unknown: 'black',
};
