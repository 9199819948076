import React, { FC } from 'react';
import styles from './Spinner.module.css';
import { Icon } from '../Icon/Icon';

export const Spinner: FC<{ noBg?: boolean }> = ({ noBg }) => {
	return (
		<div className={styles['spinner'] + (noBg ? ' !bg-transparent' : '')}>
			<Icon name="spinner" />
		</div>
	);
};
