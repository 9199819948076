import { Popover } from '@components/uikit/Popover/Popover';
import { SortSelect, SortSelectValue } from '@components/uikit/SortSelect/SortSelect';
import { TextInput } from '@components/uikit/TextInput/TextInput';
import { CameraFilter } from '../CameraFilter/CameraFilter';
import { Button } from '@components/uikit/Button/Button';
import { Badge } from '@components/uikit/Badge/Badge';
import { List } from '@components/List/List';
import { Link } from 'react-router-dom';
import { ROUTER } from '@constants/router';
import { Icon } from '@components/uikit/Icon/Icon';
import CameraPreviewWrapper from '../CameraPreview/CameraPreviewZone/CameraPreviewWrapper';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useStoreDispatch } from '@store/store';
import { useCamerasParams } from '@hooks/useCamerasParams';
import { useGetCamerasQuery, useUpdateCameraMutation } from '@store/api';
import { useDebounce } from '@hooks/useDebounce';
import { CameraIndicatorStatus } from '../CameraIndicator/CameraIndicator';
import { camerasParamsSlice } from '@store/slices/camerasParamsSlice';
import { cameraMapper } from '@utils/cameraMapper';
import { urlToType } from '@utils/urlToType';
import { ReactComponent as NoCameras } from '@assets/illustrations/no-cameras.svg';

export type Camera = {
	id: string;
	name: string;
	status: CameraIndicatorStatus;
	isFavorite: boolean;
	isEditable: boolean;
	configCount: number;
	stream_params?: {
		source?: string;
	};
	detector_params?: {
		disable: boolean;
		face?: {
			rtsp_path?: string;
		};
		quality_threshold?: number;
	};
	timestamp_created: string;
	tags: string[];
	type: string;
};

const sortMap: { [key: string]: string } = {
	newest: 'desc',
	oldest: 'asc',
};

export const CameraZonesList = () => {
	const dispatch = useStoreDispatch();

	const [searchInputValue, setSearchInputValue] = useState('');
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const { filterParams, onFilterChange } = useCamerasParams();

	const [sortState, setSortState] = useState<SortSelectValue>('newest');
	const [updateCamera] = useUpdateCameraMutation();

	const searchInputValueDebounced = useDebounce(searchInputValue, 500);

	const params = useMemo(() => {
		return {
			camera_name: searchInputValueDebounced,
			sort: sortMap[sortState],
			sort_key: 'timestamp_created',
		};
	}, [searchInputValueDebounced, sortState]);

	const { currentData, isLoading } = useGetCamerasQuery(params, {
		refetchOnMountOrArgChange: true,
	});

	useEffect(() => {
		dispatch(camerasParamsSlice.actions.setCameraParams(params));
	});

	const cameras = useMemo(() => {
		return currentData?.data === undefined
			? ([] as Camera[])
			: currentData.data.reduce<Camera[]>((acc, current) => {
					if (Object.keys(current).length <= 1) return acc;

					return acc.concat(cameraMapper(current));
			  }, []);
	}, [currentData?.data]);

	const camerasListFiltered = cameras.filter((item) => {
		const camType = urlToType(item.stream_params?.source ?? item.detector_params?.face?.rtsp_path ?? '') ?? '';

		return (
			((filterParams.favorite && item.isFavorite) || !filterParams.favorite) &&
			(!filterParams.type.length || filterParams.type.includes(camType))
		);
	});

	const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInputValue(e.target.value);
	};

	const handleSort = (e: ChangeEvent<HTMLInputElement>) => {
		setSortState(e.target.value as SortSelectValue);
	};

	const onFavoriteToggle = async (id: string, tags: string[]) => {
		const tagsRefreshed = tags.includes('isFavorite')
			? tags.filter((elem: string) => {
					return elem !== 'isFavorite';
			  })
			: [...tags, 'isFavorite'];

		await updateCamera({
			camera_uuid: id,
			tags: tagsRefreshed,
		});
	};

	const onEnabledToggle = async (id: string, camera: Camera) => {
		await updateCamera({
			camera_uuid: id,
			detector_params: {
				...camera.detector_params,
				disable: !camera.detector_params?.disable,
			},
		});
	};

	return (
		<>
			<div className="flex flex-col">
				<div className="flex items-center justify-between flex-wrap gap-y-4">
					<TextInput
						icon="search"
						variant="bordered"
						placeholder="Поиск"
						value={searchInputValue}
						className="max-w-[364px]"
						onChange={handleSearchInputChange}
					/>
					<div className="flex gap-4 items-center">
						<SortSelect value={sortState} onChange={handleSort} />
						<Popover
							content={
								<CameraFilter
									filterParams={filterParams}
									onFilterChange={onFilterChange}
									setIsOpen={setIsPopoverOpen}
								/>
							}
							positions={['bottom', 'left', 'right']}
							padding={8}
							align={'end'}
							isOpen={isPopoverOpen}
							onClose={setIsPopoverOpen.bind(null, false)}
						>
							{() => {
								return (
									<div className="relative">
										<Button variant={'grey'} onClick={setIsPopoverOpen.bind(null, !isPopoverOpen)}>
											Фильтры <Icon name="filter" />
										</Button>
										{Boolean(filterParams?.type.length) && (
											<Badge
												color={'primary'}
												variant={'filled'}
												size={'xs'}
												className={'absolute top-[-4px] right-[-2px]'}
											>
												{filterParams?.type.length}
											</Badge>
										)}
									</div>
								);
							}}
						</Popover>
					</div>
				</div>
			</div>
			<ul className="flex gap-6 flex-col mt-6 mb-6 relative">
				<List
					isLoading={isLoading}
					emptyState={
						<div className={'w-full h-[calc(100%-60px)] flex gap-4 flex-col items-center justify-center'}>
							<NoCameras className={'w-[65%] mb-4'} />
							<h1 className={'font-display-large'}>Нет доступных камер</h1>
							<p className={'font-body-large opacity-80'}>
								Добавьте новую камеру или обновите список, чтобы увидеть их в списке
							</p>
							<Link to={ROUTER.CAMERAS_CREATE.PATH}>
								<Button>
									Создать камеру <Icon name="plusCircle" />
								</Button>
							</Link>
						</div>
					}
				>
					{camerasListFiltered.map((camera) => {
						const { id } = camera;
						return (
							<li key={id}>
								<CameraPreviewWrapper
									isEditable={true}
									isDisabled={camera.detector_params?.disable ?? false}
									source={camera.stream_params?.source ?? camera.detector_params?.face?.rtsp_path ?? ''}
									configCount={camera.configCount}
									tags={camera.tags}
									id={id}
									location={''}
									name={camera.name}
									favorite={camera.isFavorite}
									status={camera.status}
									target={''}
									timestamp_created={camera.timestamp_created}
									onFavoriteToggle={onFavoriteToggle}
									onEnabledToggle={onEnabledToggle.bind(null, camera.id, camera)}
									type={camera.type}
								/>
							</li>
						);
					})}
				</List>
			</ul>
		</>
	);
};
