import { useState, useEffect, useRef } from 'react';

const useOutsideClick = (initialValue = false) => {
	const [isOpen, setIsOpen] = useState(initialValue);
	const ref = useRef<HTMLDivElement | null>(null);

	const handleOutsideClick = (e: MouseEvent) => {
		const target = e.target as HTMLElement;

		if (ref.current && !ref.current.contains(target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleOutsideClick);

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	});

	return { isOpen, setIsOpen, ref };
};

export default useOutsideClick;
