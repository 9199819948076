import React, { useEffect, useState } from 'react';
import { TextInput } from '@components/uikit/TextInput/TextInput';
import { Photo } from '@components/recognition/Photo/Photo';
import Tag from '@components/uikit/Tag/Tag';
import { Tooltip } from '@components/uikit/Tooltip/Tooltip';
import { Button } from '@components/uikit/Button/Button';
import { Badge } from '@components/uikit/Badge/Badge';
import { Icon } from '@components/uikit/Icon/Icon';
import { PushBucketParams, useBucketFacePushMutation, useCopyToAnotherBucketMutation } from '@store/api';
import { notifyError } from '@modules/toast/notifyError';
import { notify } from '@modules/toast/notify';
import { FACES_BUCKET_NAME } from '@constants/facesBucketName';

type RecognitionItemSelectProps = {
	onMatchClick?: () => void;
	prefixBucket?: string;
	bucket_name: string;
	matches: boolean;
	picture: {
		blob: Blob;
		url: string;
	};
	selectedFace?: boolean;
	name?: string;
	type: 'recognition' | 'search';
	id?: string;
	recognizedFacesFilter?: (id: string) => void;
};

const RecognitionItemSelect = ({
	picture,
	matches,
	id,
	name,
	selectedFace,
	bucket_name,
	onMatchClick,
	prefixBucket,
	recognizedFacesFilter,
	type,
}: RecognitionItemSelectProps) => {
	const [tags, setTags] = useState<string[]>([]);
	const [tagInputValue, setTagInputValue] = useState<string>('');
	const [inputTitleValue, setInputTitleValue] = useState('');
	const [isErrorTitle, setIsErrorTitle] = useState<boolean>(false);
	const [faceId, setFaceId] = useState<number>();
	const [pushToBucket, { isLoading, isError, data }] = useBucketFacePushMutation();
	const [copyToAnotherBucket] = useCopyToAnotherBucketMutation();
	const isFormDisabled = faceId !== undefined;
	const onCloseTagHandler = (title: string) => {
		setTags((prevState) => {
			return prevState.filter((value) => {
				return value !== title;
			});
		});
	};

	useEffect(() => {
		if (name) {
			setInputTitleValue(name);
		}
	}, [name]);

	const isTitleValid = () => {
		if (inputTitleValue.length === 0) {
			setIsErrorTitle(true);
			return false;
		}
		setIsErrorTitle(false);
		return true;
	};

	/**
	 * Функция добавлет лицо в картотеку, с возможностью сохранения в базу лиц
	 */
	const addFaceToBucket = async (withSaving?: boolean) => {
		if (!isTitleValid() || picture.url === undefined) return;
		const params: PushBucketParams = {
			image: picture.blob,
			params: { bucket_name, identity: inputTitleValue, tags: tags.length ? tags : undefined },
		};

		try {
			if (type === 'search') {
				const res = await pushToBucket(params).unwrap();
				setFaceId(res.face_id);
				if (withSaving && prefixBucket) {
					await copyToAnotherBucket({
						b_name_dst: prefixBucket + FACES_BUCKET_NAME,
						b_name_src: bucket_name,
						f_id_src: res.face_id,
						f_id_dst: res.face_id,
					});
				}
			} else if (type === 'recognition') {
				await pushToBucket({
					image: picture.blob,
					params: {
						bucket_name: prefixBucket + FACES_BUCKET_NAME,
						identity: inputTitleValue,
						tags: tags.length ? tags : undefined,
					},
				}).unwrap();
				notify('Лицо добавлено в базу');
				id && recognizedFacesFilter && recognizedFacesFilter(id);
			}
		} catch (e) {
			notifyError('Ошибка добавления лица в базу');
		}
	};

	return (
		<div
			className={`px-4 pb-4 pt-3 flex flex-col gap-4 bg-primary-95 rounded-[8px] w-full ${
				isFormDisabled ? 'border border-green-40' : ''
			}`}
		>
			<div className={'flex gap-2'}>
				<TextInput
					disabled={isFormDisabled || selectedFace}
					value={inputTitleValue}
					className={'!w-max'}
					error={isErrorTitle ? ' ' : undefined}
					placeholder={'Введите название '}
					onChange={(event) => {
						setInputTitleValue(event.target.value);
						setIsErrorTitle(false);
					}}
				/>
				{type === 'search' && matches && !selectedFace && (
					<button onClick={onMatchClick} disabled={selectedFace}>
						<Badge color={'green'} variant={'squared'}>
							<Icon name={'exclamationCircleFill'} />
							<span>Есть совпадение</span>
						</Badge>
					</button>
				)}
			</div>
			<div className={`flex gap-4 justify-between items-start ${isFormDisabled ? 'flex-col-reverse' : ''}`}>
				<div>
					<div className={'gap-2 flex flex-col'}>
						<div className={'min-w-[250px]'}>
							<form
								onSubmit={(e) => {
									e.preventDefault();
									setTags((prevState) => {
										if (prevState.includes(tagInputValue)) {
											notify('Данный тег уже назначен!');
											return prevState;
										}
										return prevState.concat(tagInputValue);
									});
									setTagInputValue('');
								}}
							>
								<TextInput
									disabled={isFormDisabled || selectedFace}
									placeholder={'Присвоить тег'}
									value={tagInputValue}
									name={'title'}
									size={'s'}
									variant={'bordered'}
									icon={'listUl'}
									onChange={(event) => {
										setTagInputValue(event.target.value);
									}}
								/>
							</form>
						</div>

						<div className={'flex flex-wrap gap-2 overflow-scroll max-w-[480px]'}>
							{tags.map((tag) => {
								return (
									<Tag
										key={tag}
										title={tag}
										onClose={isFormDisabled ? undefined : onCloseTagHandler}
										isDisabled={isFormDisabled}
									/>
								);
							})}
						</div>
					</div>

					{!isFormDisabled && (
						<div className="flex gap-4 mt-3">
							<Button
								onClick={() => {
									addFaceToBucket(true);
								}}
								disabled={isLoading || picture.url === undefined || selectedFace}
								className={'px-3 py-2 w-max'}
							>
								Добавить лицо в базу
							</Button>

							{type === 'search' && (
								<Button
									variant={'dark'}
									disabled={isLoading || picture.url === undefined || selectedFace}
									className={'px-3 py-2 w-max'}
									onClick={() => {
										addFaceToBucket();
									}}
								>
									Добавить без сохранения
								</Button>
							)}
						</div>
					)}
				</div>

				<Photo imageUrl={picture.url} key={picture.url} />
			</div>

			{(isErrorTitle || isError) && (
				<div>
					{isErrorTitle && <Tooltip error>Дайте название данной маске в системе</Tooltip>}
					{isError && <Tooltip error>Ошибка добавления лица в картотеку</Tooltip>}
				</div>
			)}

			{isFormDisabled && (
				<div className={'flex gap-4 justify-between items-center mt-auto'}>
					<p className={'font-body-large font-medium text-green-40'}>Лицо добавлено в систему</p>
					<p className={'font-body-medium text-secondary'}>id: {data?.face_id}</p>
				</div>
			)}
		</div>
	);
};

export default RecognitionItemSelect;
