import { countries } from '@constants/countries';
/**
 *
 * @param value Код alpha-2, RU, BY etc...
 * @returns Название страны или «Не определено», если страна не найдена.
 */
export const getCountryNameByAlphe2Code = (value: unknown) => {
	if (typeof value === 'string') {
		const code = value.toUpperCase();
		if (isAlpha2Code(code)) {
			return countries[code];
		}
	}

	return 'Не определена';
};

function isAlpha2Code(value: unknown): value is keyof typeof countries {
	return typeof value === 'string' && value in countries;
}
