import { FC, PropsWithChildren, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useAutoplay } from '@hooks/react-player/useAutoplay';
import classes from './MonitoringSlotCamera.module.css';
import CameraZoneSelector from '@components/camera/Monitoring/MonitoringSlot/CameraZoneSelector/CameraZoneSelector';
import CameraSettings from '@components/camera/Monitoring/MonitoringSlot/CameraSettings/CameraSettings';
import { Icon } from '@components/uikit/Icon/Icon';
import { ROUTER } from '@constants/router';
import { Link } from 'react-router-dom';
import { CameraZone } from './CameraZoneSelector/CameraZoneSelector.typedef';
import { Camera } from '../useMonitoring';
import { useGetCameraSnapshotQuery } from '@store/api';

export type MonitoringSlotCameraProps = {
	url?: Nullable<string>;
	cameraId: string;
	cameraName: string;
	onClose?: () => void;
	onChangeCamera?: (cameraId: string) => void;
	camerasList?: Camera[];
};

const ErrorNote: FC<PropsWithChildren> = ({ children }) => {
	return (
		<div className={'w-full h-full flex justify-center items-center border border-primary rounded-[8px]'}>
			<p className={'font-title-medium text-error-60 max-w-[85%] break-normal'}>{children}</p>
		</div>
	);
};

export const MonitoringSlotCamera: FC<MonitoringSlotCameraProps> = ({
	url,
	cameraId,
	onClose,
	cameraName,
	camerasList,
	onChangeCamera,
}) => {
	const ref = useRef<ReactPlayer>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const { isFatalError, props } = useAutoplay(ref);
	const zones = (camerasList ?? []).reduce<CameraZone[]>((accumulator, item) => {
		accumulator.push({
			id: item.id,
			title: item.title,
			isOff: item.isOff,
		});
		return accumulator;
	}, []);

	const { currentData: snapshot } = useGetCameraSnapshotQuery({ id: cameraId });

	return (
		<div className={classes['video']}>
			<div className={'absolute top-4 left-4 z-10'}>
				<CameraZoneSelector
					zones={zones}
					onZoneClick={(id) => {
						onChangeCamera && onChangeCamera(id);
					}}
				>
					<span className=" truncate max-w-[150px]">{cameraName}</span>
				</CameraZoneSelector>
			</div>
			<div className={'absolute top-4 right-4 z-10'}>
				<CameraSettings
					settings={[
						{
							id: '1',
							value: (
								<Link className="flex gap-2" to={ROUTER.CAMERAS_CREATE_ZONE.PATH + `?id=${cameraId}`}>
									<Icon name={'cameraVideo'} />
									Перейти к настройкам камеры
								</Link>
							),
							action: () => {
								return;
							},
						},
						{
							id: '2',
							value: (
								<>
									<Icon name={'x'} />
									Убрать камеру
								</>
							),
							action: () => {
								onClose && onClose();
							},
						},
					]}
				/>
			</div>
			{url && snapshot && !isFatalError && (
				<div ref={containerRef} className="h-full">
					<ReactPlayer
						muted
						playing
						ref={ref}
						width={'100%'}
						height={'100%'}
						url={url}
						stopOnUnmount
						light={snapshot}
						{...props}
					/>
					{/* <CameraZonesOverlay hls_url={url} containerRef={containerRef} cameraId={cameraId} /> */}
				</div>
			)}

			<div
				className={
					'absolute left-0 top-0 w-full h-full flex justify-center items-center text-center pointer-events-none'
				}
			>
				{!url && <ErrorNote>Отсутствует точка доступа к потоку камеры.</ErrorNote>}
				{isFatalError && <ErrorNote>Произошла ошибка при попытке проигрывания потока камеры.</ErrorNote>}
			</div>
		</div>
	);
};
