import React, { FC, ReactNode, useEffect } from 'react';
import classes from './RecognitionDatabaseTable.module.css';
import { Badge } from '@components/uikit/Badge/Badge';
import { TablePagination } from '@components/uikit/TablePagination/TablePagination';
import { SortSelectValue } from '@components/uikit/SortSelect/SortSelect';
import { SearchTaskAction } from '@components/recognition/SearchDatabaseTable/SearchTaskAction/SearchTaskAction';
import {
	useBucketExcludeFaceMutation,
	useBucketExcludePlateMutation,
	useGetFacesFromBucketsV1Query,
	useGetPlatesFromBucketsV1Query,
} from '@store/api';
import { Spinner } from '@components/uikit/Spinner/Spinner';
import { useSearchParams } from 'react-router-dom';
import { useSearchParamsPaginator } from '@hooks/useSearchParamsPaginator';
import { useInitialSearchParams } from '@hooks/useInitialSearchParams';
import { FACES_BUCKET_NAME } from '@constants/facesBucketName';
import { PLATES_BUCKET_NAME } from '@constants/platesBucketName';
import { usePrefixBucket } from '@hooks/usePrefixBucket';
import { replaceLetters } from '@utils/lisensePlateMapper';
import { RuToEnMap } from '@constants/lisenseLetters';

export type RecognitionDatabaseTableSection = 'masks' | 'plates';

export type RecognitionDatabaseTableProps = Omit<JSX.IntrinsicElements['table'], 'ref' | 'className'> & {
	sortType?: SortSelectValue;
};

type ItemOfList = {
	id: number;
	bucketId: string;
	title: string;
	subtitle?: string;
	tags: Array<ReactNode>;
};
export const RecognitionDatabaseTable: FC<RecognitionDatabaseTableProps> = ({ sortType, ...propsProxy }) => {
	const [deleteFaceFromBucket] = useBucketExcludeFaceMutation();
	const [deletePlateFromBucket] = useBucketExcludePlateMutation();
	const prefixBucket = usePrefixBucket();

	// QUERY
	const [searchParams] = useSearchParams();
	const search = searchParams.get('search');
	const section = (searchParams.get('section') ?? 'masks') as RecognitionDatabaseTableSection;
	const { searchParamsPage, onSearchParamsPageChange, searchParamsPageSize, onSearchParamsPageSizeChange } =
		useSearchParamsPaginator();

	useEffect(() => {
		onSearchParamsPageChange(1);
		//eslint-disable-next-line
	}, [search]);
	useInitialSearchParams({ section: 'masks' });
	// PAGINATION

	const { currentData: facesFromBase, isLoading: isPersonLoading } = useGetFacesFromBucketsV1Query(
		{
			limit: searchParamsPageSize,
			offset: (searchParamsPage - 1) * searchParamsPageSize,
			identity_text: search ? search : undefined,
			bucket: prefixBucket + FACES_BUCKET_NAME,
		},
		{ skip: section !== 'masks' || prefixBucket === undefined },
	);

	const { currentData: numbersFromBase, isLoading: isNumbersLoading } = useGetPlatesFromBucketsV1Query(
		{
			limit: searchParamsPageSize,
			offset: (searchParamsPage - 1) * searchParamsPageSize,
			identity_text: search ? search : undefined,
			bucket: prefixBucket + PLATES_BUCKET_NAME,
		},
		{ skip: section !== 'plates' || prefixBucket === undefined },
	);

	const persons: ItemOfList[] =
		facesFromBase?.data.map((face) => {
			return {
				id: face.face_id,
				title: face.identity,
				tags: face.tags,
				bucketId: face.bucket_name,
			};
		}) ?? [];

	const numbers: ItemOfList[] =
		numbersFromBase?.data.map((number) => {
			return {
				id: number.plate_id,
				title: number.identity.length ? number.identity : 'Без названия',
				subtitle: number.plate_text,
				tags: number.tags,
				bucketId: number.bucket_name,
			};
		}) ?? [];
	const usedData = section === 'masks' ? persons : numbers;

	const handleArchive = async (item: ItemOfList) => {
		if (section === 'masks') {
			await deleteFaceFromBucket({
				b_name: item.bucketId,
				f_id: item.id,
			});
		} else {
			await deletePlateFromBucket({
				b_name: item.bucketId,
				p_id: item.id,
			});
		}
	};

	return (
		<>
			{(isPersonLoading || isNumbersLoading) && <Spinner />}
			{(!isPersonLoading || !isNumbersLoading) && (
				<div>
					<table className={classes['table']} {...propsProxy}>
						<tbody>
							<tr>
								<th>Название</th>
								<th>ID</th>
								<th>Теги</th>
								<th>&nbsp;</th>
							</tr>
							{usedData?.map((item, index) => {
								const subtitle = replaceLetters(item.subtitle ?? '', RuToEnMap);
								const title = replaceLetters(item.title ?? '', RuToEnMap);

								return (
									<tr key={index}>
										<td>
											{title}
											{subtitle !== title && <p className={'font-body-small'}>{subtitle}</p>}
										</td>
										<td>{item.id}</td>
										<td>
											<div className={'flex flex-wrap gap-[4px]'}>
												{item.tags.map((tag, index) => {
													return (
														<Badge
															className={'!rounded-[8px]'}
															color={'primary'}
															variant={'contained'}
															size={'lg'}
															key={index}
														>
															{tag}
														</Badge>
													);
												})}
											</div>
										</td>
										<td>
											<SearchTaskAction
												onDelete={() => {
													handleArchive(item);
												}}
											/>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<TablePagination
						totalItems={(section === 'masks' ? facesFromBase?.total : numbersFromBase?.total) ?? 0}
						pageSize={searchParamsPageSize}
						currentPage={searchParamsPage}
						onPageChange={onSearchParamsPageChange}
						onPageSizeChange={onSearchParamsPageSizeChange}
					/>
				</div>
			)}
		</>
	);
};
