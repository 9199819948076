import React, { FC } from 'react';
import { Modal, ModalProps } from '../Modal/Modal';
import { useGetFaceImageOverviewQuery } from '@store/api';

export type OverviewImageModalProps = ModalProps & {
	faceId: number;
};

export const OverviewImageModal: FC<OverviewImageModalProps> = ({ onClose, isOpen, faceId }) => {
	const { currentData: imageOverview, isLoading } = useGetFaceImageOverviewQuery(faceId);
	return (
		<Modal isLoading={isLoading} isOpen={isOpen} onClose={onClose}>
			<img src={imageOverview} alt="overview" />
		</Modal>
	);
};
