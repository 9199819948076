import { useLocalStorageState } from '@hooks/useLocalStorageState';
import { MonitoringCacheData } from '@components/camera/Monitoring/Monitoring.typedef';
import { useMemo, useRef } from 'react';

const DEFAULT_TARGET_ID = 'DEFAULT_TARGET_ID';
const STORAGE_KEY = 'monitoring';
const DEFAULT_CACHE_DATA: MonitoringCacheData = {
	cameras: [],
	grid: 1,
};
// TYPE GUARD

const isMonitoringCache = (value: unknown): value is MonitoringCacheData => {
	if (typeof value !== 'object' || value === null) {
		return false;
	} else if (
		!Object.prototype.hasOwnProperty.call(value, 'grid') ||
		!Object.prototype.hasOwnProperty.call(value, 'cameras')
	) {
		return false;
	}

	const dirtyCache = value as { grid: unknown; cameras: unknown };

	if (typeof dirtyCache.grid !== 'number') {
		return false;
	} else if (!Array.isArray(dirtyCache.cameras)) {
		return false;
	}

	const cameras = dirtyCache.cameras as unknown[];

	return cameras.length
		? cameras.every((camera) => {
				return typeof camera === 'string' || camera === null;
		  })
		: true;
};

type UseMonitoringCacheParams = {
	targetId?: Nullable<string>;
};
/**
 * Хук для получения и у правления кэшем мониторинга (Monitoring).
 */
export function useMonitoringCache({ targetId = DEFAULT_TARGET_ID }: UseMonitoringCacheParams) {
	const [jsonCache, setJsonCache] = useLocalStorageState({
		key: STORAGE_KEY,
		defaultValue: '[]',
	});

	const setCache = (value: Nullable<MonitoringCacheData>) => {
		if (value === null || targetId === null) {
			return;
		}
		let parsed = jsonCache && JSON.parse(jsonCache);
		parsed = { ...parsed, [targetId]: value };
		setJsonCache(JSON.stringify(parsed));
	};

	const setJsonCacheRef = useRef(setCache);
	setJsonCacheRef.current = setCache;
	const monitoringCache = useMemo(() => {
		if (targetId === null) {
			return DEFAULT_CACHE_DATA;
		}

		try {
			const parsed = jsonCache && JSON.parse(jsonCache);
			const cache = parsed[targetId];
			if (!isMonitoringCache(cache)) {
				throw new Error('Кэш не соответствует типу MonitoringCache!');
			}

			return cache;
		} catch (err) {
			console.warn('[useMonitoringCache] Ошибка преобразования кэша (будет использовано значение по умолчанию):', err);
			setJsonCacheRef.current(DEFAULT_CACHE_DATA);
		}

		return DEFAULT_CACHE_DATA;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jsonCache, targetId]);

	return {
		monitoringCache,
		setCache,
	};
}
