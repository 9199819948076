import React, { FC } from 'react';
import classes from './ToggleRadio.module.css';

export type ToggleRadioProps = Omit<JSX.IntrinsicElements['input'], 'type'>;

export const ToggleRadio: FC<ToggleRadioProps> = (propsProxy) => {
	const checked = propsProxy.checked;

	const classList = [classes['input']];
	checked && classList.push(classes['input--checked']);
	const className = classList.join(' ');

	return <input className={className} type={'radio'} {...propsProxy} />;
};
