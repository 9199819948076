import dayjs from 'dayjs';

/**
 * Функция принимает две строки даты в качестве входных данных: одна представляет дату в UTC, а другая - локальную дату с GMT смещением.
 * @param {string} date Строка даты, представляющая дату и время в GMT.* @param {string} dateLocal (строка): Строка локальной даты, включающая GMT смещение, указывающее разницу во времени с GMT.
 * @returns {gmtLocal: number, dateStr: string, timeStr: string, timeStrLocal: str} Объект, содержащий:
 * @description gmtLocal (число): Смещение GMT локальной даты, извлеченное из dateLocal.
 * @description dateStr (строка): Форматированная строка даты в формате DD.MM.YY.
 * @description timeStr (строка): Форматированная строка времени в формате HH:mm, представляющая время по GMT.
 * @description timeStrLocal (строка): Форматированная строка времени в формате HH:mm, скорректированная с учетом локального часового пояса.
 */
export const dateParser = (date: string, dateLocal: string) => {
	const gmtLocal = +dateLocal.slice(-6, -3);
	const dateStr = dayjs(date).format('DD.MM.YY');
	const timeStr = dayjs(date).format('HH:mm:ss');
	const timeStrLocal = dayjs(dateLocal)
		.add(gmtLocal - 3, 'hour')
		.format('HH:mm:ss');

	return {
		gmtLocal,
		dateStr,
		timeStr,
		timeStrLocal,
	};
};
