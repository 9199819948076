import React, { useState } from 'react';

import { IconButton } from '@components/uikit/Button/IconButton';
import { Popover } from '@components/uikit/Popover/Popover';
import { EventDateTime } from './EventDateTime';
import { useEventsParams } from '@hooks/useEventsParams';

export const EventDateTimeWrapper = () => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const { filterParams, onDateTimeChange } = useEventsParams();

	return (
		<Popover
			content={
				<EventDateTime setIsOpen={setIsPopoverOpen} filterParams={filterParams} onDateTimeChange={onDateTimeChange} />
			}
			positions={['bottom', 'left', 'right']}
			padding={8}
			align={'end'}
			isOpen={isPopoverOpen}
			onClose={setIsPopoverOpen.bind(null, false)}
		>
			{({ toggleIsOpen }) => {
				return (
					<div className="relative">
						<IconButton
							onClick={setIsPopoverOpen.bind(null, !isPopoverOpen)}
							className={'flex-shrink-0'}
							icon={'calendar3Range'}
							variant={'grey'}
							size="l"
						/>
					</div>
				);
			}}
		</Popover>
	);
};
