import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { StepsHeader } from '@components/uikit/StepsHeader/StepsHeader';
import { ROUTER } from '@constants/router';
import { CameraCreateZone } from '@components/camera/CameraCreate/CameraCreateZone';

export const CameraCreateZonePage = () => {
	return (
		<DashboardLayout>
			<DashboardContentLayout
				showBackButton={false}
				drawDivider={false}
				overrideHeaderRenderer={
					<StepsHeader
						title={ROUTER.CAMERAS_CREATE_ZONE.TITLE}
						badges={[{ text: 'Зона детекции', color: 'tertiary', classes: '!text-primary-0' }, { text: 'Создание' }]}
					/>
				}
			>
				<CameraCreateZone />
			</DashboardContentLayout>
		</DashboardLayout>
	);
};
