import React, { FC, PropsWithChildren, useMemo } from 'react';
import { Icon, IconName } from '@components/uikit/Icon/Icon';

export type TooltipProps = PropsWithChildren & {
	icon?: IconName;
	dark?: boolean;
	error?: boolean;
	extraDark?: boolean;
};

const classNames = {
	default: {
		root: 'flex items-start gap-x-[8px] w-full',
		icon: 'text-secondary flex-shrink-0',
		text: 'font-body-medium text-secondary break-normal',
	},
	dark: {
		root: 'flex items-start gap-x-[8px]',
		icon: 'text-on-primary-fixed-variant flex-shrink-0',
		text: 'font-body-medium text-on-primary-fixed-variant break-normal',
	},
	error: {
		root: 'flex items-start gap-x-[8px]',
		icon: 'text-error flex-shrink-0',
		text: 'font-body-medium text-error break-normal',
	},
	extraDark: {
		root: 'flex items-center gap-x-[8px]',
		icon: 'flex-shrink-0',
		text: 'font-title-medium  break-normal',
	},
};

export const Tooltip: FC<TooltipProps> = ({ icon = 'info2Circle', children, dark, error, extraDark }) => {
	const classesType = useMemo(() => {
		if (dark) {
			return 'dark';
		} else if (error) {
			return 'error';
		} else if (extraDark) {
			return 'extraDark';
		} else {
			return 'default';
		}
	}, [dark, error, extraDark]);

	const classes = classNames[classesType];
	return (
		<div className={classes.root}>
			<Icon className={classes.icon} name={icon} width={20} height={20} />
			<p className={classes.text}>{children}</p>
		</div>
	);
};
