import React from 'react';
import { Icon } from '@components/uikit/Icon/Icon';
import { ToastContainer } from 'react-toastify';
import styles from './ToastProvider.module.css';
import { TOAST_ERROR_PROVIDER_ID, TOAST_PROVIDER_ID } from '@constants/toastProvider';

export const ToastProvider = () => {
	return (
		<>
			<ToastContainer
				enableMultiContainer
				containerId={TOAST_PROVIDER_ID}
				hideProgressBar
				className="p-2"
				autoClose={3000}
				closeButton={false}
				position="bottom-left"
				toastClassName={styles['toast']}
				icon={<Icon className={styles['icon']} name="info2Circle" />}
			/>
			<ToastContainer
				className={'p-0 w-max bottom-[40px]'}
				containerId={TOAST_ERROR_PROVIDER_ID}
				enableMultiContainer={true}
				autoClose={3000}
				closeButton={false}
				hideProgressBar={true}
				draggable={false}
				limit={1}
				position="bottom-center"
			/>
		</>
	);
};
