import { ReactComponent as Detector } from '@assets/illustrations/detector.svg';

export const MonitoringEventsLineList = () => {
	return (
		<div
			className={'w-full h-[calc(100vh-20px-32px*4-46px-50px)] flex flex-col gap-y-[32px] items-center justify-center'}
		>
			<Detector />
			<p style={{ wordBreak: 'break-word' }} className={'font-headline-medium '}>
				В данный момент события отсутствуют
			</p>
		</div>
	);
};
