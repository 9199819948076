import React from 'react';
import styles from './Checkbox.module.css';

export type CheckboxProps = {
	name: string;
	label: string;
	value?: string;
	checked?: boolean | undefined;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox = ({ checked, value, name, label, onChange }: CheckboxProps) => {
	return (
		<div className={styles.checkbox}>
			<label>
				<input checked={checked} type="checkbox" value={value} onChange={onChange} name={name} />

				<span className={styles.label}>{label}</span>
			</label>
		</div>
	);
};
