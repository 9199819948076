import React, { FC, useEffect, useMemo, useState } from 'react';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { RECOGNITION_LICENSE_PLATES_STEP_ROW_PRESET } from '@constants/recognition';
import { Button } from '@components/uikit/Button/Button';
import { TextInput } from '@components/uikit/TextInput/TextInput';
import { CountryType, LicensePlateInput, NumberType } from '@components/uikit/LicensePlateInput/LicensePlateInput';
import { Badge } from '@components/uikit/Badge/Badge';
import { Icon } from '@components/uikit/Icon/Icon';
import { SearchCar } from '@components/recognition/SearchCar/SearchCar';
import { Toggle } from '@components/uikit/Toggle/Toggle';
import { notify } from '@modules/toast/notify';
import { SearchFormStream } from '@components/recognition/SearchForm/SearchFormStream/SearchFormStream';
import { SearchFormExternalFile } from '@components/recognition/SearchForm/SearchFormExternalFile/SearchFormExternalFile';
import { v4 as uuidv4 } from 'uuid';
import { StepProps } from '@components/uikit/StepRow/Step/Step';
import { Tooltip } from 'react-tooltip';
import { StepRowProps } from '@components/uikit/StepRow/StepRow';
import {
	PushBucketPlateParams,
	useBucketExcludePlateMutation,
	useBucketPlatePushMutation,
	useGetBucketConfigQuery,
	useGetBucketPlatesQuery,
	useGetPlatesFromBucketsV1Query,
	useUpdateBucketMutation,
} from '@store/api';
import { notifyError } from '@modules/toast/notifyError';
import { Dropdown } from '@components/uikit/Dropdown/Dropdown';
import { useDebounceCallback } from '@hooks/useDebounceCallback';
import { SearchCarSmall } from '@components/recognition/SearchCar/SearchCarSmall/SearchCarSmall';
import { SearchFormArchive } from '@components/recognition/SearchForm/SearchFormArchive/SearchFormArchive';
import { PLATES_BUCKET_NAME } from '@constants/platesBucketName';
import { usePrefixBucket } from '@hooks/usePrefixBucket';
import { numberTypeRegexp } from '@constants/lisenseRegExp';
import { StepsHeader } from '@components/uikit/StepsHeader/StepsHeader';
import { ROUTER } from '@constants/router';

type Errors = {
	selectedNumbers?: string;
	sourceType?: string;
};

type SourceType = 'ARCHIVE' | 'FILE' | 'EXTERNAL_FILE' | 'STREAM';

export type LicensePlateConfig = unknown;

export type LicensePlateOutput = {
	selectedNumbers: unknown[];
	tags: string[];
	numbersToDetect: number;
	config: LicensePlateConfig;
	confidence: number;
};

export type LicensePlateStageProps = {
	onDone?: (output: LicensePlateOutput) => void;
	hidden?: boolean;
	onStepClick?: StepRowProps['onStepClick'];
	bucketName: string;
};

const steps = [
	{
		...RECOGNITION_LICENSE_PLATES_STEP_ROW_PRESET[0],
		type: 'active',
	},
	RECOGNITION_LICENSE_PLATES_STEP_ROW_PRESET[1],
];

type CarNumber = {
	id: string;
	tags: string[];
	car_number: string;
};

export type FoundCardNumber = {
	bucketName: string;
	plateId: number;
	plateText: string;
	tags: string[];
	identity: string;
};

export const LicensePlateStage: FC<LicensePlateStageProps> = ({ onDone, hidden, bucketName }) => {
	const [createCarNumber] = useBucketPlatePushMutation({});
	const [search, setSearch] = useState<string>();
	const [deleteFromBucket] = useBucketExcludePlateMutation();
	const [updateBucket] = useUpdateBucketMutation();
	const prefixBucket = usePrefixBucket();

	const onChange = async ({
		title,
		source_type,
		duration,
		notification,
		archive,
		confidence,
	}: {
		title: string;
		source_type: string;
		duration?: string;
		notification?: string;
		archive?: string;
		confidence: number;
	}) => {
		await updateBucket({
			bucket_name: bucketName,
			caption: title,
			tags: [
				`confidence:${confidence}`,
				'detectionType:plate',
				source_type,
				...(duration ? [duration] : []),
				...(notification ? [notification] : []),
				...(archive ? [archive] : []),
			],
		});
	};

	const { currentData: carNumbers } = useGetPlatesFromBucketsV1Query(
		{
			identity_text: search ?? '',
			bucket: prefixBucket + PLATES_BUCKET_NAME,
		},
		{ skip: search === undefined || prefixBucket === undefined },
	);

	const { currentData: bucket } = useGetBucketPlatesQuery(bucketName);
	const plates = (bucket?.plates ?? []).map((plate) => {
		return {
			bucketName: bucketName,
			plateId: plate.plate_id,
			plateText: plate.plate_text,
			identity: plate.identity,
			tags: plate.tags,
		};
	});

	// LICENSE PLATE INPUT
	const [licensePlate, setLicensePlate] = useState('');
	const [licensePlateType, setLicensePlateType] = useState('car' as NumberType);
	const [licensePlateCountry, setLicensePlateCountry] = useState('rus' as CountryType);

	const [tags, setTags] = useState<string[]>([]);
	const [tag, setTag] = useState<string>('');
	const [name, setName] = useState('');

	const isNumberExist = carNumbers?.data?.some((number) => {
		return number.plate_text === licensePlate;
	});

	/**
	 * Функция добавляет номер в картотеку, с возможностью сохранения в базу номеров
	 */
	const onAddLicensePlate = async (withSaving?: boolean) => {
		const regexp = numberTypeRegexp[licensePlateCountry][licensePlateType];

		if (!regexp.test(licensePlate)) {
			notify('Введённый номер не соответствует выбранному типу!');
			return;
		}

		// Обязательно требуем ввести не менее трёх знаков (помимо звёздочек)
		const hasEnoughSymbols = licensePlate.match(/[a-z0-9]/gi);
		if (!hasEnoughSymbols || hasEnoughSymbols.length < 3) {
			notify('Введите не менее трёх конкретных знаков номера!');
			return;
		}

		const params: PushBucketPlateParams = {
			params: {
				bucket_name: bucketName,
				identity: name.trim().length ? name : licensePlate,
				plate_text: licensePlate,
				tags: tags.length ? tags : undefined,
			},
		};

		try {
			const { identity, plate_text, tags } = await createCarNumber(params).unwrap();
			if (withSaving && prefixBucket) {
				await createCarNumber({
					params: {
						bucket_name: prefixBucket + PLATES_BUCKET_NAME,
						identity,
						plate_text,
						tags: tags.length ? tags : undefined,
					},
				});
			}
			setTags([]);
			setTag('');
			setLicensePlate('');
			setName('');
			setSelectedNumbers((prev) => {
				return prev;
			});
		} catch (e) {
			notifyError('Ошибка добавления номера в картотеку');
		}
	};

	// TAGS
	const onAddTag = (tag: string) => {
		if (tags.includes(tag)) {
			notify('Данный тег уже назначен!');
			return;
		}

		setTags((prev) => {
			return [...prev, tag];
		});
	};

	const onRemoveTag = (tag: string) => {
		setTags((prev) => {
			return prev.filter((item) => {
				return item !== tag;
			});
		});
	};

	//SEARCH
	const foundCarNumbers = carNumbers
		? carNumbers.data.reduce<{ plates: FoundCardNumber[]; nums: Set<number> }>(
				(acc, curr) => {
					if (
						plates.some((item) => {
							return item.plateText === curr.plate_text;
						})
					) {
						return acc;
					}

					if (!acc.nums.has(curr.plate_id)) {
						acc.plates = [
							...acc.plates,
							{
								bucketName: curr.bucket_name,
								plateId: curr.plate_id,
								plateText: curr.plate_text,
								tags: curr.tags,
								identity: curr.identity,
							},
						];
						acc.nums.add(curr.plate_id);
					}
					return acc;
				},
				{ nums: new Set<number>(), plates: [] },
		  ).plates
		: [];

	const [confidence, setConfidence] = useState(0.7);
	const [selectedNumbers, setSelectedNumbers] = useState<CarNumber[]>([]);

	const changeSearchQuery = useDebounceCallback((value: string) => {
		setSearch(value);
	}, 1000);

	const searchInBase: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		changeSearchQuery(event.currentTarget.value);
	};

	const addToBucket = async (plate: FoundCardNumber) => {
		const params: PushBucketPlateParams = {
			params: {
				bucket_name: bucketName,
				identity: plate.identity,
				plate_text: plate.plateText,
				tags: plate.tags.length ? plate.tags : undefined,
			},
		};

		const resp = await createCarNumber(params).unwrap();
		if ('error' in resp) {
			notifyError('Ошибка добавления номера в картотеку');
			return;
		} else {
			setLicensePlate('');
			setSelectedNumbers((prev) => {
				return prev;
			});
		}
	};

	const revomeFromBucket = async (plate: FoundCardNumber) => {
		const res = await deleteFromBucket({
			b_name: bucketName,
			p_id: plate.plateId,
		});

		if ('error' in res) {
			notifyError('Ошибка удаления номера из картотеки');
			return;
		}
	};

	// MIN NUMBERS TO DETECT

	const [numbersToDetect] = useState<number>(0);

	// SOURCE TYPE

	const [sourceType, setSourceType] = useState<Nullable<SourceType>>('STREAM');
	const { currentData: bucketConfig } = useGetBucketConfigQuery(bucketName);

	useEffect(() => {
		if (bucketConfig?.tags) {
			const type: SourceType = bucketConfig.tags.includes('stream') ? 'STREAM' : 'ARCHIVE';
			setSourceType(type);
		}
	}, [bucketConfig]);

	// SUBMISSION

	const [errors, setErrors] = useState<Errors>({});

	const validate = () => {
		const errors: Errors = {};

		if (!selectedNumbers.length) {
			errors.selectedNumbers = 'Выберите номера автомобилей для поиска!';
		}

		if (!sourceType) {
			errors.sourceType = 'Выберите тип источника данных!';
		}

		if (Object.keys(errors).length > 0) {
			setErrors(errors);
			return false;
		}

		setErrors({});
		return true;
	};

	const onSubmit = (config: LicensePlateConfig) => {
		const isValid = validate();

		if (!isValid) {
			return;
		}

		onDone?.({
			tags,
			selectedNumbers,
			numbersToDetect,
			config,
			confidence,
		});
	};

	const matchTooltipId = useMemo(() => {
		return 'license-plate-match-' + uuidv4();
	}, []);

	const hiddenClass = hidden ? 'absolute hidden' : '';

	return (
		<DashboardLayout className={hiddenClass}>
			<DashboardContentLayout
				overrideHeaderRenderer={
					<StepsHeader
						title={ROUTER.SEARCH_DATABASE_CREATE_LICENSE_PLATES.TITLE}
						badges={[{ text: 'Создание' }]}
						steps={steps as StepProps[]}
					/>
				}
				showBackButton={false}
				drawDivider={false}
			>
				<Tooltip
					anchorSelect={`#${matchTooltipId}`}
					place={'right'}
					content={'Есть совпадение по введенным вами данным в базе номеров.'}
					className={'z-50'}
				/>
				<div className={'flex flex-col gap-y-[20px] mt-[8px]'}>
					<section className={'flex gap-x-[24px]'}>
						<div className={'flex flex-col gap-y-[16px]'}>
							<div className={'flex gap-x-[8px]'}>
								<div className={'relative'}>
									<LicensePlateInput
										value={licensePlate}
										onChange={setLicensePlate}
										numberType={licensePlateType}
										onNumberTypeChange={(numberType) => {
											setLicensePlateType(numberType);
										}}
										numberCountry={licensePlateCountry}
										onCountryChange={(countryType) => {
											setLicensePlateCountry(countryType);
										}}
									/>
									{isNumberExist && (
										<div
											id={matchTooltipId}
											className={'absolute right-[-16px] top-[36px] p-[4px] rounded-[100%] bg-green z-10 !opacity-100'}
										>
											<Icon className={'text-primary-100'} name={'info'} width={16} height={16} />
										</div>
									)}
								</div>
								<Button
									onClick={() => {
										onAddLicensePlate(true);
									}}
									className={'h-[52px] mt-auto'}
									variant={'primary'}
									size={'xl'}
									disabled={isNumberExist}
								>
									Добавить номер в базу
								</Button>
								<Button
									className={'h-[52px] mt-auto'}
									variant={'dark'}
									size={'xl'}
									disabled={isNumberExist}
									onClick={() => {
										onAddLicensePlate();
									}}
								>
									Добавить без сохранения
								</Button>
							</div>
							<div className={'flex gap-3 min-w-[250px]'}>
								<TextInput
									className="!w-1/2"
									max={255}
									placeholder={'Введите название'}
									value={name}
									name={'name'}
									size={'s'}
									variant={'bordered'}
									onChange={(event) => {
										setName(event.target.value);
									}}
								/>
								<form
									className="w-1/2"
									onSubmit={(e) => {
										e.preventDefault();
										onAddTag(tag);
										setTag('');
									}}
								>
									<TextInput
										placeholder={'Присвоить тег'}
										value={tag}
										name={'title'}
										size={'s'}
										variant={'bordered'}
										icon={'listUl'}
										onChange={(event) => {
											setTag(event.target.value);
										}}
									/>
								</form>
							</div>
							<div className={'flex gap-[4px] flex-wrap'}>
								{tags.map((tag) => {
									return (
										<Badge color={'primary'} variant={'contained'} size={'lg'} key={tag}>
											{tag}
											<button onClick={onRemoveTag.bind(null, tag)}>
												<Icon name={'x'} width={16} height={16} className={'text-primary'} />
											</button>
										</Badge>
									);
								})}
							</div>
						</div>
						<div className={'h-[auto] w-[1px] bg-outline-variant'} />
						<div className={'flex flex-col gap-y-[16px]'}>
							<h2 className={'font-headline-xsmall text-on-background'}>Поиск номеров из базы</h2>
							<Dropdown
								header={
									<TextInput
										className={'!h-fit min-w-[300px]'}
										variant={'bordered'}
										icon={'search'}
										placeholder={'Поиск'}
										onChange={searchInBase}
									/>
								}
								body={
									<div className={'flex flex-col w-full gap-y-[12px] items-start'}>
										<div className={'flex flex-col w-full gap-y-[12px]'}>
											{foundCarNumbers.length === 0 && (
												<span className={'rounded-[8px] p-[10px_12px]'}>Не найдено</span>
											)}
											{foundCarNumbers.length > 0 &&
												foundCarNumbers.map((plate) => {
													return (
														<SearchCarSmall
															key={plate.plateId}
															title={plate.identity}
															identifier={plate.plateId.toString()}
															tags={plate.tags}
															onClick={addToBucket.bind(null, plate)}
														/>
													);
												})}
										</div>
									</div>
								}
							/>
						</div>
					</section>

					<div className={'flex flex-col w-full gap-y-[12px] items-start'}>
						<div className={'flex flex-col w-full gap-y-[12px]'}>
							{plates.map((plate) => {
								return (
									<SearchCar
										key={plate.plateId}
										className={'w-[952px]'}
										licensePlate={plate.identity}
										identifier={plate.plateId.toString()}
										tags={plate.tags}
										onCloseClick={revomeFromBucket.bind(null, plate)}
									/>
								);
							})}
						</div>
					</div>

					<div className={'h-[1px] bg-outline-variant'} />
					<h2 className={'font-headline-xsmall text-on-background mt-[16px]'}>Выберите метод анализа данных</h2>
					<div className={'flex gap-x-[32px]'}>
						<Toggle
							type={'radio'}
							label={'Поток'}
							checked={sourceType === 'STREAM'}
							value={'STREAM'}
							description={'Данные анализируются с прямой трансляции'}
							onChange={(e) => {
								setSourceType(e.target.value as SourceType);
							}}
							disabled={bucketConfig?.tags && sourceType !== 'STREAM'}
						/>
						<Toggle
							type={'radio'}
							label={'Архив'}
							checked={sourceType === 'ARCHIVE'}
							description={'Данные анализируются из нашего архива'}
							value={'ARCHIVE'}
							onChange={(e) => {
								setSourceType(e.target.value as SourceType);
							}}
							disabled={bucketConfig?.tags && sourceType !== 'ARCHIVE'}
						/>
						<Toggle
							type={'radio'}
							disabled={true}
							label={'Данные из внешнего ресурса'}
							checked={sourceType === 'EXTERNAL_FILE'}
							value={'EXTERNAL_FILE'}
							description={'Можете загрузить свои архивы и система проанализирует их'}
							onChange={(e) => {
								setSourceType(e.target.value as SourceType);
							}}
						/>
					</div>
					{(() => {
						const onConfidenceChange = (value: number) => {
							setConfidence(value);
						};

						switch (sourceType) {
							case 'ARCHIVE': {
								return (
									<SearchFormArchive
										onChange={onChange}
										bucketId={bucketName}
										amount={plates.length}
										type="plate"
										title="Название поиска номеров"
									/>
								);
							}
							case 'STREAM': {
								return (
									<SearchFormStream
										bucketId={bucketName}
										onChange={onChange}
										amount={plates.length}
										type="plate"
										title="Название поиска номеров"
									/>
								);
							}
							case 'EXTERNAL_FILE': {
								const onExternalFileChange = (config: unknown) => {
									onSubmit(config);
								};

								return (
									<SearchFormExternalFile
										onChange={onExternalFileChange}
										onChangeConfidence={onConfidenceChange}
										onValidate={validate}
									/>
								);
							}
							default:
								return errors.sourceType ? (
									<p className={'font-body-medium text-error'}>Выберите тип данных для обработки.</p>
								) : (
									<p className={'font-body-medium text-secondary'}>Выберите тип данных для обработки.</p>
								);
						}
					})()}
				</div>
			</DashboardContentLayout>
		</DashboardLayout>
	);
};
