import { EventDataFace, EventDataPlate } from './useEventsPage';
import { useEventsParams } from './useEventsParams';

/**
 * Хук содержит логику фильтрации событий
 */

export const useEventsFilter = () => {
	const { filterParams } = useEventsParams();

	const filterFacesHandler = (data: EventDataFace) => {
		if (!filterParams) {
			return true;
		}

		const { ageRange, gender, glasses, mask, race } = filterParams;

		if (data.age === null || (data.age && (data.age < ageRange[0] || data.age > ageRange[1]))) {
			return false;
		}

		if (data.gender === null || (data.gender && gender && data.gender !== gender)) {
			return false;
		}

		if (data.glasses === null || (data.glasses && glasses && data.glasses !== 'none' && glasses === 'none')) {
			return false;
		} else if (data.glasses && glasses && data.glasses === 'none' && glasses !== 'none') {
			return false;
		}

		if (data.glasses === null || (data.mask && mask && data.mask !== 'none' && mask === 'none')) {
			return false;
		} else if (data.mask && mask && data.mask === 'none' && mask !== 'none') {
			return false;
		}

		if (data.race && Boolean(race.length)) {
			return race.includes(data.race);
		} else if (data.race === null) {
			return;
		}

		return true;
	};

	const filterPlatesHandler = (data: EventDataPlate) => {
		if (!filterParams) {
			return true;
		}

		const { plate, vehicle } = data;
		const { section, overlay, quality, valid, plateCategory, driverLicense, color, vehicleCategory } = filterParams;

		if (section === 'faces') {
			return false;
		}

		if (plate) {
			const { occlusion, valid: plateValid, quality: plateQuality, category: plateCategoryValue } = plate;

			if (occlusion < overlay[0] || occlusion > filterParams?.overlay[1]) {
				return false;
			}

			if (plateQuality < quality[0] || plateQuality > quality[1]) {
				return false;
			}

			if (plateValid && valid === 'none') {
				return false;
			}

			if (!plateValid && valid !== 'none') {
				return false;
			}

			if (plateCategoryValue && plateCategory.length) {
				return plateCategory.includes(plateCategoryValue);
			}
		}

		if (vehicle) {
			const { category: vehicleCategoryValue, color: vehicleColor, purpose: vehiclePurpose } = vehicle;

			if (vehicleCategoryValue && driverLicense.length) {
				return driverLicense.includes(vehicleCategoryValue);
			}

			if (vehicleColor && color.length) {
				return color.includes(vehicleColor);
			}

			if (vehiclePurpose && vehicleCategory.length) {
				return vehicleCategory.includes(vehiclePurpose);
			}
		}

		return true;
	};

	return {
		filterParams,
		filterFacesHandler,
		filterPlatesHandler,
	};
};
