import { toast } from 'react-toastify';
import classes from '@modules/toast/notifyCameraError.module.css';
import { TOAST_ERROR_PROVIDER_ID } from '@constants/toastProvider';
import React from 'react';
import { ErrorAlert } from '@components/uikit/ErrorAlert/ErrorAlert';

export const notifyError = (message: string) => {
	const location = window.location;

	if (location.pathname.startsWith('/cameras')) {
		return;
	}

	return toast.error(<ErrorAlert>{message}</ErrorAlert>, {
		className: classes['toast'],
		bodyClassName: classes['body'],
		containerId: TOAST_ERROR_PROVIDER_ID,
		closeOnClick: false,
	});
};
