/**
 * Функция для замены букв номера автомобиля
 * @param str - номер автомобиля (например, 'a123bc193')
 * @param mapping - на какие буквы делать замену (например с английского на русский)
 * @return - строку с замененнными символами
 */
export const replaceLetters = (str: string, mapping: Record<string, string>) => {
	// Преобразуем строку в массив символов
	const strArray = str.split('');

	// Проходимся по каждому символу в массиве
	const replacedArray = strArray.map((char) => {
		// Ищем символ в объекте mapping и возвращаем его ключ (или сам символ, если не найден)
		for (const key in mapping) {
			if (mapping[key] === char) {
				return key;
			}
		}
		return char; // Если символ не найден в объекте, возвращаем его как есть
	});

	// Объединяем массив символов обратно в строку
	const result = replacedArray.join('');
	return result;
};
