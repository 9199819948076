import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

// Хук для установки начальных GET параметров (при первом рендере)
export function useInitialSearchParams(map: Record<string, string>) {
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const params = new URLSearchParams(searchParams);

		for (const [key, value] of Object.entries(map)) {
			const isSet = params.has(key);
			if (isSet) {
				continue;
			}
			params.set(key, value);
		}

		setSearchParams(params);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
