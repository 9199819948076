import React, { FC } from 'react';
import { Badge, BadgeProps } from '@components/uikit/Badge/Badge';

export type CameraIndicatorStatus = 'STOPPED' | 'UNKNOWN' | 'STARTING' | 'ACTIVE' | 'WARNING' | 'ERROR';

const badgePropsMap: Record<CameraIndicatorStatus, BadgeProps> = {
	ACTIVE: {
		size: 'lg',
		color: 'green',
		variant: 'filled',
		children: (
			<div className={'gap-x-[8px] flex items-center'}>
				<div className={'w-[13px] h-[13px] bg-[#3CAB77] rounded-[100%]'} />
				Активно
			</div>
		),
	},
	STARTING: {
		size: 'lg',
		color: 'primary',
		variant: 'contained',
		children: (
			<div className={'gap-x-[8px] flex items-center'}>
				<div className={'w-[13px] h-[13px] bg-primary rounded-[100%]'} />В ожидании
			</div>
		),
	},
	ERROR: {
		size: 'lg',
		color: 'error',
		variant: 'contained',
		children: (
			<div className={'gap-x-[8px] flex items-center'}>
				<div className={'w-[13px] h-[13px] bg-error rounded-[100%]'} />
				Ошибка
			</div>
		),
	},
	STOPPED: {
		size: 'lg',
		color: 'secondary',
		variant: 'filled',
		children: (
			<div className={'gap-x-[8px] flex items-center'}>
				<div className={'w-[13px] h-[13px] bg-on-primary rounded-[100%]'} />
				Остановлено
			</div>
		),
	},
	UNKNOWN: {
		size: 'lg',
		color: 'secondary',
		variant: 'filled',
		children: (
			<div className={'gap-x-[8px] flex items-center'}>
				<div className={'w-[13px] h-[13px] bg-on-primary rounded-[100%]'} />
				Неизвестно
			</div>
		),
	},
	WARNING: {
		size: 'lg',
		color: 'tertiary',
		variant: 'contained',
		children: (
			<div className={'gap-x-[8px] flex items-center'}>
				<div className={'w-[13px] h-[13px] bg-tertiary-80 rounded-[100%]'} />
				Проблема
			</div>
		),
	},
};

export type CameraIndicatorProps = {
	status: CameraIndicatorStatus;
	size?: BadgeProps['size'];
};

export const CameraIndicator: FC<CameraIndicatorProps> = ({ status, size }) => {
	if (!status) {
		return null;
	}

	const statusProps = badgePropsMap[status];
	const propsProxy = {
		...statusProps,
		size: size ?? statusProps.size,
	};

	return <Badge className={'!rounded-[8px]'} {...propsProxy} />;
};
