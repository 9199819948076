import { useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

/**
 * Хук для отслеживания состояния проигрывания видео.
 */
export function usePlayState() {
	const [playing, setPlaying] = useState(false);
	const [error, setError] = useState<unknown>();
	const [isFatalError, setIsFatalError] = useState(false);

	// Метод для синхронизации состояния с новым плеером, либо при повторной попытке запуске после ошибки
	const reloadSync = (reactPlayer: ReactPlayer) => {
		const internalPlayer = reactPlayer.getInternalPlayer() as HTMLVideoElement | undefined;
		if (!internalPlayer) {
			return;
		}

		// https://stackoverflow.com/questions/6877403/how-to-tell-if-a-video-element-is-currently-playing
		const isPlaying =
			internalPlayer.currentTime > 0 &&
			!internalPlayer.paused &&
			!internalPlayer.ended &&
			internalPlayer.readyState > 2;

		setPlaying(isPlaying);
		setError(undefined);
		setIsFatalError(false);
	};

	const onStart: ReactPlayerProps['onStart'] = () => {
		!playing && setPlaying(true);
	};

	const onPlay: ReactPlayerProps['onPlay'] = () => {
		!playing && setPlaying(true);
	};

	const onPause: ReactPlayerProps['onPause'] = () => {
		playing && setPlaying(false);
	};

	const onEnded: ReactPlayerProps['onEnded'] = () => {
		playing && setPlaying(false);
	};

	const onError: ReactPlayerProps['onError'] = (e, data) => {
		setError(data);
		if (data?.fatal === true) {
			console.warn('[usePlayState] Фатальная ошибка проигрывателя:', data);
			setIsFatalError(true);
			playing && setPlaying(false);
		}
	};

	return {
		playing,
		error,
		isFatalError,
		reloadSync,
		props: {
			onStart,
			onPlay,
			onPause,
			onEnded,
			onError,
		},
	};
}
