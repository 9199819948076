import React, { FC, useMemo, useState } from 'react';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { Button } from '@components/uikit/Button/Button';
import { TextInput } from '@components/uikit/TextInput/TextInput';
import { CountryType, LicensePlateInput, NumberType } from '@components/uikit/LicensePlateInput/LicensePlateInput';
import { Badge } from '@components/uikit/Badge/Badge';
import { Icon } from '@components/uikit/Icon/Icon';
import { notify } from '@modules/toast/notify';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from 'react-tooltip';
import { PushBucketPlateParams, useBucketPlatePushMutation, useGetPlatesFromBucketsV1Query } from '@store/api';
import { notifyError } from '@modules/toast/notifyError';
import { PLATES_BUCKET_NAME } from '@constants/platesBucketName';
import { usePrefixBucket } from '@hooks/usePrefixBucket';
import { numberTypeRegexp } from '@constants/lisenseRegExp';
import { StepsHeader } from '@components/uikit/StepsHeader/StepsHeader';

export type LicensePlateConfig = unknown;

export type LicensePlateOutput = {
	selectedNumbers: unknown[];
	tags: string[];
	numbersToDetect: number;
	config: LicensePlateConfig;
};

export type LicensePlateStageProps = {
	hidden?: boolean;
	bucketName: string;
	type?: 'recognition' | 'search';
};

export type FoundCardNumber = {
	bucketName: string;
	plateId: number;
	plateText: string;
	tags: string[];
	identity: string;
};

export const LicensePlateStage: FC<LicensePlateStageProps> = ({ hidden, bucketName, type = 'search' }) => {
	const [createCarNumber] = useBucketPlatePushMutation({});
	const prefixBucket = usePrefixBucket();

	const { currentData: carNumbers } = useGetPlatesFromBucketsV1Query(
		{
			bucket: prefixBucket + PLATES_BUCKET_NAME,
		},
		{ skip: prefixBucket === undefined },
	);

	// LICENSE PLATE INPUT
	const [licensePlate, setLicensePlate] = useState('');
	const [licensePlateType, setLicensePlateType] = useState('car' as NumberType);
	const [licensePlateCountry, setLicensePlateCountry] = useState('rus' as CountryType);

	const [tags, setTags] = useState<string[]>([]);
	const [tag, setTag] = useState<string>('');
	const [name, setName] = useState('');

	const isNumberExist = carNumbers?.data?.some((number) => {
		return number.plate_text === licensePlate;
	});

	/**
	 * Функция добавляет номер в картотеку, с возможностью сохранения в базу номеров
	 */
	const onAddLicensePlate = async (withSaving?: boolean) => {
		const regexp = numberTypeRegexp[licensePlateCountry][licensePlateType];

		if (!regexp.test(licensePlate)) {
			notify('Введённый номер не соответствует выбранному типу!');
			return;
		}

		// Обязательно требуем ввести не менее трёх знаков (помимо звёздочек)
		const hasEnoughSymbols = licensePlate.match(/[a-z0-9]/gi);
		if (!hasEnoughSymbols || hasEnoughSymbols.length < 3) {
			notify('Введите не менее трёх конкретных знаков номера!');
			return;
		}

		const params: PushBucketPlateParams = {
			params: {
				bucket_name: prefixBucket + PLATES_BUCKET_NAME,
				identity: name.trim().length ? name : licensePlate,
				plate_text: licensePlate,
				tags: tags.length ? tags : undefined,
			},
		};

		try {
			if (withSaving && prefixBucket) {
				await createCarNumber(params);
			}
			setLicensePlate('');
			setName('');
			setTags([]);
			setTag('');
			notify('Номер добавлен в базу');
		} catch (e) {
			notifyError('Ошибка добавления номера в базу');
		}
	};

	// TAGS
	const onAddTag = (tag: string) => {
		if (tags.includes(tag)) {
			notify('Данный тег уже назначен!');
			return;
		}

		setTags((prev) => {
			return [...prev, tag];
		});
	};

	const onRemoveTag = (tag: string) => {
		setTags((prev) => {
			return prev.filter((item) => {
				return item !== tag;
			});
		});
	};

	const matchTooltipId = useMemo(() => {
		return 'license-plate-match-' + uuidv4();
	}, []);

	const hiddenClass = hidden ? 'absolute hidden' : '';

	return (
		<DashboardLayout className={hiddenClass}>
			<DashboardContentLayout
				overrideHeaderRenderer={
					<StepsHeader title={'Добавление номеров в базу данных'} badges={[{ text: 'Создание' }]} />
				}
				showBackButton={false}
				drawDivider={false}
			>
				<Tooltip
					anchorSelect={`#${matchTooltipId}`}
					place={'right'}
					content={'Есть совпадение по введенным вами данным в базе номеров.'}
					className={'z-50'}
				/>
				<div className={'flex flex-col gap-y-[20px] mt-[8px]'}>
					<section className={'flex gap-x-[24px]'}>
						<div className={'flex flex-col gap-y-[16px]'}>
							<div className={'flex gap-x-[8px]'}>
								<div className={'relative'}>
									<LicensePlateInput
										value={licensePlate}
										onChange={setLicensePlate}
										numberType={licensePlateType}
										onNumberTypeChange={(numberType) => {
											setLicensePlateType(numberType);
										}}
										numberCountry={licensePlateCountry}
										onCountryChange={(countryType) => {
											setLicensePlateCountry(countryType);
										}}
									/>
									{isNumberExist && (
										<div
											id={matchTooltipId}
											className={'absolute right-[-16px] top-[36px] p-[4px] rounded-[100%] bg-green z-10 !opacity-100'}
										>
											<Icon className={'text-primary-100'} name={'info'} width={16} height={16} />
										</div>
									)}
								</div>
								<Button
									onClick={() => {
										onAddLicensePlate(true);
									}}
									className={'h-[52px] mt-auto'}
									variant={'primary'}
									size={'xl'}
									disabled={isNumberExist}
								>
									Добавить номер в базу
								</Button>
								{type === 'search' && (
									<Button
										className={'h-[52px] mt-auto'}
										variant={'dark'}
										size={'xl'}
										disabled={isNumberExist}
										onClick={() => {
											onAddLicensePlate();
										}}
									>
										Добавить без сохранения
									</Button>
								)}
							</div>
							<div className={'flex gap-3 min-w-[250px]'}>
								<TextInput
									className="!w-1/2"
									max={255}
									placeholder={'Введите название'}
									value={name}
									name={'name'}
									size={'s'}
									variant={'bordered'}
									onChange={(event) => {
										setName(event.target.value);
									}}
								/>
								<form
									className="w-1/2"
									onSubmit={(e) => {
										e.preventDefault();
										onAddTag(tag);
										setTag('');
									}}
								>
									<TextInput
										placeholder={'Присвоить тег'}
										value={tag}
										name={'title'}
										size={'s'}
										variant={'bordered'}
										icon={'listUl'}
										onChange={(event) => {
											setTag(event.target.value);
										}}
									/>
								</form>
							</div>
							<div className={'flex gap-[4px] flex-wrap'}>
								{tags.map((tag) => {
									return (
										<Badge color={'primary'} variant={'contained'} size={'lg'} key={tag}>
											{tag}
											<button onClick={onRemoveTag.bind(null, tag)}>
												<Icon name={'x'} width={16} height={16} className={'text-primary'} />
											</button>
										</Badge>
									);
								})}
							</div>
						</div>
					</section>
				</div>
			</DashboardContentLayout>
		</DashboardLayout>
	);
};
