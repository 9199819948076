import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { Button } from '@components/uikit/Button/Button';
import { DetectionPreview } from '@components/uikit/DetectionPreview/DetectionPreview';
import { StepsHeader } from '@components/uikit/StepsHeader/StepsHeader';
import { ROUTER } from '@constants/router';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const CameraCreateTypePage = () => {
	const [type, setType] = useState<'zone' | 'line' | undefined>();
	const [objects, setObjects] = useState<string[]>([]);

	const handleObj = (value: string) => {
		return objects.includes(value)
			? setObjects((prev) => {
					return prev.filter((item) => {
						return item !== value;
					});
			  })
			: setObjects((prev) => {
					return [...prev, value];
			  });
	};

	const createZoneParams = () => {
		const urlParams = new URLSearchParams();
		urlParams.append('objects', objects.join(','));
		return urlParams.toString();
	};

	return (
		<DashboardLayout>
			<DashboardContentLayout
				showBackButton={false}
				drawDivider={false}
				overrideHeaderRenderer={<StepsHeader title={ROUTER.CAMERAS_CREATE.TITLE} badges={[{ text: 'Создание' }]} />}
			>
				<div className={'mt-6 flex flex-col gap-[56px]'}>
					<h2 className={'font-headline-large'}>Выберите тип детекции</h2>

					<div className={'flex gap-8 w-[calc(400px*2+32px)]'}>
						<DetectionPreview
							onClick={() => {
								setType('zone');
							}}
							title="Зона детекции"
							description="Выставите зону детекции для обнаружения необходимой вам информации"
							icon="areaDetection"
							isActive={type === 'zone'}
						/>

						<DetectionPreview
							onClick={() => {
								setType('line');
							}}
							title="Линии детекции"
							description="Выставите линии и считайте количество проходящих по ним людей"
							icon="lineDetection"
							isActive={type === 'line'}
						/>
					</div>

					{type === 'zone' && (
						<>
							<h2 className={'font-headline-large'}>Выберите один или несколько объектов детекции</h2>
							<div className={'flex gap-8 w-[calc(450px*2+32px)]'}>
								<DetectionPreview
									onClick={() => {
										handleObj('face');
									}}
									size="s"
									title="Детекция лиц"
									description="Детектирует нахождение людей и лиц в выбраной зоне"
									icon="peopleDetection"
									isActive={objects.includes('face')}
								/>

								<DetectionPreview
									onClick={() => {
										handleObj('plate');
									}}
									size="s"
									title="Детекция авто"
									description="Детектирует автомобильные номера различных типов"
									icon="carDetection"
									isActive={objects.includes('plate')}
								/>
							</div>
						</>
					)}

					{type === 'zone' && Boolean(objects.length) && (
						<Link to={`${ROUTER.CAMERAS_CREATE_ZONE.PATH}?${createZoneParams()}`}>
							<Button className="w-max">Продолжить</Button>
						</Link>
					)}

					{type === 'line' && (
						<Link to={ROUTER.CAMERAS_CREATE_LINES.PATH}>
							<Button className="w-max">Продолжить</Button>
						</Link>
					)}
				</div>
			</DashboardContentLayout>
		</DashboardLayout>
	);
};
