import { useStoreSelector } from '@store/store';
import { EventSettingsWithImage } from '../EventSettings/EventSettings';
import { EventSettingsPlateWithImage } from '../EventSettings/EventPlateSettings';
import React from 'react';

export const EventDetatils = () => {
	const activeEvent = useStoreSelector((state) => {
		return state.events.activeEvent;
	});

	const selectedEvent = activeEvent;
	return (
		<>
			{selectedEvent !== undefined &&
				(selectedEvent.type === 'face' ? (
					<EventSettingsWithImage
						eventId={selectedEvent.id}
						camera={selectedEvent.camera_name}
						title={selectedEvent.identity}
						age={selectedEvent.age}
						gender={selectedEvent.gender}
						glasses={selectedEvent.glasses}
						facial_hair={selectedEvent.facial_hair}
						mask={selectedEvent.mask}
						race={selectedEvent.race}
						date={selectedEvent.timestamp}
						dateLocal={selectedEvent.timestamp_local}
						box={selectedEvent.box}
					/>
				) : (
					<EventSettingsPlateWithImage
						eventId={selectedEvent.id}
						title={selectedEvent.identity}
						date={selectedEvent.timestamp}
						dateLocal={selectedEvent.timestamp_local}
						box={selectedEvent.box}
						camera={selectedEvent.camera_name}
						category={selectedEvent.category}
						color={selectedEvent.vehicle?.color}
						purpose={selectedEvent.vehicle?.purpose}
						categoryVehicle={selectedEvent.vehicle?.category}
						country={selectedEvent.country}
						plate={selectedEvent.plate}
						qualityVehicle={selectedEvent.vehicle?.visibility}
						occlusion={selectedEvent.occlusion}
						valid={selectedEvent.valid}
						quality={selectedEvent.quality}
					/>
				))}
		</>
	);
};
