export const timezones = [
	{ label: 'UTC', value: '0' },
	{ label: 'UTC+1', value: '3600' },
	{ label: 'UTC+2', value: '7200' },
	{ label: 'UTC+3', value: '10800' },
	{ label: 'UTC+4', value: '14400' },
	{ label: 'UTC+5', value: '18000' },
	{ label: 'UTC+6', value: '21600' },
	{ label: 'UTC+7', value: '25200' },
	{ label: 'UTC+8', value: '28800' },
	{ label: 'UTC+9', value: '32400' },
	{ label: 'UTC+10', value: '36000' },
	{ label: 'UTC+11', value: '39600' },
	{ label: 'UTC+12', value: '43200' },
	{ label: 'UTC-1', value: '-3600' },
	{ label: 'UTC-2', value: '-7200' },
	{ label: 'UTC-3', value: '-10800' },
	{ label: 'UTC-4', value: '-14400' },
	{ label: 'UTC-5', value: '-18000' },
	{ label: 'UTC-6', value: '-21600' },
	{ label: 'UTC-7', value: '-25200' },
	{ label: 'UTC-8', value: '-28800' },
	{ label: 'UTC-9', value: '-32400' },
	{ label: 'UTC-10', value: '-36000' },
	{ label: 'UTC-11', value: '-39600' },
	{ label: 'UTC-12', value: '-43200' },
];
