import React, { FC } from 'react';
import { DashboardLayout } from '@components/layouts/DashboardLayout/DashboardLayout';
import { DashboardContentLayout } from '@components/layouts/DashboardContentLayout/DashboardContentLayout';
import { ROUTER } from '@constants/router';
import { RecognitionDatabaseTable } from '@components/recognition/RecognitionDatabaseTable/RecognitionDatabaseTable';
import { QueryTab } from '@components/uikit/Tab/QueryTab';
import { SearchDatabaseSearch } from '@components/search-database/SearchDatabaseSearch/SearchDatabaseSearch';
import { RecognitionCreateButton } from '@components/recognition/RecognitionCreateButton/RecognitionCreateButton';

export const RecognitionDatabaseOverviewPage: FC = () => {
	return (
		<DashboardLayout>
			<DashboardContentLayout
				headerContent={
					<div className={'flex bg-primary-95 p-2 rounded-3 gap-1'}>
						<QueryTab variant={'pill'} queryName={'section'} matchOn={'masks'} pagination={true}>
							База лиц
						</QueryTab>
						<QueryTab variant={'pill'} queryName={'section'} matchOn={'plates'} pagination={true}>
							База номеров
						</QueryTab>
					</div>
				}
				title={ROUTER.RECOGNITION_DATABASE.TITLE}
				showBackButton={false}
				drawDivider={false}
			>
				<div className={'flex flex-col gap-y-[24px]'}>
					<section className={'flex justify-between gap-x-[20px] w-full'}>
						<SearchDatabaseSearch />
						<div>
							<RecognitionCreateButton />
						</div>
					</section>
					<section>
						<RecognitionDatabaseTable />
					</section>
				</div>
			</DashboardContentLayout>
		</DashboardLayout>
	);
};
