import React, { FC } from 'react';
import { LicensePlateStage } from '@pages/recognition-database/config/LicensePlateStage';
import { useBucketName } from '@hooks/useBucketName';

export const RecognitionDatabaseCreateLicensePlatesConfigPage: FC = () => {
	const { bucketName } = useBucketName();
	return (
		<>
			{bucketName !== undefined && (
				<>
					<LicensePlateStage bucketName={bucketName} type="recognition" />
				</>
			)}
		</>
	);
};
