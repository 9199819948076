import React, { useCallback, useEffect } from 'react';
import { useKeypressEventListener } from '@hooks/useKeypressEventListener';
import { DefaultWrapper, PopoverChildrenRendererArgument, PopoverProps } from '@components/uikit/Popover/Popover';
import classes from '@components/uikit/Popover/Popover.module.css';

const predefinedProps: Partial<PopoverProps> = {
	align: 'start',
	positions: ['top', 'bottom', 'left', 'right'],
	padding: 16,
};

export function usePopover({
	children: propChildren,
	containerClassName,
	onClose,
	shouldCloseOnClickOutside = true,
	isOpen: propIsOpen,
	ToggleWrapper = DefaultWrapper,
	...propsProxy
}: PopoverProps) {
	const [isOpen, reactSetIsOpen] = React.useState(!!propIsOpen);

	// Закрываем поповер по нажатию на Escape
	useKeypressEventListener((event) => {
		const { code } = event;
		if (code !== 'Escape') {
			return;
		}
		setIsOpen(false);
	});

	// Закрываем поповер по клику вне его
	const onClickOutside = () => {
		const isFn = typeof shouldCloseOnClickOutside === 'function';

		if (isFn && shouldCloseOnClickOutside?.() === false) {
			return;
		}

		if (!shouldCloseOnClickOutside) {
			return;
		}

		setIsOpen(false);
	};

	// Закрываем поповер вместе с вызовом onClose
	const setIsOpen = useCallback(
		(value: boolean) => {
			!value && onClose?.();
			reactSetIsOpen(value);
		},
		[onClose],
	);

	// Переключатель открытия/закрытия поповера
	const toggleIsOpen = () => {
		setIsOpen(!isOpen);
	};

	// Синхронизируем isOpen с внешним значением
	useEffect(() => {
		if (typeof propIsOpen === 'undefined' || isOpen === propIsOpen) {
			return;
		}
		setIsOpen(propIsOpen);
	}, [isOpen, propIsOpen, setIsOpen]);

	const className = [classes['container'], containerClassName].filter(Boolean).join(' ');
	const throughProps = { ...predefinedProps, ...propsProxy };

	const internalState: PopoverChildrenRendererArgument = { isOpen, setIsOpen, toggleIsOpen };

	const children = typeof propChildren === 'function' ? propChildren(internalState) : propChildren;

	return {
		className,
		isOpen,
		children,
		onClickOutside,
		propsProxy: throughProps,
		Wrapper: ToggleWrapper,
	};
}
