import React, { FC, MouseEvent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tab, TabProps } from '@components/uikit/Tab/Tab';

export type QueryTabProps = TabProps & {
	queryName: string;
	matchOn: string;
	pagination?: boolean;
};

/**
 * Компонент таба, который при клике на неактивный таб назначает GET параметр
 * @param queryName - Название GET параметра
 * @param matchOn - Значение GET параметра по которому будет происходить сравнение
 * @param onClick - Обработчик клика
 * @param propsProxy - Проксируемые свойства для Tab
 */
export const QueryTab: FC<QueryTabProps> = ({ queryName, matchOn, onClick, pagination, ...propsProxy }) => {
	const [searchParams, setSearchParams] = useSearchParams();

	const isActive = searchParams.get(queryName) === matchOn;

	const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
		// При клике на активный таб, назначение GET параметра не происходит
		if (isActive) {
			onClick && onClick(e);
			return;
		}
		// При клике на неактивный таб происходит назначение GET параметра
		setSearchParams((searchParams) => {
			const params = new URLSearchParams(searchParams);
			params.set(queryName, matchOn);
			if (pagination) params.set('page', '1');

			return params;
		});

		// Вызов переданного onClick
		onClick && onClick(e);
	};

	return <Tab active={isActive} onClick={handleClick} {...propsProxy} />;
};
