import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/query';
import appSlice from '@store/slices/appSlice';
import { api } from '@store/api';
import { authSlice } from '@store/slices/authSlice';
import faceSearch from '@store/slices/faceSearchSlice';
import { monitoringSlice } from './slices/monitoringSlice';
import { eventsSlice } from './slices/eventsSlice';
import { camerasParamsSlice } from './slices/camerasParamsSlice';
import { eventsListSlice } from './slices/eventListSlice';
import { apiPeopleCounter } from './ApiPeopleCounter/apiPeopleCounter';

export const store = configureStore({
	reducer: {
		app: appSlice.reducer,
		api: api.reducer,
		apiPeopleCounter: apiPeopleCounter.reducer,
		auth: authSlice.reducer,
		faceSearch: faceSearch.reducer,
		monitoring: monitoringSlice.reducer,
		events: eventsSlice.reducer,
		eventsList: eventsListSlice.reducer,
		camerasParams: camerasParamsSlice.reducer,
	},
	devTools: true,
	middleware(getDefaultMiddleware) {
		return getDefaultMiddleware().concat([api.middleware, apiPeopleCounter.middleware]);
	},
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type StoreRootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type StoreDispatch = typeof store.dispatch;

// Create typed versions of the useDispatch and useSelector
// Use throughout app instead of plain `useSelector` and `useDispatch`
export const useStoreSelector: TypedUseSelectorHook<StoreRootState> = useSelector;
export const useStoreDispatch: () => StoreDispatch = useDispatch;
